const { isString } = require('./type');

/**
 * Returns an array with the parameters in the url
 * @param {string} url Optional url. If not given, window.location used.
 * @returns {array} Array with the parameters, ex. [0] "lang=es"
 */
function getUrlParamsArray(url) {
    var queryString = url ? url.split('?')[1] : window.location.search.substring(1);
    var urlParams = queryString ? queryString.split('&') : null;
    return urlParams;
}

/**
 * Returns a string from de params array, using '&' to concat
 * @param {array} params Array with parameters to reformat
 * @returns {string} Params as url queryString (without initial '?')
 */
function paramArrayToString(params) {
    var string = '';
    params.forEach(function (item, index, array) {
        if (index === array.length - 1) {
            string += item;
        } else {
            string += item + '&';
        }
    });
    return string;
}

/**
 * Returns the query string from URL if it exists
 * @param {string} url URL
 * @returns {string|null} QueryString or null
 */
function getQueryStringFromUrl(url) {
    return url.split('?').length > 1 ? url.split('?')[1] : null;
}

/**
 * Returns the value of the param if it exists
 * @param {string} searchedParam Url param to search for
 * @param {string} [queryString] Optional. QueryString to search in, window.location.search instead
 * @returns {string|null} Value of the param if it exists
 */
function getUrlParameter(searchedParam, queryString) {
    var url = queryString || window.location.search.substring(1);
    var urlParams = url.split('&');
    var urlParam;

    var paramString = urlParams.find(param => param.indexOf(searchedParam + '=') === 0);
    if (typeof paramString !== 'undefined') {
        urlParam = paramString.split('=');
        if (typeof urlParam[1] !== 'undefined') {
            return decodeURIComponent(urlParam[1]);
        }
    }

    return null;
}

/**
 * Returns true if the given url is absolute. Otherwise, false.
 * @param {string} url url to check
 * @returns {boolean} true if the url is absolute
 */
const isAbsoluteUrl = (url) => url.indexOf('http') === 0;

/**
 * Returns true if the given url is relative. Otherwise, false.
 * @param {string} url url to check
 * @returns {boolean} true if the url is relative
 */
const isRelativeUrl = (url) => !isAbsoluteUrl(url);

/**
 * Factory function for an URL object
 * @param {string|URL} url string or URL
 * @returns {URL} URL object
 */
const createURL = (url) => {
    let result = url;
    if (isString(url)) {
        result = isRelativeUrl(url)
            ? new URL(url, window.location.origin)
            : new URL(url);
    }
    return result;
};

/**
 * Parse an url to a absolute url
 * @param {string|URL} url url to parse
 * @returns {string} absolute url
 */
const parseAbsoluteUrl = (url) => {
    const urlObj = createURL(url);
    return urlObj.toString();
};

/**
 * Parse an url to a relative url
 * @param {string|URL} url url to parse
 * @returns {string} relative url
 */
const parseRelativeUrl = (url) => {
    const urlObj = createURL(url);
    return urlObj.pathname + urlObj.search + urlObj.hash;
};

/**
 * Removes the given param (and it's value) from the given querystring, if it exists
 * @param {string} queryString QueryString to update
 * @param {string} param Param to search for
 * @returns {string} new querystring with param removed, or initial querystring
 */
function removeParamInQueryString(queryString, param) {
    var reg = new RegExp('\\b' + param + '=([^&]+)', 'g'); // Finds searched param (full word) and value (until end or &); ex. page=1
    var updatedQueryString = queryString;
    if (reg.test(queryString)) {
        var matches = queryString.match(reg);
        matches.forEach(function (item) {
            var itemReg = new RegExp('\\b&?' + item + '&?'); // ej. &param=1, &param=11&, param=21&
            if (updatedQueryString.match(itemReg).index === (updatedQueryString.length - item.length - 1) || updatedQueryString.match(itemReg).index === 0) {
                // first or last item, remove '&' if it has (before or after)
                updatedQueryString = updatedQueryString.replace(itemReg, '');
            } else {
                // middle param, remove param and both '&', and puts one '&' instead
                updatedQueryString = updatedQueryString.replace(itemReg, '&');
            }
        });
        return updatedQueryString;
    }

    return queryString;
}

/**
 * Removes sort related params start and sz.
 * @param {string} queryString QueryString to be updated
 * @returns {string} new querystring with no start/sz params, with no initial '?'
 */
function removeStartSzParams(queryString) {
    var reg = new RegExp('(start|sz)(=([^&]+))?', 'g');
    var updatedQueryString = queryString;
    if (reg.test(queryString)) {
        var matches = queryString.match(reg);
        matches.forEach(function (item) {
            var itemReg = new RegExp('&?' + item + '&?'); // ej. &start=1, &start=11&, sz=21&
            if (updatedQueryString.match(itemReg).index === (updatedQueryString.length - item.length - 1) || updatedQueryString.match(itemReg).index === 0) {
                // first or last item, remove '&' if it has (before or after)
                updatedQueryString = updatedQueryString.replace(itemReg, '');
            } else {
                // middle param, remove param and both '&', and puts one '&' instead
                updatedQueryString = updatedQueryString.replace(itemReg, '&');
            }
        });
    }
    return updatedQueryString;
}

/**
 * Removes filter related params: cgid, prefnX, prefvX, pmin, pmax
 * @param {string} queryString QueryString to be updated
 * @returns {string} new querystring with no filter params, with no initial '?'
 */
function removeFilterParams(queryString) {
    var reg = new RegExp('(cgid|prefn[0-9]|prefv[0-9]|pmin|pmax|srule)(=([^&]+))?', 'g'); // Finds filter params; ex. cgid, prefnX, prefvX.
    var updatedQueryString = queryString;
    if (reg.test(queryString)) {
        var matches = queryString.match(reg);
        matches.forEach(function (item) {
            var itemReg = new RegExp('&?' + item + '&?'); // ej. &cgid=1, &cgid=11&, cgid=21&
            if (updatedQueryString.match(itemReg).index === (updatedQueryString.length - item.length - 1) || updatedQueryString.match(itemReg).index === 0) {
                // first or last item, remove '&' if it has (before or after)
                updatedQueryString = updatedQueryString.replace(itemReg, '');
            } else {
                // middle param, remove param and both '&', and puts one '&' instead
                updatedQueryString = updatedQueryString.replace(itemReg, '&');
            }
        });
    }

    return updatedQueryString;
}

/**
 * Updates the given querystring, with the new value of the param if it exists.
 *  If doesn't exist, it returns the initial querystring.
 * @param {string} queryString QueryString to update
 * @param {string} param Url param to search for
 * @param {string} value New value of the param
 * @returns {string} new querystring with param updated, but with no initial '?'
 */
function updateParamInQueryString(queryString, param, value) {
    var reg = new RegExp('\\b' + param + '=([^&]*)'); // Finds searched param (full word) and value (until end or &); ex. page=1
    var updatedQueryString;
    if (reg.test(queryString)) {
        updatedQueryString = queryString.replace(reg, param + '=' + value);
    }
    return updatedQueryString || queryString;
}

/**
 * Updates de query string, adding a new param or updating it
 * @param {string} param Url param to create or update
 * @param {string} value Value of the param
 * @param {string} queryString queryString to search in, with no '?'
 * @returns {string} new updated queryString
 */
function updateOrAddParamToQuerystring(param, value, queryString) {
    var paramsArray = queryString.split('&');
    var newQuerystring;

    if (!paramsArray || paramArrayToString(paramsArray) === '') {
        newQuerystring = param + '=' + value;
    } else if (getUrlParameter(param, queryString)) {
        newQuerystring = updateParamInQueryString(paramArrayToString(paramsArray), param, value);
    } else {
        newQuerystring = paramArrayToString(paramsArray) + '&' + param + '=' + value;
    }
    return newQuerystring || null;
}

/**
 * Returns a new url after adding or modifing its search params with the given
 * object (param/value pairs).
 *
 * @param {string} url URL (absolute or relative)
 * @param {Object} params param/value pairs to set
 * @returns {string}  Modified url
 */
function urlWithSearchParams(url, params) {
    const isRelative = isRelativeUrl(url);
    const urlObj = createURL(url);
    Object.keys(params).forEach((param) => urlObj.searchParams.set(param, params[param]));
    return isRelative
        ? parseRelativeUrl(urlObj) // Return a relative url as the funtion received
        : parseAbsoluteUrl(urlObj); // Return an absolute url
}

module.exports = {
    getQueryStringFromUrl: getQueryStringFromUrl,
    getUrlParamsArray: getUrlParamsArray,
    getUrlParameter: getUrlParameter,
    paramArrayToString: paramArrayToString,
    removeFilterParams: removeFilterParams,
    removeStartSzParams: removeStartSzParams,
    removeParamInQueryString: removeParamInQueryString,
    updateParamInQueryString: updateParamInQueryString,
    updateOrAddParamToQuerystring: updateOrAddParamToQuerystring,
    urlWithSearchParams: urlWithSearchParams
};
