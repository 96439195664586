const { setBooleanAttribute, getBooleanAttribute } = require('../../../../utilities/dom/booleanAttribute');
const { getListFromDOM, getListUidFromDOM } = require('../helpers/list');
const onDOMContentLoaded = require('../../../../utilities/dom/onDOMContentLoaded');
const getAnalyticsConfig = require('../../../../common/getAnalyticsConfig');
const store = require('../../stores/listsStore');

const analyticsConfig = getAnalyticsConfig();

/**
 * Set the list into store after building it from DOM data.
 * @async
 * @param {Element} listRootElement The root element of a product list
 * @returns {Promise<EcommerceListObject>} the list just stored
 */
function setListFromDOM(listRootElement) {
    const isReady = getBooleanAttribute(listRootElement, analyticsConfig.dataAttributes.listIsReady);
    const isBuilding = getBooleanAttribute(listRootElement, analyticsConfig.dataAttributes.listIsBuilding);
    const listUid = getListUidFromDOM(listRootElement);

    if (isReady || isBuilding) return store.getItem(listUid);

    setBooleanAttribute(listRootElement, analyticsConfig.dataAttributes.listIsBuilding, true);

    return store
        .setItem(listUid, getListFromDOM(listRootElement))
        .then((list) => {
            setBooleanAttribute(listRootElement, analyticsConfig.dataAttributes.listIsBuilding, false);
            setBooleanAttribute(listRootElement, analyticsConfig.dataAttributes.listIsReady, true);
            return list;
        })
        .catch(() => {
            setBooleanAttribute(listRootElement, analyticsConfig.dataAttributes.listIsBuilding, false);
            return null;
        });
}

/**
 * Get the Ecommerce list object for a rendered product
 * @async
 * @param {Element} listRootElement The root element of the list
 * @returns {Promise<EcommerceListObject>} The list
 */
async function getList(listRootElement) {
    const listUid = getListUidFromDOM(listRootElement);
    return store.getItem(listUid)
        .then(
            (list) => list === null && listRootElement
                // First query with no results from store
                ? setListFromDOM(listRootElement)
                : list
        );
}

/**
 * Track the lists
 */
function track() {
    // Process all loaded lists
    onDOMContentLoaded(() => {
        var listRootElements = document.querySelectorAll(`[${analyticsConfig.dataAttributes.list}]`);
        for (let i = 0, max = listRootElements.length; i < max; i++) {
            setListFromDOM(listRootElements[i]);
        }
    });
}

module.exports = {
    track,
    getList
};
