const mixinDTO = require('../../../utilities/mixins/dto');
const { trim } = require('../../../utilities/stringUtils');
const uid = require('../../../utilities/uid/non-secure');
const getAnalyticsConfig = require('../../../common/getAnalyticsConfig');
const { compose } = require('../../../utilities/fp');
const mixinClone = require('../../../utilities/mixins/clone');
const mixinEquals = require('../../../utilities/mixins/equals');

const analyticsConfig = getAnalyticsConfig();

/**
 * AD list object for ecommerce object of datalayer
 * @typedef {object} EcommerceListObject
 * @property {string} listPrefix - Prefix (ex: '/catalogo', '/directo',
 * '/productos_similares')
 * @property {string} [referrerPage] - Parent page (or previous page for PDP)
 * (ex: '/home').
 * @property {string} [referrerComponent] - The component that contains the
 * product (or the clicked one in referrer page for PDP). It may be a Page
 * Designer component (ex: 'banner-principal') or simple DOM element as a link
 * of the floating navigation (ex: 'flotantes').
 * @property {string} [categoryListId] - Category identifier for lists (ex:
 * '/mujer/coleccion/pantalones')
 * @property {string} [referrerProductId] - Product ID (11 digits) of the PDP
 * where the recommended products list, as completeTheLook, is shown (ex:
 * '12022171731')
 * @param {string} uid - UID of the list in order to store it
 * @property {function():object} clone - Get a copy
 * @property {function():string} toString - Get the list ready for GTM
 * @property {function():object} toDTO - Get the raw data of the list
 */

const applyMixins = compose(mixinDTO, mixinClone, mixinEquals);

const EcommerceListObjectProto = applyMixins({
    toDatalayer() {
        let orderedChunks;

        if (this.referrerPage === analyticsConfig.list.referrerPages.cart) {
            // Special case: cart (ex: /cesta/productos_similares/)
            orderedChunks = [
                this.referrerPage,
                this.listPrefix
            ];
        } else if (this.referrerComponent === analyticsConfig.list.referrerComponents.minicart) {
            // Special case: minicart (ex: /minicesta/productos_similares/)
            orderedChunks = [
                this.referrerComponent,
                this.listPrefix
            ];
        } else {
            orderedChunks = [
                this.referrerPage,
                this.referrerComponent,
                this.listPrefix,
                this.categoryListId,
                this.referrerProductId
            ];
        }
        const stringifiedList = orderedChunks
            .filter(id => id) // clean empty data
            .map(str => trim(str, '/')) // avoid repeated slashes
            .filter(id => id) // clean empty data
            .join('/'); // build the string
        return `/${stringifiedList}/`;
    }
});

/**
 * @description
 * Create an EcommerceListObject to build lists for GTM.
 *
 * Here are some examples of the stringified list needed for GTM:
 *   * /buscador/
 *   * /catalogo/{{identificador_categoria}}
 *   * /productos_completa_look/{{id_pagina}}/{{id_componente}}/{{identificador_categoria}}/{{ref_orig}}
 *   * /productos_similares/{{ref_orig}}
 *   * /wishlist/{{identificador_categoria}}/
 *
 * Besides, the list can include referrer page information. This info should be
 * prepended to list when impressions are viewed or when entering to the PDP and
 * sending the pageview event. Examples:
 *   * /home/productos_shop_the_look/
 *   * /home/{{identificador_categoria}}
 *   * /home/banner-principal/{{identificador_categoria}}
 *   * /home/flotantes/{{identificador_categoria}}
 *   * /productos_completa_look/home/banner_principal/mujer/special_prices/22016170141/
 *
 * If floating navigation is used outside a PD page (ex: PLP)
 *   * /flotantes/{{identificador_categoria}}
 *
 * Finally, special cases where list prefix is not the first chunk:
 *   * /cesta/productos_similares/
 *   * /minicesta/productos_similares/
 *
 * @param {Object} dto - DTO of an EcommerceListObject
 * @param {string} dto.listPrefix - Prefix (ex: '/catalogo', '/directo',
 * '/productos_similares'...)
 * @param {string} [dto.referrerPage] - Parent page (or previous page for PDP)
 * (ex: '/home', '/cart'...)
 * @param {string} [dto.referrerComponent] - The component that contains the
 * product (or the clicked one in referrer page for PDP). It may be a Page
 * Designer component (ex: 'banner-principal') or simple DOM element as a link
 * of the floating navigation (ex: 'flotantes').
 * @param {string} [dto.categoryListId] - Category identifier for lists (ex:
 * '/mujer/coleccion/pantalones')
 * @param {string} [dto.referrerProductId] - Product ID (11 digits) of the PDP
 * where the recommended products list, as completeTheLook, is shown (ex:
 * '12022171731')
 * @param {string} [dto.uid] - UID of the list in order to store it
 * @returns {EcommerceListObject} new instance
 */
function createEcommerceListObject(dto) {
    const instance = Object.create(EcommerceListObjectProto);
    instance.listPrefix = dto.listPrefix;
    instance.referrerPage = dto.referrerPage;
    instance.referrerComponent = dto.referrerComponent;
    instance.categoryListId = dto.categoryListId;
    instance.referrerProductId = dto.referrerProductId;
    instance.uid = dto.uid || uid();
    return instance;
}

/**
 * Check whether a given value is an instance of the model "EcommerceListObject"
 * @param {any} value Target to check
 * @returns {boolean} return true if it is anb instance of EcommerceListObject
 */
function isEcommerceListObject(value) {
    return Object.prototype.isPrototypeOf.call(EcommerceListObjectProto, value);
}

module.exports = {
    constants: {
        propNames: {
            categoryListId: 'categoryListId',
            listPrefix: 'listPrefix',
            referrerComponent: 'referrerComponent',
            referrerPage: 'referrerPage',
            referrerProductId: 'referrerProductId'
        }
    },
    createEcommerceListObject,
    isEcommerceListObject
};
