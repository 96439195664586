const base = require('./base');
const detail = require('base/product/detail');
const deviceUtilities = require('../utilities/deviceUtilities');
const pageUtilities = require('../utilities/pageUtilities');
const bundleDetails = require('./bundleDetails');
const imageZoom = require('./imageZoom');
const recommendationsSlider = require('../product/recommendationsSlider');
const { onScrollStart } = require('../common/monitors/scroll');
const scroll = require('../utilities/scroll');

const SWATCHES_CLASS = 'product-detail__color-swatches';
const SWATCHES_ALL_VISIBLE_CLASS = 'product-detail__color-swatches--all-visible';

/**
 * Close Size Selector Popup
 * @param {Element|null} target clicked close button
 *
 */
function closeSizeSelector(target) {
    var closeIcon = target || document.querySelector('.product-sizes__close');
    var sizesInfo = closeIcon ? closeIcon.closest('.product-info__sizes-info')
        : document.querySelector('.product-info__sizes-info');

    if (!sizesInfo) return;

    if (pageUtilities.isBundleDetailPage()) {
        if (deviceUtilities.isMobile()) {
            bundleDetails.methods.closeBundleSizeSelector($('.cloned-sizes .product-info__sizes-info'));
        } else {
            bundleDetails.methods.closeBundleSizeSelector($(sizesInfo));
        }
    } else if (
        sizesInfo.classList.contains('opened')
        && pageUtilities.isProductDetailPage()
        && !deviceUtilities.isDesktop()
    ) {
        imageZoom.methods.enableZoom();
        sizesInfo.style.bottom = '-100%';
        sizesInfo.classList.remove('opened');
        scroll.resumePageScroll();
    }
}

/**
 * Updates color name position for both mobile and desktop swatches
 *  to be under selected swatch
 */
function updateColorNamePosition() {
    const selectedSwatches = document.querySelectorAll(
        '.product-detail__color-swatches .selected, .product-colors__selector .selected'
    );

    selectedSwatches.forEach(function (swatch) {
        const swatchWrapper = swatch.closest('.product-colors[data-attr=color]')
            || swatch.closest('.product-detail__color-swatches');

        if (!swatchWrapper) {
            return;
        }

        const firstSwatch = swatchWrapper?.querySelector('.color-value:first-of-type');
        const nameElement = swatchWrapper?.querySelector('.product-colors__selected-name');

        if (swatch === firstSwatch) {
            nameElement.removeAttribute('style');
        } else {
            var swatchDomRect = swatch.getBoundingClientRect();
            var swatchXPosition = swatchWrapper.classList.contains('product-colors')
                ? (swatchDomRect.x - swatchWrapper.getBoundingClientRect().x)
                : swatchDomRect.x;
            var parentPadding = parseFloat(getComputedStyle(swatchWrapper).paddingLeft);
            var distanceToMove = swatchXPosition + (swatchDomRect.width / 2) - parentPadding;

            nameElement.style.transform = 'translateX(-50%)';
            nameElement.style.left = distanceToMove + 'px';
        }
    });
}

/**
 * Opens all swatches view
 */
function openSwatches() {
    var swatchesElement = document.querySelector('.' + SWATCHES_CLASS);
    if (!swatchesElement.classList.contains(SWATCHES_ALL_VISIBLE_CLASS)) {
        imageZoom.methods.disableZoom();
        swatchesElement.classList.toggle(SWATCHES_ALL_VISIBLE_CLASS);
        swatchesElement.querySelectorAll('.color-swatches__link').forEach(link => {
            link.classList.remove('d-none');
        });

        updateColorNamePosition();
    }
}

/**
 * Closes all swatches view
 */
function closeSwatches() {
    var swatchesElement = document.querySelector('.' + SWATCHES_CLASS);
    if (!swatchesElement) return;
    if (swatchesElement.classList.contains(SWATCHES_ALL_VISIBLE_CLASS)) {
        imageZoom.methods.enableZoom();
        swatchesElement.querySelectorAll('.color-swatches__link').forEach(
            function (swatch, index) {
                if (index >= 2) {
                    swatch.classList.add('d-none');
                }
            }
        );

        swatchesElement.classList.toggle(SWATCHES_ALL_VISIBLE_CLASS);
    }
}

/**
 * Scrolls page to ADN Section
 * @param {Element} adnPremiumSection adn premium section
 */
function scrollToADNSection(adnPremiumSection) {
    var headerHeight = document.querySelector('.page-header')
        ?.getBoundingClientRect().height || 0;
    var scrollTop;

    if (deviceUtilities.isMobile()) {
        var sectionTop = adnPremiumSection.getBoundingClientRect().top;
        scrollTop = sectionTop - headerHeight;
    } else {
        var sectionHeight = adnPremiumSection.getBoundingClientRect().height;
        var productMainElement = document.querySelector('.product-detail__main');
        var imagesSectionHeight = document.querySelector('.product-images-container')
            ?.getBoundingClientRect().height || 0;
        var mainMarginBottom = productMainElement
            ? parseFloat(getComputedStyle(productMainElement).marginBottom) : 0;
        scrollTop = imagesSectionHeight - sectionHeight - headerHeight - mainMarginBottom;
    }

    $('html, body').animate({
        scrollTop: scrollTop
    }, 500);
}

module.exports = {
    methods: {
        closeSizeSelector: closeSizeSelector,
        updateColorNamePosition: updateColorNamePosition
    },
    availability: base.availability,
    updateAttributesAndDetails: detail.updateAttributesAndDetails,
    showSpinner: detail.showSpinner,
    updateAttribute: detail.updateAttribute,

    updateAddToCart: function () {
        $('body').on('product:updateAddToCart', function (e, response) {
            // update local add to cart (for sets)
            $('button.add-to-cart', response.$productContainer).attr('disabled',
                (!response.product.available));

            var enable = $('.product-availability').toArray().every(function (item) {
                return $(item).data('available') && $(item).data('ready-to-order');
            });

            // Always enabled for ProductBundle
            if (response.$productContainer.hasClass('bundle-item')) {
                enable = true;
                // TODO always enabled but it changes text.
            }

            $('button.add-to-cart-global').attr('disabled', !enable);
        });
    },

    updateAvailability: detail.updateAvailability,
    copyProductLink: detail.copyProductLink,

    shippingAndReturns: function () {
        var container = $('.shipping-and-returns');
        var content = $('.shipping-and-returns .content');
        var productID = $('.product-detail').data('pid');

        if (content.is(':empty')) {
            $.ajax({
                url: container.data('url'),
                type: 'get',
                dataType: 'json',
                data: {
                    returnTerm: container.data('return-term'),
                    productID: productID
                },
                success: function (data) {
                    content.append(data.shippingContent);
                    content.append(data.returnContent);
                }
            });
        }
    },

    slideClick: function () {
        $('.product-images-slider .swiper-slide').on('click', function (event) {
            if (!$(this).hasClass('video-slide')) {
                $('body').trigger('product:image-zoom', event);
            } else {
                var video = $(this).find('.pdp-video').get(0);
                // Linter is disabled because although any value is set to video, it is being used to play or pause itself
                // eslint-disable-next-line no-unused-expressions
                video && video.paused ? video.play() : video.pause();
            }
        });
    },

    adnGiftButton: function () {
        $('body').on('detail:updateSaveAdnGift', function (e, response) {
            var button = $('button.save-adn-gift');
            var form = button.closest('form');
            button.attr('disabled', false);
            button.removeClass('disabled');
            form.find('.variation-product-id').attr('value', response.product.id);
        });
    },

    swatchMoreButton: function () {
        $('#swatch-more-pdp').on('click', function () {
            openSwatches();
        });
    },

    swatchCloseOnClick: function () {
        document.addEventListener('click', function (e) {
            var swatchesElement = document.querySelector('.' + SWATCHES_CLASS);
            if (!swatchesElement) return;
            var isSwatchesElement = e.target.classList.contains(SWATCHES_CLASS)
                || !!$(swatchesElement).has(e.target).length;
            var isVisible = swatchesElement.classList.contains(SWATCHES_ALL_VISIBLE_CLASS);

            if (!isSwatchesElement && isVisible) {
                closeSwatches();
            }
        });
    },

    selectSizeButton: function () {
        $('#select-size-button').on('click', function () {
            if (!deviceUtilities.isDesktop() && $(this).closest('.product-quickview').length === 0) {
                const buttonPid = $(this).data('pid');
                const associatedSizeInfo = $('.product-info__sizes-info[data-pid="' + buttonPid + '"]');

                if (associatedSizeInfo && associatedSizeInfo.length > 0) {
                    $('html, body').animate({
                        scrollTop: 0
                    }, 500, function () {
                        associatedSizeInfo.css('bottom', '0');
                        associatedSizeInfo.css('top', 'unset');
                        associatedSizeInfo.addClass('opened');
                        scroll.stopPageScroll();
                    });
                }
                imageZoom.methods.disableZoom();
            }
        });
    },
    bundleSelectSize: function () {
        $('input[class*=bundled-product-input], .bundled-product__size-and-edit .edit-icon').on('click', function () {
            const bundleItemPid = $(this).data('pid')
                || $(this).closest('.bundled-product').find('input[class*=bundled-product-input]').data('pid');
            const associatedSizeInfo = $(this).closest('.bundled-product').find('.product-info__sizes-info[data-pid="' + bundleItemPid + '"]');

            // remove all size-errors
            $('.bundled-product .form-check-label').removeClass('unselected-size');
            $('.bundled-product').find('.bundled-product__error').hide();

            // size is selected and it's not edit-icon
            var isEmptySizeOption = $(this).closest('.bundled-product').find('.form-control.select-size option:selected').attr('data-hide');
            if ((!isEmptySizeOption && !$(this).hasClass('edit-icon'))
                || $(this).hasClass('edit-icon disabled')) {
                bundleDetails.methods.closeBundleSizeSelector($('.product-info__sizes-info'));
                return;
            }

            if (associatedSizeInfo && associatedSizeInfo.length > 0) {
                if (deviceUtilities.isDesktop() || deviceUtilities.isTablet()) {
                    bundleDetails.methods.closeBundleSizeSelector($(':not(.cloned-sizes) .product-info__sizes-info'));
                    bundleDetails.methods.showDesktopBundleSizes(associatedSizeInfo);
                } else {
                    bundleDetails.methods.closeBundleSizeSelector($('.cloned-sizes .product-info__sizes-info'));
                    bundleDetails.methods.showMobileBundleSizes(associatedSizeInfo, bundleItemPid);
                }
            }
        });
    },

    closeSizeSelectorEvent: function () {
        $('body').on('click', '.product-sizes__close', function (e) {
            closeSizeSelector(e.currentTarget);
        });

        $('body').on('click', function (e) {
            var clickedElement = e.target;

            if (!deviceUtilities.isSmallerThan768()
                || clickedElement?.closest('.product-info-sizes')) {
                return;
            }

            var sizesInfo = document.querySelector('.product-info__sizes-info');
            if (sizesInfo?.classList.contains('opened')) {
                closeSizeSelector(sizesInfo);
            }
        });
    },

    focusChooseBonusProductModal: base.focusChooseBonusProductModal,

    initRecommendationsSliders: function () {
        recommendationsSlider.initSliders(document.querySelector('.product-detail__recommendations > div'));
    },

    createScrollMonitor: function () {
        onScrollStart(closeSwatches);
    },

    adnPremiumLink: function () {
        const adnPremiumLink = document.querySelector('.adn-premium-tag__link');
        const adnPremiumSection = document.querySelector('.pdp-adn-premium');
        const { triggerNativeEvent } = require('../utilities/javascriptUtils');

        if (!adnPremiumLink || !adnPremiumSection) return;

        adnPremiumLink.addEventListener('click', function (event) {
            event.preventDefault();

            if (!adnPremiumSection.classList.contains('active')) {
                if (adnPremiumSection.querySelector('.title')) {
                    triggerNativeEvent(adnPremiumSection.querySelector('.title'), 'click');
                }
                setTimeout(function () {
                    scrollToADNSection(adnPremiumSection);
                }, 500);
            } else {
                scrollToADNSection(adnPremiumSection);
            }
        });
    }
};
