var DL_SKU_SUFFIX = '_DL';

/**
 * Returns original product id
 * @param {string} productId Product Id
 * @returns {string} Canonical product Id
 */
function getCanonicalProductId(productId) {
    var result = new RegExp('^(.*)' + DL_SKU_SUFFIX + '[1-9]$').exec(productId);
    return result ? result[1] : productId;
}

/**
 * Check if product is a Double Look product
 * @param {string} productId Product Id
 * @returns {boolean} is a Double Look product
 */
function isDoubleLookProduct(productId) {
    return productId !== getCanonicalProductId(productId);
}

module.exports = {
    isDoubleLookProduct: isDoubleLookProduct,
    getCanonicalProductId: getCanonicalProductId
};
