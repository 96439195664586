const { compose } = require('../../../utilities/fp');
const mixinClone = require('../../../utilities/mixins/clone');
const mixinDTO = require('../../../utilities/mixins/dto');
const { isEcommerceListObject, createEcommerceListObject } = require('./ecommerceListObject');

const applyMixins = compose(mixinDTO, mixinClone);

/**
 * AD list object and position of a product for ecommerce object of datalayer
 * @typedef {object} EcommerceListAndPosition
 * @property {import('./ecommerceListObject').EcommerceListObject} list - The
 * parent list related to the position of a product
 * @property {number} [position] - The position of a product inside the list
 * @property {function():object} clone - Get a copy
 * @property {function():object} toDTO - Get the raw data of the object list to
 * store it
 */

const EcommerceListAndPositionProto = applyMixins({});

/**
 * Create an EcommerceListAndPosition to save and restore GTM list data related to
 * a product.
 *
 * @param {Object} dto - DTO of an EcommerceListAndPosition
 * @param {EcommerceListObject|Object} dto.list - The DTO of the parent EcommerceListObject
 * @param {number} [dto.position] - The position of a product inside the list.
 * Optional because there is no position when the list is direct access.
 * @returns {EcommerceListAndPosition} new instance
 */
function createEcommerceListAndPosition(dto) {
    const instance = Object.create(EcommerceListAndPositionProto);

    if (isEcommerceListObject(dto.list)) {
        instance.list = dto.list;
    } else {
        instance.list = createEcommerceListObject(dto.list);
    }

    if (dto.position) {
        instance.position = dto.position;
    }

    return instance;
}

module.exports = {
    createEcommerceListAndPosition
};
