var cart = require('../cart/cart');
var overlay = require('./overlay');
var minicartUtils = require('./minicartUtils');
const recommendationsSlider = require('../product/recommendationsSlider');
const getBaseConfig = require('../common/getBaseConfig');
const nativefy = require('../utilities/dom/nativefy');
const getAnalyticsEventCenter = require('../common/getAnalyticsEventCenter');

const analyticsEventCenter = getAnalyticsEventCenter();
var BASE_CONFIG = getBaseConfig();

const SPACE_BETWEEN_SLIDES = 8;
var updateMiniCart = true;

/**
 * Opens miniCart
 */
function openMiniCart() {
    var miniCart = $('.minicart .hide-no-link');
    if ($('.search:visible').length === 0 || $('.refinement-bar-overlay').length > 0
    || $('.minicart .popover.show').length > 0) {
        return;
    }

    minicartUtils.calculateMinicartPosition();
    miniCart.addClass('is-opened');

    var url = $('.minicart').data('action-url');
    var count = parseInt($('.minicart .minicart-quantity').text(), 10);
    var saveForLaterCount = parseInt($('.saveForLater-quantity').val() || 0, 10);
    var totalCount = count + saveForLaterCount;
    const $minicartPopover = $('.minicart .popover');
    let $minicartBody = $minicartPopover.find('.minicart-body');

    const newObserverForRecomm = new MutationObserver(function (mutations) {
        mutations.forEach(async function (mutation) {
            var mutationTarget = mutation.target;
            var slider = mutationTarget.querySelector('.minicart .recommendations__swiper-container');
            var isInitialized = slider?.classList.contains('recommendations__swiper-container')
                && slider?.classList.contains('swiper-container-initialized');

            if (mutation.type === 'childList' && slider && !isInitialized) {
                await recommendationsSlider.initSlider(slider);
                newObserverForRecomm.disconnect();
            }
        });
    });

    newObserverForRecomm.observe($minicartPopover[0], { childList: true, subtree: true });

    if (totalCount !== 0 && !$minicartPopover.hasClass('show')) {
        if (!updateMiniCart) {
            overlay.createOverlay('minicart', '.minicart');
            overlay.stopPageScroll();
            $minicartPopover.addClass('show');
            analyticsEventCenter.publish(
                analyticsEventCenter.EVENTS.modalShown,
                {
                    modal: nativefy($minicartPopover),
                    name: analyticsEventCenter.PAYLOADS.modalShown.name.minicart,
                    scrollableContainer: nativefy($minicartBody)
                }
            );
            $('body').trigger('minicart:afterUpdate');
            return;
        }

        overlay.createOverlay('minicart', '.minicart');
        overlay.stopPageScroll();
        $('.overlay').spinner().start();
        $.get(url, function (data) {
            $minicartPopover.empty();
            $minicartPopover.append(data);
            minicartUtils.manageNoStockMessage();
            updateMiniCart = false;
            $.spinner().stop();
            $minicartPopover.addClass('show');
            $minicartBody = $minicartPopover.find('.minicart-body');

            const swiperContainer = document.querySelector(
                '.minicart .recommendations__swiper-container'
            );
            if (swiperContainer) {
                recommendationsSlider.initSlider(
                    swiperContainer,
                    {
                        spaceBetween: SPACE_BETWEEN_SLIDES,
                        scrollbar: true
                    }
                ).then(() => analyticsEventCenter.publish(
                    analyticsEventCenter.EVENTS.modalShown,
                    {
                        modal: nativefy($minicartPopover),
                        name: analyticsEventCenter.PAYLOADS.modalShown.name.minicart,
                        scrollableContainer: nativefy($minicartBody)
                    }
                ));
            } else {
                // If there is no swiper container for waiting for
                analyticsEventCenter.publish(
                    analyticsEventCenter.EVENTS.modalShown,
                    {
                        modal: nativefy($minicartPopover),
                        name: analyticsEventCenter.PAYLOADS.modalShown.name.minicart,
                        scrollableContainer: nativefy($minicartBody)
                    }
                );
            }

            $('body').trigger('minicart:afterUpdate');
        });
    } else if ($('.empty-cart-alert').length === 0 && $('.minicart .popover.show').length === 0) {
        var message = $('.empty-cart-text').html();
        $('body').append(
            '<div class="empty-cart-message"></div>'
        );

        $('.empty-cart-message')
            .append('<div class="alert alert-warning empty-cart-alert text-center" role="alert"> '
            + message
            + '</div>');

        setTimeout(function () {
            $('.empty-cart-alert').remove();
        }, 3000);
    }
}

/**
 * Close the minicart popup
 */
function closeMinicart() {
    const $minicartPopover = $('.minicart .popover');
    const $minicartBody = $minicartPopover.find('.minicart-body');
    analyticsEventCenter.publish(
        analyticsEventCenter.EVENTS.modalWillBeHidden,
        {
            modal: nativefy($minicartPopover),
            name: analyticsEventCenter.PAYLOADS.modalWillBeHidden.name.minicart,
            scrollableContainer: nativefy($minicartBody)
        }
    );
    $minicartPopover.removeClass('show');
    $('.minicart .hide-no-link').removeClass('is-opened');
    overlay.deleteOverlay();
    overlay.resumePageScroll();
    analyticsEventCenter.publish(
        analyticsEventCenter.EVENTS.modalHidden,
        {
            name: analyticsEventCenter.PAYLOADS.modalHidden.name.minicart
        }
    );
}

module.exports = function () {
    cart();
    if (BASE_CONFIG.isWishlistEnabled) {
        var wishlistMinicart = require('plugin_wishlists/components/miniCart');
        var wishlistHeart = require('./../product/wishlistHeart');
        wishlistMinicart.moveToWishlist();
        wishlistHeart.addToWishlist();
    }

    if (BASE_CONFIG.isSaveForLaterEnabled) {
        $('body').on('click', '.view-reserved-btn-minicart', function () {
            $('.nav-link.save-for-later-tab').trigger('click');
        });

        $('body').on('click', '.nav-link.save-for-later-tab', function () {
            $('.container.cart').addClass('minicart--no-footer');
        });
    }

    $(document).ready(function () {
        minicartUtils.calculateMinicartPosition();
    });

    $('.minicart').on('count:update', function (event, count) {
        if (count && $.isNumeric(count.quantityTotal)) {
            $('.minicart .minicart-quantity').text(count.quantityTotal);
            $('.minicart .minicart-link').attr({
                'aria-label': count.minicartCountOfItems,
                title: count.minicartCountOfItems
            });
        }
    });

    $('.minicart .hide-no-link').on('click', openMiniCart);

    $('.minicart .popover').on('click', '.minicart-header .close', closeMinicart);

    $('body').on('click', '.minicart-overlay', function (e) {
        if ($('.remove-product-modal').has(e.target).length > 0) {
            return;
        }
        closeMinicart();
    });

    $('body').on('click', '.nav-link.minicart-tab', function () {
        if ($('.minicart-products-tabcontent .cart-empty').length === 0) {
            $('.container.cart').removeClass('minicart--no-footer');
            $('.checkout-btn').removeClass('disabled');
        }
    });

    $('body').on('change', '.minicart .quantity', function () {
        if ($(this).parents('.bonus-product-line-item').length && $('.cart-page').length) {
            // eslint-disable-next-line no-restricted-globals
            location.reload();
        }
    });

    $('body').on('product:afterAddToCart', function () {
        updateMiniCart = true;
    });

    $('body').on('cart:update', function () {
        updateMiniCart = true;
    });

    $('body').on('cart:emptySfl', function () {
        if ($('.minicart-tab').length) {
            $('.minicart-tab').trigger('click');
        }
    });
};
