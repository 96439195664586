var scrollAnimate = require('./scrollAnimate');

var BASE_CONFIG = window.BASE_CONFIG;
var externalSites = BASE_CONFIG.externalSites;

/**
 * Sets the country selector language
 */
function setCountrySelectorLanguages() {
    var externalSite;
    var langCode;
    var selectedCountryCode = $('.country-selector-form .country option:selected').val();
    var siteWithOwnLangBlock = ['jp', 'fr', 'pt', 'gb', 'cl'];
    if (selectedCountryCode) {
        for (var i = 0; i < externalSites.length; i++) {
            if (externalSites[i].code === selectedCountryCode) {
                externalSite = externalSites[i];
                break;
            }
        }
        if (BASE_CONFIG.euCountries.indexOf(selectedCountryCode) !== -1
            && BASE_CONFIG.euCountriesWithOwnSite.indexOf(selectedCountryCode) === -1) {
            langCode = 'de-en';
        } else if (externalSite) {
            langCode = 'external';
        } else if (siteWithOwnLangBlock.indexOf(selectedCountryCode) !== -1) {
            langCode = selectedCountryCode;
        } else {
            langCode = 'es-en';
        }

        var langCodeSelector = '.' + langCode + '-lang-options';
        $('.country-selector-form .language').html($(langCodeSelector).html());
    } else {
        $('.country-selector-form .language').empty();
    }
}

module.exports = function () {
    $('.back-to-top').click(function () {
        scrollAnimate();
    });

    $('.country-selector-form .country').ready(function () {
        setCountrySelectorLanguages();
    });

    $('.country-selector-form .country').on('change', function () {
        setCountrySelectorLanguages();
    });
};
