/**
 * Renders a modal window that will track the users consenting to accepting site tracking policy
 */
function showConsentModal() {
    if (!$('.tracking-consent').data('caonline')) {
        return;
    }

    var urlContent = $('.tracking-consent').data('url');
    var urlAccept = $('.tracking-consent').data('accept');
    var urlReject = $('.tracking-consent').data('reject');
    var textYes = $('.tracking-consent').data('accepttext');
    var textHeader = $('.tracking-consent').data('heading');

    var htmlString = '<!-- Modal -->'
        + '<div class="cookie-warning-messaging cookie-warning" id="consent-tracking" role="dialog" style="display: block;">'
        + '     <div class="modal-header p-0">'
        + '         <button type="button" class="close pull-right decline" data-url="' + urlReject + '">'
        + '             <svg class="close-icon">'
        + '                 <use xlink:href="#closeIcon"></use>'
        + '             </svg>'
        + '         </button>'
        + '     </div>'
        + ' <div class="alert valid-cookie-warning fade show" role="dialog"">'
        + ' <!-- Modal content-->'
        + '     <div class="alert-warning-text">'
        + '         <div class="modal-header tracking-modal-header">'
                        + textHeader
        + '         </div>'
        + '         <div class="modal-body grey-text consent-tracking-body"></div>'
        + '     </div>'
        + '     <div class="btn-container justify-content-center">'
        + '         <button class="affirm btn btn-primary" data-url="' + urlAccept + '">'
                        + textYes
        + '         </button>'
        + '     </div>'
        + ' </div>'
        + '</div>';
    $('body').append(htmlString);

    $.ajax({
        url: urlContent,
        type: 'get',
        dataType: 'html',
        success: function (response) {
            $('.consent-tracking-body').html(response);
        },
        error: function () {
            $('#consent-tracking').remove();
        }
    });

    $('#consent-tracking button').click(function (e) {
        e.preventDefault();
        var url = $(this).data('url');
        $.ajax({
            url: url,
            type: 'get',
            dataType: 'json',
            success: function () {
                $('#consent-tracking').remove();
                $.spinner().stop();
            },
            error: function () {
                $('#consent-tracking').remove();
                $.spinner().stop();
            }
        });
    });
}

module.exports = function () {
    if ($('.consented').length === 0 && $('.tracking-consent').hasClass('api-true')) {
        showConsentModal();
    }

    if ($('.tracking-consent').hasClass('api-true')) {
        $('.tracking-consent').click(function () {
            showConsentModal();
        });
    }
};
