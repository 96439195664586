const { existsElement } = require('./dom/query');
const { isEmpty } = require('./type');

/**
 * Returns true if current page is Cart page
 * @returns {boolean} - The result
 */
function isCartPage() {
    return existsElement('.page[data-action="Cart-Show"]');
}

/**
 * Returns true if current page is a Checkout page
 * @returns {boolean} - The result
 */
function isCheckoutPage() {
    return existsElement('.page.checkout-page:not([data-action="Login-Show"])');
}

/**
 * Returns true if current item is inside a Quickview modal
 * @param {Object} currentItem - Clicked jQuery item
 * @returns {boolean} - The result
 */
function isQuickView(currentItem) {
    return currentItem.closest('.product-quickview').length > 0;
}

/**
 * Returns true if current item is inside a QuickShop tile
 * @param {Object} currentItem - Clicked jQuery item
 * @returns {boolean} - The result
 */
function isQuickShop(currentItem) {
    return currentItem && currentItem.closest('.product-tile-quick-shop').length > 0;
}

/**
 * Returns true if current page has current category info
 * @returns {boolean} - The result
 */
function isCurrentCategory() {
    return $('.js-current-category').length > 0 && $('.js-current-category').data('category') !== 'non-category';
}

/**
 * Returns true if the referrer of the current page is external (ex: an email of
 * the newsletter)
 * @returns {boolean} - The result
 */
function isExternalReferrer() {
    /*
    True when:
        - Referrer-Policy is `no-referrer`
        - Or the Referrer-Policy enters in `(no referrer)` case
            Examples: https://developer.mozilla.org/en-US/docs/Web/HTTP/Headers/Referrer-Policy#examples
    */
    const hasNoReferrer = isEmpty(document.referrer);
    /* True when the referrer begins with the current site origin */
    const hasReferrerWithSameOrigin = document.referrer?.indexOf(window.location.origin) === 0;
    return hasNoReferrer || !hasReferrerWithSameOrigin;
}

/**
 * Returns true if the current page is the PD page associated with a category
 * as its landing page.
 * @returns {boolean} - The result
 */
function isLandingPageOfCategory() {
    return existsElement('.storepage[data-is-landing-page-of-category]');
}

/**
 * Returns true if current page is Product Detail Page
 * @returns {boolean} - The result
 */
function isProductDetailPage() {
    return existsElement('.page[data-action="Product-Show"]');
}

/**
 * Returns true if current page is Bundle Detail Page
 * @returns {boolean} - The result
 */
function isBundleDetailPage() {
    return existsElement('.product-detail.product-detail-page.bundle-detail-page');
}

/**
 * Returns true if current page is Save for later page
 * @returns {boolean} - The result
 */
function isSaveForLaterPage() {
    return existsElement('.page[data-action="Cart-SaveForLaterShow"]');
}

/**
 * Returns true if current page is PLP (any case, by category or keyword)
 * @returns {boolean} - The result
 */
function isSearchPage() {
    return existsElement('.page[data-action="Search-Show"]');
}

/**
 * Returns true if current page is Wishlist page
 * @returns {boolean} - The result
 */
function isWishlistPage() {
    return existsElement('.page[data-action="Wishlist-Show"]');
}

/**
 * Returns true if current page is Home page
 * @returns {boolean} - The result
 */
function isHomePage() {
    return existsElement('.homepage');
}

module.exports = {
    isBundleDetailPage: isBundleDetailPage,
    isCartPage: isCartPage,
    isCheckoutPage: isCheckoutPage,
    isCurrentCategory: isCurrentCategory,
    isExternalReferrer: isExternalReferrer,
    isHomePage: isHomePage,
    isLandingPageOfCategory: isLandingPageOfCategory,
    isProductDetailPage: isProductDetailPage,
    isQuickShop: isQuickShop,
    isQuickView: isQuickView,
    isSaveForLaterPage: isSaveForLaterPage,
    isSearchPage: isSearchPage,
    isWishlistPage: isWishlistPage
};
