const ATTR_DATALAYER_REFERRER_COMPONENT = 'data-datalayer-list-referrer-component';

/**
 * Get the referrer Page Designer component from a target element.
 * @param {Element} element - The target or descendant element (ex: clicked one)
 * @returns {string} - The list chunk that identifies the component
 */
function getReferrerComponentFromElement(element) {
    let referrerComponent;

    const hasTheNeededData = element?.hasAttribute(ATTR_DATALAYER_REFERRER_COMPONENT);
    if (hasTheNeededData) {
        // PD Component with direct link: button, video...
        referrerComponent = element?.getAttribute(ATTR_DATALAYER_REFERRER_COMPONENT);
    } else {
        // PD Component with links inside: image, two-images, image-text, image-w-caption, etc
        const pdComponent = element?.closest(`[${ATTR_DATALAYER_REFERRER_COMPONENT}]`);
        if (pdComponent) {
            referrerComponent = pdComponent.getAttribute(
                ATTR_DATALAYER_REFERRER_COMPONENT
            );
        }
    }
    return referrerComponent;
}

module.exports = {
    getReferrerComponentFromElement
};
