/**
 * Javascript Vanilla equivalent toJquery $(document).on(eventName, elementSelector, handler);
 *
 * @param {string} eventName event name
 * @param {string} elementSelector Selector of element that triggers the event
 * @param {function(Event,Element)} handler function to be executed
 *
 * @example delegateEvent('click', 'td', function (e, targetTd) {
 *   // The element that receives the click
 *   console.log(e.target.tagName) // => 'STRONG'
 *   // The element that matches the selector and dispatches the delegated event
 *   console.log(targetTd.tagName) // => 'TD'
 * })
 * @link https://javascript.info/event-delegation
 */
var delegateEvent = (eventName, elementSelector, handler) => {
    document.addEventListener(eventName, function (e) {
        var matchingTarget = e.target && e.target.closest(elementSelector);
        var shouldHandle = e.target && matchingTarget !== null;
        if (shouldHandle) {
            handler(e, matchingTarget);
        }
    }, false);
};

/**
 * Javascript Vanilla equivalent to Jquery $(element).trigger(eventName, eventData);
 * @param {HTMLElement} element event element
 * @param {string} eventName event name
 * @param {Object} [eventData] event data
 */
function triggerCustomEvent(element, eventName, eventData) {
    let event;
    const eventDataObject = eventData || {};
    if (window.CustomEvent && typeof window.CustomEvent === 'function') {
        event = new CustomEvent(eventName, { detail: eventDataObject });
    } else {
        event = document.createEvent('CustomEvent');
        event.initCustomEvent(eventName, true, true, eventDataObject);
    }

    element.dispatchEvent(event);
}

/**
 * Javascript Vanilla equivalent to Jquery $(element).trigger(eventType);
 * @param {HTMLElement} element event element
 * @param {string} eventType event type
 */
function triggerNativeEvent(element, eventType) {
    const nativefy = require('./../utilities/dom/nativefy');
    let nativeElement = nativefy(element);
    if (typeof eventType === 'string' && typeof nativeElement[eventType] === 'function') {
        nativeElement[eventType]();
    } else {
        const event = typeof eventType === 'string'
            ? new Event(eventType, { bubbles: true })
            : eventType;
        nativeElement.dispatchEvent(event);
    }
}

/**
 * Empties the element, removing every child
 * @param {HTMLElement} element - Element to empty
 */
function empty(element) {
    while (element.firstChild) {
        element.removeChild(element.firstChild);
    }
}

module.exports = {
    delegateEvent: delegateEvent,
    empty: empty,
    triggerCustomEvent: triggerCustomEvent,
    triggerNativeEvent: triggerNativeEvent
};
