const { isFunction } = require('../../../utilities/type');
const fetchUserInfo = require('../helpers/fetchUserInfo');
const getAnalyticsConfig = require('../../../common/getAnalyticsConfig');

const analyticsConfig = getAnalyticsConfig();

/**
 * Event to send to data layer
 * @typedef {object} DataLayerEvent
 * @property {string} event
 * @property {object} event_data
 * @property {string} event_data.action - Action of the event
 * @property {string} event_data.category - Category of the event (ex: ECC - Purchase)
 * @property {string} event_data.label - Label of the event
 * @property {object} [user]
 * @property {string} [user.userID]
 * @property {string} [user.tienda]
 * @property {string} [user.siteLang]
 * @property {string} [user.registerDate]
 * @property {string} [user.seniority]
 * @property {string} [user.postal_code]
 * @property {string} [user.city]
 * @property {object} [ecommerce]
 */

/**
 * Create a new DataLayerEvent for sending to datalayer
 * @async
 * @param {Object} data Needed data
 * @param {string} [data.event] The identifier of the event
 * @param {Object} data.eventData Event-related data
 * @param {string} [data.eventData.action] The action of the event
 * @param {string} data.eventData.category The category of the event
 * @param {string} data.eventData.label The label of the event
 * @param {Object} data.ecommerce The ecommerce object of the event
 * @param {Object} [options] Options for the model creation
 * @param {boolean} [options.addUser=true] Add current user info from server
 * @param {Function} [options.callback] Function to execute after pushin to
 * datalayer
 * @param {boolean} [options.timeout=2000] Milliseconds to force the callback
 * execution
 * @returns {DataLayerEvent} New instance of DataLayerEvent
 */
async function createDataLayerEvent(
    {
        event = analyticsConfig.gtm.events.action,
        eventData: {
            action = analyticsConfig.gtm.eventActions.category,
            category = undefined,
            label = undefined
        },
        ecommerce
    },
    {
        addUser = true,
        callback,
        timeout = 2000
    } = {}
) {
    const instance = {
        event,
        event_data: {
            action,
            category,
            label
        }
    };

    if (ecommerce) {
        instance.ecommerce = ecommerce;
    }

    if (isFunction(callback)) {
        instance.eventCallback = callback;
        instance.eventTimeout = timeout;
    }

    if (addUser) {
        instance.user = await fetchUserInfo();
    }

    return instance;
}

module.exports = {
    createDataLayerEvent
};
