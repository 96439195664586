/**
 * Returns true if given param is a function
 * @param {any} val - value to check
 * @returns {boolean} - true or false
 */
const isFunction = (val) => Object.prototype.toString.call(val) === '[object Function]';

/**
 * Returns true if given param is an object
 * @param {any} val - value to check
 * @returns {boolean} - true or false
 */
const isObject = (val) => Object.prototype.toString.call(val) === '[object Object]';

/**
 * Returns true if given param is a promise
 * @param {any} val - value to check
 * @returns {boolean} - true or false
 */
const isPromise = (val) => (isObject(val) || isFunction(val)) && isFunction(val.then);

/**
 * Returns true if the given value is an string
 * @param {any} val value to check
 * @returns {boolean} true if val is an string
 */
const isString = (val) => Object.prototype.toString.call(val) === '[object String]';

/**
 * Returns true if the given value is a number
 * @param {any} val value to check
 * @returns {boolean} true if val is a number
 */
const isNumber = (val) => typeof val === 'number' && !Number.isNaN(val);

/**
 * Returns true if the given value is considered empty for its type
 * @param {any} val value to check
 * @returns {boolean} true if val is empty
 */
const isEmpty = (val) => {
    if (val === undefined || val === null) {
        return true;
    }
    if (isObject(val)) {
        return Object.keys(val).length === 0;
    }
    if (Array.isArray(val) || isString(val)) {
        return val.length === 0;
    }
    return false;
};

module.exports = {
    isEmpty,
    isFunction,
    isNumber,
    isObject,
    isPromise,
    isString
};
