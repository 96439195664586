var debounce = require('lodash/debounce');
var throttle = require('lodash/throttle');

var cartHelpers = require('./cartHelpers');
const getBaseConfig = require('../common/getBaseConfig');
var customSelects = require('../components/customSelects');
var minicartUtils = require('../components/minicartUtils');
var overlay = require('../components/overlay');
var base = require('../product/base');
var quickShop = require('../product/quickShop');
var quickViewImageSlider = require('../product/quickViewImageSlider');
var recommendationsSlider = require('../product/recommendationsSlider');
var checkColorBrightness = require('../utilities/checkColorBrightness');
const pageUtilities = require('../utilities/pageUtilities');
const { processDataLayerCartActionOnProduct } = require('../utilities/datalayerHelpers');
const redemptionsAndCoupons = require('../components/redemptionsAndCoupons').methods;

var focusHelper = require('base/components/focus');

var BASE_CONFIG = getBaseConfig();

/**
 * appends params to a url
 * @param {string} url - Original url
 * @param {Object} params - Parameters to append
 * @returns {string} result url with appended parameters
 */
function appendToUrl(url, params) {
    var newUrl = url;
    newUrl += (newUrl.indexOf('?') !== -1 ? '&' : '?') + Object.keys(params).map(function (key) {
        return key + '=' + encodeURIComponent(params[key]);
    }).join('&');

    return newUrl;
}

/**
 * re-render the paypal buttons if the total amount is zero
 * @param {Objet} data - AJAX response from the server
 */
function showPaypalButtons(data) {
    $('.paypal-cart-buttons-container').parent().removeClass('d-none');
    if (data.totals.grandTotalValue === 0) {
        $('.paypal-cart-buttons-container').addClass('d-none');
    } else {
        $('.paypal-cart-buttons-container').removeClass('d-none');
    }
}

/**
 * Updates the availability of a product line item
 * @param {Object} data - AJAX response from the server
 * @param {string} uuid - The uuid of the product line item to update
 */
function updateAvailability(data, uuid) {
    var lineItem;
    var messages = '';

    for (var i = 0; i < data.items.length; i++) {
        if (data.items[i].UUID === uuid) {
            lineItem = data.items[i];
            break;
        }
    }

    $('.availability-' + lineItem.UUID).empty();

    if (lineItem.availability) {
        if (lineItem.availability.messages) {
            lineItem.availability.messages.forEach(function (message) {
                messages += '<p class="line-item-attributes">' + message + '</p>';
            });
        }

        if (lineItem.availability.inStockDate) {
            messages += '<p class="line-item-attributes line-item-instock-date">'
                + lineItem.availability.inStockDate
                + '</p>';
        }
    }

    $('.availability-' + lineItem.UUID).html(messages);
}

/**
 * Finds an element in the array that matches search parameter
 * @param {array} array - array of items to search
 * @param {function} match - function that takes an element and returns a boolean indicating if the match is made
 * @returns {Object|null} - returns an element of the array that matched the query.
 */
function findItem(array, match) {
    for (var i = 0, l = array.length; i < l; i++) {
        if (match.call(this, array[i])) {
            return array[i];
        }
    }
    return null;
}

/**
 * Adds new line item Cart product card to cart page
 * @param {string} newLineItemHtml new product card html
 */
function createNewLineItem(newLineItemHtml) {
    if (newLineItemHtml && newLineItemHtml !== null && $('.cart-page-flex__left').length > 0) {
        $('.cart-page-flex__left').append(newLineItemHtml);
    }
}

/**
 * Updates details of a product line item
 * @param {Object} data - AJAX response from the server
 * @param {string} uuid - The uuid of the product line item to update
 * @param {boolean} isQuickShop - Is Quick shop
 */
function updateProductDetails(data, uuid, isQuickShop) {
    var lineItem = findItem(data.cartModel.items, function (item) {
        return item.UUID === uuid;
    });

    // If lineItem by uuid doesn't exist in cart, check products with same pid
    if (!lineItem && isQuickShop) {
        lineItem = findItem(data.cartModel.items, function (item) {
            if (item.id === data.newProductId) {
                uuid = item.UUID; // eslint-disable-line no-param-reassign
            }
            return item.id === data.newProductId;
        });
    }

    var elementContainer = $('.product-card.uuid-' + uuid + '');
    if (!lineItem || elementContainer.length === 0) {
        createNewLineItem(data.newCartItemHtml);
        return;
    }

    if (lineItem.variationAttributes) {
        var colorAttr = findItem(lineItem.variationAttributes, function (attr) {
            return attr.attributeId === 'color';
        });

        if (colorAttr) {
            var colorSelector = '.Color-' + uuid + ' .color-image__image';
            $(colorSelector).attr('src', lineItem.images.swatch[0].url);
            $(colorSelector).attr('alt', lineItem.images.swatch[0].alt);
            $(colorSelector).attr('title', lineItem.images.swatch[0].title);
        }

        var sizeAttr = findItem(lineItem.variationAttributes, function (attr) {
            return attr.attributeId === 'size';
        });

        if (sizeAttr) {
            var sizeSelector = '.line-item-attributes-value.Size-' + uuid;
            var newSize = sizeAttr.displayValue;
            $(sizeSelector).text(newSize);
        }

        var imageSelector = '.card.product-card.uuid-' + uuid + ' .item-image img';
        $(imageSelector).attr('src', lineItem.images.small[0].url);
        $(imageSelector).attr('alt', lineItem.images.small[0].alt);
        $(imageSelector).attr('title', lineItem.images.small[0].title);
    }

    if (lineItem.options && lineItem.options.length) {
        var option = lineItem.options[0];
        var optSelector = '.lineItem-options-values[data-option-id="' + option.optionId + '"]';
        $(optSelector).data('value-id', option.selectedValueId);
        $(optSelector + ' .line-item-attributes').text(option.displayName);
    }

    var quantityDesktop = '.line-item-attributes-value.Quantity-' + uuid;
    $(quantityDesktop).text(lineItem.quantity);

    var qtySelector = '.quantity[data-uuid="' + uuid + '"]';
    $(qtySelector).val(lineItem.quantity);
    $(qtySelector).data('pid', data.newProductId);

    $('.remove-product[data-uuid="' + uuid + '"]').data('pid', data.newProductId);

    var priceSelector = '.line-item-price-' + uuid + ' .sales .value';
    $(priceSelector).text(lineItem.price.sales.formatted);
    $(priceSelector).attr('content', lineItem.price.sales.decimalPrice);

    if (lineItem.price.list) {
        var listPriceSelector = '.line-item-price-' + uuid + ' .list .value';
        $(listPriceSelector).text(lineItem.price.list.formatted);
        $(listPriceSelector).attr('content', lineItem.price.list.decimalPrice);
    }
}

/**
 * Generates the modal window on the first call.
 *
 */
function getModalHtmlElement() {
    if ($('#editProductModal').length !== 0) {
        $('#editProductModal').remove();
    }
    var htmlString = '<!-- Modal -->'
        + '<div class="modal fade edit-product-modal" id="editProductModal" tabindex="-1" role="dialog">'
        + '<span class="enter-message sr-only" ></span>'
        + '<div class="modal-dialog quick-view-dialog">'
        + '<!-- Modal content-->'
        + '<div class="modal-content">'
        + '<div class="modal-header">'
        + '     <button type="button" class="close pull-right" data-dismiss="modal">'
        + '         <svg class="close-icon">'
        + '             <use xlink:href="#closeIcon"></use>'
        + '         </svg>'
        + '         <span class="sr-only"> </span>'
        + '     </button>'
        + '</div>'
        + '     <div class="modal-body"></div>'
        + '     <div class="modal-footer"></div>'
        + '</div>'
        + '</div>'
        + '</div>';
    $('body').append(htmlString);
}

/**
 * Parses the html for a modal window
 * @param {string} html - representing the body and footer of the modal window
 *
 * @return {Object} - Object with properties body and footer.
 */
function parseHtml(html) {
    var $html = $('<div>').append($.parseHTML(html));

    var body = $html.find('.product-quickview');
    var footer = $html.find('.modal-footer').children();

    return { body: body, footer: footer };
}

/**
 * replaces the content in the modal window for product variation to be edited.
 * @param {string} editProductUrl - url to be used to retrieve a new product model
 */
function fillModalElement(editProductUrl) {
    $('.modal-body').spinner().start();
    $.ajax({
        url: editProductUrl,
        method: 'GET',
        dataType: 'json',
        success: function (data) {
            var parsedHtml = parseHtml(data.renderedTemplate);

            $('#editProductModal').removeClass('show');
            $('#editProductModal .modal-body').empty();
            $('#editProductModal .modal-body').html(parsedHtml.body);
            $('#editProductModal .modal-footer').html(parsedHtml.footer);
            $('#editProductModal .modal-header .close .sr-only').text(data.closeButtonText);
            $('#editProductModal .enter-message').text(data.enterDialogMessage);
            $('#editProductModal').modal('show');
            $('#editProductModal').addClass('show');
            customSelects.customizeSelects();
            quickViewImageSlider();
            checkColorBrightness.checkColorBrightness();
            $.spinner().stop();
        },
        error: function () {
            $.spinner().stop();
        }
    });
}

/**
 * replace content of modal
 * @param {string} actionUrl - url to be used to remove product
 * @param {string} productID - pid
 * @param {string} productName - product name
 * @param {string} uuid - uuid
 */
function confirmDelete(actionUrl, productID, productName, uuid) {
    var $deleteConfirmBtn = $('.cart-delete-confirmation-btn');
    var $productToRemoveSpan = $('.product-to-remove');

    $deleteConfirmBtn.data('pid', productID);
    $deleteConfirmBtn.data('action', actionUrl);
    $deleteConfirmBtn.data('uuid', uuid);

    $productToRemoveSpan.empty().append(productName);
}

/**
 * Add/remove Gift Wrap to/from current basket
 * @param {string} actionUrl - url to be used to remove or add product. False: remove gift wrap / True: add gift wrap
 * @param {boolean} propChecked - gift wrap checked or not
 * @param {Object} inputElement - gift wrap input element. Not required
 */
function setGiftWrap(actionUrl, propChecked, inputElement) {
    $.ajax({
        url: actionUrl,
        method: 'POST',
        dataType: 'json',
        data: { isGift: propChecked },
        success: function (data) {
            if ($('.cart-gift__personalize').length > 0) {
                if (propChecked) {
                    $('.cart-gift__personalize').slideDown(350);
                } else {
                    $('.cart-gift__personalize').slideUp(350);
                }
            }

            cartHelpers.methods.updateCartTotals(data.basket);
            cartHelpers.methods.updateApproachingDiscounts(data.basket.approachingDiscounts);
            $('body').trigger('setShippingMethodSelection', data.basket);
            cartHelpers.methods.validateBasket(data.basket);

            if (data.uuidToBeDeleted !== null) {
                $('.uuid-' + data.uuidToBeDeleted).remove();
            } else if (data.giftWrapLineItemHtml && $('.cart-page-flex__left').length > 0) {
                $('.cart-page-flex__left').append(data.giftWrapLineItemHtml);
            }

            cartHelpers.methods.createSuccessNotification(data.successMessage);

            $('body').trigger('cart:update');
            $.spinner().stop();
        },
        error: function (err) {
            if (inputElement && inputElement.length > 0) {
                inputElement.prop('checked', !inputElement.prop('checked'));
            }

            if (err.responseJSON.redirectUrl) {
                window.location.href = err.responseJSON.redirectUrl;
            }

            if (err.responseJSON.errorMessage && err.responseJSON.errorMessage !== 'undefined') {
                cartHelpers.methods.createErrorNotification(err.responseJSON.errorMessage);
            }
            $.spinner().stop();
        }
    });
}

/**
 * Refactor gift wrap message removing capital letters that are not after a dot
 * @param {Object} giftMessage - gift wrap message input element
 */
function refactorGiftMessage(giftMessage) {
    var tmpChar;
    var tmpChar1;
    var preString;
    var postString;
    var tmpGiftMessage = giftMessage.value.toLowerCase();
    var strLen = tmpGiftMessage.length;

    if (strLen > 0) {
        for (var i = 0; i < strLen; i++) {
            if (i === 0) {
                tmpChar = tmpGiftMessage.substring(0, 1).toUpperCase();
                postString = tmpGiftMessage.substring(1, strLen);
                tmpGiftMessage = tmpChar + postString;
            } else {
                tmpChar = tmpGiftMessage.substring(i, i + 1);
                tmpChar1 = tmpGiftMessage.substring(i + 1, i + 2);
                if (tmpChar === '.' && tmpChar1 === ' ' && i < (strLen - 1)) {
                    tmpChar = tmpGiftMessage.substring(i + 2, i + 3).toUpperCase();
                    preString = tmpGiftMessage.substring(0, i + 2);
                    postString = tmpGiftMessage.substring(i + 3, strLen);
                    tmpGiftMessage = preString + tmpChar + postString;
                }
            }
        }
    }
    // eslint-disable-next-line no-param-reassign
    giftMessage.value = tmpGiftMessage;
}

/**
 * Shows corresponding promotion name
 *  in applied promotion info
 * @param {string} cashbackType - cashbackType
 */
function showCashbackPromotionName(cashbackType) {
    document.querySelectorAll('.promotion-name').forEach((name)=> {
        name.classList.add('d-none');
    });
    document.querySelector('.promotion-name--' + cashbackType).classList.remove('d-none');
}

module.exports = function () {
    recommendationsSlider.initSliders(document.querySelector('.cart-recommendations > div'));
    if (pageUtilities.isCartPage()) {
        minicartUtils.manageNoStockMessage();
    }

    $('body').on('click', '.remove-product.remove-product--gift-wrap', function (e) {
        // Unused event: Gift-wrap remove btn is hidden
        e.preventDefault();
        var actionUrl;
        var productID;
        var productName;
        var uuid;

        if (!pageUtilities.isCartPage()) {
            e.stopPropagation();
            actionUrl = $(this).data('gift-wrap-action');
            setGiftWrap(actionUrl, false);
        } else if ($('.cart-gift__check').length && $('#cart-gift-checkbox').prop('checked')) {
            e.stopPropagation();
            $('#maincontent').spinner().start();
            $('#cart-gift-checkbox').prop('checked', false).trigger('change');
        } else {
            actionUrl = $(this).data('action');
            productID = $(this).data('pid');
            productName = $(this).data('name');
            uuid = $(this).data('uuid');
            confirmDelete(actionUrl, productID, productName, uuid);
        }
    });

    $('body').on('afterRemoveFromCart', function (e, data) {
        e.preventDefault();
        confirmDelete(data.actionUrl, data.productID, data.productName, data.uuid);
    });

    $('body').on('click', '.remove-product:not(.saveForLater-remove-product, .confirm-remove-from-whislist)', function (e) {
        e.preventDefault();

        var productID = $(this).data('pid');
        var url = $(this).data('action');
        var uuid = $(this).data('uuid');
        var urlParams = {
            pid: productID,
            uuid: uuid
        };

        url = appendToUrl(url, urlParams);

        $('body > .modal-backdrop').remove();

        if (pageUtilities.isCartPage()) {
            $.spinner().start();
        } else {
            $(this).closest('.product-card')?.spinner().start();
        }

        $.ajax({
            url: url,
            type: 'get',
            dataType: 'json',
            success: function (data) {
                if (data.basket.items.length === 0) {
                    cartHelpers.methods.updateCartTemplate($('.emptyCartTemplate').html());

                    $('.number-of-items').empty().append(data.basket.resources.numberOfItems);
                    $('.minicart-quantity').empty().append(data.basket.numItems);
                    $('.minicart-link').attr({
                        'aria-label': data.basket.resources.minicartCountOfItems,
                        title: data.basket.resources.minicartCountOfItems
                    });
                    $('.minicart .popover').empty();
                    $('.minicart .popover').removeClass('show');
                    overlay.deleteOverlay();
                    $('body').removeClass('modal-open');
                    $('html').removeClass('veiled');
                } else {
                    if (data.toBeDeletedUUIDs && data.toBeDeletedUUIDs.length > 0) {
                        for (var i = 0; i < data.toBeDeletedUUIDs.length; i++) {
                            $('.uuid-' + data.toBeDeletedUUIDs[i]).remove();
                        }
                    }
                    $('.uuid-' + uuid).remove();
                    if (!data.basket.hasBonusProduct) {
                        $('.bonus-product').remove();
                    }
                    $('.coupons-and-promos').empty().append(data.basket.totals.discountsHtml);
                    minicartUtils.manageNoStockMessage();
                    cartHelpers.methods.updateCartTotals(data.basket);
                    cartHelpers.methods.updateApproachingDiscounts(data.basket.approachingDiscounts);
                    $('body').trigger('setShippingMethodSelection', data.basket);
                    cartHelpers.methods.validateBasket(data.basket);
                }

                cartHelpers.methods.createSuccessNotification(data.basket.resources.productRemoved, true);
                redemptionsAndCoupons.hideRedemptionAppliedMessage();

                processDataLayerCartActionOnProduct(data.dataLayerCartActionOnProduct);

                $('body').trigger('cart:update');

                $.spinner().stop();
            },
            error: function (err) {
                if (err.responseJSON.redirectUrl) {
                    window.location.href = err.responseJSON.redirectUrl;
                } else {
                    cartHelpers.methods.createErrorNotification(err.responseJSON.errorMessage);
                    $.spinner().stop();
                }
            }
        });
    });

    $('body').on('change', '.quantity-form > .quantity', function () {
        var preSelectQty = $(this).data('pre-select-qty');
        var quantity = $(this).val();
        var productID = $(this).data('pid');
        var url = $(this).data('action');
        var uuid = $(this).data('uuid');

        var urlParams = {
            pid: productID,
            preSelectQty: preSelectQty,
            quantity: quantity,
            uuid: uuid
        };
        url = appendToUrl(url, urlParams);

        $(this).parents('.card').spinner().start();

        $.ajax({
            url: url,
            type: 'get',
            context: this,
            dataType: 'json',
            success: function (data) {
                $('.quantity[data-uuid="' + uuid + '"]').val(quantity);
                $('.coupons-and-promos').empty().append(data.totals.discountsHtml);
                redemptionsAndCoupons.hideRedemptionAppliedMessage();
                cartHelpers.methods.updateCartTotals(data);
                showPaypalButtons(data);
                cartHelpers.methods.updateApproachingDiscounts(data.approachingDiscounts);
                updateAvailability(data, uuid);
                cartHelpers.methods.validateBasket(data);

                processDataLayerCartActionOnProduct(data.dataLayerCartActionOnProduct);

                $('body').trigger('cart:update');
                $(this).data('pre-select-qty', quantity);

                $.spinner().stop();
                if ($(this).parents('.product-info').hasClass('bonus-product-line-item') && $('.cart-page').length) {
                    // eslint-disable-next-line no-restricted-globals
                    location.reload();
                }
            },
            error: function (err) {
                if (err.responseJSON.redirectUrl) {
                    window.location.href = err.responseJSON.redirectUrl;
                } else {
                    cartHelpers.methods.createErrorNotification(err.responseJSON.errorMessage);
                    $(this).val(parseInt(preSelectQty, 10));
                    $.spinner().stop();
                }
            }
        });
    });

    $('.shippingMethods').change(function () {
        var url = $(this).attr('data-actionUrl');
        var urlParams = {
            methodID: $(this).find(':selected').attr('data-shipping-id')
        };
        // url = appendToUrl(url, urlParams);

        $('.totals').spinner().start();
        $.ajax({
            url: url,
            type: 'post',
            dataType: 'json',
            data: urlParams,
            success: function (data) {
                if (data.error) {
                    window.location.href = data.redirectUrl;
                } else {
                    $('.coupons-and-promos').empty().append(data.totals.discountsHtml);
                    cartHelpers.methods.updateCartTotals(data);
                    cartHelpers.methods.updateApproachingDiscounts(data.approachingDiscounts);
                    cartHelpers.methods.validateBasket(data);
                    showPaypalButtons(data);
                }
                $.spinner().stop();
            },
            error: function (err) {
                if (err.redirectUrl) {
                    window.location.href = err.redirectUrl;
                } else {
                    cartHelpers.methods.createErrorNotification(err.responseJSON.errorMessage);
                    $.spinner().stop();
                }
            }
        });
    });

    /**
     * Custom Event for Cupon Code Ajax Call
     */
    document.addEventListener('redemptionsAndCoupons:couponCode', function (e) {
        e.preventDefault();

        var codeInput = document.querySelector('.promo-code-form .form-control');
        if (!codeInput || !codeInput.value) {
            return;
        }

        $.spinner().start();
        var form = document.querySelector('.redemptions-coupons-form');
        var url = form.dataset.couponUrl;
        var serializedForm = new URLSearchParams(new FormData(form)).toString();

        $.ajax({
            url: url,
            type: 'GET',
            dataType: 'json',
            data: serializedForm,
            success: function (data) {
                if (data.error) {
                    $('.redemptions-coupons-form .invalid-feedback').empty().append(data.errorMessage);
                } else {
                    $('.coupons-and-promos').empty().append(data.totals.discountsHtml);
                    cartHelpers.methods.updateCartTotals(data);
                    showPaypalButtons(data);
                    cartHelpers.methods.updateApproachingDiscounts(data.approachingDiscounts);
                    cartHelpers.methods.validateBasket(data);
                }
            },
            error: function (err) {
                if (err.responseJSON?.redirectUrl) {
                    window.location.href = err.responseJSON.redirectUrl;
                } else {
                    cartHelpers.methods.createErrorNotification(err.errorMessage);
                }
            },
            complete: function () {
                codeInput.value = '';
                $.spinner().stop();
            }
        });
    });

    $('body').on('click', '.remove-coupon', function (e) {
        e.preventDefault();

        var couponCode = $(this).data('code');
        var uuid = $(this).data('uuid');
        var $deleteConfirmBtn = $('.delete-coupon-confirmation-btn');
        var $productToRemoveSpan = $('.coupon-to-remove');

        $deleteConfirmBtn.data('uuid', uuid);
        $deleteConfirmBtn.data('code', couponCode);

        $productToRemoveSpan.empty().append(couponCode);
    });

    /**
     * Remove coupon
     */
    $('body').on('click', '.delete-coupon-confirmation-btn', function (e) {
        e.preventDefault();

        var url = $(this).data('action');
        var uuid = $(this).data('uuid');
        var couponCode = $(this).data('code');
        var urlParams = {
            code: couponCode,
            uuid: uuid
        };

        url = appendToUrl(url, urlParams);

        $('body > .modal-backdrop').remove();

        $.spinner().start();
        $.ajax({
            url: url,
            type: 'get',
            dataType: 'json',
            success: function (data) {
                $('.coupon-uuid-' + uuid).remove();
                cartHelpers.methods.updateCartTotals(data);
                showPaypalButtons(data);
                cartHelpers.methods.updateApproachingDiscounts(data.approachingDiscounts);
                cartHelpers.methods.validateBasket(data);
                $('.promo-code-form .invalid-feedback').empty();
            },
            error: function (err) {
                if (err.responseJSON.redirectUrl) {
                    window.location.href = err.responseJSON.redirectUrl;
                } else {
                    cartHelpers.methods.createErrorNotification(err.responseJSON.errorMessage);
                }
            },
            complete: function () {
                $.spinner().stop();
            }
        });
    });

    /**
     * Custom Event for Loyalty Redemptions Ajax Call
     *  Cashback and PremiumBalance
    */
    document.addEventListener('redemptionsAndCoupons:loyaltyRedemptions', function (e) {
        e.preventDefault();
        $.spinner().start();

        var $form = $('.loyalty-cashback-form');
        var url = $form.data('loyalty-redemption-url');

        $.ajax({
            url: url,
            type: 'GET',
            dataType: 'json',
            data: $form.serialize(),
            success: function (data) {
                if (data.error) {
                    $('.loyalty-cashback-form .form-control').addClass('is-invalid');
                    $('.loyalty-cashback-form .form-control').attr('aria-describedby', 'invalidCashbackValue');
                    redemptionsAndCoupons.updateFormError(data.errorMessage);
                } else {
                    $('.coupons-and-promos').empty().append(data.totals.discountsHtml);
                    cartHelpers.methods.updateCartTotals(data);
                    showPaypalButtons(data);
                    cartHelpers.methods.updateApproachingDiscounts(data.approachingDiscounts);
                    cartHelpers.methods.validateBasket(data);

                    showCashbackPromotionName(data.cashbackType);
                    redemptionsAndCoupons.showRedemptionAppliedMessage();

                    if (data.cashbackType === BASE_CONFIG.loyalty.promotionType.cashback) {
                        $('.cashback-platinum-message').show();
                    } else {
                        $('.cashback-platinum-message').hide();
                    }
                }
            },
            error: function (err) {
                if (err.responseJSON?.redirectUrl) {
                    window.location.href = err.responseJSON.redirectUrl;
                } else {
                    cartHelpers.methods.createErrorNotification(err.errorMessage);
                }
            },
            complete: function () {
                $.spinner().stop();
            }
        });
        return false;
    });

    $('body').on('click', '.remove-price-adjustment', function (e) {
        e.preventDefault();
    });

    $('body').on('click', '.delete-priceAdjustment-confirmation-btn', function (e) {
        e.preventDefault();

        var url = $(this).data('action');

        $('body > .modal-backdrop').remove();

        $.spinner().start();
        $.ajax({
            url: url,
            type: 'get',
            dataType: 'json',
            success: function (data) {
                cartHelpers.methods.updateCartTotals(data);
                showPaypalButtons(data);
                cartHelpers.methods.updateApproachingDiscounts(data.approachingDiscounts);
                cartHelpers.methods.validateBasket(data);
                redemptionsAndCoupons.updateFormError();
                redemptionsAndCoupons.hideRedemptionAppliedMessage();
                $.spinner().stop();
            },
            error: function (err) {
                if (err.responseJSON.redirectUrl) {
                    window.location.href = err.responseJSON.redirectUrl;
                } else {
                    cartHelpers.methods.createErrorNotification(err.responseJSON.errorMessage);
                    $.spinner().stop();
                }
            }
        });
    });

    $('body').on('click', '.cart-page .bonus-product-button', function () {
        $.spinner().start();
        $(this).addClass('launched-modal');
        $.ajax({
            url: $(this).data('url'),
            method: 'GET',
            dataType: 'json',
            success: function (data) {
                base.methods.editBonusProducts(data);
                $.spinner().stop();
            },
            error: function () {
                $.spinner().stop();
            }
        });
    });

    $('body').on('hidden.bs.modal', '#chooseBonusProductModal', function () {
        $('#chooseBonusProductModal').remove();
        $('.modal-backdrop').remove();
        $('body').removeClass('modal-open');

        if ($('.cart-page').length) {
            $('.launched-modal .btn-outline-primary').trigger('focus');
            $('.launched-modal').removeClass('launched-modal');
        } else {
            $('.product-detail .add-to-cart').focus();
        }
    });

    $('body').on('click', '.cart-page .product-edit .edit, .cart-page .bundle-edit .edit', function (e) {
        e.preventDefault();

        var editProductUrl = $(this).attr('href');
        getModalHtmlElement();
        fillModalElement(editProductUrl);
    });

    $('body').on('shown.bs.modal', '#editProductModal', function () {
        $('#editProductModal').siblings().attr('aria-hidden', 'true');
        $('#editProductModal .close').focus();
    });

    $('body').on('hidden.bs.modal', '#editProductModal', function () {
        $('#editProductModal').siblings().attr('aria-hidden', 'false');
    });

    $('body').on('keydown', '#editProductModal', function (e) {
        var focusParams = {
            event: e,
            containerSelector: '#editProductModal',
            firstElementSelector: '.close',
            lastElementSelector: '.update-cart-product-global',
            nextToLastElementSelector: '.modal-footer .quantity-select'
        };
        focusHelper.setTabNextFocus(focusParams);
    });

    $('body').on('product:updateAddToCart', function (e, response) {
        // update global add to cart (single products, bundles)
        var dialog = $(response.$productContainer)
            .closest('.quick-view-dialog');

        $('.update-cart-product-global', dialog).attr('disabled',
            !$('.global-availability', dialog).data('ready-to-order')
            || !$('.global-availability', dialog).data('available'));
    });

    $('body').on('product:updateAvailability', function (e, response) {
        // bundle individual products
        $('.product-availability', response.$productContainer)
            .data('ready-to-order', response.product.readyToOrder)
            .data('available', response.product.available)
            .find('.availability-msg')
            .empty()
            .html(response.message);

        var dialog = $(response.$productContainer)
            .closest('.quick-view-dialog');

        if ($('.product-availability', dialog).length) {
            // bundle all products
            var allAvailable = $('.product-availability', dialog).toArray()
                .every(function (item) { return $(item).data('available'); });

            var allReady = $('.product-availability', dialog).toArray()
                .every(function (item) { return $(item).data('ready-to-order'); });

            $('.global-availability', dialog)
                .data('ready-to-order', allReady)
                .data('available', allAvailable);

            $('.global-availability .availability-msg', dialog).empty()
                .html(allReady ? response.message : response.resources.info_selectforstock);
        } else {
            // single product
            $('.global-availability', dialog)
                .data('ready-to-order', response.product.readyToOrder)
                .data('available', response.product.available)
                .find('.availability-msg')
                .empty()
                .html(response.message);
        }
    });

    $('body').on('product:afterAttributeSelect', function (e, response) {
        if ($('.modal.show .product-quickview .bundle-items').length) {
            $('.modal.show').find(response.container).data('pid', response.data.product.id);
            $('.modal.show').find(response.container).find('.product-id').text(response.data.product.id);
        } else {
            $('.modal.show .product-quickview').data('pid', response.data.product.id);
        }
    });

    $('body').on('change', '.quantity-select', function () {
        var selectedQuantity = $(this).val();
        $('.modal.show .update-cart-url').data('selected-quantity', selectedQuantity);
    });

    $('body').on('change', 'select[class*="select-"].options-select', function () {
        var selectedOptionValueId = $(this).children('option:selected').data('value-id');
        $('.modal.show .update-cart-url').data('selected-option', selectedOptionValueId);
    });

    /** Cart-EditProductLineItem  */
    $('body').on('click', '.update-cart-product-global', function (e) {
        e.preventDefault();

        var updateProductUrl = $(this).closest('.cart-and-ipay').find('.update-cart-url').val();
        var selectedQuantity = $(this).closest('.cart-and-ipay').find('.update-cart-url').data('selected-quantity');
        var selectedOptionValueId = $(this).closest('.cart-and-ipay').find('.update-cart-url').data('selected-option');
        var uuid = $(this).closest('.cart-and-ipay').find('.update-cart-url').data('uuid');

        var form = {
            uuid: uuid,
            pid: base.getPidValue($(this)),
            quantity: selectedQuantity,
            selectedOptionValueId: selectedOptionValueId
        };

        $(this).closest('.product-quickview').spinner().start();

        if (updateProductUrl) {
            $.ajax({
                url: updateProductUrl,
                type: 'post',
                context: this,
                data: form,
                dataType: 'json',
                success: function (data) {
                    $('#editProductModal').modal('hide');

                    $('.coupons-and-promos').empty().append(data.cartModel.totals.discountsHtml);
                    cartHelpers.methods.updateCartTotals(data.cartModel);
                    cartHelpers.methods.updateApproachingDiscounts(data.cartModel.approachingDiscounts);
                    updateAvailability(data.cartModel, uuid);
                    updateProductDetails(data, uuid);

                    if (data.uuidToBeDeleted) {
                        $('.uuid-' + data.uuidToBeDeleted).remove();
                    }

                    cartHelpers.methods.validateBasket(data.cartModel);

                    processDataLayerCartActionOnProduct(data.dataLayerCartActionAddOnProduct);
                    processDataLayerCartActionOnProduct(data.dataLayerCartActionRemoveOnProduct);

                    $('body').trigger('cart:update');

                    redemptionsAndCoupons.hideRedemptionAppliedMessage();

                    $.spinner().stop();
                },
                error: function (err) {
                    if (err.responseJSON.redirectUrl) {
                        window.location.href = err.responseJSON.redirectUrl;
                    } else {
                        cartHelpers.methods.createErrorNotification(err.responseJSON.errorMessage);
                        $.spinner().stop();
                    }
                }
            });
        }
    });

    $('body').on('change', '#cart-gift-checkbox', function () {
        $('.cart-page').spinner().start();

        setGiftWrap($(this).data('url'), $(this).prop('checked'), $(this));
    });

    $('body').on('click', '.personalize-gift__button', function (e) {
        e.preventDefault();

        $('.cart-gift__content').spinner().start();

        $.ajax({
            url: $(this).data('url'),
            method: 'POST',
            dataType: 'json',
            data: {
                signedby: $('.personalize-gift__signed-by').val(),
                message: $('.personalize-gift__message').val()
            },
            success: function (data) {
                if (data.error && data.errorMessage) {
                    cartHelpers.methods.createErrorNotification(data.errorMessage);
                } else {
                    cartHelpers.methods.createSuccessNotification(data.successMessage);
                }

                $.spinner().stop();
            },
            error: function (err) {
                cartHelpers.methods.createErrorNotification(err.responseJSON.errorMessage);
                $.spinner().stop();
            }
        });
    });

    $('.personalize-gift__preview-link').on('click', function (e) {
        e.preventDefault();

        var message = ($('.personalize-gift__message').length > 0) ? $('.personalize-gift__message').val() : '';
        var signedBy = ($('.personalize-gift__signed-by').length > 0) ? $('.personalize-gift__signed-by').val() : '';

        if ($('.gift-wrap-preview__message').length > 0 && message !== '') {
            $('.gift-wrap-preview__message').text(message);
        }

        if ($('.gift-wrap-preview__signed-by').length > 0 && signedBy !== '') {
            $('.gift-wrap-preview__signed-by').text(signedBy);
        }

        $('#giftWrapPreviewModal').show();
    });

    // Don't allow line breaks on personalize gif message
    $('.personalize-gift__message').on('keyup', function () {
        $(this).val($(this).val().replace(/[\r\n\v]+/g, ''));
    });

    $('.personalize-gift__message').on('keydown', debounce(function (e) {
        refactorGiftMessage(e.currentTarget);
    }, 300));

    window.addEventListener(
        'resize',
        throttle(function () {
            $('.minicart .popover').removeClass('show');
            overlay.deleteOverlay();
            overlay.resumePageScroll();
            minicartUtils.calculateMinicartPosition();
        }, 100),
        false
    );

    $('body').on('cart:UpdateCartPage', function (e, data) {
        // A product has been added in CartPage
        if ($('.full-cart').length <= 0) {
            cartHelpers.methods.updateCartTemplate(data.cartTemplate);
        } else {
            cartHelpers.methods.generalCartUpdate(data.cartModel);
            updateProductDetails(data, data.newProductUuid, true);
            minicartUtils.manageNoStockMessage();
        }
    });

    base.addToCart();
    base.sizeChart();
    base.selectAttribute();
    base.colorAttribute();
    base.productTileColor();
    base.removeBonusProduct();
    base.selectBonusProduct();
    base.enableBonusProductSelection();
    base.showMoreBonusProducts();
    base.addBonusProductsToCart();
    base.focusChooseBonusProductModal();
    base.trapChooseBonusProductModalFocus();
    base.onClosingChooseBonusProductModal();
    quickShop.init();

    if (BASE_CONFIG.isSaveForLaterEnabled) {
        var saveForLater = require('./saveForLater');
        saveForLater();
    }
};
