var RGBaster = require('rgbaster');

/**
 * Used to determine if a color is dark or light
 * @param {string} color Hexadecimal or rgb
 * @return {string} dark or light
 */
function lightOrDark(color) {
    // Variables for red, green, blue values
    var r; var g; var b; var hsp; var rgbColor;

    if (color && color !== 'none') {
        // Check the format of the color, HEX or RGB?
        if (color.match(/^rgb/)) {
            // If RGB --> store the red, green, blue values in separate variables
            rgbColor = color.match(/^rgba?\((\d+),\s*(\d+),\s*(\d+)(?:,\s*(\d+(?:\.\d+)?))?\)$/);

            r = rgbColor[1];
            g = rgbColor[2];
            b = rgbColor[3];
        } else {
            // If hex --> Convert it to RGB: http://gist.github.com/983661
            rgbColor = +('0x' + color.slice(1).replace(
                color.length < 5 && /./g, '$&$&'
            ));

            /* eslint-disable no-bitwise,no-mixed-operators */
            r = rgbColor >> 16;
            g = rgbColor >> 8 & 255;
            b = rgbColor & 255;
            /* eslint-enable no-bitwise,no-mixed-operators */
        }

        // HSP (Highly Sensitive Poo) equation from http://alienryderflex.com/hsp.html
        hsp = Math.sqrt(
            0.299 * (r * r)
            + 0.587 * (g * g)
            + 0.114 * (b * b)
        );

        // Using the HSP value, determine whether the color is light or dark
        if (hsp > 250) {
            return 'light';
        }
    }

    return 'dark';
}

/**
 * Used to add a class based on color brightness
 * @param {Object} element to add class
 * @param {string} brightness dark or light
 */
function setBrightnessClass(element, brightness) {
    if (brightness) {
        if (brightness === 'dark') {
            element.addClass('light-text');
        } else {
            element.addClass('dark-text');
        }
    }
}

/**
 * Used to extract predominant color from an image, background-image or background-color
 * @param {Object} element to extract color
 * @return {Promise} Promise with the predominant color
 */
function extractColor(element) {
    var imageSrc;
    if (element.is('img')) {
        imageSrc = element.src;
    } else if (element.css('background-image')) {
        var bgImage = element.css('background-image');
        imageSrc = bgImage.replace(/url\(['"]?([^'"]*)['"]?\)/, '$1');
    }
    if (imageSrc && imageSrc !== 'none') {
        return RGBaster(imageSrc).then(result => result[0].color);
    }
    return Promise.resolve(element.css('background-color'));
}

module.exports = {
    checkColorBrightness: function () {
        $('.check-color-brightness').each(function () {
            var element = $(this);
            extractColor(element).then(function (hex) {
                var brightness = lightOrDark(hex);
                setBrightnessClass(element, brightness);
            });
        });
    }
};
