/**
 * Run a function when DOMContentLoaded event is fired, or run immediately if
 * the DOM is already loaded.
 * @param {Function} fn Callback function
 * @returns {void}
 */
function onDOMContentLoaded(fn) {
    if (document.readyState === 'loading') {
        // Loading hasn't finished yet, add a listener with once option
        document.addEventListener('DOMContentLoaded', fn, { once: true });
    } else {
        // `DOMContentLoaded` has already fired
        fn();
    }
}

module.exports = onDOMContentLoaded;
