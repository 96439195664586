module.exports = function (element, showAtBottom = false) {
    var position = 0;
    const bottomSpacing = 16;

    if (element && element.length) {
        if (showAtBottom) {
            position = element.offset().top + element.innerHeight() - window.innerHeight + bottomSpacing;
        } else {
            position = element.offset().top - $('.page-header').innerHeight();
        }
    }

    $('html, body').animate({
        scrollTop: position
    }, 500);
};
