/**
 * Check whether an element exists in the DOM
 * @param {string} selector - CSS selector
 * @returns {boolean} - True if there is an element in the DOM tree for given
 * selector
 */
function existsElement(selector) {
    return !!document.querySelector(selector);
}

module.exports = {
    existsElement
};
