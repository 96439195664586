const { isFunction } = require('../type');

const mixin = {
    /**
     * Get an in-depth copy of an object
     * @returns {Object} Copied object
     */
    clone() {
        const model = this;
        const cloned = Object.getOwnPropertyNames(model).reduce(
            (result, key) => {
                let value = model[key];
                const includeIntoDTO = !isFunction(value);
                if (includeIntoDTO) {
                    if (isFunction(value?.clone)) {
                        value = value.clone();
                    }
                    // eslint-disable-next-line no-param-reassign
                    result[key] = value;
                }
                return result;
            },
            {}
        );
        Object.setPrototypeOf(cloned, Object.getPrototypeOf(model));
        return cloned;
    }
};

/**
 * Apply the mixin to be able to get an in-depth copy of an object
 * @param {Object} base Object to extend
 * @returns {Object} New object with new DTO-related methods
 */
function mixinClone(base) {
    return Object.assign({}, base, mixin);
}

module.exports = mixinClone;
