var focusState = false;
var tabFocusClass = 'keyfocus';
var CODE_TAB = 'Tab';

/**
 * Handle logic to change state to keyboard navigation.
 */
function onFocusInHandler() {
    focusState = true;
    document.body.classList.add(tabFocusClass);
    document.body.removeEventListener('focusin', onFocusInHandler);
}

/**
 * Handle logic to change state to keyboard navigation.
 */
function onFocusOutHandler() {
    focusState = false;
    document.body.classList.remove(tabFocusClass);
    document.body.removeEventListener('focusout', onFocusOutHandler);
}

/**
 * Handle logic to remove keyboard navigation state after onTabKeyPress to default.
 */
function onClickHandler() {
    focusState = false;
    document.body.classList.remove(tabFocusClass);
    document.body.removeEventListener('click', onClickHandler);
}

/**
 * Main logic: call differ actions onTabKeyPress and onClick
 */
function smartKeyboardFocus() {
    document.addEventListener('keydown', function (event) {
        if (event.key === CODE_TAB) {
            if (!focusState) {
                document.body.addEventListener('focusin', onFocusInHandler);
                document.body.addEventListener('click', onClickHandler);
            } else {
                document.body.addEventListener('focusout', onFocusOutHandler);
            }
        }
    });
}
module.exports = {
    init: function () {
        smartKeyboardFocus();
    },
    onFocusInHandler: onFocusInHandler,
    onClickHandler: onClickHandler,
    smartKeyboardFocus: smartKeyboardFocus
};
