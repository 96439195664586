const GTM_CONFIG = require('../../../../../../config/gtmConfig');

/**
 * Generate the ecommerce object for impressions
 * @param {Object[]} impressionList - impressions in Google format
 * @returns {DataLayerEvent} - DataLayerEvent
 */
const createEcommerceObjectForImpressions = (impressionList) => impressionList?.length > 0
    ? {
        impressions: impressionList
    }
    : undefined;

/**
 * Generate a DataLayer event for impressions (pageview or action)
 * @param {ImpressionFieldObject[]} impressionList - impressions
 * @returns {DataLayerEvent} - DataLayerEvent
 */
const createEvent = (impressionList) => impressionList?.length > 0
    ? {
        event: GTM_CONFIG.events.action,
        event_data: {
            category: GTM_CONFIG.eventCategories.impression,
            action: 'categoria',
            label: undefined
        },
        ecommerce: createEcommerceObjectForImpressions(impressionList)
    }
    : undefined;

/**
 * Extract the array of impressions from a DatalayerEvent.
 * Note: These impressions are POJOs with the format that GTM needs.
 *
 * @param {DataLayerEvent} datalayerEvent The event
 * @returns {Object[]} Array of impressions with the format that GTM needs
 */
function getListOfImpressionsFromEvent(datalayerEvent) {
    return datalayerEvent?.ecommerce?.impressions ?? [];
}

/**
 * Transform our impression model into the Google ImpressionFieldObject
 * @param {ImpressionFieldObject} impressionFieldObject Our impression model
 * @returns {Object} The Google's ImpressionFieldObject ready for Datalayer
 */
function parseToGoogleImpressionFieldObject(impressionFieldObject) {
    return impressionFieldObject.toDatalayer();
}

module.exports = {
    createEvent,
    createEcommerceObjectForImpressions,
    parseToGoogleImpressionFieldObject,
    getListOfImpressionsFromEvent
};
