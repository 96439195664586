const { internalEventCenter } = require('../../events');
const store = require('../../stores/lastListAndPositionWhereProductWasClickedStore');
const {
    makeSetEcommerceListAndPositionFromElement,
    makeGetEcommerceListAndPosition
} = require('../helpers/impressionBasedStoreFunctions');
const { getAdDatalayerProductIdFromDOM } = require('../../helpers/impressions');
const { isDoubleLookProduct, getCanonicalProductId } = require('../../../../utilities/doubleLookHelpers');
const { chainDefaultPromise } = require('../../../../utilities/promises');
const { unknownClickedListAndPosition } = require('../predefinedLists');
const createLinkHandlerBeforeRedirect = require('../../../../utilities/dom/createLinkHandlerBeforeRedirect');
const getAnalyticsConfig = require('../../../../common/getAnalyticsConfig');
const { createEcommerceListObject } = require('../../models/ecommerceListObject');
const { createEcommerceListAndPosition } = require('../../models/ecommerceListAndPosition');
const { delegateEvent } = require('../../../../utilities/javascriptUtils');

const analyticsConfig = getAnalyticsConfig();

const setEcommerceListAndPosition = makeSetEcommerceListAndPositionFromElement(
    store
);
const getEcommerceListAndPosition = makeGetEcommerceListAndPosition(
    store,
    setEcommerceListAndPosition
);

/**
 * Handles clicks on any banner category that links a single product.
 *
 * @constant {function} handleClickInBannerLinkedToProduct
 * @type {(event: Event) => Promise<void>}
 *
 * @param {Event} event - The click event.
 * @property {HTMLElement} event.target - The clicked element.
 * @property {object} event.detail - Custom event data.
 */
const handleClickInBannerLinkedToProduct = createLinkHandlerBeforeRedirect(
    (_, { linkElement }) => {
        const linkedProductId = linkElement?.dataset?.pid;
        if (linkedProductId) {
            const newEcommerceListAndPosition = createEcommerceListAndPosition({
                list: createEcommerceListObject({
                    referrerComponent:
                        analyticsConfig.list.referrerComponents.bannerCategory
                })
            });
            return store.setItem(linkedProductId, newEcommerceListAndPosition);
        }
        throw new Error(`Unknown product linked: ${linkedProductId}`);
    }
);

/**
 * Listen for some events that are useful for banners inside the list of PLP
 */
function attachBannerCategoryEvents() {
    delegateEvent(
        'click',
        '.grid-banner__link[data-pid]',
        handleClickInBannerLinkedToProduct
    );
}

/**
 * Track some UI events that may modify the last list where a product has been
 * viewed
 */
function track() {
    attachBannerCategoryEvents();

    internalEventCenter.subscribe(
        internalEventCenter.EVENTS.productClicked,
        async function ({ target }) {
            const adDatalayerProductId = await getAdDatalayerProductIdFromDOM(
                target
            );

            const promisesOfSetting = [
                setEcommerceListAndPosition(adDatalayerProductId, target)
            ];

            if (isDoubleLookProduct(adDatalayerProductId)) {
                /*
                We must record a click in the canonical PID for posible addings
                to cart. This is caused by the fact that the Cart controller
                omits the ID of the DL in endpoints like `AddProduct`.
                The events that are based in a DataLayerCartActionOnProduct from
                the server may lost the adDatalayerProductId of the Double Look
                in favour of the ID of the variation group
                */
                promisesOfSetting.push(
                    setEcommerceListAndPosition(
                        getCanonicalProductId(adDatalayerProductId),
                        target
                    )
                );
            }

            await Promise.all(promisesOfSetting);
        },
        true
    );

    internalEventCenter.subscribe(
        internalEventCenter.EVENTS.productColorClicked,
        ({ previousProductId, productFieldObject }) => {
            // Copy the list and position from previous color
            store.setItem(
                productFieldObject.addons.adDatalayerProductId,
                store.getItem(previousProductId)
            );
        }
    );
}

module.exports = {
    get: chainDefaultPromise(
        getEcommerceListAndPosition,
        unknownClickedListAndPosition
    ),
    setItem: store.setItem,
    track
};
