const getAnalyticsEventCenter = require('../common/getAnalyticsEventCenter');
const getAnalyticsConfig = require('../common/getAnalyticsConfig');
const nativefy = require('./dom/nativefy');
const { isEmpty } = require('./type');

const analyticsConfig = getAnalyticsConfig();
const analyticsEventCenter = getAnalyticsEventCenter();
/**
 * Process a dataLayerCartActionOnProduct received from server and publish the
 * event related in analyticsEventCenter.
 *
 * @param {DataLayerCartActionOnProduct} dataLayerCartActionOnProduct The object
 * @param {Element|jQuery} [productElement] The DOM element related with the
 * action. A product impression element or a descendant
 */
function processDataLayerCartActionOnProduct(dataLayerCartActionOnProduct, productElement) {
    if (isEmpty(dataLayerCartActionOnProduct?.productFieldObject)) return;

    const {
        cartAction,
        currencyCode,
        productFieldObject
    } = dataLayerCartActionOnProduct;
    const cartActions = analyticsConfig.dataLayerCartActionOnProductModel.cartAction;

    let analyticsEventName;
    if (cartAction === cartActions.add) {
        analyticsEventName = analyticsEventCenter.EVENTS.productAddedToCart;
    } else if (cartAction === cartActions.remove) {
        analyticsEventName = analyticsEventCenter.EVENTS.productRemovedFromCart;
    }

    const payload = {
        currencyCode,
        productFieldObject
    };

    if (productElement) {
        payload.target = nativefy(productElement);
    }

    analyticsEventCenter.publish(
        analyticsEventName,
        payload
    );
}

module.exports = {
    processDataLayerCartActionOnProduct
};
