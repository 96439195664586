const FAKE_SELECT_SELECTOR = '.fake-select';
const FAKE_SELECT_BUTTON = '.fake-select__button';
const FAKE_SELECT_ITEM = '.fake-select__item';
const FAKE_SELECT_LIST = '.fake-select__list';
const FAKE_SELECT_ORIGINAL_SELECT = '.fake-select__original-select';

/**
 * Updates fake select with given value
 * @param {HTMLElement} fakeSelect fake select
 * @param {string} value value
 */
function updateFakeSelectorSelected(fakeSelect, value) {
    if (!fakeSelect) return;
    var fakeOption = fakeSelect.querySelector(FAKE_SELECT_ITEM + '[data-value="' + value + '"]');
    var optionHtml = fakeOption.innerHTML;
    var fakeSelectButton = fakeSelect.querySelector(FAKE_SELECT_BUTTON);
    fakeSelectButton.innerHTML = optionHtml;
}

/**
 * Updates real hidden select with given value
 * @param {HTMLElement} fakeSelect real select
 * @param {string} value value
 */
function updateOriginalSelector(fakeSelect, value) {
    if (!fakeSelect) return;
    var originalSelect = fakeSelect.querySelector(FAKE_SELECT_ORIGINAL_SELECT);
    originalSelect.value = value;
}

/**
 * Update both real and fake selectors
 * @param {HTMLElement} fakeSelect fake select
 * @param {string} value value
 */
function updateSelect(fakeSelect, value) {
    updateFakeSelectorSelected(fakeSelect, value);
    updateOriginalSelector(fakeSelect, value);
}

/**
 * Show/Hide option list visibility
 * @param {HTMLElement} select fake select
 */
function toggleListVisibility(select) {
    var list = select.querySelector(FAKE_SELECT_LIST);
    list?.classList.toggle('d-none');
}

/**
 * Show/Hide selector options and handle button aria expanded attribute
 * @param {HTMLElement} select fake select
 */
function toggleFakeSelect(select) {
    if (!select) return;
    toggleListVisibility(select);
    var button = select.querySelector(FAKE_SELECT_BUTTON);
    var buttonExpandedStatus = button.getAttribute('aria-expanded') === 'true';
    button.setAttribute('aria-expanded', !buttonExpandedStatus);
}

/**
 * Inits event listener for every fake select option button
 */
function initButtonEventListeners() {
    var buttons = document.querySelectorAll(FAKE_SELECT_BUTTON);
    buttons.forEach(button => {
        var fakeSelect = button.closest(FAKE_SELECT_SELECTOR);
        button.addEventListener('click', () => {
            toggleFakeSelect(fakeSelect);
        });
    });
}

/**
 * Inits event listener for every fake select option item
 */
function initOptionItemsEventListeners() {
    var optionItems = document.querySelectorAll(FAKE_SELECT_ITEM);
    optionItems.forEach(item => {
        item.addEventListener('click', (e) => {
            e.preventDefault();
            var fakeSelect = item.closest('.fake-select');
            var value = item.dataset.value;
            updateSelect(fakeSelect, value);
            toggleFakeSelect(fakeSelect);
        });
    });
}

module.exports = {
    init: function () {
        initButtonEventListeners();
        initOptionItemsEventListeners();
    },
    methods: {
        updateSelect: updateSelect
    }
};
