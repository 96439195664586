const sum = require('hash-sum');

const hashPropertyName = '__hash_for_equals__';

const haveSameHash = (source, target) => source[hashPropertyName] === target[hashPropertyName];

// eslint-disable-next-line no-param-reassign
const hashIt = (target) => { target[hashPropertyName] = sum(target); };

const isNotHashed = (target) => !target[hashPropertyName];

const proxyHandler = {
    set(obj, prop, value) {
        // The default behavior to store the value
        // eslint-disable-next-line no-param-reassign
        obj[prop] = value;

        hashIt(obj);

        // Indicate success
        return true;
    }
};

const mixin = {
    /**
     * Get an in-depth copy of an object
     * @param {Object} target target to compare
     * @returns {boolean} Copied object
     */
    equals(target) {
        const model = this;
        if (isNotHashed(model)) {
            hashIt(model);
        }
        return haveSameHash(model, target);
    }
};

/**
 * Apply the mixin to be able compare with another obj
 * @param {Object} base Object to extend
 * @returns {Object} New object with equals method
 */
function mixinEquals(base) {
    const proxiedBase = new Proxy(base, proxyHandler);
    return Object.assign({}, proxiedBase, mixin);
}

module.exports = mixinEquals;
