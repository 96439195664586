/**
 * Wrap a function to get a promise with default result
 *
 * @param {function():Promise} targetFunction The function to add a default
 * promise as result
 * @param {*} defaultResult The result that must be returned if the target
 * function returns a void value
 * @returns {function():Promise} The new wrapped function
 */
function chainDefaultPromise(targetFunction, defaultResult) {
    return (...args) => targetFunction(...args).then(
        (result) => result || defaultResult
    );
}

module.exports = {
    chainDefaultPromise
};
