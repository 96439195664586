/**
 * Manages back link button
 * - Goes back in history.
 * - Behavior when there's no history
 *  @param {HTMLElement} element clicked link
 */
function goBackLinkHandler(element) {
    if (!document.referrer || document.referrer === '') {
        if (element.getAttribute('data-default-back')) {
            window.location = element.getAttribute('data-default-back');
            return;
        }

        var headerLogo = $('.navbar-header a.navbar-header__logo');
        if (headerLogo && headerLogo.attr('href')) {
            window.location = headerLogo.attr('href');
            return;
        }
    } else if (window.location.pathname.indexOf('checkout-begin') !== -1) {
        if (document.referrer.indexOf('checkout') !== -1) {
            window.location = element.getAttribute('data-default-back');
        } else {
            window.location = document.referrer;
        }
        return;
    } else if (document.referrer.indexOf('cgid') !== -1) {
        var pid = $('.product-detail.product-detail-page').attr('data-pid');
        window.localStorage.setItem('previousPid', pid);
    }

    window.history.back();
}

module.exports = function () {
    $('body').on('click', '.go-back-link', function (e) {
        e.preventDefault();
        goBackLinkHandler(this);
    });
};
