var base = require('base/components/clientSideValidation');
var nifHelper = require('../utilities/nifHelper');
const { filterEmoji } = require('../utilities/stringUtils');

/**
 * Clean any emoji char from an input text or textarea
 * @param {jQuery|Element} input - The form control to clean
 * @returns {void}
 */
function cleanEmojiFromInput(input) {
    var inputElement = input instanceof jQuery ? input.get(0) : input;
    var shouldCleanEmoji = inputElement.matches('textarea,input[type="text"]');
    if (shouldCleanEmoji) {
        inputElement.value = filterEmoji(inputElement.value);
    }
}

/**
 * Validate inputs and call valid/invalid actions
 * @param {Object} input element
 */
function validateInput(input) {
    if (input) {
        if (input.validity && !input.validity.valid) {
            $(input).trigger('invalid', this.validity);
            $(input).closest('.form-group').removeClass('is-valid');
        } else if (input.value && input.value !== '' && !$(input).is('select')) {
            if (input.id !== 'billingAdNif' || (input.id === 'billingAdNif' && nifHelper.isValidNif(input.value))) {
                $(input).closest('.form-group').addClass('is-valid');
            } else {
                var validationMessage = $(input).data('pattern-mismatch');
                $(input).parents('.form-group').find('.invalid-feedback').text(validationMessage);
                $(input).closest('.form-group').removeClass('is-valid');
            }
        }
    }
}

/**
 * Validate whole form. Requires `this` to be set to form object
 * @param {jQuery.event} event - Event to be canceled if form is invalid.
 * @returns {boolean} - Flag to indicate if form is valid
 */
function validateForm(event) {
    var valid = true;
    if (this.checkValidity && !this.checkValidity()) {
        // safari
        valid = false;
        if (event) {
            event.preventDefault();
            event.stopPropagation();
            event.stopImmediatePropagation();
        }
        $(this).find('input, select, textarea').each(function () {
            cleanEmojiFromInput(this);
            validateInput(this);
        });
    }
    return valid;
}

module.exports = {
    invalid: function () {
        $('form input, form select, form textarea').on('invalid', function (e) {
            e.preventDefault();
            this.setCustomValidity('');
            if (!this.validity.valid) {
                var validationMessage = this.validationMessage;
                $(this).addClass('is-invalid');
                if (this.validity.patternMismatch && $(this).data('pattern-mismatch')) {
                    validationMessage = $(this).data('pattern-mismatch');
                }
                if ((this.validity.rangeOverflow || this.validity.rangeUnderflow)
                    && $(this).data('range-error')) {
                    validationMessage = $(this).data('range-error');
                }
                if ((this.validity.tooLong || this.validity.tooShort)
                    && $(this).data('range-error')) {
                    validationMessage = $(this).data('range-error');
                }
                if (this.validity.valueMissing && $(this).data('missing-error')) {
                    validationMessage = $(this).data('missing-error');
                }
                $(this).parents('.form-group').find('.invalid-feedback')
                    .text(validationMessage);
            }
        });
    },
    submit: base.submit,
    buttonClick: base.buttonClick,
    formControlFocusOut: function () {
        $(document).on('focusout', '.form-control', function () {
            cleanEmojiFromInput(this);
            validateInput(this);
        });
    },
    functions: {
        validateForm: function (form, event) {
            validateForm.call($(form), event || null);
        },
        clearForm: base.clearForm
    }
};
