const getAnalyticsConfig = require('../../../common/getAnalyticsConfig');
const { queryProductImpressionElementFromDescendant } = require('./queryElement');
const { createProductFieldObject } = require('../models/productFieldObject');
const impressionsStore = require('../stores/impressionsStore');

const analyticsConfig = getAnalyticsConfig();

/* -------------------------------------------------------------------------- *\
 * MUST READ
 * ----------------------------------------
 * This file contains impression related helpers that MUST be outside
 * 'impression' directory for two reasons:
 *
 *  1. Avoid a require loop
 *  2. They are used by function outside 'impressions' context
\* -------------------------------------------------------------------------- */

/**
 * Get the impression data from the impressions store given a product impression
 * element or a descendant
 *
 * IMPORTANT NOTE:
 * Almost a copy of another 'getStoredImpressionFieldObjectFromDOM' from
 * 'datalayer/impressions/dom.js'.
 * In order to avoid a require loop we cannot import that function here.
 *
 * @async
 * @param {Element} element Product impression element or descendant
 * @returns {Promise<ImpressionFieldObject|null>} Datalayer impression
 */
const getStoredImpressionFieldObjectFromDOM = (element) => {
    const productImpressionElement = queryProductImpressionElementFromDescendant(element);
    const productImpressionId = productImpressionElement.getAttribute(
        analyticsConfig.dataAttributes.productImpressionId
    );
    return impressionsStore.getItem(productImpressionId);
};

/**
 * Get the product field object derived from a product impression element or a
 * descendant.
 *
 * @async
 * @param {Element} element Product impression element or descendant
 * @returns {Promise<ProductFieldObject|null>} The ProductFieldObject for the product
 * impression
 */
const getProductFieldObjectFromDOM = (element) => {
    return getStoredImpressionFieldObjectFromDOM(element).then(
        impressionFieldObject => impressionFieldObject !== null
            ? createProductFieldObject(
                impressionFieldObject.toDTO()
            )
            : null
    );
};

/**
 * Get the adDatalayerProductId by looking into impression store given a product
 * impression element or descendant
 *
 * @async
 * @param {Element} element Product impression element or descendant
 * @returns {Promise<stringImpression>} adDatalayerProductId
 */
const getAdDatalayerProductIdFromDOM = (element) => {
    return getStoredImpressionFieldObjectFromDOM(element)
        .then(
            (impressionFieldObject) => {
                if (impressionFieldObject === null) {
                    return Promise.reject(
                        new Error('Impression not found from the given element')
                    );
                }
                return impressionFieldObject.addons.adDatalayerProductId;
            }
        );
};

module.exports = {
    getAdDatalayerProductIdFromDOM,
    getStoredImpressionFieldObjectFromDOM,
    getProductFieldObjectFromDOM
};
