/**
 * Used to clean and expand the "collapsibles" from the size in which they should no longer work as collapsible
 * @param {string} size Collapsible type
 * @param {string} screen Screen size
 */
function cleanCollapsibles(size, screen) {
    if (size && screen) {
        if (size !== 'xxxl' && window.matchMedia('(min-width: ' + screen + 'px)').matches) {
            var parent = $('.collapsible-' + size);
            parent.removeClass('active');
            parent.find('.title').attr('aria-expanded', false);
            parent.find('.content').removeAttr('style');
        }
    }
}

module.exports = function () {
    var sizes = {
        xs: '375', sm: '768', md: '1025', lg: '1280', xl: '1440', xxl: '1920', xxxl: ''
    };

    jQuery.each(sizes, function (size, screen) {
        var selector = '.collapsible-' + size + ' .title';

        $('body').on('click', selector, function (e) {
            if (size === 'xxxl' || window.matchMedia('(max-width: ' + screen + 'px)').matches) {
                e.preventDefault();
                var parent = $(this).parents('.collapsible-' + size);
                var isFilterCollapsible = parent.hasClass('refinement');
                if (!isFilterCollapsible) {
                    parent.siblings().removeClass('active');
                    parent.siblings().find('.content').slideUp(350);
                    parent.siblings().find('.title').attr('aria-expanded', false);
                }

                parent.toggleClass('active');
                parent.find('.content').slideToggle(350);

                if (parent.hasClass('active') && parent.hasClass('scroll-to-content')) {
                    $('html, body').animate({
                        scrollTop: parent.offset().top + $(window).height()
                    }, 350);
                }

                if (parent.hasClass('active')) {
                    $(this).attr('aria-expanded', true);
                } else {
                    $(this).attr('aria-expanded', false);
                }
            }
        });

        window.addEventListener('resize', function () {
            cleanCollapsibles(size, screen);
        }, false);

        window.addEventListener('orientationchange', function () {
            cleanCollapsibles(size, screen);
        }, false);
    });
};
