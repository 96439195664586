/* INHERITED FROM MAGENTO 1 */
jQuery(document).ready(function () {
    /**
     * Show "centimeter" or "inch" tables depending on the selected tab
     */
    jQuery('.size-chart-modal__nav .nav-link').on('click', function () {
        var classActivNavS = jQuery(this).attr('id');
        var textNavCent = jQuery(this).attr('class');
        if (classActivNavS === 'centimetros-tab') {
            if (textNavCent.indexOf('active') === -1) {
                jQuery('.size-chart-modal__nav .nav-link').removeClass('active');
                jQuery(this).addClass('active');
                jQuery('.wrapperContentTallas .contentTallas').each(function () {
                    jQuery(this).children('.articleTallas').children('.contentGeneralTallas').children('.contentGuia.pulgadas')
                        .removeClass('activo');
                    jQuery(this).children('.articleTallas').children('.contentGeneralTallas').children('.contentGuia.centimetros')
                        .addClass('activo');
                });
            }
        } else if (textNavCent.indexOf('active') === -1) {
            jQuery('.size-chart-modal__nav .nav-link').removeClass('active');
            jQuery(this).addClass('active');
            jQuery('.wrapperContentTallas .contentTallas').each(function () {
                jQuery(this).children('.articleTallas').children('.contentGeneralTallas').children('.contentGuia.centimetros')
                    .removeClass('activo');
                jQuery(this).children('.articleTallas').children('.contentGeneralTallas').children('.contentGuia.pulgadas')
                    .addClass('activo');
            });
        }
    });

    /**
     * Show hover effect on the size tables
     */
    jQuery('.wrapperContentTallas .contentTallas .articleTallas .contentGeneralTallas .contentGuia .tablaTallas .columnTalla .tallaLi').on('mouseover', function () {
        jQuery('.wrapperContentTallas .contentTallas .articleTallas .contentGeneralTallas .contentGuia .tablaTallas .columnTalla').removeClass('activo');
        jQuery('.wrapperContentTallas .contentTallas .articleTallas .contentGeneralTallas .contentGuia .tablaTallas .columnTalla .tallaLi').removeClass('activo');
        jQuery(this).parent('.columnTalla').addClass('activo');

        jQuery(this).addClass('activo');

        jQuery('.wrapperContentTallas .contentTallas .articleTallas .contentGeneralTallas .contentGuia .tablaTallas .columnTalla.activo .tallaLi').each(function (index) {
            var numPosTalla = jQuery(this).attr('class');
            if (numPosTalla.indexOf('activo') !== -1) {
                var isActiveYes = index;
                jQuery(this).parent('.columnTalla').parent('.tablaTallas').children('.columnTalla.one')
                    .children('.tallaLi')
                    .eq(isActiveYes)
                    .addClass('activo');
                jQuery(this).parent('.columnTalla').parent('.tablaTallas').children('.columnTalla.two')
                    .children('.tallaLi')
                    .eq(isActiveYes)
                    .addClass('activo');
                jQuery(this).parent('.columnTalla').parent('.tablaTallas').children('.columnTalla.three')
                    .children('.tallaLi')
                    .eq(isActiveYes)
                    .addClass('activo');
                jQuery(this).parent('.columnTalla').parent('.tablaTallas').children('.columnTalla.four')
                    .children('.tallaLi')
                    .eq(isActiveYes)
                    .addClass('activo');
                jQuery(this).parent('.columnTalla').parent('.tablaTallas').children('.columnTalla.five')
                    .children('.tallaLi')
                    .eq(isActiveYes)
                    .addClass('activo');
                jQuery(this).parent('.columnTalla').parent('.tablaTallas').children('.columnTalla.six')
                    .children('.tallaLi')
                    .eq(isActiveYes)
                    .addClass('activo');
                jQuery(this).parent('.columnTalla').parent('.tablaTallas').children('.columnTalla.seven')
                    .children('.tallaLi')
                    .eq(isActiveYes)
                    .addClass('activo');
                jQuery(this).parent('.columnTalla').parent('.tablaTallas').children('.columnTalla.eight')
                    .children('.tallaLi')
                    .eq(isActiveYes)
                    .addClass('activo');
                jQuery(this).parent('.columnTalla').parent('.tablaTallas').children('.columnTalla.nine')
                    .children('.tallaLi')
                    .eq(isActiveYes)
                    .addClass('activo');
            }
        });
    });
    jQuery('.wrapperContentTallas .contentTallas .articleTallas .contentGeneralTallas .contentGuia .tablaTallas .columnTalla .tallaLi').on('mouseleave', function () {
        jQuery('.wrapperContentTallas .contentTallas .articleTallas .contentGeneralTallas .contentGuia .tablaTallas .columnTalla').removeClass('activo');
        jQuery('.wrapperContentTallas .contentTallas .articleTallas .contentGeneralTallas .contentGuia .tablaTallas .columnTalla .tallaLi').removeClass('activo');
    });
});
