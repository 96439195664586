const checkIfCurrentPage = require('../../../helpers/checkIfCurrentPage');
const getAnalyticsConfig = require('../../../../../common/getAnalyticsConfig');
const { constants: { propNames }, createEcommerceListObject } = require('../../../models/ecommerceListObject');
const { getReferrerComponentFromElement } = require('../../helpers/listChunkReferrerComponent');
const { getCurrentPageFromDOM } = require('../../helpers/listChunkReferrerPage');
const { getReferrerProductIdFromDOM } = require('../../helpers/listChunkReferrerProductId');
const store = require('./store');
const { getCurrentCategoryListIdFromDOM } = require('../../helpers/listChunkCategoryListId');
const nativefy = require('../../../../../utilities/dom/nativefy');
const { isEmpty } = require('../../../../../utilities/type');
const { internalEventCenter } = require('../../../events');
const createLinkHandlerBeforeRedirect = require('../../../../../utilities/dom/createLinkHandlerBeforeRedirect');
const { delegateEvent } = require('../../../../../utilities/javascriptUtils');

const analyticsConfig = getAnalyticsConfig();

/**
 * Handles clicks on any banner category rendered in PLP before redirection.
 *
 * This handler is created using `createLinkHandlerBeforeRedirect`, ensuring
 * that any required actions (such as tracking data layer updates) are executed
 * before the user is redirected.
 *
 * @constant {function} handleClickInBannerCategory
 * @type {(event: Event) => Promise<void>}
 *
 * @param {Event} event - The click event.
 * @property {HTMLElement} event.target - The clicked element.
 * @property {object} event.detail - Custom event data.
 *
 * @example
 * document.addEventListener('click', handleClickInBannerCategory);
 */
const handleClickInBannerCategory = createLinkHandlerBeforeRedirect(() => {
    const newDatalayerListContext = createEcommerceListObject({
        referrerComponent: analyticsConfig.list.referrerComponents.bannerCategory
    });
    return store.set(newDatalayerListContext);
});

/**
 * Handle the click in or inside a Page Designer component in order to save a
 * new list context
 * @param {jQueryEvent} evt The event
 * @param {Object} [options] Optional fixed values
 * @param {string} [options.referrerComponentElement] The element which contains
 * the info of referrer component. Use in delega
 * @param {string} [options.urlToRedirect] Url to redirect after the tracking
 * @returns {void}
 */
function handleClickInReferrerComponent(evt, {
    referrerComponentElement,
    urlToRedirect
} = {}) {
    if (window.google_tag_manager) {
        evt.preventDefault();
        const clickedElement = nativefy(referrerComponentElement ?? $(this));

        // Use the given params or extract the data from the clicked element

        const nextLocation = urlToRedirect ?? clickedElement.href;
        if (!nextLocation) return;

        const referrerComponent = getReferrerComponentFromElement(clickedElement);

        const newDatalayerListContext = createEcommerceListObject({
            listPrefix: undefined,
            referrerPage: getCurrentPageFromDOM(),
            referrerComponent: referrerComponent,
            categoryListId: undefined
        });
        store.set(newDatalayerListContext);

        const willAnotherHandlerRedirect = clickedElement.classList.contains('js-dl-product-click');
        if (!willAnotherHandlerRedirect) {
            window.location = nextLocation;
        }
    }
}

/**
 * Listen for some events that are useful for banners inside the list of PLP
 */
function attachBannerCategoryEvents() {
    delegateEvent(
        'click',
        '.grid-banner__link',
        handleClickInBannerCategory
    );
}

/**
 * Listen for some events that are useful for lists with category
 */
function attachCategoryTrackingEvents() {
    $('.dropdown-link:not(.dropdown-toggle)').click(function (e) {
        e.preventDefault();
        var target = e.currentTarget;

        const newDatalayerListContext = createEcommerceListObject({
            categoryListId: target.getAttribute('data-category-list'),
            listPrefix: analyticsConfig.list.prefixes.catalog,
            // Category links from menu never register the page
            referrerPage: undefined
        });
        store.set(newDatalayerListContext);

        window.location = target.href;
    });

    $('.floating-navigation-item__link, .floating-plp-navigation-item__link').click(function (e) {
        e.preventDefault();
        var target = e.currentTarget;

        const newDatalayerListContext = createEcommerceListObject({
            categoryListId: target.getAttribute('data-category-list'),
            listPrefix: undefined,
            referrerComponent: analyticsConfig.list.referrerComponents.floatingNavigation,
            referrerPage: getCurrentPageFromDOM()
        });
        store.set(newDatalayerListContext);

        window.location = $(this).attr('href');
    });
}

/**
 * Listen for some events in page designer components that are useful for
 * tracking the referrer component of a datalayer list.
 */
function attachPageDesignerEvents() {
    // PD Component with links inside: image, two-images, image-text, image-w-caption, etc
    $('body').on(
        'click',
        '.pd-image a, .pd-two-images a, .pd-three-images a, .pd-image-text a, .pd-image-w-text-caption a, .pd-image-carousel-slide a',
        handleClickInReferrerComponent
    );

    // PD Component with direct link: buttons...
    $('body').on(
        'click',
        '.pd-button, .pd-inspirational-slider__button',
        handleClickInReferrerComponent
    );

    /*
    PD Component with special programmed link: pd-video
    ---------------------------------------------------
    video.pd-video__video has no pointer-events (inline styles), so we must to
    use the ancestor to detect the click
    */
    $('body').on(
        'click',
        '.pd-video',
        function (e) {
            const video = $(this).find('.pd-video__video');
            if (video && video.data('href') && video.data('hidecontrols') && video.data('autoplay')) {
                handleClickInReferrerComponent(e, {
                    referrerComponentElement: video,
                    urlToRedirect: video.data('href')
                });
            }
        }
    );
}

/**
 * Track some UI events that may trigger a new list context.
 * @async
 * @returns {Promise<void>} Result
 */
async function track() {
    attachBannerCategoryEvents();
    attachCategoryTrackingEvents();
    attachPageDesignerEvents();

    internalEventCenter.subscribe(
        internalEventCenter.EVENTS.productClicked,
        async function () {
            return store.get().then((listContext) => {
                const isDirectPDP = (
                    listContext.listPrefix === analyticsConfig.list.prefixes.direct
                    && !!listContext.referrerProductId
                    && !listContext.categoryListId
                );
                if (isDirectPDP) {
                    // List prefix cannot be /direct after clicking in another
                    // product
                    return store.update({
                        toReset: [propNames.listPrefix]
                    });
                }
                return Promise.resolve();
            });
        },
        true
    );

    const contextToSet = {};
    const contextToReset = [];

    if (
        checkIfCurrentPage().isHomePage
        || checkIfCurrentPage().isCartPage
        || checkIfCurrentPage().isStorePage.blackFriday
    ) {
        /* ------------------------------------------------------------------ *\
         * Case: Well-known page
        \* ------------------------------------------------------------------ */

        contextToSet.referrerPage = getCurrentPageFromDOM();
        contextToReset.push(
            propNames.categoryListId,
            propNames.listPrefix,
            propNames.referrerComponent,
            propNames.referrerProductId
        );
    } else if (
        checkIfCurrentPage().isSearchPage
        || checkIfCurrentPage().isLandingPageOfCategory
    ) {
        /* ------------------------------------------------------------------ *\
         * Case: PLP
        \* ------------------------------------------------------------------ */

        contextToSet.categoryListId = getCurrentCategoryListIdFromDOM();
        if (checkIfCurrentPage().hasExternalReferrer) {
            contextToSet.listPrefix = analyticsConfig.list.prefixes.direct;
            /* Direct accesses to PLP must force the reset of cached page and
            component */
            contextToReset.push(
                propNames.referrerPage,
                propNames.referrerComponent
            );
        }
        // No single product in PLP, only in PDP.
        contextToReset.push(
            propNames.referrerProductId
        );
    } else if (checkIfCurrentPage().isProductDetailPage) {
        /* ------------------------------------------------------------------ *\
         * Case: PDP
        \* ------------------------------------------------------------------ */

        if (checkIfCurrentPage().hasExternalReferrer) {
            // Reset the clicked category if we come from an external referrer
            // Await to avoid colisioning with next write
            contextToSet.listPrefix = analyticsConfig.list.prefixes.direct;
            contextToReset.push(
                propNames.categoryListId,
                propNames.referrerComponent,
                propNames.referrerPage
            );
        }

        // We track the referrer product only once, avoiding check this on every
        // product clicked. This info is about the context, and we should update
        // every time we think that the PDP or the PID can vary.
        var referrerProductId = getReferrerProductIdFromDOM();
        if (referrerProductId) {
            contextToSet.referrerProductId = referrerProductId;
        } else {
            contextToReset.push(propNames.referrerProductId);
        }
    }

    let lastPromise;
    const willSet = !isEmpty(contextToSet);
    const willReset = !isEmpty(contextToReset);
    if (willReset || willSet) {
        lastPromise = store.update({
            toReset: willReset ? contextToReset : undefined,
            toSet: willSet ? createEcommerceListObject(contextToSet) : undefined
        });
    }

    return lastPromise ?? Promise.resolve();
}

module.exports = {
    track,
    /**
     * Get current list context
     * @async
     * @returns {Promise.<EcommerceListObject>} list context
     */
    getListContext() {
        return store.get();
    }
};
