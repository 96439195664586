/**
 * Boolean attribute (HTML)
 *
 * A boolean attribute in HTML is an attribute that represents true or false
 * values. If an HTML tag contains a boolean attribute - no matter the value
 * of that attribute - the attribute is set to true on that element. If an HTML
 * tag does not contain the attribute, the attribute is set to false.
 *
 * Example:
 *  ```
 *      <!-- The following checkboxes will be checked on initial rendering -->
 *      <input type="checkbox" checked />
 *      <input type="checkbox" checked="" />
 *      <input type="checkbox" checked="checked" />
 *
 *      <!-- The following checkbox will not be checked on initial rendering -->
 *      <input type="checkbox" />
 *  ```
 *
 * [more]{@link https://developer.mozilla.org/en-US/docs/Glossary/Boolean/HTML}
 */

/**
 * Get the boolean value of a boolean attribute
 *
 * @param {Element} element The target element of the DOM
 * @param {string} attrName The name of the attribute
 * @returns {boolean} The boolean value of the attribute
 */
function getBooleanAttribute(element, attrName) {
    return element.hasAttribute(attrName);
}

/**
 * Set a boolean attribute into an element
 * @param {Element} element The target element of the DOM
 * @param {string} attrName The name of the attribute
 * @param {boolean} bool New value
 * @returns {void}
 */
function setBooleanAttribute(element, attrName, bool) {
    if (bool) {
        element.setAttribute(attrName, '');
    } else {
        element.removeAttribute(attrName);
    }
}

module.exports = {
    getBooleanAttribute: getBooleanAttribute,
    setBooleanAttribute: setBooleanAttribute
};
