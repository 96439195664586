const internalEventCenter = require('./events').internalEventCenter;
const eventTracker = require('./eventTracker');
const impressions = require('./impressions');
const pageview = require('./pageview');
const ecommerceListTracking = require('./ecommerceListTracking');

var isInitialized = false;
var onReadyCallbacks = [];

const exportedToWindow = {
    onReady: function (callback) {
        if (isInitialized) {
            callback();
        } else {
            onReadyCallbacks.push(callback);
        }
    },
    analyticsEventCenter: internalEventCenter
};

window.adAnalytics = exportedToWindow;

module.exports = {
    init: async function () {
        isInitialized = false;
        await ecommerceListTracking.init();
        impressions.init();
        eventTracker.init();
        pageview.init();

        isInitialized = true;
        onReadyCallbacks.forEach(cb => cb());
    }
};
