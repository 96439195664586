/* eslint-disable implicit-arrow-linebreak */
const { queryProductImpressionElementFromDescendant } = require('../../helpers/queryElement');
const { getEcommerceListAndPositionFromImpressionOfElement } = require('./ecommerceListAndPosition');
const { trackUpdate } = require('../trackers/lastUpdate');

const makeSetEcommerceListAndPositionFromElement = (store) =>
    /**
     * Build the list and position of the product which is ancestor of the element
     * provided
     * @async
     * @param {string} adDatalayerProductId variation group id
     * @param {Element} element Node of the DOM
     * @returns {Promise<EcommerceListAndPosition>} The list and position just stored
     */
    function setEcommerceListAndPositionFromElement(adDatalayerProductId, element) {
        const productImpressionElement = queryProductImpressionElementFromDescendant(element);
        return getEcommerceListAndPositionFromImpressionOfElement(
            productImpressionElement
        ).then(
            (listAndPosition) => store.setItem(
                adDatalayerProductId,
                listAndPosition
            )
        ).then(trackUpdate);
    };

const makeGetEcommerceListAndPosition = (store, setEcommerceListAndPositionFromElement) =>
    /**
     * Get the last list and position where the product was interacted
     * @param {string} adDatalayerProductId The target product
     * @param {Element} [productElement] The element of the product or descendant.
     * Used as fallback to calculate the result from DOM.
     * @returns {Promise.<EcommerceListAndPosition>} list and position
     */
    function getEcommerceListAndPosition(adDatalayerProductId, productElement) {
        return store
            .getItem(adDatalayerProductId)
            .then(
                (listAndPosition) => (listAndPosition === null && productElement)
                    ? setEcommerceListAndPositionFromElement(adDatalayerProductId, productElement)
                    : listAndPosition
            );
    };

module.exports = {
    makeGetEcommerceListAndPosition,
    makeSetEcommerceListAndPositionFromElement
};
