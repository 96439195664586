const { compose } = require('../../../utilities/fp');
const mixinClone = require('../../../utilities/mixins/clone');
const mixinDTO = require('../../../utilities/mixins/dto');
const { isString, isObject } = require('../../../utilities/type');
const { isEcommerceListObject, createEcommerceListObject } = require('./ecommerceListObject');

/**
 * ImpressionFieldObject model
 * ---------------------------
 * Data structure with product impression related info.
 *
 * Differences with ProductFieldObject
 * -----------------------------------
 * The differences are few. For instance, the impression does not need it the
 * quantity. So, nowadays the main difference is the concept and the purpose of
 * each model.
 *
 * @link https://developers.google.com/analytics/devguides/collection/ua/gtm/enhanced-ecommerce?hl=es#product-impressions
 *
 * @typedef {object} ImpressionFieldObject
 * @property {string} id - ID of the master (no color, no size)
 * @property {string} name - The name of the product
 * @property {string} brand - 'hombre' | 'mujer'
 * @property {string} price - Price of the product
 * @property {string} category - Category of the product
 * @property {string} variant - Color of the product
 * @property {EcommerceListObject|string} list - List of the product
 * @property {number} [position] - The position of the product in the list
 * @property {string} dimension1 - Size of the product
 * @property {string} dimension7 - is the product in discount ('true' or 'false')
 * @property {string} content_ids - Variation Group ID for Facebook's pixel or variant ID
 * @property {Object} addons - Addons required for workflow of the app
 * @property {string} addons.adDatalayerProductId - The adDatalayerProductId
 * which is usually the Variation Group ID
 * @property {function():ImpressionFieldObject} clone - Get an in-depth copy
 * @property {function():object} toDatalayer - Get the data of the object for
 * sending in a datalayer event
 * @property {function():object} toDTO - Get the raw data of the object list to
 * store it
 */

const applyMixins = compose(mixinDTO, mixinClone);

const ImpressionFieldObjectProto = applyMixins({
    toDatalayer() {
        const item = {
            id: this.id,
            name: this.name,
            brand: this.brand,
            price: this.price,
            category: this.category,
            variant: this.variant,
            list: isEcommerceListObject(this.list)
                ? this.list.toDatalayer()
                : this.list,
            position: this.position,
            dimension1: this.dimension1,
            dimension7: this.dimension7,
            content_ids: this.content_ids
        };
        return item;
    },
    /**
     * Update the object with the list and position of an oncoming
     * EcommerceListAndPosition.
     * No side-effects. New instace is returned.
     * @async
     * @param {EcommerceListAndPosition|Promise<EcommerceListAndPosition>} ecommerceListAndPosition The new data
     * @returns {Promise<ImpressionFieldObject>} The new instance with the updated data
     */
    async updateListAndPosition(ecommerceListAndPosition) {
        const { list, position } = await ecommerceListAndPosition;
        const updated = this.clone();
        updated.list = list;
        updated.position = position;
        return updated;
    }
});

/**
 * Create an impression field object
 *
 * @param {Object} dto - DTO of an ImpressionFieldObject
 * @param {string} dto.id - ID of the master (no color, no size)
 * @param {string} dto.name - The name of the product
 * @param {string} dto.brand - 'hombre' | 'mujer'
 * @param {string} dto.price - Price of the product
 * @param {string} dto.category - Category of the product
 * @param {string} dto.variant - Color of the product
 * @param {EcommerceListObject|string} dto.list - List of the product
 * @param {number} dto.position - The position of the product in the list
 * @param {string} dto.dimension1 - Size of the product
 * @param {string} dto.dimension7 - is the product in discount ('true' or 'false')
 * @param {string} dto.content_ids - Variation Group ID for Facebook's pixel or variant ID
 * @param {Object} dto.addons - Addons required for workflow of the app
 * @param {string} dto.addons.adDatalayerProductId - The adDatalayerProductId
 * which is usually the Variation Group ID
 * @returns {ImpressionFieldObject} new instance
 */
function createImpressionFieldObject(dto) {
    const instance = Object.create(ImpressionFieldObjectProto);
    instance.id = dto.id;
    instance.name = dto.name;
    instance.brand = dto.brand;
    instance.price = dto.price;
    instance.category = dto.category;
    instance.variant = dto.variant;
    instance.dimension1 = dto.dimension1;
    instance.dimension7 = dto.dimension7;
    instance.content_ids = dto.content_ids;
    instance.addons = dto.addons;

    if (isEcommerceListObject(dto.list) || isString(dto.list)) {
        instance.list = dto.list;
    } else if (isObject(dto.list)) {
        instance.list = createEcommerceListObject(dto.list);
    } else {
        instance.list = dto.list;
    }

    instance.position = dto.position;

    return instance;
}

module.exports = {
    ImpressionFieldObjectProto,
    createImpressionFieldObject
};
