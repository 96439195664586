const checkIfCurrentPage = require('../../helpers/checkIfCurrentPage');
const getAnalyticsConfig = require('../../../../common/getAnalyticsConfig');
const { queryListRootElement, queryStorepageRootElement, queryProductImpressionElements } = require('../../helpers/queryElement');

const analyticsConfig = getAnalyticsConfig();

/**
 * Get the position of a product tile inside a parent datalayer list.
 * Calculate the position from number 1 (not zero).
 * @param {Element} targetProductTile - Node of the product tile
 * @param {Element} [parent] - Node with data-datalayer-list
 * @returns {number} - position from 1..n
 */
const getProductPositionFromDOM = (targetProductTile, parent) => {
    let position = targetProductTile.getAttribute(
        analyticsConfig.dataAttributes.productImpressionPosition
    );

    const mustCalculatePositionForTheFirstTime = !position;
    if (mustCalculatePositionForTheFirstTime) {
        let root = parent;

        if (!root) {
            root = queryListRootElement(targetProductTile);
        }

        if (checkIfCurrentPage().isLandingPageOfCategory) {
            const storepage = queryStorepageRootElement(targetProductTile);
            /**
             * @type {boolean} True whether we must ignore the list of the parent
             * slider. When the target tile is child of a slider with the same list than
             * the landing page of a category, we must consider its position against the
             * whole page, as a PLP.
            */
            const mustIgnoreParent = (storepage?.getAttribute(analyticsConfig.dataAttributes.list) === root.getAttribute(analyticsConfig.dataAttributes.list)
            );
            if (mustIgnoreParent) {
                root = storepage;
            }
        }

        const tiles = Array.from(queryProductImpressionElements(root));
        const zeroBasedPosition = tiles.indexOf(targetProductTile);
        position = zeroBasedPosition > -1 ? zeroBasedPosition + 1 : undefined;

        // Save the result for future queries
        targetProductTile.setAttribute(
            analyticsConfig.dataAttributes.productImpressionPosition,
            position
        );
    }

    return +position; // force parse to number
};

module.exports = {
    getProductPositionFromDOM
};
