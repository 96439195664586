/**
 * Get the native DOM element or elements wrapped inside a jQuery Object.
 * If the function receives a native reference to an element it returns it as
 * well.
 *
 * @param {jQuery|Element} element The target element of the DOM
 * @returns {Element|Element[]} The desired native element or elements
 */
function nativefy(element) {
    let nativefied;

    const isNativeAlready = !(element instanceof jQuery);
    if (isNativeAlready) nativefied = element;
    else if (element.length === 0) nativefied = null;
    else if (element.length > 1) nativefied = element.get();
    else nativefied = element[0];

    return nativefied;
}

module.exports = nativefy;
