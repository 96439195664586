const createSwiper = require('../components/swiperFactory');
const pageUtilities = require('../utilities/pageUtilities');

/**
 * Init swiper slider.
 *
 * @param {Element|string} container - HTMLElement or string (with CSS
 * Selector) of swiper container HTML element. Required.
 * @param {boolean} isSearchPage true if is search page (grid)
 * @returns {Promise.<Object>} - Swiper instance
 */
async function swiperInit(container, isSearchPage) {
    return createSwiper(container, {
        direction: 'horizontal',
        slidesPerView: 1,
        slidesPerColumn: 1,
        spaceBetween: 3,
        grabCursor: !!isSearchPage,
        allowTouchMove: !!isSearchPage,
        mousewheel: false,
        speed: 250,
        loop: true,
        updateOnWindowResize: true,
        watchOverflow: true,
        pagination: {
            el: '.product-tile-images-slider__pagination',
            dynamicBullets: true,
            dynamicMainBullets: 1
        },
        navigation: {
            nextEl: '.product-tile-images-slider__swiper-button-next',
            prevEl: '.product-tile-images-slider__swiper-button-prev'
        }
    });
}

module.exports = {
    init: function () {
        $('.product-tile-images-slider__swiper-container:not(.swiper-container-initialized)').each(async function () {
            var tileSliderContainer = $(this);
            var isSearchPage = pageUtilities.isSearchPage();
            var slider = await swiperInit(tileSliderContainer, isSearchPage);

            $('body').on('grid:rebuildSlidersOnSwitchColums', async function () {
                if (slider) {
                    if (!slider.destroyed) { slider.destroy(); }
                    slider = await swiperInit(tileSliderContainer, isSearchPage);
                }
            });
        });
    },
    /**
     * @param {jQuery} element - Parent element to init the non grid tiles
     */
    initNonGridTiles: function (element) {
        var tiles = element.find('.product-tile-images-slider__swiper-container');
        tiles.each(async function () {
            var tileSliderContainer = $(this);
            if (tileSliderContainer) {
                await swiperInit(tileSliderContainer, pageUtilities.isSearchPage());
            }
        });
    }
};
