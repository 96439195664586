var minicartUtils = require('../components/minicartUtils');
var scrollAnimate = require('../components/scrollAnimate');

/**
 * Updates the product tab
 */
function updateMinicartProductsTab() {
    var url = $('.cart-products-url').val();

    if (!$('.minicart-products-tabcontent')) {
        return;
    }

    $.ajax({
        url: url,
        type: 'get',
        success: function (data) {
            if (data) {
                $('.minicart-products-tabcontent').html(data);
                minicartUtils.manageNoStockMessage();
            }
        }
    });
}

/**
 * re-renders the order totals and the number of items in the cart
 * @param {Object} message - Error message to display
 */
function createErrorNotification(message) {
    if (!$('.cart-error').length) {
        return;
    }
    var errorHtml = '<div class="alert alert-danger text-center valid-cart-error fade show" role="alert">'
        + message + '</div>';

    $('.cart-error').empty().append(errorHtml);
    scrollAnimate($('body'));

    setTimeout(function () {
        $('.valid-cart-error').remove();
    }, 3000);
}

/**
 * @param {Object} message - Success message to display
 * @param {boolean} removeProduct - is "remove product" message
 */
function createSuccessNotification(message, removeProduct) {
    if (!$('.cart-error').length) {
        return;
    }

    var errorHtml = '<div class="alert alert-success text-center valid-cart-message fade show" role="alert">';

    if (removeProduct) { // add remove icon
        errorHtml += '<svg class="delete"><use xlink:href="#deleteIcon"></use></svg>';
    }

    errorHtml += '<span>' + message + '</span>'
        + '</div>';

    $('.cart-error').empty().append(errorHtml);
    scrollAnimate($('body'));

    setTimeout(function () {
        $('.valid-cart-message').remove();
    }, 3000);
}

/**
 * re-renders the order totals and the number of items in the cart
 * @param {Object} data - AJAX response from the server
 */
function updateCartTotals(data) {
    $('.number-of-items').empty().append(data.resources.numberOfItems);
    $('.shipping-cost').empty().append(data.totals.totalShippingCost);
    $('.tax-total').empty().append(data.totals.totalTax);
    $('.grand-total').empty().append(data.totals.grandTotal);
    $('.sub-total').empty().append(data.totals.subTotal);
    $('.minicart-quantity').empty().append(data.numItems);
    $('.minicart-link').attr({
        'aria-label': data.resources.minicartCountOfItems,
        title: data.resources.minicartCountOfItems
    });
    if (data.totals.orderLevelDiscountTotal.value > 0) {
        $('.order-discount').removeClass('hide-order-discount');
        $('.order-discount-total').empty()
            .append('- ' + data.totals.orderLevelDiscountTotal.formatted);
    } else {
        $('.order-discount').addClass('hide-order-discount');
    }

    if (data.totals.shippingLevelDiscountTotal.value > 0) {
        $('.shipping-discount').removeClass('hide-shipping-discount');
        $('.shipping-discount-total').empty().append('- '
            + data.totals.shippingLevelDiscountTotal.formatted);
    } else {
        $('.shipping-discount').addClass('hide-shipping-discount');
    }

    data.items.forEach(function (item) {
        // Update promo message
        var promoElement = $('.line-item-promo.item-' + item.UUID);
        if (item.renderedPromotions) {
            if (promoElement.length > 0) {
                promoElement.empty().append(item.renderedPromotions);
            } else {
                $('.product-card.uuid-' + item.UUID + '.product-card__color-size').append(
                    '<div class="line-item-promo item-' + item.UUID + '">'
                    + item.renderedPromotions
                    + '</div>'
                );
            }
            promoElement.removeClass('d-none');
        } else {
            promoElement.empty();
        }

        // Update each item price
        if (item.price) {
            var renderedNewPrice = '';
            if (item.price.list) {
                renderedNewPrice += '<del>'
                    + '   <span class="strike-through list">'
                    + '       <span class="value" content="'
                            + item.price.list.decimalPrice
                    + '       ">'
                            + item.price.list.formatted
                    + '       </span>'
                    + '   </span>'
                    + '</del>';
            }

            if (item.price.sales) {
                renderedNewPrice += '<span class="sales">'
                    + '<span class="value" content="'
                        + item.price.sales.decimalPrice
                    + '">'
                    + item.price.sales.formatted
                    + '</span>'
                    + '</span>';
            }

            if (item.price.discount) {
                renderedNewPrice += '<span class="discount tag tag--dark"> '
                    + item.price.discount.formatted
                + '</span>';
            }

            $('.line-item-price-' + item.UUID + ' .price').empty().append(renderedNewPrice);
        }

        // Update each item subtotal
        if (item.priceTotal && item.priceTotal.renderedPrice) {
            $('.item-total-' + item.UUID).empty().append(item.priceTotal.renderedPrice);
        }
    });

    $('body').trigger('cart:afterUpdateCartTotals', data);
}

/**
 * re-renders the approaching discount messages
 * @param {Object} approachingDiscounts - updated approaching discounts for the cart
 */
function updateApproachingDiscounts(approachingDiscounts) {
    var html = '';
    $('.approaching-discounts').empty();
    if (approachingDiscounts.length > 0) {
        approachingDiscounts.forEach(function (item) {
            html += '<div class="single-approaching-discount text-center">'
                + item.discountMsg + '</div>';
        });
    }
    $('.approaching-discounts').append(html);
}

/**
 * Updates cart content with cart template
 * @param {string} cartTemplate - Cart content html
 */
function updateCartTemplate(cartTemplate) {
    if (cartTemplate) {
        $('.cart-content').empty().append(cartTemplate);
    }
}

/**
 * Inserts the empty cart template
 */
function setEmptyCartTemplate() {
    if (!$('.empty-cart-url').length) {
        return;
    }
    var url = $('.empty-cart-url').val();

    $.ajax({
        url: url,
        type: 'get',
        success: function (renderedTemplate) {
            var emptyCartTemplate = $('.emptyCartTemplate').html();
            var emptyMinicartTemplate = $('.empty-minicart-content').html();
            var templateToRender = renderedTemplate || emptyCartTemplate;

            updateCartTemplate(templateToRender);

            if ($('.minicart-products-tabcontent').length && emptyMinicartTemplate) {
                // eslint-disable-next-line no-unused-expressions
                $('.container.cart').addClass('minicart--no-footer');
                $('.minicart-products-tabcontent').empty().append(emptyMinicartTemplate);
            }
        }
    });
}

/**
 * Checks whether the basket is valid. if invalid displays error message and disables
 * checkout button
 * @param {Object} data - AJAX response from the server
 */
function validateBasket(data) {
    $('.cart-error').empty();
    if (data.valid.error || data.productsRemovedMessage) {
        if (data.valid.message || data.productsRemovedMessage) {
            var errorHtml = '<div class="alert alert-danger alert-dismissible valid-cart-error '
                + 'fade show" role="alert">'
                + '<button type="button" class="close" data-dismiss="alert" aria-label="Close">'
                + '<span aria-hidden="true">&times;</span>'
                + '</button>' + (data.valid.message ? data.valid.message : data.productsRemovedMessage) + '</div>';

            $('.cart-error').append(errorHtml);
        } else {
            setEmptyCartTemplate();
            $('.number-of-items').empty().append(data.resources.numberOfItems);
            $('.minicart-quantity').empty().append(data.numItems);
            $('.minicart-link').attr({
                'aria-label': data.resources.minicartCountOfItems,
                title: data.resources.minicartCountOfItems
            });
        }

        $('.checkout-btn').addClass('disabled');
    } else {
        $('.checkout-btn').removeClass('disabled');
    }
}

/**
 * Makes a generic update of the Cart
 * @param {Object} basket - The current basket
 */
function generalCartUpdate(basket) {
    $('.coupons-and-promos').empty().append(basket.totals.discountsHtml);
    updateCartTotals(basket);
    updateApproachingDiscounts(basket.approachingDiscounts);
    $('body').trigger('setShippingMethodSelection', basket);
    validateBasket(basket);
    $('body').trigger('cart:update');
}

module.exports = {
    methods: {
        createErrorNotification: createErrorNotification,
        createSuccessNotification: createSuccessNotification,
        generalCartUpdate: generalCartUpdate,
        updateMinicartProductsTab: updateMinicartProductsTab,
        updateCartTotals: updateCartTotals,
        updateApproachingDiscounts: updateApproachingDiscounts,
        validateBasket: validateBasket,
        updateCartTemplate: updateCartTemplate
    }
};
