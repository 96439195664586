const analyticsConfig = require('../../../../config/analyticsConfig');

/**
 * Get the analytics config object.
 * @returns {analyticsConfig.AnalyticsConfig} the config
 */
function getAnalyticsConfig() {
    return analyticsConfig;
}

module.exports = getAnalyticsConfig;
