/**
 * Show a spinner inside a given element
 * @param {element} $target - Element to block by the veil and spinner.
 *                            Pass body to block the whole page.
 * @param {boolean} $useUnderlay  - Include underlay or not. Default = true
 */
function addSpinner($target, $useUnderlay = true) {
    var $veil = $('<div class="veil"></div>');
    if ($useUnderlay === true) $veil.append('<div class="underlay"></div>');
    $veil.append('<div class="loader loader--style7">'
        + '  <svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"'
        + '     width="24px" height="30px" viewBox="0 0 24 30" style="enable-background:new 0 0 50 50;" xml:space="preserve">'
        + '    <rect x="0" y="0" width="2" height="30" fill="#212121">'
        + '      <animate attributeName="opacity" attributeType="XML"'
        + '        values="1; .15; 1" '
        + '        begin="0s" dur="0.6s" repeatCount="indefinite" />'
        + '    </rect>'
        + '    <rect x="11" y="0" width="2" height="30" fill="#212121">'
        + '      <animate attributeName="opacity" attributeType="XML"'
        + '        values="1; .15; 1" '
        + '        begin="0.2s" dur="0.6s" repeatCount="indefinite" />'
        + '    </rect>'
        + '    <rect x="22" y="0" width="2" height="30" fill="#212121">'
        + '      <animate attributeName="opacity" attributeType="XML"'
        + '        values="1; .15; 1" '
        + '        begin="0.4s" dur="0.6s" repeatCount="indefinite" />'
        + '    </rect>'
        + '  </svg>'
        + '</div>');

    if ($target.get(0).tagName === 'IMG') {
        $target.after($veil);
        $veil.css({ width: $target.width(), height: $target.height() });
        if ($target.parent().css('position') === 'static') {
            $target.parent().css('position', 'relative');
        }
    } else {
        $target.append($veil);
        if ($target.css('position') === 'static') {
            $target.parent().css('position', 'relative');
            $target.parent().addClass('veiled');
        }
        if ($target.get(0).tagName === 'BODY') {
            $veil.find('.spinner').css('position', 'fixed');
        }
    }
    $veil.click(function (e) {
        e.stopPropagation();
    });
}

/**
 * Remove existing spinner
 * @param  {element} $veil - jQuery pointer to the veil element
 */
function removeSpinner($veil) {
    if ($veil.parent().hasClass('veiled')) {
        $veil.parent().css('position', '');
        $veil.parent().removeClass('veiled');
    }
    $veil.off('click');
    $veil.remove();
}

// element level spinner:
$.fn.spinner = function () {
    var $element = $(this);
    var Fn = function () {
        this.start = function ($useUnderlay = true) {
            if ($element.length) {
                addSpinner($element, $useUnderlay);
                $('.loader').addClass('loader__element-level');
            }
        };
        this.stop = function () {
            if ($element.length) {
                var $veil = $('.veil');
                removeSpinner($veil);
            }
        };
    };
    return new Fn();
};

// page-level spinner:
$.spinner = function () {
    var Fn = function () {
        this.start = function () {
            addSpinner($('body'));
        };
        this.stop = function () {
            removeSpinner($('.veil'));
        };
    };
    return new Fn();
};
