const listContext = require('./trackers/listContext/tracker');
const lastListAndPositionWhereProductWasAddedToCart = require('./trackers/lastListAndPositionWhereProductWasAddedToCart');
const lastListAndPositionWhereProductWasClicked = require('./trackers/lastListAndPositionWhereProductWasClicked');
const listTracker = require('./trackers/listTracker');
const { getEcommerceListAndPositionFromDOM, getEcommerceListAndPositionForDirectAccess } = require('./helpers/ecommerceListAndPosition');
const { getProductIdFromDOM } = require('../../../utilities/pdpHelpers');
const getAnalyticsConfig = require('../../../common/getAnalyticsConfig');

const analyticsConfig = getAnalyticsConfig();

/**
 * Get the list and position for the pageview event of product detail page
 * @returns {Promise<EcommerceListAndPosition>} List and position
 */
async function getListAndPositionForProductDetailPageview() {
    let result;
    /**
     * @type {boolean}
     * Save the list into store for PDP interactions (ex: swatch click)
     */
    let saveDirectAccess = false;
    const adDatalayerProductId = getProductIdFromDOM();
    const context = await listContext.getListContext();
    if (context.listPrefix === analyticsConfig.list.prefixes.direct) {
        result = getEcommerceListAndPositionForDirectAccess(context);
        saveDirectAccess = true;
    } else {
        result = lastListAndPositionWhereProductWasClicked.get(
            adDatalayerProductId
        );
    }

    if (saveDirectAccess) {
        // Save the list into store for PDP interactions (ex: swatch click)
        lastListAndPositionWhereProductWasClicked.setItem(
            adDatalayerProductId,
            result
        );
    }

    return result;
}

/**
 * Get the last list and position where the product was interacted
 * @param {string} adDatalayerProductId The target product
 * @param {Element} [productElement] The element of the product or descendant.
 * Used as fallback to calculate the result from DOM.
 * @returns {Promise.<EcommerceListAndPosition>} list and position
 */
function getLastListAndPositionWhereProductWasAddedToCart(adDatalayerProductId, productElement) {
    return lastListAndPositionWhereProductWasAddedToCart
        .get(adDatalayerProductId, productElement);
}

/**
 * Track the events related to ecommerce lists for datalayer
 * @async
 */
async function init() {
    // Update list context before list tracking
    await listContext.track();
    listTracker.track();
    lastListAndPositionWhereProductWasClicked.track();
    lastListAndPositionWhereProductWasAddedToCart.track();
}

module.exports = {
    init,
    getListAndPositionFromDOM: getEcommerceListAndPositionFromDOM,
    getLastListAndPositionWhereProductWasAddedToCart,
    getLastListAndPositionWhereProductWasClicked: lastListAndPositionWhereProductWasClicked.get,
    getListAndPositionForProductDetailPageview

};
