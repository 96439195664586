const globalVarname = 'adModuleInstances';
const singletonInstances = globalVarname in window
    ? window[globalVarname]
    : {};
window[globalVarname] = singletonInstances;

/**
 * Assure that there is an unique module.exports among the duplicates of a
 * module which is required in different bundles
 * @param {string} moduleUid Use a name or a random string (but constant, not
 * on-the-fly generated). The aim is that all the instances of one module
 * duplicated in different bundles share the same uid.
 * @param {Object} moduleExports The object of the public methods exported by a
 * module in CommonJS
 * @returns {Object} It will return the same object reference for every call to
 * this function
 */
export function generateSingletonExports(moduleUid, moduleExports) {
    const cached = moduleUid in singletonInstances;
    if (!cached) {
        singletonInstances[moduleUid] = moduleExports;
    }
    return singletonInstances[moduleUid];
}
