const { getCanonicalProductId } = require('../../../../utilities/doubleLookHelpers');
const { getProductIdFromDOM } = require('../../../../utilities/pdpHelpers');
const { chainDefaultPromise } = require('../../../../utilities/promises');
const { internalEventCenter } = require('../../events');
const checkIfCurrentPage = require('../../helpers/checkIfCurrentPage');
const store = require('../../stores/lastListAndPositionWhereProductWasAddedToCartStore');
const {
    makeSetEcommerceListAndPositionFromElement,
    makeGetEcommerceListAndPosition
} = require('../helpers/impressionBasedStoreFunctions');
const { unknownAddedToCartListAndPosition } = require('../predefinedLists');
const lastListAndPositionWhereProductWasClicked = require('./lastListAndPositionWhereProductWasClicked');

const setEcommerceListAndPosition = makeSetEcommerceListAndPositionFromElement(
    store
);
const getEcommerceListAndPosition = makeGetEcommerceListAndPosition(
    store,
    setEcommerceListAndPosition
);

/**
 * Check if the given productFieldObject is the current product of PDP
 * @param {ProductFieldObject} productFieldObject The target product
 * @returns {boolean} true if it is the main product of PDP
 */
function isProductDetailProduct(productFieldObject) {
    if (checkIfCurrentPage().isProductDetailPage) {
        return productFieldObject.addons.adDatalayerProductId === getCanonicalProductId(getProductIdFromDOM());
    }
    return false;
}

/**
 * Track some UI events that may modify the last list where a product has been
 * added to cart
 */
function track() {
    internalEventCenter.subscribe(
        internalEventCenter.EVENTS.productAddedToCart,
        ({ productFieldObject, target }) => {
            let endPromise = Promise.resolve();
            const adDatalayerProductId = productFieldObject.addons.adDatalayerProductId;
            if (isProductDetailProduct(productFieldObject)) {
                // Get the list info from the last clicked store
                endPromise = store.setItem(
                    adDatalayerProductId,
                    lastListAndPositionWhereProductWasClicked.get(adDatalayerProductId)
                );
            } else if (target) {
                endPromise = setEcommerceListAndPosition(
                    productFieldObject.addons.adDatalayerProductId,
                    target
                );
            }
            return endPromise;
        },
        true
    );
}

module.exports = {
    /**
     * Avoid a fatal error returning a default list and position
     * when it is no possible to get the original list where the product
     * was added to cart.
     *
     * This rare case can occurs because of:
     * - the cleaning the application data having products in cart
     * - the recovery of a long-time-stored cart in LocalStorage
     */
    get: chainDefaultPromise(
        getEcommerceListAndPosition,
        unknownAddedToCartListAndPosition
    ),
    setItem: store.setItem,
    track
};
