/**
 * @param {Object} selectedOption - Selected size option
 */
function selectModalSize(selectedOption) {
    const sizeId = selectedOption.data('attr-value') ? selectedOption.data('attr-value') : selectedOption[0].innerHTML.trim();
    const modalOption = $('.stockAlertModalForm').find("[data-sizeid='" + sizeId + "']");

    if (modalOption.length > 0) {
        modalOption.trigger('click');
    }
}

/**
 * @param {Object} product - Selected product
 */
function changeAddToCartButton(product) {
    var outOfStock = !product.available;
    var readyToOrder = product.readyToOrder;
    var selectedOption = $('.product-sizes__selector').find('li.selected');

    if (selectedOption.length && outOfStock && readyToOrder) {
        var selectedSizeOption = selectedOption;

        $('button.add-to-cart').addClass('d-none');
        $('button.stock-alert-modal-btn').removeClass('d-none');

        selectModalSize(selectedSizeOption);
    } else {
        $('button.add-to-cart').removeClass('d-none');
        $('button.stock-alert-modal-btn').addClass('d-none');
    }
}

/**
 * @param {Object} product - The product with the data to be reload
 */
function reloadModalSizeOptions(product) {
    var sizeFormContainer = $('.stockAlertModalForm .size-selector .size-group');

    product.variationAttributes[1].values.forEach(function (entry) {
        if (!entry || !entry.id) {
            return;
        }
        var currentSize = sizeFormContainer.find("[data-sizeid='" + entry.id + "']");

        if (!currentSize) {
            return;
        }

        if (!entry.selectable) {
            currentSize.removeClass('d-none');
        } else if (entry.selectable && !currentSize.hasClass('d-none')) {
            currentSize.addClass('d-none');
        }
    });
}

/**
 * Updates stock alert modal data.
 *  - selected pid
 *  - sizes options
 *
 * @param {string} productId product id
 * @param {jQueryObject} allSizes all sizes list
 */
function reloadModalData(productId, allSizes) {
    $('.stockAlert-form').data('pid', productId);
    var sizesHtml = '';

    allSizes.each(function () {
        var size = $(this);
        sizesHtml += '<span '
            + 'data-sizeid="' + size.data('attr-value') + '" '
            + 'role="option"'
            + ' class="product-sizes__option product-sizes__option--show-selection '
            + (size.hasClass('disabled--unavailable') ? '' : 'd-none')
            + '">'
            + size.find('.product-sizes__value').text().trim()
            + '</span>';
    });

    $('.stock-alert-modal .size-selector .size-group').empty().append(sizesHtml);
}

module.exports = function () {
    $('body').on('product:loadStockConfig', function (e, response) {
        if (response.product) {
            reloadModalSizeOptions(response.product);
            changeAddToCartButton(response.product);
        }
    });

    /**
     * Returns the selected size id
     * @returns {string} the selected option
     */
    function getSelectedSize() {
        var selectedOption = null;

        $('.stock-alert-modal .size-selector .size-group .product-sizes__option').each(function () {
            if ($(this).hasClass('selected')) {
                selectedOption = $(this).data('sizeid');
            }
        });

        return selectedOption;
    }

    /**
     * Displays error or success message
     * @param {Object} data - the obtained data from the controller
     * @param {Object} button - the button object
     */
    function displayMessage(data, button) {
        $.spinner().stop();
        if (data.error === true) {
            $('.invalid-feedback-button').addClass('invalid-feedback');
            $('.invalid-feedback-button').html(data.msg);
            setTimeout(function () {
                $('.invalid-feedback-button').html('');
            }, 3000);
        } else {
            $('.stock-alert-modal button.close').trigger('click');

            if ($('.stockalert-submit-message').length === 0) {
                $('body').append('<div class="stockalert-submit-message"></div>');
            }

            $('.stockalert-submit-message').append('<div class="alert alert-success add-to-basket-alert text-center" role="alert">' + data.msg + '</div>');
            setTimeout(function () {
                $('.stockalert-submit-message').remove();
            }, 3000);
        }
        button.removeAttr('disabled');
    }

    /**
     * Set option as selected
     */
    $('body').on('click', '.stock-alert-modal .size-group span.product-sizes__option', function () {
        var listItems = $('.stock-alert-modal .size-selector .size-group').children('span');

        listItems.removeClass('selected');
        listItems.removeAttr('checked');
        $(this).addClass('selected');
        $(this).attr('checked', true);
    });

    /**
     * Reloads modal data and
     * sets option as selected when stock-alert-modal-link is clicked
     */
    $('body').on('click', '.stock-alert-modal-link', function () {
        var selectedProductId = $(this).closest('.product-info__sizes-info').data('pid');
        var sizesList = $(this).closest('.product-sizes__selector').find('.product-sizes__option');
        reloadModalData(selectedProductId, sizesList);

        var selectedOption = $(this).closest('.product-sizes__option');
        if (selectedOption.length) {
            selectModalSize(selectedOption);
        }
    });

    /**
     * Form validation before submit
     */
    $('#stockAlert-form').on('submit', function (e) {
        e.preventDefault();
        $('.modal-body').spinner().start();

        var form = $(this);
        var button = form.find('.form-submit');
        var url = form.attr('action');
        var sizeId = getSelectedSize();
        var email = form.find('input[name=stockAlertEmail]').val();
        var pid = form.data('pid');
        form.find('[class*=invalid-feedback-]').html('');

        if (!sizeId || sizeId === null || sizeId === '') {
            $('.invalid-feedback-size').html($('.size-selector').attr('data-missing-error'));
            $('.modal-body').spinner().stop();
            return;
        }

        button.attr('disabled', true);
        $.ajax({
            url: url,
            type: 'post',
            dataType: 'json',
            data: {
                email: email,
                pid: pid,
                size_id: sizeId
            },
            success: function (data) {
                displayMessage(data, button);
            },
            error: function (err) {
                displayMessage(err, button);
            },
            complete: function () {
                $('.modal-body').spinner().stop();
            }
        });
    });
};
