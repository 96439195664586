const STOP_PAGE_SCROLL_CLASS = 'u-stop-page-scroll';

/**
 * Stops page scrolling
 * adding format to body:
 *  - overflow hidden
 */
function stopPageScroll() {
    $('body').addClass(STOP_PAGE_SCROLL_CLASS);
}

/**
 * Resume page scrolling
 */
function resumePageScroll() {
    $('body').removeClass(STOP_PAGE_SCROLL_CLASS);
}

module.exports = {
    stopPageScroll: stopPageScroll,
    resumePageScroll: resumePageScroll
};
