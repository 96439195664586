const targetPropName = 'actionExecutionsBeforeRedirection';

/**
 * Retrieves the list of action executions from the event's target.
 * @param {Event} event - The event containing the target.
 * @returns {Promise<*>[]} An array of promises representing
 * action executions.
 */
const getActionExecutions = (event) => event.target[targetPropName];

/**
 * Initializes the action executions array on the event's target.
 * Ensures the target property exists and is an array.
 * @param {Event} event - The event whose target will be initialized.
 */
const initActionExecutions = (event) => {
    /* eslint-disable no-param-reassign */
    // This rule is disabled because modifying event.detail is necessary
    // to store and track all handlers before redirection. Since event.detail
    // is an expected mutable part of the event object, this reassignment
    // ensures correct event data propagation.
    const target = event.target;
    target[targetPropName] = Array.isArray(target[targetPropName])
        ? target[targetPropName]
        : [];
    /* eslint-enable no-param-reassign */
};

/**
 * Pushes a new action execution promise into the event's
 * action executions array.
 * @param {Event} event - The event containing the executions array.
 * @param {Promise<*>} execution - A promise representing an
 * action execution.
 */
const pushActionExecution = (event, execution) => {
    getActionExecutions(event).push(execution);
};

/**
 * Determines whether the clicked link should be processed.
 *
 * @param {HTMLAnchorElement} link - The clicked link element.
 * @param {Event} event - The click event.
 * @returns {boolean} True if the link should be processed.
 */
function shouldProcessLink(link, event) {
    const origin = window.location.origin;
    return !!(
        link?.href
        && link.target !== '_blank'
        && !event.ctrlKey
        && !event.metaKey
        && new URL(link.href, origin).origin === origin
    );
}

/**
 * Redirects the user once all registered actions have completed.
 *
 * @param {string} href - The link's destination URL.
 * @param {Promise[]} handlers - The list of registered handler promises.
 */
function redirectAfterAllActionsCompleted(href, handlers) {
    queueMicrotask(() => {
        Promise.allSettled(handlers)
            .then(() => {
                window.location.href = href;
            })
            .catch(error => {
                throw new Error(
                    'Error before redirecting: ' + error.message
                );
            });
    });
}

/**
 * Callback executed when a link is clicked, before redirecting.
 * Callbacks run concurrently; promises are awaited before redirect.
 *
 * @callback LinkClickBeforeRedirectCallback
 *
 * @param {Event} event - The original click event object.
 * @param {Object} eventData - Additional link click event data.
 * @param {Element} eventData.linkElement - The clicked link element.
 *
 * @returns {void | Promise<void>} Optionally returns:
 *  - `void` or promise resolving to `void`. Redirect awaits callbacks.
 */

/**
 * Creates a handler that executes an action before navigating to a link.
 *
 * If multiple handlers are registered for the same event and element,
 * they will automatically organize themselves to ensure all actions
 * are executed before redirection occurs.
 *
 * @function createLinkHandlerBeforeRedirect
 * @param {LinkClickBeforeRedirectCallback} actionBeforeRedirect - Function to
 * execute before redirection, which receives a click event.
 * @returns {function(Event)} Event handler function.
 */
function createLinkHandlerBeforeRedirect(actionBeforeRedirect) {
    return function handleLinkClick(clickEvent) {
        const linkElement = clickEvent.target.closest('a');
        if (!shouldProcessLink(linkElement, clickEvent)) return;

        clickEvent.preventDefault(); // Prevents immediate navigation

        initActionExecutions(clickEvent);

        // Register action execution promise
        const actionExecution = Promise.resolve(
            actionBeforeRedirect(clickEvent, { linkElement })
        );
        pushActionExecution(clickEvent, actionExecution);

        // Ensure only one handler triggers navigation
        if (getActionExecutions(clickEvent).length === 1) {
            redirectAfterAllActionsCompleted(
                linkElement.href,
                getActionExecutions(clickEvent)
            );
        }
    };
}

module.exports = createLinkHandlerBeforeRedirect;
