const getAnalyticsConfig = require('../../../common/getAnalyticsConfig');

const analyticsConfig = getAnalyticsConfig();

/**
 * Get the list type from the root DOM element of a list of products.
 *
 * @param {Element} listRootElement The root element of a list
 * @returns {string} Type of list
 */
const getListTypeFromListRootElement = (listRootElement) => {
    const { types, selectorsByType } = analyticsConfig.list;
    const listType = Object.values(types).find(
        (type) => listRootElement.matches(selectorsByType[type])
    );
    return listType;
};

module.exports = {
    getListTypeFromListRootElement
};
