const { internalEventCenter } = require('../events');
const sendEvent = require('../helpers/sendEvent');
const createEvent = require('./createEvent');
const fetchProductFieldObject = require('../helpers/fetchProductFieldObject');
const fetchCurrentCurrencyCode = require('../helpers/fetchCurrentCurrencyCode');
const fetchUserInfo = require('../helpers/fetchUserInfo');

/**
 * Send the pageview event to datalayer
 * @param {string} pdpProductId - ID of the product if it is the PDP
 * @returns {void}
 */
const sendPageViewEvent = (pdpProductId) => {
    Promise.all([
        fetchUserInfo(),
        fetchProductFieldObject(pdpProductId),
        fetchCurrentCurrencyCode()
    ]).then(
        ([user, productFieldObject, currencyCode]) => createEvent(
            user,
            productFieldObject,
            currencyCode
        )
    ).then(sendEvent);
};

/**
 * Initialize the module. Listen to pageview event.
 * @returns {void}
 */
const init = () => {
    internalEventCenter.subscribe(
        internalEventCenter.EVENTS.pageviewLoaded,
        (payload) => sendPageViewEvent(payload.pid)
    );
};

module.exports = {
    init
};
