'use strict';

/**
 * @typedef {object} GtmConfig
 *
 * @property {object} events
 * @property {string} events.action
 * @property {string} events.cookie
 * @property {string} events.pageView
 *
 * @property {object} eventCategories
 * @property {string} eventCategories.addToCart
 * @property {string} eventCategories.adnEcosistema
 * @property {string} eventCategories.adnClub1
 * @property {string} eventCategories.adnClub2
 * @property {string} eventCategories.checkoutStep1
 * @property {string} eventCategories.checkoutStep2
 * @property {string} eventCategories.checkoutStep3
 * @property {string} eventCategories.checkoutStep4
 * @property {string} eventCategories.checkoutStep5
 * @property {string} eventCategories.checkoutStep6
 * @property {string} eventCategories.click
 * @property {string} eventCategories.home
 * @property {string} eventCategories.impression
 * @property {string} eventCategories.purchase
 * @property {string} eventCategories.removeFromCart
 * @property {object} eventCategories.checkout
 * @property {Array} eventCategories.checkout.cart
 * @property {Array} eventCategories.checkout.initCheckout
 * @property {Array} eventCategories.checkout.shipping
 * @property {Array} eventCategories.checkout.payment
 * @property {Array} eventCategories.checkout.placeOrder
 * @property {Array} eventCategories.checkout.submitted
 *
 * @property {object} eventActions
 * @property {string} eventActions.adn
 * @property {string} eventActions.category
 * @property {string} eventActions.segmentModal
 */

/**
 * @typedef {object} AnalyticsConfig
 *
 * @property {object} dataAttributes
 * @property {string} dataAttributes.isProductInView
 * @property {string} dataAttributes.list
 * @property {string} dataAttributes.listIsReady
 * @property {string} dataAttributes.listIsBuilding
 * @property {string} dataAttributes.listUid
 * @property {string} dataAttributes.productImpression
 * @property {string} dataAttributes.productImpressionCached
 * @property {string} dataAttributes.productImpressionCaching
 * @property {string} dataAttributes.productImpressionId
 * @property {string} dataAttributes.productImpressionPosition
 *
 * @property {object} dataLayerCartActionOnProductModel
 * @property {object} dataLayerCartActionOnProductModel.cartAction
 * @property {string} dataLayerCartActionOnProductModel.cartAction.add
 * @property {string} dataLayerCartActionOnProductModel.cartAction.remove
 *
 * @property {GtmConfig} gtm Full GTM config
 *
 * @property {object} list
 *
 * @property {object} list.prefixes
 * @property {string} list.prefixes.catalog
 * @property {string} list.prefixes.direct
 * @property {string} list.prefixes.saveForLater
 * @property {string} list.prefixes.search
 * @property {string} list.prefixes.similarProducts
 * @property {string} list.prefixes.sliderCompleteTheLook
 * @property {string} list.prefixes.sliderShopTheLook
 * @property {string} list.prefixes.unknownAddedToCartList
 * @property {string} list.prefixes.unknownClickedList
 * @property {string} list.prefixes.wishlist
 *
 * @property {object} list.referrerComponents
 * @property {string} list.referrerComponents.bannerCategory Componente Banner Image aplicado en un PLP
 * @property {string} list.referrerComponents.floatingNavigation
 * @property {string} list.referrerComponents.minicart
 *
 * @property {object} list.referrerPages
 * @property {string} list.referrerPages.blackFriday
 * @property {string} list.referrerPages.cart
 * @property {string} list.referrerPages.home
 *
 * @property {object} list.selectorsByType CSS selectors of the root element of
 * each type of list. The root is the element which has the attribute
 * "data-datalayer-list"
 * @property {string} list.selectorsByType.landingPageOfCategory
 * @property {string} list.selectorsByType.recommendationGridSlotEinstein
 * @property {string} list.selectorsByType.recommendationGridSlotProducts
 * @property {string} list.selectorsByType.recommendationSliderSlotEinstein
 * @property {string} list.selectorsByType.recommendationSliderSlotProducts
 * @property {string} list.selectorsByType.saveForLater
 * @property {string} list.selectorsByType.searchByCategory
 * @property {string} list.selectorsByType.searchByKeyword
 * @property {string} list.selectorsByType.sliderCompleteTheLook
 * @property {string} list.selectorsByType.sliderShopTheLook
 * @property {string} list.selectorsByType.sliderPageDesignerEinstein
 * @property {string} list.selectorsByType.sliderPageDesignerProducts
 * @property {string} list.selectorsByType.suggestions
 * @property {string} list.selectorsByType.wishlist
 *
 * @property {object} list.types
 * @property {string} list.types.landingPageOfCategory
 * @property {string} list.types.recommendationGridSlotEinstein
 * @property {string} list.types.recommendationGridSlotProducts
 * @property {string} list.types.recommendationSliderSlotEinstein
 * @property {string} list.types.recommendationSliderSlotProducts
 * @property {string} list.types.saveForLater
 * @property {string} list.types.searchByCategory
 * @property {string} list.types.searchByKeyword
 * @property {string} list.types.sliderCompleteTheLook
 * @property {string} list.types.sliderShopTheLook
 * @property {string} list.types.sliderPageDesignerEinstein
 * @property {string} list.types.sliderPageDesignerProducts
 * @property {string} list.types.suggestions
 * @property {string} list.types.wishlist
 *
 * @property {object} localforage
 * @property {string} localforage.dbDatalayer
 * @property {object} localforage.stores
 * @property {object} localforage.stores.general
 * @property {string} localforage.stores.general.name
 * @property {object} localforage.stores.general.items
 * @property {string} localforage.stores.general.items.lastUpdate
 * @property {string} localforage.stores.general.items.listContext
 * @property {object} localforage.stores.lastListAndPositionWhereProductWasAddedToCart
 * @property {string} localforage.stores.lastListAndPositionWhereProductWasAddedToCart.name
 * @property {object} localforage.stores.lastListAndPositionWhereProductWasClicked
 * @property {string} localforage.stores.lastListAndPositionWhereProductWasClicked.name
 *
 * @property {object} selectors
 * @property {string} selectors.productImpressionElement
*/

/** @type {GtmConfig} */
var gtmConfig = require('./gtmConfig');

/** @type {AnalyticsConfig} */
var config = {
    dataAttributes: {
        isProductInView: 'data-in-view',
        list: 'data-datalayer-list',
        listIsReady: 'data-is-datalayer-list-ready',
        listIsBuilding: 'data-is-datalayer-list-building',
        listUid: 'data-datalayer-list-uid',
        productImpression: 'data-datalayer-impression',
        productImpressionCached: 'data-datalayer-impression-cached',
        productImpressionCaching: 'data-datalayer-impression-caching',
        productImpressionId: 'data-datalayer-impression-id',
        productImpressionPosition: 'data-datalayer-impression-position'
    },
    dataLayerCartActionOnProductModel: {
        cartAction: {
            add: 'add',
            remove: 'remove'
        }
    },
    gtm: gtmConfig,
    list: {
        prefixes: {
            catalog: 'catalogo',
            direct: 'directo',
            saveForLater: 'guardar_para_mas_tarde',
            search: 'buscador',
            similarProducts: 'productos_similares',
            sliderCompleteTheLook: 'productos_completa_look',
            sliderShopTheLook: 'productos_shop_the_look',
            unknownAddedToCartList: 'add_to_cart_desconocido',
            unknownClickedList: 'click_desconocido',
            wishlist: 'wishlist'
        },
        referrerComponents: {
            bannerCategory: 'banner_categoria',
            floatingNavigation: 'flotantes',
            minicart: 'minicesta'
        },
        referrerPages: {
            blackFriday: 'black_friday',
            cart: 'cesta',
            home: 'home'
        },
        selectorsByType: {
            landingPageOfCategory: '.storepage[data-is-landing-page-of-category]',
            recommendationGridSlotEinstein: '.recommendations--grid.einstein-recommendations-slot',
            recommendationGridSlotProducts: '.recommendations--grid.manual-recommendations-slot',
            recommendationSliderSlotEinstein: '.recommendations--slider.einstein-recommendations-slot',
            recommendationSliderSlotProducts: '.recommendations--slider.manual-recommendations-slot',
            saveForLater: '.sflProductContainer',
            searchByCategory: '.search-results[data-is-search-by-category]',
            searchByKeyword: '.search-results[data-is-search-by-keyword]',
            sliderCompleteTheLook: '.complete-the-look',
            sliderShopTheLook: '.pd-shop-the-look',
            sliderPageDesignerEinstein: '.pd-einstein-slider',
            sliderPageDesignerProducts: '.pd-slider',
            suggestions: '.suggestions-block-products',
            wishlist: '.wishlist-item-cards'
        },
        types: {
            landingPageOfCategory: 'landingPageOfCategory',
            recommendationGridSlotEinstein: 'recommendationGridSlotEinstein',
            recommendationGridSlotProducts: 'recommendationGridSlotProducts',
            recommendationSliderSlotEinstein: 'recommendationSliderSlotEinstein',
            recommendationSliderSlotProducts: 'recommendationSliderSlotProducts',
            saveForLater: 'saveForLater',
            searchByCategory: 'searchByCategory',
            searchByKeyword: 'searchByKeyword',
            sliderCompleteTheLook: 'sliderCompleteTheLook',
            sliderShopTheLook: 'sliderShopTheLook',
            sliderPageDesignerEinstein: 'sliderPageDesignerEinstein',
            sliderPageDesignerProducts: 'sliderPageDesignerProducts',
            suggestions: 'suggestions',
            wishlist: 'wishlist'
        }
    },
    localforage: {
        dbDatalayer: 'ad-datalayer-db',
        stores: {
            general: {
                name: 'datalayer',
                items: {
                    lastUpdate: 'last_update',
                    listContext: 'list_context'
                }
            },
            lastListAndPositionWhereProductWasAddedToCart: {
                name: 'list_product_added_to_cart'
            },
            lastListAndPositionWhereProductWasClicked: {
                name: 'list_product_clicked'
            }
        }
    },
    selectors: {
        // Contains the attribute dataAttributes.productImpressionId
        productImpressionElement: '[data-datalayer-impression-id]'
    }
};

module.exports = config;
