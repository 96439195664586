var BASE_CONFIG = window.BASE_CONFIG;
var CHECK_CHARACTER_EQ = ['W', 'A', 'B', 'C', 'D', 'E', 'F', 'G', 'H', 'I', 'J', 'K', 'L', 'M', 'N', 'O', 'P', 'Q', 'R', 'S', 'T', 'U', 'V'];

/**
 * Returns true if the UID is valid (AT)
 * @param {string} nif - The user UID
 * @returns {boolean} - True if the UID is valid
 */
function isValidUid(nif) {
    var firstCheckValue = Math.trunc(nif[2] / 5) + ((nif[2] * 2) % 10);
    var secondCheckValue = Math.trunc(nif[4] / 5) + ((nif[4] * 2) % 10);
    var thirdCheckValue = Math.trunc(nif[6] / 5) + ((nif[6] * 2) % 10);
    var summation = firstCheckValue + secondCheckValue + thirdCheckValue;
    var expectedValue = (10 - ((summation + parseInt(nif[1], 10) + parseInt(nif[3], 10) + parseInt(nif[5], 10) + parseInt(nif[7], 10) + 4) % 10)) % 10;
    var receivedValue = parseInt(nif.slice(-1), 10);

    if (expectedValue !== receivedValue) {
        return false;
    }
    return true;
}

/**
 * Returns true if the nif is valid - AT
 * @param {string} nif - The user nif
 * @returns {boolean} - True if the nif is valid
 */
function isValidNifAT(nif) {
    var uidRegex = new RegExp(BASE_CONFIG.nifConfig.AT.uidRegex);

    if (uidRegex.test(nif)) {
        return isValidUid(nif);
    }

    return false;
}

/**
 * Returns true if the Identificatienummer is valid (BE)
 * @param {string} nif - The user Identificatienummer
 * @returns {boolean} - True if the Identificatienummer is valid
 */
function isValidIdentificatienummer(nif) {
    var receivedValue = parseInt(nif.slice(-2), 10);
    var expectedValue = 97 - ((nif[0] + nif[1] + nif[2] + nif[3] + nif[4] + nif[5] + nif[6] + nif[7]) % 97);

    if (expectedValue !== receivedValue) {
        return false;
    }
    return true;
}

/**
 * Returns true if the nif is valid - BE
 * @param {string} nif - The user Identificatienummer
 * @returns {boolean} - True if the nif is valid
 */
function isValidNifBE(nif) {
    var identificatienummerRegex = new RegExp(BASE_CONFIG.nifConfig.BE.identificatienummerRegex);

    if (identificatienummerRegex.test(nif)) {
        return isValidIdentificatienummer(nif);
    }

    return false;
}

/**
 * Returns true if the VatRegistrationNumber is valid (BG)
 * @param {string} nif - The user VatRegistrationNumber
 * @returns {boolean} - True if the VatRegistrationNumber is valid
 */
function isValidVatRegistrationNumberBG(nif) {
    var receivedValue = parseInt(nif.slice(-1), 10);
    var summation = 1 * nif[0] + 2 * nif[1] + 3 * nif[2] + 4 * nif[3] + 5 * nif[4] + 6 * nif[5] + 7 * nif[6] + 8 * nif[7];
    var moduleResult = summation % 11;
    var expectedValue;
    if (moduleResult === 10) {
        var checkSummation = 3 * nif[0] + 4 * nif[1] + 5 * nif[2] + 6 * nif[3] + 7 * nif[4] + 8 * nif[5] + 9 * nif[6] + 10 * nif[7];
        var checkResult = checkSummation % 11;
        if (checkResult === 10) {
            expectedValue = 0;
        } else {
            expectedValue = checkResult;
        }
    } else {
        expectedValue = moduleResult;
    }

    if (expectedValue !== receivedValue) {
        return false;
    }
    return true;
}

/**
 * Returns mod 11 method applied to param number
 * @param {string} number - The user DNI
 * @returns {number} - Mod 11 method result integer
 */
function calculateMod11Method(number) {
    var wFactors = [2, 3, 4, 5, 6, 7];
    var wFactorsLength = wFactors.length;
    var wFIndex = 0;
    var length = number.length;
    var p1Results = [];
    var initialValue = 0;
    var checkDigit;

    // Steps 1 and 2
    for (var i = length - 1; i > 0; i--) {
        p1Results[i] = number[i] * wFactors[wFIndex];
        wFIndex += wFIndex;
        if (wFIndex >= wFactorsLength) {
            wFIndex = 0;
        }
    }
    // Step 3
    var sumP1Results = p1Results.reduce(
        (previousValue, currentValue) => previousValue + currentValue,
        initialValue
    );
    // Step 4
    var p4Result = sumP1Results % 11;
    // Step 5
    var p5Result = 11 - p4Result;
    // Result
    if (p5Result < 10) {
        checkDigit = p5Result;
    } else if (p5Result === 11) {
        checkDigit = 0;
    } else if (p5Result === 10) {
        checkDigit = 1;
    }

    return checkDigit;
}

/**
 * Returns true if the UnifiedIdCode is valid (BG)
 * @param {string} nif - The user UnifiedIdCode
 * @returns {boolean} - True if the UnifiedIdCode is valid
 */
function isValidUnifiedIdCodeBG(nif) {
    var receivedValue = parseInt(nif.slice(-1), 10);
    var expectedValue = calculateMod11Method(nif);

    if (expectedValue !== receivedValue) {
        return false;
    }
    return true;
}

/**
 * Returns true if the PersonalId is valid (BG)
 * @param {string} nif - The user PersonalId
 * @returns {boolean} - True if the PersonalId is valid
 */
function isValidPersonalIdBG(nif) {
    var receivedValue = parseInt(nif.slice(-1), 10);
    var expectedValue = calculateMod11Method(nif);

    if (expectedValue !== receivedValue) {
        return false;
    }
    return true;
}

/**
 * Returns true if the nif is valid - BG
 * @param {string} nif - The user nif
 * @returns {boolean} - True if the nif is valid
 */
function isValidNifBG(nif) {
    var vatRegistrationNumberRegex = new RegExp(BASE_CONFIG.nifConfig.BG.vatRegistrationNumberRegex);
    var unifiedIdCodeRegex = new RegExp(BASE_CONFIG.nifConfig.BG.unifiedIdCodeRegex);
    var personalIdRegex = new RegExp(BASE_CONFIG.nifConfig.BG.personalIdRegex);

    if (vatRegistrationNumberRegex.test(nif) || unifiedIdCodeRegex.test(nif)) {
        return isValidVatRegistrationNumberBG(nif) || isValidUnifiedIdCodeBG(nif);
    }

    if (personalIdRegex.test(nif)) {
        return isValidPersonalIdBG(nif);
    }

    return false;
}

/**
 * Returns true if the nif is valid - CL
 * @param {string} nif - The user nif
 * @returns {boolean} - True if the nif is valid
 */
function isValidNifCL(nif) {
    var rutRegex = new RegExp(BASE_CONFIG.nifConfig.CL.rutRegex);
    return rutRegex.test(nif);
}

/**
 * Returns true if the Daňové identifikační číslo - legal entities is valid  (CZ)
 * @param {string} nif - The Daňové identifikační číslo - legal entities
 * @returns {boolean} - True if the Daňové identifikační číslo - legal entities is valid
 */
function isValidLegalEntitiesDicCZ(nif) {
    var receivedValue = parseInt(nif.slice(-1), 10);
    var summation = 8 * nif[0] + 7 * nif[1] + 6 * nif[2] + 5 * nif[3] + 4 * nif[4] + 3 * nif[5] + 2 * nif[6];
    var checkDigit;
    if (summation % 11 === 0) {
        checkDigit = summation + 11;
    } else {
        checkDigit = Math.ceil(summation / 11) * 11;
    }
    var checkResult = checkDigit - summation;
    var expectedValue = checkResult % 10;

    if (expectedValue !== receivedValue) {
        return false;
    }
    return true;
}

/**
 * Returns true if the Daňové identifikační číslo - individuals (speacial cases) is valid (CZ)
 * @param {string} nif - The Daňové identifikační číslo - individuals (speacial cases)
 * @returns {boolean} - True if the Daňové identifikační číslo - individuals (speacial cases) is valid
 */
function isValidIndividualsSCDicCZ(nif) {
    var receivedValue = parseInt(nif.slice(-1), 10);
    var summation = 8 * nif[1] + 7 * nif[2] + 6 * nif[3] + 5 * nif[4] + 4 * nif[5] + 3 * nif[6] + 2 * nif[7];
    var eqTable = [8, 7, 6, 5, 4, 3, 2, 1, 0, 9, 8];
    var checkDigit;
    if (summation % 11 === 0) {
        checkDigit = summation + 11;
    } else {
        checkDigit = Math.ceil(summation / 11) * 11;
    }
    var result = checkDigit - summation;
    var expectedValue = eqTable[result - 1];

    if (expectedValue !== receivedValue) {
        return false;
    }
    return true;
}

/**
 * Returns true if the Daňové identifikační číslo - individuals (other cases) is valid (CZ)
 * @param {string} nif - The Daňové identifikační číslo - individuals (other cases)
 * @returns {boolean} - True if the Daňové identifikační číslo - individuals (other cases) is valid
 */
function isValidIndividualsOCDicCZ(nif) {
    var summation = parseInt(nif[0] + nif[1], 10) + parseInt(nif[2] + nif[3], 10) + parseInt(nif[4] + nif[5], 10) + parseInt(nif[6] + nif[7], 10) + parseInt(nif[8] + nif[9], 10);
    var condition1 = summation % 11;
    var condition2 = nif % 11;

    if (condition1 === 0 && condition2 === 0) {
        return true;
    }
    return false;
}

/**
 * Returns true if the identifikační číslo osoby (CZ)
 * @param {string} nif - identifikační číslo osoby
 * @returns {boolean} - True if the identifikační číslo osoby is valid
 */
function isValidIdentifikacniCisloOsobyCZ(nif) {
    var receivedValue = parseInt(nif.slice(-1), 10);
    var expectedValue = ((11 - ((8 * nif[0] + 7 * nif[1] + 6 * nif[2] + 5 * nif[3] + 4 * nif[4] + 3 * nif[5] + 2 * nif[6]) % 11))) % 10;
    if (expectedValue !== receivedValue) {
        return false;
    }
    return true;
}

/**
 * Returns true if the nif is valid - CZ
 * @param {string} nif - The user nif
 * @returns {boolean} - True if the nif is valid
 */
function isValidNifCZ(nif) {
    var legalEntitiesDicRegex = new RegExp(BASE_CONFIG.nifConfig.CZ.legalEntitiesDicRegex);
    var individualsDicRegex = new RegExp(BASE_CONFIG.nifConfig.CZ.individualsDicRegex);
    var individualsSCDicRegex = new RegExp(BASE_CONFIG.nifConfig.CZ.individualsSCDicRegex);
    var individualsOCDicRegex = new RegExp(BASE_CONFIG.nifConfig.CZ.individualsOCDicRegex);
    var identifikacniCisloOsobyRegex = new RegExp(BASE_CONFIG.nifConfig.CZ.identifikacniCisloOsobyRegex);

    if (legalEntitiesDicRegex.test(nif)) {
        return isValidLegalEntitiesDicCZ(nif);
    }

    if (individualsDicRegex.test(nif)) {
        return true;
    }

    if (individualsSCDicRegex.test(nif)) {
        return isValidIndividualsSCDicCZ(nif);
    }

    if (individualsOCDicRegex.test(nif)) {
        return isValidIndividualsOCDicCZ(nif);
    }

    if (identifikacniCisloOsobyRegex.test(nif)) {
        return isValidIdentifikacniCisloOsobyCZ(nif);
    }

    return false;
}

/**
 * Returns true if the USt-IdNr is valid (DE)
 * @param {string} nif - The user USt-IdNr
 * @returns {boolean} - True if the USt-IdNr is valid
 */
function isValidUstIdnr(nif) {
    var p = 10;
    var expectedValue;
    var receivedValue = parseInt(nif.slice(-1), 10);

    for (var index = 0; index < nif.length - 1; index++) {
        var s = parseInt(nif[index], 10) + p;
        var m = s % 10;
        if (m === 0) {
            m = 10;
        }
        p = (2 * m) % 11;
    }

    expectedValue = 11 - p;
    if (expectedValue !== receivedValue) {
        return false;
    }
    return true;
}

/**
 * Returns true if the nif is valid - DE
 * @param {string} nif - The user nif
 * @returns {boolean} - True if the nif is valid
 */
function isValidNifDE(nif) {
    var steuernummerRegex = new RegExp(BASE_CONFIG.nifConfig.DE.steuernummerRegex);
    var ustIdnrRegex = new RegExp(BASE_CONFIG.nifConfig.DE.ustIdnrRegex);

    if (ustIdnrRegex.test(nif)) {
        return isValidUstIdnr(nif);
    }

    if (steuernummerRegex.test(nif)) {
        return true;
    }

    return false;
}

/**
 * Returns true if the CVR nummer is valid (DK)
 * @param {string} nif - The user CVR nummer
 * @returns {boolean} - True if the CVR nummer is valid
 */
function isValidCvrNummerDK(nif) {
    var checkDigit = (2 * nif[0]) + (7 * nif[1]) + (6 * nif[2]) + (5 * nif[3]) + (4 * nif[4]) + (3 * nif[5]) + (2 * nif[6]) + parseInt(nif[7], 10);
    if (checkDigit % 11 === 0) {
        return true;
    }
    return false;
}

/**
 * Returns true if the CPR is valid (DK)
 * @param {string} nif - The user CPR
 * @returns {boolean} - True if the CPR is valid
 */
function isValidCprDK(nif) {
    var checkDigit = (4 * nif[0]) + (3 * nif[1]) + (2 * nif[2]) + (7 * nif[3]) + (6 * nif[4]) + (5 * nif[5]) + (4 * nif[6]) + (3 * nif[7]) + (2 * nif[8]) + parseInt(nif[9], 10);

    if (checkDigit % 11 === 0) {
        return true;
    }
    return false;
}

/**
 * Returns true if the nif is valid - DK
 * @param {string} nif - The user nif
 * @returns {boolean} - True if the nif is valid
 */
function isValidNifDK(nif) {
    var cvrNummerRegex = new RegExp(BASE_CONFIG.nifConfig.DK.cvrNummerRegex);
    var cprRegex = new RegExp(BASE_CONFIG.nifConfig.DK.cprRegex);

    if (cvrNummerRegex.test(nif)) {
        return isValidCvrNummerDK(nif);
    }

    if (cprRegex.test(nif)) {
        return isValidCprDK(nif);
    }

    return false;
}

/**
 * Returns true if the Käibemaksukohustuslase number is valid (EE)
 * @param {string} nif - Käibemaksukohustuslase number
 * @returns {boolean} - True if the Käibemaksukohustuslase number is valid
 */
function isValidkmkrEE(nif) {
    var summation = 3 * nif[0] + 7 * nif[1] + 1 * nif[2] + 3 * nif[3] + 7 * nif[4] + 1 * nif[5] + 3 * nif[6] + 7 * nif[7];
    var checkSummation = Math.ceil(summation / 10) * 10;
    var expectedValue = checkSummation - summation;
    var receivedValue = parseInt(nif.slice(-1), 10);

    if (expectedValue !== receivedValue) {
        return false;
    }
    return true;
}

/**
 * Returns true if the nif is valid - EE
 * @param {string} nif - The user nif
 * @returns {boolean} - True if the nif is valid
 */
function isValidNifEE(nif) {
    var kmkrRegex = new RegExp(BASE_CONFIG.nifConfig.EE.kmkrRegex);

    if (kmkrRegex.test(nif)) {
        return isValidkmkrEE(nif);
    }

    return false;
}

/**
 * Returns true if the DNI is valid
 * @param {string} dni - The user DNI
 * @returns {boolean} - True if the DNI is valid
 */
function isValidDniES(dni) {
    var number = dni.substr(0, dni.length - 1);
    var receivedLetter = dni.substr(dni.length - 1, 1);
    var nifLetters = BASE_CONFIG.nifConfig.ES.nifLetters;
    var position = number % 23;
    var expectedLetter = nifLetters.substring(position, position + 1);
    if (expectedLetter !== receivedLetter.toUpperCase()) {
        return false;
    }
    return true;
}

/**
 * Returns true if the NIF or NIE is valid
 * @param {string} id - The user NIF or NIE
 * @returns {boolean} - True if the NIF or NIE is valid
 */
function isValidNifOrNieES(id) {
    var niePrefix = id.charAt(0);
    var nieDefaultPrefixes = BASE_CONFIG.nifConfig.ES.niePrefixParsing;

    if (!nieDefaultPrefixes) {
        return false;
    }

    var prefixIndex = nieDefaultPrefixes.indexOf(niePrefix);
    return isValidDniES(prefixIndex + id.substr(1));
}

/**
 * Returns true if the CIF is valid
 * @param {string} cif - The user CIF
 * @param {RegExp} cifRegex - The user CIF
 * @returns {boolean} - True if the CIF is valid
 */
function isValidCifES(cif, cifRegex) {
    var match = cif.match(cifRegex);
    if (!match) {
        return false;
    }

    var letter = match[1];
    var number = match[2];
    var control = match[3];

    var evenSum = 0;
    var oddSum = 0;
    var n;

    for (var i = 0; i < number.length; i++) {
        n = parseInt(number[i], 10);
        if (i % 2 === 0) {
            n *= 2;
            oddSum += n < 10 ? n : n - 9;
        } else {
            evenSum += n;
        }
    }

    var controlDigit = (10 - (evenSum + oddSum).toString().substr(-1)) % 10;
    var controlLetter = BASE_CONFIG.nifConfig.ES.cifControlLetters.substr(controlDigit, 1);

    if (letter.match(new RegExp(BASE_CONFIG.nifConfig.ES.cifControlDigitRegex))) {
        return control === controlDigit.toString();
    }
    if (letter.match(new RegExp(BASE_CONFIG.nifConfig.ES.cifControlLetterRegex))) {
        return control === controlLetter;
    }
    return control === controlDigit.toString() || control === controlLetter;
}

/**
 * Returns true if the nif is valid
 * @param {string} nif - The user nif
 * @returns {boolean} - True if the nif is valid
 */
function isValidNifES(nif) {
    var dniRegex = new RegExp(BASE_CONFIG.nifConfig.ES.dniRegex);
    var nifAndNieRegex = new RegExp(BASE_CONFIG.nifConfig.ES.nifAndNieRegex);
    var cifRegex = new RegExp(BASE_CONFIG.nifConfig.ES.cifRegex);
    var passportRegex = new RegExp(BASE_CONFIG.nifConfig.ES.passportRegex);

    if (dniRegex.test(nif)) {
        return isValidDniES(nif);
    }
    // This if is merged because both regex can match the same string
    if (nifAndNieRegex.test(nif) || cifRegex.test(nif)) {
        return isValidNifOrNieES(nif) || isValidCifES(nif, cifRegex);
    }
    if (passportRegex.test(nif)) {
        // The passport does not have specific logic behind its digits and letters
        return true;
    }
    return false;
}

/**
 * Returns true if the Arvonlisäveronumero is valid (FI)
 * @param {string} nif - Arvonlisäveronumero
 * @returns {boolean} - True if the Arvonlisäveronumero is valid
 */
function isValidAlvNroFI(nif) {
    var receivedValue = parseInt(nif.slice(-1), 10);
    var expectedValue = calculateMod11Method(nif);

    if (receivedValue === expectedValue) {
        return true;
    }

    return false;
}

/**
 * Returns true if the nif is valid - FI
 * @param {string} nif - The user nif
 * @returns {boolean} - True if the nif is valid
 */
function isValidNifFI(nif) {
    var alvNroRegex = new RegExp(BASE_CONFIG.nifConfig.FI.alvNroRegex);

    if (alvNroRegex.test(nif)) {
        return isValidAlvNroFI(nif);
    }

    return false;
}

/**
 * Returns true if the nif is valid - FR
 * @param {string} nif - The user nif
 * @returns {boolean} - True if the nif is valid
 */
function isValidNifFR(nif) {
    var tvaIntracommunautaireRegex = new RegExp(BASE_CONFIG.nifConfig.FR.tvaIntracommunautaireRegex);
    var siretNumberRegex = new RegExp(BASE_CONFIG.nifConfig.FR.siretNumberRegex);
    var sirenNumberRegex = new RegExp(BASE_CONFIG.nifConfig.FR.sirenNumberRegex);

    if (tvaIntracommunautaireRegex.test(nif)) {
        return true;
    }

    if (siretNumberRegex.test(nif)) {
        return true;
    }

    if (sirenNumberRegex.test(nif)) {
        return true;
    }

    return false;
}

/**
 * Returns true if the VAT Registration number is valid (GB)
 * @param {string} nif - VAT Registration number
 * @returns {boolean} - True if the VAT Registration number is valid
 */
function isValidVatRegistrationNumberGB(nif) {
    var checkNumbers = parseInt(nif.slice(-2), 10);
    var firstCheckResult = (8 * nif[0] + 7 * nif[1] + 6 * nif[2] + 5 * nif[3] + 4 * nif[4] + 3 * nif[5] + 2 * nif[6] + checkNumbers) % 97;
    var secondCheckResult = ((8 * nif[0] + 7 * nif[1] + 6 * nif[2] + 5 * nif[3] + 4 * nif[4] + 3 * nif[5] + 2 * nif[6] + checkNumbers) + 55) % 97;

    if (firstCheckResult === 0 || secondCheckResult === 0) {
        return true;
    }

    return false;
}

/**
 * Returns true if the nif is valid - GB
 * @param {string} nif - The user nif
 * @returns {boolean} - True if the nif is valid
 */
function isValidNifGB(nif) {
    var vatRegistrationNumberRegex = new RegExp(BASE_CONFIG.nifConfig.GB.vatRegistrationNumberRegex);
    var ninoRegex = new RegExp(BASE_CONFIG.nifConfig.GB.ninoRegex);
    var crnRegex = new RegExp(BASE_CONFIG.nifConfig.GB.crnRegex);
    var utrRegex = new RegExp(BASE_CONFIG.nifConfig.GB.utrRegex);

    if (vatRegistrationNumberRegex.test(nif)) {
        return isValidVatRegistrationNumberGB(nif);
    }

    if (ninoRegex.test(nif)) {
        return true;
    }

    if (crnRegex.test(nif)) {
        return true;
    }

    if (utrRegex.test(nif)) {
        return true;
    }

    return false;
}

/**
 * Returns true if the VAT Registration Number is valid (GR)
 * @param {string} nif - VAT Registration Number
 * @returns {boolean} - True if the VAT Registration Number is valid
 */
function isValidVatRegistrationNumberGR(nif) {
    var receivedValue = parseInt(nif.slice(-1), 10);
    var summation = 256 * nif[0] + 128 * nif[1] + 64 * nif[2] + 32 * nif[3] + 16 * nif[4] + 8 * nif[5] + 4 * nif[6] + 2 * nif[7];
    var checkSummation = summation % 11;
    var expectedValue = checkSummation % 10;

    if (expectedValue !== receivedValue) {
        return false;
    }
    return true;
}

/**
 * Returns true if the nif is valid - GR
 * @param {string} nif - The user nif
 * @returns {boolean} - True if the nif is valid
 */
function isValidNifGR(nif) {
    var vatRegistrationNumberRegex = new RegExp(BASE_CONFIG.nifConfig.GR.vatRegistrationNumberRegex);
    var personalIdRegex = new RegExp(BASE_CONFIG.nifConfig.GR.personalIdRegex);
    var afmRegex = new RegExp(BASE_CONFIG.nifConfig.GR.afmRegex);

    if (vatRegistrationNumberRegex.test(nif)) {
        return isValidVatRegistrationNumberGR(nif);
    }

    if (personalIdRegex.test(nif)) {
        return true;
    }

    if (afmRegex.test(nif)) {
        return true;
    }

    return false;
}

/**
 * Returns true if the VAT Registration Number is valid (HU)
 * @param {string} nif - VAT Registration Number
 * @returns {boolean} - True if the VAT Registration Number is valid
 */
function isValidVatRegistrationNumberHU(nif) {
    var receivedValue = parseInt(nif.slice(-1), 10);
    var summation = 9 * nif[0] + 7 * nif[1] + 3 * nif[2] + 1 * nif[3] + 9 * nif[4] + 7 * nif[5] + 3 * nif[6];
    var rightColumnValue = summation % 10;
    var expectedValue = rightColumnValue === 0 ? 0 : 10 - rightColumnValue;

    if (expectedValue !== receivedValue) {
        return false;
    }
    return true;
}

/**
 * Returns true if the nif is valid - HU
 * @param {string} nif - The user nif
 * @returns {boolean} - True if the nif is valid
 */
function isValidNifHU(nif) {
    var vatRegistrationNumberRegex = new RegExp(BASE_CONFIG.nifConfig.HU.vatRegistrationNumberRegex);
    var taxNumberRegex = new RegExp(BASE_CONFIG.nifConfig.HU.taxNumberRegex);

    if (vatRegistrationNumberRegex.test(nif)) {
        return isValidVatRegistrationNumberHU(nif);
    }

    if (taxNumberRegex.test(nif)) {
        return true;
    }

    return false;
}

/**
 * Returns true if the Value added tax identification no is valid (IE)
 * @param {string} nif - Value added tax identification no
 * @returns {boolean} - True if the Value added tax identification no is valid
 */
function isValidVatNoOldStyleIE(nif) {
    var receivedValue = nif.slice(-1);
    var checkDigits = '0' + nif[2] + nif[3] + nif[4] + nif[5] + nif[6] + nif[0];
    var result = (checkDigits[0] * 8 + checkDigits[1] * 7 + checkDigits[2] * 6 + checkDigits[3] * 5 + checkDigits[4] * 4 + checkDigits[5] * 3 + checkDigits[6] * 2) % 23;
    var expectedValue = CHECK_CHARACTER_EQ[result];

    if (expectedValue !== receivedValue) {
        return false;
    }
    return true;
}

/**
 * Returns true if the Value added tax identification no is valid (IE)
 * @param {string} nif - Value added tax identification no
 * @returns {boolean} - True if the Value added tax identification no is valid
 */
function isValidVatNoNewStyleIE(nif) {
    var receivedValue = nif.slice(-1);
    var summationResult = (nif[0] * 8 + nif[1] * 7 + nif[2] * 6 + nif[3] * 5 + nif[4] * 4 + nif[5] * 3 + nif[6] * 2) % 23;
    var expectedValue = CHECK_CHARACTER_EQ[summationResult];

    if (expectedValue !== receivedValue) {
        return false;
    }
    return true;
}

/**
 * Returns true if the nif is valid - IE
 * @param {string} nif - The user nif
 * @returns {boolean} - True if the nif is valid
 */
function isValidNifIE(nif) {
    var vatNoOldStyleRegex = new RegExp(BASE_CONFIG.nifConfig.IE.vatNoOldStyleRegex);
    var vatNoNewStyleRegex = new RegExp(BASE_CONFIG.nifConfig.IE.vatNoNewStyleRegex);

    if (vatNoOldStyleRegex.test(nif)) {
        return isValidVatNoOldStyleIE(nif);
    }

    if (vatNoNewStyleRegex.test(nif)) {
        return isValidVatNoNewStyleIE(nif);
    }

    return false;
}

/**
 * Returns mod 10 method applied to param number
 * @param {string} number - The user DNI
 * @returns {number} - Mod 10 method check digit result integer
 */
function calculateLuhnAlgorithm(number) {
    var numberWithoutCD = number.slice(0, -1);
    var nDigits = numberWithoutCD.length;
    var nSum = 0;
    for (var i = nDigits - 1; i >= 0; i--) {
        var digit = parseInt(numberWithoutCD[i], 10);
        var control = i + 1;

        if (control % 2 === 1) {
            nSum += digit;
        } else {
            nSum += (digit * 2) > 9 ? (digit * 2) - 9 : digit * 2;
        }
    }
    var t = nSum % 10;
    return ((10 - t) % 10);
}

/**
 * Returns true if the Partita IVA is valid (IT)
 * @param {string} nif - Partita IVA
 * @returns {boolean} - True if the Partita IVA is valid
 */
function isValidPartitaIva(nif) {
    var receivedValue = parseInt(nif.slice(-1), 10);
    var expectedValue = calculateLuhnAlgorithm(nif);

    if (expectedValue !== receivedValue) {
        return false;
    }
    return true;
}

/**
 * Returns true if the nif is valid - IT
 * @param {string} nif - The user nif
 * @returns {boolean} - True if the nif is valid
 */
function isValidNifIT(nif) {
    var partitaIvaRegex = new RegExp(BASE_CONFIG.nifConfig.IT.partitaIvaRegex);
    var codiceFiscaleRegex = new RegExp(BASE_CONFIG.nifConfig.IT.codiceFiscaleRegex);

    if (partitaIvaRegex.test(nif)) {
        return isValidPartitaIva(nif);
    }

    if (codiceFiscaleRegex.test(nif)) {
        return true;
    }

    return false;
}

/**
 * Returns true if the PVM Legal Persons is valid (LT)
 * @param {string} nif - PVM Legal Persons
 * @returns {boolean} - True if the PVM Legal Persons is valid
 */
function isValidPvmLegalPersonsLT(nif) {
    var receivedValue = parseInt(nif.slice(-1), 10);
    var expectedValue;
    var summation = 1 * nif[0] + 2 * nif[1] + 3 * nif[2] + 4 * nif[3] + 5 * nif[4] + 6 * nif[5] + 7 * nif[6] + 8 * nif[7];
    var summationModule = summation % 11;

    if (summationModule !== 10) {
        expectedValue = summationModule;
    } else {
        var checkSummation = 3 * nif[0] + 4 * nif[1] + 5 * nif[2] + 6 * nif[3] + 7 * nif[4] + 8 * nif[5] + 9 * nif[6] + 1 * nif[7];
        var moduleResult = checkSummation % 11;

        if (moduleResult === 10) {
            expectedValue = 0;
        } else {
            expectedValue = moduleResult;
        }
    }

    if (expectedValue === receivedValue) {
        return true;
    }

    return false;
}

/**
 * Returns true if the PVM Temporarily Registered Taxpayers is valid (LT)
 * @param {string} nif - PVM Temporarily Registered Taxpayers
 * @returns {boolean} - True if the PVM Temporarily Registered Taxpayers is valid
 */
function isValidPvmTemporarilyRegisteredTaxPayersLT(nif) {
    var receivedValue = parseInt(nif.slice(-1), 10);
    var summation = 1 * nif[0] + 2 * nif[1] + 3 * nif[2] + 4 * nif[3] + 5 * nif[4] + 6 * nif[5] + 7 * nif[6] + 8 * nif[7] + 9 * nif[8] + 1 * nif[9] + 2 * nif[10];
    var moduleValue = summation % 11;
    var expectedValue;

    if (moduleValue !== 10) {
        expectedValue = moduleValue;
    } else {
        var checkResult = 3 * nif[0] + 4 * nif[1] + 5 * nif[2] + 6 * nif[3] + 7 * nif[4] + 8 * nif[5] + 9 * nif[6] + 1 * nif[7] + 2 * nif[8] + 3 * nif[9] + 4 * nif[10];
        var moduleResult = checkResult % 11;

        if (moduleResult === 10) {
            expectedValue = 0;
        } else {
            expectedValue = moduleResult;
        }
    }

    if (expectedValue === receivedValue) {
        return true;
    }

    return false;
}

/**
 * Returns true if the nif is valid - LT
 * @param {string} nif - The user nif
 * @returns {boolean} - True if the nif is valid
 */
function isValidNifLT(nif) {
    var pvmLegalPersonsRegex = new RegExp(BASE_CONFIG.nifConfig.LT.pvmLegalPersonsRegex);
    var pvmTemporarilyRegisteredTaxpayersRegex = new RegExp(BASE_CONFIG.nifConfig.LT.pvmTemporarilyRegisteredTaxpayersRegex);

    if (pvmLegalPersonsRegex.test(nif)) {
        return isValidPvmLegalPersonsLT(nif);
    }

    if (pvmTemporarilyRegisteredTaxpayersRegex.test(nif)) {
        return isValidPvmTemporarilyRegisteredTaxPayersLT(nif);
    }

    return false;
}

/**
 * Returns true if the VAT Registration number is valid (LU)
 * @param {string} nif - VAT Registration number
 * @returns {boolean} - True if the VAT Registration number is valid
 */
function isValidVatRegistrationNumberLU(nif) {
    var receivedValue = parseInt(nif.slice(-2), 10);
    var checkDigits = parseInt(nif.slice(0, 6), 10);
    var expectedValue = checkDigits % 89;

    if (expectedValue === receivedValue) {
        return true;
    }

    return false;
}

/**
 * Returns true if the nif is valid - LU
 * @param {string} nif - The user nif
 * @returns {boolean} - True if the nif is valid
 */
function isValidNifLU(nif) {
    var vatRegistrationNumberRegex = new RegExp(BASE_CONFIG.nifConfig.LU.vatRegistrationNumberRegex);

    if (vatRegistrationNumberRegex.test(nif)) {
        return isValidVatRegistrationNumberLU(nif);
    }

    return false;
}

/**
 * Returns true if the PVN Legal Persons is valid (LV)
 * @param {string} nif - PVN Legal Persons
 * @returns {boolean} - True if the PVN Legal Persons is valid
 */
function isValidPvnLegalPersonsLV(nif) {
    var receivedValue = parseInt(nif.slice(-1), 10);
    var summation = 9 * nif[0] + 1 * nif[1] + 4 * nif[2] + 8 * nif[3] + 3 * nif[4] + 10 * nif[5] + 2 * nif[6] + 5 * nif[7] + 7 * nif[8] + 6 * nif[9];
    var checkResult = 3 - (summation % 11);
    var expectedValue;

    if (checkResult < -1) {
        expectedValue = checkResult + 11;
    } else if (checkResult > -1) {
        expectedValue = checkResult;
    } else { // expectedValue === -1
        return false;
    }

    if (expectedValue === receivedValue) {
        return true;
    }

    return false;
}

/**
 * Returns true if the nif is valid - LV
 * @param {string} nif - The user nif
 * @returns {boolean} - True if the nif is valid
 */
function isValidNifLV(nif) {
    var legalPersonsRegex = new RegExp(BASE_CONFIG.nifConfig.LV.legalPersonsRegex);
    var naturalPersonRegex = new RegExp(BASE_CONFIG.nifConfig.LV.naturalPersonRegex);

    if (legalPersonsRegex.test(nif)) {
        return isValidPvnLegalPersonsLV(nif);
    }

    if (naturalPersonRegex.test(nif)) {
        return true;
    }

    return false;
}

/**
 * Returns true if the nif is valid - MX
 * @param {string} nif - The user nif
 * @returns {boolean} - True if the nif is valid
 */
function isValidNifMX(nif) {
    var rfcNumberRegex = new RegExp(BASE_CONFIG.nifConfig.MX.rfcNumberRegex);
    var curpRegex = new RegExp(BASE_CONFIG.nifConfig.MX.curpRegex);

    if (rfcNumberRegex.test(nif)) {
        return true;
    }

    if (curpRegex.test(nif)) {
        return true;
    }

    return false;
}

/**
 * Returns true if the nif is valid - NL
 * @param {string} nif - The user nif
 * @returns {boolean} - True if the nif is valid
 */
function isValidNifNL(nif) {
    var vatRegistrationNumberRegex = new RegExp(BASE_CONFIG.nifConfig.NL.vatRegistrationNumberRegex);
    var citizenServiceNumberRegex = new RegExp(BASE_CONFIG.nifConfig.NL.citizenServiceNumberRegex);
    var aansluitnummerRegex = new RegExp(BASE_CONFIG.nifConfig.NL.aansluitnummerRegex);
    var loonheffingennummerRegex = new RegExp(BASE_CONFIG.nifConfig.NL.loonheffingennummerRegex);

    if (vatRegistrationNumberRegex.test(nif)) {
        return true;
    }

    if (citizenServiceNumberRegex.test(nif)) {
        return true;
    }

    if (aansluitnummerRegex.test(nif)) {
        return true;
    }

    if (loonheffingennummerRegex.test(nif)) {
        return true;
    }

    return false;
}

/**
 * Returns true if the VAT Registration number is valid (PL)
 * @param {string} nif - VAT Registration number
 * @returns {boolean} - True if the VAT Registration number is valid
 */
function isValidVatRegistrationNumberPL(nif) {
    var receivedValue = parseInt(nif.slice(-1), 10);
    var a1 = 6 * nif[0] + 5 * nif[1] + 7 * nif[2] + 2 * nif[3] + 3 * nif[4] + 4 * nif[5] + 5 * nif[6] + 6 * nif[7] + 7 * nif[8];
    var expectedValue = a1 % 11;

    if (expectedValue !== 10 && receivedValue === expectedValue) {
        return true;
    }

    return false;
}

/**
 * Returns true if the PESEL Number is valid (PL)
 * @param {string} nif - PESEL Number
 * @returns {boolean} - True if the PESEL Number is valid
 */
function isValidPeselPL(nif) {
    var receivedValue = parseInt(nif.slice(-1), 10);
    var checkDigit = 10 - ((nif[0] * 1 + nif[1] * 3 + nif[2] * 7 + nif[3] * 9 + nif[4] * 1 + nif[5] * 3 + nif[6] * 7 + nif[7] * 9 + nif[8] * 1 + nif[9] * 3) % 10);
    var expectedValue = checkDigit === 10 ? 0 : checkDigit;

    if (receivedValue === expectedValue) {
        return true;
    }

    return false;
}

/**
 * Returns true if the nif is valid - PL
 * @param {string} nif - The user nif
 * @returns {boolean} - True if the nif is valid
 */
function isValidNifPL(nif) {
    var vatRegistrationNumber = new RegExp(BASE_CONFIG.nifConfig.PL.vatRegistrationNumber);
    var nipRegex = new RegExp(BASE_CONFIG.nifConfig.PL.nipRegex);
    var peselRegex = new RegExp(BASE_CONFIG.nifConfig.PL.peselRegex);

    if (vatRegistrationNumber.test(nif)) {
        return isValidVatRegistrationNumberPL(nif);
    }

    if (nipRegex.test(nif)) {
        return true;
    }

    if (peselRegex.test(nif)) {
        return isValidPeselPL(nif);
    }

    return false;
}

/**
 * Returns true if the VAT Registration number is valid (PT)
 * @param {string} nif - VAT Registration number
 * @returns {boolean} - True if the VAT Registration number is valid
 */
function isValidVatAndNifPT(nif) {
    var receivedValue = parseInt(nif.slice(-1), 10);
    var checkResult = 11 - ((9 * nif[0] + 8 * nif[1] + 7 * nif[2] + 6 * nif[3] + 5 * nif[4] + 4 * nif[5] + 3 * nif[6] + 2 * nif[7]) % 11);
    var expectedValue = (checkResult === 10 || checkResult === 11) ? 0 : checkResult;

    if (expectedValue === receivedValue) {
        return true;
    }

    return false;
}

/**
 * Returns true if the nif is valid - PT
 * @param {string} nif - The user nif
 * @returns {boolean} - True if the nif is valid
 */
function isValidNifPT(nif) {
    var vatRegistrationNumberRegex = new RegExp(BASE_CONFIG.nifConfig.PT.vatRegistrationNumberRegex);
    var nifRegex = new RegExp(BASE_CONFIG.nifConfig.PT.nifRegex);

    if (vatRegistrationNumberRegex.test(nif) || nifRegex.test(nif)) {
        return isValidVatAndNifPT(nif);
    }

    return false;
}

/**
 * Returns true if the Codul de identificare fiscală is valid (RO)
 * @param {string} nif - The Codul de identificare fiscală
 * @returns {boolean} - True if the Codul de identificare fiscală is valid
 */
function isValidCifRO(nif) {
    var receivedValue = parseInt(nif.slice(-1), 10);
    var nifLength = nif.length;
    var fullNif = nif;
    if (nifLength < 10) {
        var addZeros = 10 - nifLength;
        for (var i = 0; i < addZeros; i++) {
            fullNif = '0' + fullNif;
        }
    }
    var summation = fullNif[0] * 7 + fullNif[1] * 5 + fullNif[2] * 3 + fullNif[3] * 2 + fullNif[4] * 1 + fullNif[5] * 7 + fullNif[6] * 5 + fullNif[7] * 3 + fullNif[8] * 2;
    var checkDigit = summation * 10;
    var result = checkDigit % 11;
    var expectedValue = result === 10 ? 0 : result;

    if (expectedValue !== receivedValue) {
        return false;
    }
    return true;
}

/**
 * Returns true if the unique registration number is valid (RO)
 * @param {string} nif - The unique registration number
 * @returns {boolean} - True if the unique registration number is valid
 */
function isValidCuiRO(nif) {
    var receivedValue = parseInt(nif.slice(-1), 10);
    var digitNumbers = [2, 7, 9, 1, 4, 6, 3, 5, 8, 2, 7, 9];
    var checksumValue = 0;

    for (var i = 0; i < nif.length - 1; i++) {
        var n = nif[i] * digitNumbers[i];
        checksumValue += n;
    }

    var expectedValue = checksumValue % 11;

    if (expectedValue !== receivedValue) {
        return false;
    }
    return true;
}

/**
 * Returns true if the nif is valid - RO
 * @param {string} nif - The user nif
 * @returns {boolean} - True if the nif is valid
 */
function isValidNifRO(nif) {
    var cifRegex = new RegExp(BASE_CONFIG.nifConfig.RO.cifRegex);
    var cuiRegex = new RegExp(BASE_CONFIG.nifConfig.RO.cuiRegex);

    if (cifRegex.test(nif)) {
        return isValidCifRO(nif);
    }

    if (cuiRegex.test(nif)) {
        return isValidCuiRO(nif);
    }

    return false;
}

/**
 * Returns true if the momsregistreringsnummer is valid (SE)
 * @param {string} nif - The momsregistreringsnummer
 * @returns {boolean} - True if the momsregistreringsnummer  is valid
 */
function isValidMomsnrSE(nif) {
    var receivedValue = parseInt(nif[9], 10);
    var result = (Math.trunc(nif[0] / 5) + ((nif[0] * 2) % 10)) + (Math.trunc(nif[2] / 5) + ((nif[2] * 2) % 10)) + (Math.trunc(nif[4] / 5) + ((nif[4] * 2) % 10)) + (Math.trunc(nif[6] / 5) + ((nif[6] * 2) % 10)) + (Math.trunc(nif[8] / 5) + ((nif[8] * 2) % 10));
    var expectedValue = (10 - ((result + parseInt(nif[1], 10) + parseInt(nif[3], 10) + parseInt(nif[5], 10) + parseInt(nif[7], 10)) % 10)) % 10;

    if (expectedValue !== receivedValue) {
        return false;
    }
    return true;
}

/**
 * Returns true if the Organisationsnummer is valid (SE)
 * @param {string} nif - The Organisationsnummer
 * @returns {boolean} - True if the Organisationsnummer is valid
 */
function isValidOrgnrSE(nif) {
    var receivedValue = parseInt(nif.slice(-1), 10);
    var expectedValue = calculateLuhnAlgorithm(nif);

    if (expectedValue !== receivedValue) {
        return false;
    }
    return true;
}

/**
 * Returns true if the nif is valid - SE
 * @param {string} nif - The user nif
 * @returns {boolean} - True if the nif is valid
 */
function isValidNifSE(nif) {
    var momsnrRegex = new RegExp(BASE_CONFIG.nifConfig.SE.momsnrRegex);
    var orgnrRegex = new RegExp(BASE_CONFIG.nifConfig.SE.orgnrRegex);

    if (momsnrRegex.test(nif)) {
        return isValidMomsnrSE(nif);
    }

    if (orgnrRegex.test(nif)) {
        return isValidOrgnrSE(nif);
    }

    return false;
}

/**
 * Returns true if the Identifikační číslo osoby is valid (SK)
 * @param {string} nif - Identifikační číslo osoby
 * @returns {boolean} - True if the Identifikační číslo osoby is valid
 */
function isValidIdentifikacniCisloOsobySK(nif) {
    var summation = (8 * nif[0]) + (7 * nif[1]) + (6 * nif[2]) + (5 * nif[3]) + (4 * nif[4]) + (3 * nif[5]) + (2 * nif[6]);
    var checkDigit = summation % 11;
    if ((11 - checkDigit) % 10 === 1) {
        return true;
    }
    return false;
}

/**
 * Returns true if the nif is valid - SK
 * @param {string} nif - The user nif
 * @returns {boolean} - True if the nif is valid
 */
function isValidNifSK(nif) {
    var icDphRegex = new RegExp(BASE_CONFIG.nifConfig.SK.icDphRegex);
    var danoveIdentifikacniCisloRegex = new RegExp(BASE_CONFIG.nifConfig.SK.danoveIdentifikacniCisloRegex);
    var identifikacniCisloOsobyRegex = new RegExp(BASE_CONFIG.nifConfig.SK.identifikacniCisloOsobyRegex);

    if (icDphRegex.test(nif)) {
        return true;
    }

    if (danoveIdentifikacniCisloRegex.test(nif)) {
        return true;
    }

    if (identifikacniCisloOsobyRegex.test(nif)) {
        return isValidIdentifikacniCisloOsobySK(nif);
    }

    return false;
}

/**
 * Returns true if the Security Number SSN is valid (US)
 * @param {string} nif - The Security Number SSN
 * @returns {boolean} - True if the Security Number SSN is valid
 */
function isValidSsnUS(nif) {
    var randomNumbers = nif.slice(0, 3);
    var groupNumber = nif.slice(4, 2);
    var serialNumber = nif.slice(-4);

    if (randomNumbers === '000' || randomNumbers === '666' || randomNumbers >= '900') {
        return false;
    }

    if (groupNumber === '00') {
        return false;
    }

    if (serialNumber === '0000') {
        return false;
    }

    return true;
}

/**
 * Returns true if the nif is valid - US
 * @param {string} nif - The user nif
 * @returns {boolean} - True if the nif is valid
 */
function isValidNifUS(nif) {
    var ssnRegex = new RegExp(BASE_CONFIG.nifConfig.US.ssnRegex);
    var einRegex = new RegExp(BASE_CONFIG.nifConfig.US.einRegex);

    if (ssnRegex.test(nif)) {
        return isValidSsnUS(nif);
    }

    if (einRegex.test(nif)) {
        return true;
    }

    return false;
}

/**
 * Returns true if the nif is valid
 * @param {string} nif - the nif to validate
 * @returns {boolean} - true if the nif is valid
 */
function isValidNif(nif) {
    var billingAddressCountryCode = $('#billingCountry') ? $('#billingCountry').val() : null;
    if (!nif || nif === '') {
        return false;
    }

    // If the field is not found, it is better not to validate the NIF than to lose a sale.
    if (billingAddressCountryCode) {
        switch (billingAddressCountryCode.toUpperCase()) {
            case 'AD': // Andorra
                return true;
            case 'AT': // Austria
                return isValidNifAT(nif);
            case 'BE': // Bélgica
                return isValidNifBE(nif);
            case 'BG': // Bulgaria
                return isValidNifBG(nif);
            case 'CL': // Chile
                return isValidNifCL(nif);
            case 'CZ': // República Checa
                return isValidNifCZ(nif);
            case 'DE': // Alemania
                return isValidNifDE(nif);
            case 'DK': // Dinamarca
                return isValidNifDK(nif);
            case 'EE': // Estonia
                return isValidNifEE(nif);
            case 'ES':
                return isValidNifES(nif);
            case 'FI': // Finlandia
                return isValidNifFI(nif);
            case 'FR': // Francia
                return isValidNifFR(nif);
            case 'GB': // Reino Unido
                return isValidNifGB(nif);
            case 'GR': // Grecia
                return isValidNifGR(nif);
            case 'HU': // Hungría
                return isValidNifHU(nif);
            case 'IE': // Irlanda
                return isValidNifIE(nif);
            case 'IT': // ITalia
                return isValidNifIT(nif);
            case 'JP': // Japón
                return true;
            case 'LT': // Lituania
                return isValidNifLT(nif);
            case 'LU': // Luxemburgo
                return isValidNifLU(nif);
            case 'LV': // Letonia
                return isValidNifLV(nif);
            case 'MX': // México
                return isValidNifMX(nif);
            case 'NL': // Países Bajos
                return isValidNifNL(nif);
            case 'PL': // Polonia
                return isValidNifPL(nif);
            case 'PT': // Portugal
                return isValidNifPT(nif);
            case 'RO': // Rumania
                return isValidNifRO(nif);
            case 'SE': // Suecia
                return isValidNifSE(nif);
            case 'SK': // Eslovaquia
                return isValidNifSK(nif);
            case 'US': // Estados Unidos
                return isValidNifUS(nif);
            default:
                return true;
        }
    }

    return true;
}

module.exports = {
    isValidNif: isValidNif
};
