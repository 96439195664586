/*
Based on https://github.com/csstools/css-blank-pseudo

Original polyfill applied over SFRA might cause infinite loops because legacy
polyfill forces "change" event when any option changes its "selected" property.

For instance, in shipping address forms on checkout. SFRA listens "change" event
on "state" select, does an AJAX request and sets all the form fields values on
response by means of jQuery. jQuery does not throws change event when setting a
value programatically. With the legacy polyfill a new "change" event is
triggered, so browser enters in an infinite loop.

This custom polyfill is based on original one, but triggering a custom jQuery
event for IE 11 compatibility.
*/
const EVENT_FORM_CONTROL_CHANGE = 'form-control:update-label';
const window = (document.ownerDocument || document).defaultView;
const $ = window.$ || window.jQuery;

/**
 * Check whether a form control is empty. If so, an attribute "blank" will be
 * added to suit the CSS selector generated by "css blank pseudo" postcss
 * plugin.
 * @param {Element} node Element to check
 */
function configureCssBlankAttribute(node) {
    const currentValue = $(node).val();
    const hasValue = currentValue && currentValue !== null && currentValue !== '';
    if (hasValue) {
        node.removeAttribute('blank');
    } else {
        node.setAttribute('blank', 'blank');
    }
}

/**
 * Observe changes to the "selected" property on an HTML Element.
 * It will trigger an event (EVENT_FORM_CONTROL_CHANGE) when any element changes
 * its "selected" property.
 * Focused on option elements of select.
 * @param {HTMLElement} HTMLElement element type to observe
 * @returns {undefined}
 */
function observeSelectedOfHTMLElement(HTMLElement) {
    const descriptor = Object.getOwnPropertyDescriptor(
        HTMLElement.prototype,
        'selected'
    );
    const nativeSet = descriptor.set;

    // eslint-disable-next-line no-unused-vars
    descriptor.set = function set(value) {
        nativeSet.apply(this, arguments);
        $(this.parentNode).trigger(EVENT_FORM_CONTROL_CHANGE);
    };

    Object.defineProperty(HTMLElement.prototype, 'selected', descriptor);
}

/**
 * Observe changes to the "value" property on an HTML Element.
 * It will trigger an event (EVENT_FORM_CONTROL_CHANGE) when any element changes
 * its "value" property.
 * @param {HTMLElement} HTMLElement element type to observe
 * @returns {undefined}
 */
function observeValueOfHTMLElement(HTMLElement) {
    const descriptor = Object.getOwnPropertyDescriptor(
        HTMLElement.prototype,
        'value'
    );
    const nativeSet = descriptor.set;

    // eslint-disable-next-line no-unused-vars
    descriptor.set = function set(value) {
        nativeSet.apply(this, arguments);
        let $this = $(this);
        $this.trigger(EVENT_FORM_CONTROL_CHANGE);
    };

    Object.defineProperty(HTMLElement.prototype, 'value', descriptor);
}

/**
 * Observe changes on <input>, <select> and <textarea> elements in order to
 * check if they are empty or not and add the required attribute for :blank
 * css selector fallback.
 * @param {Document} document Document of the web page where is applied
 * @param {Object} opts polyfill options (force: boolean)
 */
function cssBlankPseudo(document, opts) {
    // configuration
    const force = Object(opts).force;

    /*
     * refreshCssBlankPseudo()
     * ------------------------
     * jQuery plugin to force to check a form control when its change is not
     * detected and triggering "change" event has undesired side effects because
     * of SFRA.
     */
    $.fn.refreshCssBlankPseudo = function () {
        this.trigger(EVENT_FORM_CONTROL_CHANGE);
        return this;
    };

    try {
        document.querySelector(':blank');

        if (!force) {
            // If it is supported by browser exit
            return;
        }
    } catch (ignoredError) {
        // do nothing and continue
    }

    const selector = 'input, select, textarea';
    const selectorRegExp = /^(INPUT|SELECT|TEXTAREA)$/;

    // observe value changes on <input>, <select>, and <textarea>
    observeValueOfHTMLElement(window.HTMLInputElement);
    observeValueOfHTMLElement(window.HTMLSelectElement);
    observeValueOfHTMLElement(window.HTMLTextAreaElement);
    observeSelectedOfHTMLElement(window.HTMLOptionElement);

    $(document).on('input change', selector, function () {
        $(this).trigger(EVENT_FORM_CONTROL_CHANGE);
    });

    $(document).on(EVENT_FORM_CONTROL_CHANGE, function (evt) {
        configureCssBlankAttribute(evt.target);
    });

    // Apply to elements in the document (first checking)
    const initialElements = Array.prototype.slice.call(
        document.querySelectorAll(selector)
    );
    initialElements.forEach(configureCssBlankAttribute);

    // Apply to dynamically added elements
    new MutationObserver(function (mutationsList) {
        mutationsList.forEach(function (mutation) {
            Array.prototype.forEach.call(mutation.addedNodes || [], function (
                node
            ) {
                if (node.nodeType === 1 && selectorRegExp.test(node.nodeName)) {
                    configureCssBlankAttribute(node);
                }
            });
        });
    }).observe(document, { childList: true, subtree: true });
}

module.exports = cssBlankPseudo;
