const cssBlankPseudo = require('./cssBlankPseudo');

module.exports = function () {
    cssBlankPseudo(document);

    // Header sticky
    var pageHeader = document.getElementsByClassName('page-header')[0];
    if (pageHeader) {
        window.Stickyfill.addOne(pageHeader);
    }

    // Product Details Info
    var productInfo = document.querySelector('.product-detail-page .product-info__wrapper');
    if (productInfo) {
        window.Stickyfill.addOne(productInfo);
    }
    // Floating Plp Navigation
    var plpNavigation = document.getElementsByClassName('floating-plp-navigation')[0];
    if (plpNavigation) {
        window.Stickyfill.addOne(plpNavigation);
    }
};
