const getAnalyticsConfig = require('../../../common/getAnalyticsConfig');
const { createPersistentStore } = require('../../../utilities/createStore');
const { createEcommerceListObject } = require('../models/ecommerceListObject');

const analyticsConfig = getAnalyticsConfig();

/**
 * @type {import('../../../utilities/createStore').LocalStore}
 * Main table of datalayer component for storing listContext, flags or update
 * records.
 */
const generalStore = createPersistentStore({
    createModelFromDto: createEcommerceListObject,
    dbName: analyticsConfig.localforage.dbDatalayer,
    dbTable: analyticsConfig.localforage.stores.general.name
});

module.exports = generalStore;
