const getPipelinesUrls = require('../../../common/getPipelinesUrls');

const PIPELINES_URLS = getPipelinesUrls();
/**
 * Fetch the current currency code from server session
 * @returns {Promise.<string>} - Currency code
 */
function fetchCurrentCurrencyCode() {
    return fetch(PIPELINES_URLS.analyticsGetCurrentCurrencyCode)
        .then((response) => response.json())
        .then((json) => json.currencyCode);
}

module.exports = fetchCurrentCurrencyCode;
