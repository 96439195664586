const { createPromisesQueue, queueInputTypes } = require('../../utilities/queue');

const mitt = require('mitt').default;

/**
 * Pub/Sub events for triggering commands
 */
const COMMANDS = {
    /**
     * Push the analytics event related to the first step of the checkout
     * @param {Object} payload
     * @param {string} payload.currencyCode Currency code
     * @param {string} payload.eventDataLabel The label of the analytics event
     * @param {object[]} payload.productFieldObjects Array of DTOs of the
     * productFieldObjects from the cart items
     */
    sendEventFirstStepOfCheckout: 'datalayer__command-send-event-checkout-step-1',
    /**
     * Push the analytics event related to the first step of the checkout
     * @param {Object} datalayerPurchaseInfo Data from the server
     */
    sendEventPurchase: 'datalayer__command-send-event-purchase'
};

/**
 * Pub/Sub events for target actions to collect data from
 */
const EVENTS = {
    /**
     * Event triggered after sending and event to datalayer with
     * sendEvent helper
     * @param {DataLayerEvent} datalayerEvent - event sent
     */
    datalayerEventSent: 'datalayer__event-sent',
    /**
     * Event triggered after changing the stage in the checkout process
     * @param {object} payload - The data
     * @param {string} payload.stageName - The name of the stage
     * @param {string} payload.redirectUrl - URL to redirect after pushing the
     * event
     */
    checkoutStageChanged: 'datalayer__checkout-stage-changed',
    /**
     * Event triggered after the first into-view appearence of a product
     * impression
     * @param {Object} payload - Payload
     * @param {Element} payload.element - The element which triggers the
     * impression
     * @param {ImpressionFieldObject} payload.impression - Impression data
     */
    impressionShownForTheFirstTime: 'datalayer__impression-first-time-shown',
    /**
     * Event triggered after sending some impressions to datalayer
     * @param {ImpressionFieldObject[]} payload - Array of impressions sent to
     * datalayer
     */
    impressionsSent: 'datalayer__impressions-sent',
    /**
     * Event triggered after creating and showing the modal (full content loaded)
     * @param {object} payload
     * @param {Element} payload.modal - modal element
     * @param {string} payload.name - Name of the modal
     * @param {Element} payload.scrollableContainer - the element that has the
     * scroll
     */
    modalShown: 'analytics__modal-shown',
    /**
     * Event triggered when closing the modal, before modal content removing
     * @param {object} payload
     * @param {Element} payload.modal - modal element
     * @param {string} payload.name - Name of the modal
     * @param {Element} payload.scrollableContainer - the element that has the
     * scroll
     */
    modalWillBeHidden: 'analytics__modal-will-be-hidden',
    /**
     * Event triggered after closing a modal
     * @param {object} payload
     * @param {string} payload.name - Name of the modal
     */
    modalHidden: 'analytics__modal-hidden',
    /**
     * Event triggered when loading a page.
     * For instance: It can be used for sending the Google Tag Manager page view
     * event.
     * @param {object} payload
     * @param {string} [payload.pid] - Product ID
     */
    pageviewLoaded: 'analytics__pageview',
    /**
     * Event triggered after adding a product to cart
     * @param {string} currencyCode - The current currency code
     * of the clicked product returned from server
     * @param {Object} productFieldObject - The DTO of the productFieldObject
     * of the just added product returned from server
     * @param {Element} target - The element that has a the product impression
     * or a descendant
     */
    productAddedToCart: 'datalayer__product-added-to-cart',
    /**
     * Event triggered after clicking a product
     * @param {string} currencyCode - The current currency code
     * of the clicked product returned from server
     * @param {Object} productFieldObject - The DTO of the productFieldObject
     * of the clicked product returned from server
     * @param {string} redirectUrl - The Url to redirect after pushing
     * the data to datalayers
     * @param {Element} target - The element just clicked
     */
    productClicked: 'datalayer__product-clicked',
    /**
     * Event triggered after clicking a product color in the color selector of
     * PDP
     * @param {string} currencyCode The current currency code of the clicked
     * product returned from server
     * @param {string} previousProductId The product ID of the previous color
     * @param {Object} productFieldObject The DTO of the productFieldObject
     * of the clicked color returned from server
     */
    productColorClicked: 'datalayer__product-color-clicked',
    /**
     * Event triggered after removed a product from cart
     * @param {string} currencyCode - The current currency code
     * of the clicked product returned from server
     * @param {Object} productFieldObject - The DTO of the productFieldObject
     * of the just removed product returned from server
     */
    productRemovedFromCart: 'datalayer__product-removed-from-cart',
    /**
     * Event triggered after setting the content segment
     * @param {number} segment - segment new value
     */
    segmentSet: 'analytics__segment-set',
    /**
     * Event triggered after creating and showing shop the look modal
     * @param {object} payload
     * @param {Element} payload.modal - shop-the-look modal
     * @param {Element} payload.scrollableContainer - the element that has the
     * scroll
     */
    shopTheLookModalShown: 'analytics__shop-the-look-modal-shown',
    /**
     * Event triggered when hiding the modal is triggered
     * removing
     * @param {object} payload
     * @param {Element} payload.modal - shop-the-look modal
     * @param {Element} payload.scrollableContainer - the element that has the
     * scroll
     */
    shopTheLookModalWillBeHidden: 'analytics__shop-the-look-modal-will-be-hidden',
    /**
     * Event triggered after hiding shop the look
     */
    shopTheLookModalHidden: 'analytics__shop-the-look-modal-hidden',
    /**
     * Event triggered after changing the active slide in a swiper slider of
     * products
     * @param {Swiper} swiper
     */
    sliderActiveSlideChanged: 'analytics__slider-active-slide-changed'
};

/**
 * Constant values of payloads of some events.
 *
 * Defined by means of a IIFE because some consts can be shared between events
 * and we need a private `shared` property in order to avoid duplicating consts.
 *
 * Object structure:
 *   {
 *     [event1-name]: {
 *       [param]: {
 *          [value1]: any,
 *          [value2]: any,
 *          ...
 *          [valueN]: any,
 *       },
 *       [param2]: shared.paramXY
 *     },
 *     [event2-name]: {
 *       [param]: shared.paramXY
 *     }
 *   }
 */
const PAYLOADS = (function () {
    const shared = {
        modalName: {
            addedToCart: 'added-to-cart',
            minicart: 'minicart',
            shopTheLook: 'shop-the-look',
            searchSuggestions: 'search-suggestions'
        }
    };

    return {
        modalHidden: {
            name: shared.modalName
        },
        modalShown: {
            name: shared.modalName
        },
        modalWillBeHidden: {
            name: shared.modalName
        }
    };
}());

// Assure a single broker for every js which requires this module.
const broker = window.adAnalyticsBroker || mitt();
window.adAnalyticsBroker = broker;

const syncBroker = (function () {
    const queues = {};
    const existsQueue = (eventName) => eventName in queues;
    return {
        on(eventName, handler) {
            if (!existsQueue(eventName)) {
                queues[eventName] = createPromisesQueue({
                    inputType: queueInputTypes.COMMON_INPUT
                });
            }
            queues[eventName].add(handler);
        },
        off(eventName, handler) {
            queues[eventName]?.remove(handler);
        },
        emit(eventName, payload) {
            return queues[eventName]?.start(payload) ?? Promise.resolve();
        }
    };
}());

const eventNames = Object.values(EVENTS);
const commandNames = Object.values(COMMANDS);
const validNames = [...eventNames, ...commandNames];
const exists = (eventName) => validNames.includes(eventName);

/**
 * Publish an analytic event
 * @param {ANALYTICS_EVENTS} eventName The event to publish to
 * @param {*} [data] The payload that is useful for event subscribers
 */
function publish(eventName, data) {
    if (!exists(eventName)) {
        throw new TypeError(`Unknown analytic event: '${eventName}'`);
    }
    syncBroker.emit(eventName, data).then(
        () => {
            broker.emit(eventName, data);
        }
    );
}

/**
 * Subscribe to an analytic event
 * @param {string|string[]} eventName The event/s to subscribe to
 * @param {function((Event|JQuery.event|any), any)} handler The handler of the
 * subscription, which receives an event
 * @param {boolean} [synchronous=false] The handler will be called synchronously
 * and before the async handlers
 */
function subscribe(eventName, handler, synchronous = false) {
    if (Array.isArray(eventName)) {
        eventName.forEach((singleEventName) => subscribe(singleEventName, handler));
        return;
    }
    if (!exists(eventName)) {
        throw new TypeError(`Unknown analytic event: '${eventName}'`);
    }
    if (synchronous === true) {
        syncBroker.on(eventName, handler);
    } else {
        broker.on(eventName, handler);
    }
}

/**
 * Unsubscribe from an analytic event
 * @param {string|string[]} eventName The event/s from ANALYTICS_EVENTS to
 * unsubscribe from
 * @param {function((Event|JQuery.event|any), any)} handler The handler of the
 * subscription that must be deleted
 */
function unsubscribe(eventName, handler) {
    if (Array.isArray(eventName)) {
        eventName.forEach((singleEventName) => unsubscribe(singleEventName, handler));
        return;
    }
    if (!exists(eventName)) {
        throw new TypeError(`Unknown analytic event: '${eventName}'`);
    }
    syncBroker.off(eventName, handler);
    broker.off(eventName, handler);
}

/* eslint-disable no-console */
// Disable no-console because the following code is only for debugging purpose
const debug = {
    mustShowMoreInfo: false,
    print: (eventName) => (payload) => {
        console.group(`[AD/analytics] "${eventName}"`);
        console.log('payload:', payload);
        if (debug.mustShowMoreInfo) {
            console.trace();
        }
        console.groupEnd();
    },
    on(mustShowMoreInfo) {
        this.mustShowMoreInfo = mustShowMoreInfo ?? false;
        const callback = this.print;
        broker.on('*', (e, data) => callback(e)(data));
        console.log('[AD/analytics] DEBUG ON');
    }
};
/* eslint-enable no-console */

const internalEventCenter = {
    COMMANDS,
    EVENTS,
    PAYLOADS,
    debug,
    publish,
    subscribe,
    unsubscribe
};

module.exports = {
    internalEventCenter
};
