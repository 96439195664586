const segments = window.BASE_CONFIG.contentSegments.segments;
const segmentsByValue = window.BASE_CONFIG.contentSegments.segmentsByValue;
const getAnalyticsEventCenter = require('../common/getAnalyticsEventCenter');
const cookieHelper = require('../utilities/cookie');
const { isEmpty, isString, isFunction } = require('../utilities/type');

const analyticsEventCenter = getAnalyticsEventCenter();

/**
 * Apply custom styles for a given segment
 * @param {int} contentSegmentValue Segment type value
 * @returns {void}
 */
function enableCustomSegmentedStyles(contentSegmentValue) {
    var menStyles = document.getElementById('style-custom-men');
    var womenStyles = document.getElementById('style-custom-women');

    if (!menStyles && !womenStyles) return;

    switch (contentSegmentValue) {
        case segments.man.value:
            menStyles.media = 'all';
            womenStyles.media = 'not all';
            break;
        case segments.woman.value:
            menStyles.media = 'not all';
            womenStyles.media = 'all';
            break;
        default:
            menStyles.media = 'not all';
            womenStyles.media = 'not all';
    }
}

/**
 * Check whether a segment value is valid
 * @param {number} segmentValue segment value
 * @returns {boolean} true if the segment is available
 */
function isValidSegment(segmentValue) {
    return segmentValue in segmentsByValue;
}

/**
 * Return the corresponding segment value
 * @param {*} value ENtry value
 * @returns {number} Valid segment value
 */
function parseSegmentValue(value) {
    if (isEmpty(value)) return null;
    if (isString(value)) {
        // eslint-disable-next-line no-param-reassign
        value = parseInt(value, 10);
    }
    if (!isValidSegment(value)) {
        throw new Error('Invalid segment: ' + value);
    }
    return value;
}

/**
 * Get content segment value
 * If url segment parameter is correct, the value of the cookie will be set
 *
 * @return {number} contentSegmentValue Obtained from URL param or segment cookie
 */
function getContentSegmentCookie() {
    var contentSegmentValue = null;
    if (cookieHelper.cookieExists('segment')) {
        var segmentCookieValue = parseInt(cookieHelper.getCookieValue('segment'), 10);
        if (isValidSegment(segmentCookieValue)) {
            contentSegmentValue = segmentCookieValue;
        }
    }
    return contentSegmentValue;
}

/**
 * Set content segment cookie
 * @param {number|string} segmentValue Segment type value
 * @returns {number|null} new content segment value
 */
function setContentSegmentCookie(segmentValue) {
    var newValue = parseSegmentValue(segmentValue);

    var oldValue = getContentSegmentCookie();
    if (newValue === oldValue) return null;

    var date = new Date();
    var maxDays = window.BASE_CONFIG.contentSegments.cookieMaxDays;
    date.setTime(date.getTime() + (maxDays * 24 * 60 * 60 * 1000));
    document.cookie = 'segment=' + newValue + '; expires=' + date.toUTCString() + ';path=/';

    analyticsEventCenter.publish(
        analyticsEventCenter.EVENTS.segmentSet,
        newValue
    );

    return newValue;
}

/**
 * Set the current first section
 */
function setFirstActiveSection() {
    $('.pd-section').removeClass('first-active-section');
    $('#maincontent').find('.pd-section:visible:first').addClass('first-active-section');
}

/**
 * Set the current first col component in each column
 */
function setFirstActiveColComponent() {
    $('.pd-layout__col-component').removeClass('first-active-col-component');
    $('.pd-layout-column').find('.pd-layout__col-component:visible:first').addClass('first-active-col-component');
}

/**
 * If there are dynamic changes it shows selected segment and hide the other ones
 * @param {number|string} contentSegmentValue Segment type value
 */
function updateDomForContentSegment(contentSegmentValue) {
    if (isEmpty(contentSegmentValue)) return;

    enableCustomSegmentedStyles(contentSegmentValue);

    var $navigations = $('.floating-navigation[data-content-segment]');
    $navigations.filter(`[data-content-segment="${contentSegmentValue}"]`).css('display', 'block');
    $navigations.filter(`:not([data-content-segment="${contentSegmentValue}"])`).css('display', 'none');

    setFirstActiveSection();
    setFirstActiveColComponent();
}

/**
 * If the passed segment value will change the current segment, the function
 * will update the UI dinamically by default.
 *
 * This function accepts a second parameter: a function that determines if the
 * user is redirected to the segment main URL or the UI is updated dinamically.
 * The "mustRedirectFn" function will receive the new segment value and the
 * current one as parameters.
 *
 * @param {number|string} segmentValue The new segment value
 * @param {Function.<number,number>} [mustRedirectFn] The function that decides
 * whether the user is redirected or not.
 */
function setContentSegmentValue(segmentValue, mustRedirectFn) {
    var mustRedirect = (isFunction(mustRedirectFn)) ? mustRedirectFn : () => false;
    var newValue = parseSegmentValue(segmentValue);
    var currentSegmentValue = getContentSegmentCookie();
    if (mustRedirect(newValue, currentSegmentValue)) {
        var contentSegmentUrl = segmentsByValue[segmentValue]?.url;
        if (contentSegmentUrl) window.location.href = contentSegmentUrl;
    } else {
        setContentSegmentCookie(newValue);
        updateDomForContentSegment(newValue);
    }
}

/**
 * Initialize event listeners for segment selector
 * @link cartridge/templates/default/home/segmentSelector.isml
 */
function addSegmentSelectorListeners() {
    const segmentSelectorOptions = document.querySelectorAll('.segment-selector__option');
    const mustAlwaysRedirect = () => true;

    /**
     * Handle a click event over a segment selector option
     * @param {Event} ev The event
     */
    function handleSegmentSelectorOptionClick(ev) {
        const segment = ev.currentTarget.dataset.segment;
        setContentSegmentValue(segment, mustAlwaysRedirect);
    }
    segmentSelectorOptions.forEach((node) => {
        node.addEventListener('click', handleSegmentSelectorOptionClick);
    });
}

module.exports = {
    /**
     * Initialize the cookie if it is neccessary
     */
    init: function () {
        setFirstActiveSection();
        setFirstActiveColComponent();

        var node = document.getElementById('set-content-segment-cookie');
        var contentSegmentToSet = node?.dataset?.contentSegment;
        if (!isEmpty(contentSegmentToSet)) {
            setContentSegmentCookie(contentSegmentToSet);
        }

        addSegmentSelectorListeners();
        updateDomForContentSegment(getContentSegmentCookie());
    },
    getContentSegmentValue: getContentSegmentCookie,
    setContentSegmentValue: setContentSegmentValue
};
