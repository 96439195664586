const getAnalyticsConfig = require('../../../../common/getAnalyticsConfig');
const { getListTypeFromListRootElement } = require('../../helpers/getListType');
const { getListContext } = require('../trackers/listContext/tracker');

var analyticsConfig = getAnalyticsConfig();

/**
 * Get the list prefix suitable for a product tile
 * @async
 * @param {Element} listRootElement The root element of a list of products
 * @returns {Promise<string>} The list prefix
 */
async function getListPrefixFromDOM(listRootElement) {
    let listPrefix;

    const listType = getListTypeFromListRootElement(listRootElement);
    const { types, prefixes } = analyticsConfig.list;

    const listPrefixByListType = {
        [types.saveForLater]: prefixes.saveForLater,
        [types.sliderCompleteTheLook]: prefixes.sliderCompleteTheLook,
        [types.sliderShopTheLook]: prefixes.sliderShopTheLook,
        // Search lists
        [types.searchByKeyword]: prefixes.search,
        [types.suggestions]: prefixes.search,
        // Similar products
        [types.recommendationGridSlotEinstein]: prefixes.similarProducts,
        [types.recommendationGridSlotProducts]: prefixes.similarProducts,
        [types.recommendationSliderSlotEinstein]: prefixes.similarProducts,
        [types.recommendationSliderSlotProducts]: prefixes.similarProducts,
        [types.sliderPageDesignerEinstein]: prefixes.similarProducts,
        [types.sliderPageDesignerProducts]: prefixes.similarProducts
    };

    const isPLP = (
        listType === types.searchByCategory
        || listType === types.landingPageOfCategory
    );
    if (isPLP) {
        /*
        In terms of list prefix, a common PLP of a category is equal to a
        category with a page-designered page.
        For instance, their products will use the `/catalog` prefix if the
        menu has been used to access to category
        */
        const listContext = await getListContext();
        listPrefix = listContext.listPrefix;
    } else {
        listPrefix = listPrefixByListType[listType];
    }

    return listPrefix;
}

module.exports = {
    getListPrefixFromDOM
};
