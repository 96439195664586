const recommendationsSlider = require('./recommendationsSlider');
const getPipelinesUrls = require('../common/getPipelinesUrls');
const { urlWithSearchParams } = require('../utilities/urlUtilities');
const getAnalyticsEventCenter = require('../common/getAnalyticsEventCenter');

const analyticsEventCenter = getAnalyticsEventCenter();
const PIPELINES_URLS = getPipelinesUrls();

const addedToCartModalClass = 'added-to-cart-modal';
const addedToCartModalShowClass = `${addedToCartModalClass}--show`;

let timeoutId = '';

/**
 * Fills the added to cart modal message element with the parameter message
 * @param {string} message - Message to add into the added to cart modal message element
 */
function fillMessage(message) {
    const messageElement = document.querySelector(
        `.${addedToCartModalClass}__message`
    );
    if (messageElement) {
        messageElement.innerText = message;
    }
}

/**
 * Fill the added to cart modal line item container with the parameter newLineItemHtml
 * @param {string} newLineItemHtml - html to add into the added to cart modal line item container
 */
function fillLineItemContainer(newLineItemHtml) {
    const lineItemContainerElement = document.querySelector(
        `.${addedToCartModalClass}__line-item-container`
    );
    if (lineItemContainerElement) {
        lineItemContainerElement.innerHTML = newLineItemHtml;
    }
}
/**
 * Fill the added to cart modal slider container
 * @param {string} productId - Added to cart Product ID
 * @returns {Promise.<Swiper|null>} new swiper instance if there are recomendations. Otherwise,
 * null
 */
function fillSliderContainer(productId) {
    const sliderContainerElement = document.querySelector(
        `.${addedToCartModalClass}__slider-container`
    );
    const cartRecommendationsElement = document.querySelector(
        `.${addedToCartModalClass}__cart-recommendations`
    );
    if (!sliderContainerElement
        || !cartRecommendationsElement
    ) {
        return Promise.resolve(null);
    }

    sliderContainerElement.innerHTML = '';
    cartRecommendationsElement.classList.add('d-none');
    return fetch(
        urlWithSearchParams(PIPELINES_URLS.getCompleteTheLook, {
            pid: productId
        })
    )
        .then((response) => response.text())
        .then((html) => {
            let swiperInstance;
            const swiperConfig = {
                spaceBetween: 8,
                scrollbar: true
            };
            const productHasCompleteTheLook = html.includes('complete-the-look recommendations');
            if (productHasCompleteTheLook) {
                sliderContainerElement.innerHTML = html;
                swiperInstance = recommendationsSlider.initSlider(
                    document.querySelector(`.${addedToCartModalClass} .complete-the-look.recommendations > div`),
                    swiperConfig
                );
            } else {
                swiperInstance = recommendationsSlider.initSlider(
                    cartRecommendationsElement.querySelector('.swiper-container'),
                    swiperConfig,
                    true
                );
                cartRecommendationsElement.classList.remove('d-none');
            }
            return swiperInstance;
        });
}

/**
 * Closes and empties the added to cart modal
 */
function closeModal() {
    const addedToCartModalElement = document.querySelector(`.${addedToCartModalClass}`);
    if (!addedToCartModalElement) {
        return;
    }

    analyticsEventCenter.publish(
        analyticsEventCenter.EVENTS.modalWillBeHidden,
        {
            modal: addedToCartModalElement,
            name: analyticsEventCenter.PAYLOADS.modalWillBeHidden.name.addedToCart,
            scrollableContainer: addedToCartModalElement
        }
    );

    addedToCartModalElement.classList.remove(addedToCartModalShowClass);

    analyticsEventCenter.publish(
        analyticsEventCenter.EVENTS.modalHidden,
        {
            name: analyticsEventCenter.PAYLOADS.modalHidden.name.addedToCart
        }
    );
}

/**
 * Clear the timeout interval if the user interaction is inside the modal
 * Close the modal if the user interaction is outside the modal
 * @param {string} event - Event data
 */
function handleUserInteractions(event) {
    const addedToCartModalElement = document.querySelector(`.${addedToCartModalClass}`);
    if (addedToCartModalElement.contains(event.target)) {
        clearInterval(timeoutId);
    } else {
        closeModal();
        document.body.removeEventListener('click', handleUserInteractions);
    }
}

/**
 * Opens and fills the added to cart modal
 * @param {string} productId - Added product id
 * @param {string} message - Text to show when added to cart
 * @param {string} newLineItemHtml - New Line Item HTML
 */
async function openModal(productId, message = '', newLineItemHtml = '') {
    const addedToCartModalElement = document.querySelector(`.${addedToCartModalClass}`);
    if (!addedToCartModalElement) {
        return;
    }

    fillMessage(message);
    fillLineItemContainer(newLineItemHtml);
    await fillSliderContainer(productId);

    addedToCartModalElement.classList.add(addedToCartModalShowClass);

    analyticsEventCenter.publish(
        analyticsEventCenter.EVENTS.modalShown,
        {
            modal: addedToCartModalElement,
            name: analyticsEventCenter.PAYLOADS.modalShown.name.addedToCart,
            scrollableContainer: addedToCartModalElement
        }
    );

    timeoutId = setTimeout(closeModal, 6000);
    document.body.addEventListener('click', handleUserInteractions);
}

module.exports = {
    openModal: openModal
};
