import 'core-js/stable';
import 'regenerator-runtime/runtime';
import 'element-closest-polyfill';

require('intersection-observer');

// Used in adnPrefrences.js
require('jquery-mousewheel');

var jQuery = require('jquery');
window.jQuery = jQuery;
window.$ = jQuery;

var processInclude = require('base/util');

import lazySizes from 'lazysizes';
window.lazySizes = lazySizes;

var Stickyfill = require('stickyfilljs');
window.Stickyfill = Stickyfill;

require('./common/monitors/scroll').init();
/**
 * Datalayer component
 * --------------------------------------
 *  - Depends on monitors
 *  - Initialize as soon as posible. It controls when its sources are ready,
 *    so do not wait for $(document).ready.
 */
require('./components/datalayer').init();

$(document).ready(function () {
    processInclude(require('./components/menu'));
    processInclude(require('base/components/cookie'));
    processInclude(require('./components/consentTracking'));
    processInclude(require('./components/miniCart'));
    processInclude(require('./components/collapsibleItem'));
    processInclude(require('./components/footer'));
    processInclude(require('./components/newsletter'));
    processInclude(require('./components/clientSideValidation'));
    processInclude(require('base/components/toolTip'));
    processInclude(require('./components/search'));
    processInclude(require('./components/countrySelector'));
    processInclude(require('./components/customSelects'));
    processInclude(require('./common/sitePolyfills'));
    processInclude(require('./components/spinner'));
    processInclude(require('./components/goBackLink'));
    processInclude(require('./utilities/cookie'));
    processInclude(require('./product/stockAlert'));
    processInclude(require('./components/dynamicBanner'));
    processInclude(require('./components/productTileVideo'));
    processInclude(require('./components/ellipsedText'));
    require('./components/contentSegmentCookie').init();
    require('./components/lineTracking/ltAddToCart').init();
    require('./components/formControl').init();
    require('./utilities/keyboardFocusHelper').init();
    require('./components/sizeChart');
    require('./components/fakeSelect').init();
    require('./components/transparentHeader').init();
    require('./components/discountMessage').init();
});

require('./components/buttonCopyToClipboard').init();
require('./common/lazyLoad');

require('base/thirdParty/bootstrap');
