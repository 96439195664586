const BASE_CONFIG = window.BASE_CONFIG;

const dynamicBannerModalSelector = '.dynamic-banner-modal';
const dynamicBannerModalShowClass = 'dynamic-banner-modal--show';

/** Check if the dynamicBannerClosed cookie is set
 * @returns {boolean} true if the dynamicBannerClosed cookie is set, false otherwise
 */
function isDynamicBannerCookieSet() {
    var cookieHelper = require('../utilities/cookie');
    return cookieHelper.getCookieValue('dynamicBannerClosed') !== '';
}

/** Return true if the current page is an exclusion defined in the data-visibility-exclusions attribute
 * @returns {boolean} True if the currentPage is excluded, false otherwise
 */
function pageHasExcludedVisibility() {
    const visibilityExclusions = BASE_CONFIG.dynamicBanner.dynamicBannerVisibilityExclusions
        || [];
    if (visibilityExclusions.length > 0) {
        const pageUtilities = require('../utilities/pageUtilities');
        const isHomePageExcluded = visibilityExclusions.includes('home');
        const isPLPExcluded = visibilityExclusions.includes('plp');
        const isPDPExcluded = visibilityExclusions.includes('pdp');
        const isCartExcluded = visibilityExclusions.includes('cart');
        const isCheckoutExcluded = visibilityExclusions.includes('checkout');

        if (isHomePageExcluded
            && pageUtilities.isHomePage()) {
            return true;
        }

        if (isPLPExcluded
            && pageUtilities.isSearchPage()) {
            return true;
        }

        if (isPDPExcluded
            && pageUtilities.isProductDetailPage()) {
            return true;
        }

        if (isCartExcluded
            && pageUtilities.isCartPage()) {
            return true;
        }

        if (isCheckoutExcluded
            && pageUtilities.isCheckoutPage()
        ) {
            return true;
        }
    }
    return false;
}

/** Show modal after data-showing-time miliseconds
 * @param  {HTMLElement} dynamicBannerElement - Dynamic Banner HTMLElement
 */
function showBannerModal(dynamicBannerElement) {
    const isVisibilityForced = BASE_CONFIG.dynamicBanner.dynamicBannerForceVisibility;
    const isCurrentPageExcluded = pageHasExcludedVisibility();
    if (!isCurrentPageExcluded
        && (isVisibilityForced
        || !isDynamicBannerCookieSet())
    ) {
        const showingTime = BASE_CONFIG.dynamicBanner.dynamicBannerShowingTime || 0;
        setTimeout(() => {
            dynamicBannerElement.classList.add(dynamicBannerModalShowClass);
        }, showingTime);
    }
}

/** Set the dynamicBannerClosed cookie with the data-expiring-time of dynamicBannerElement expiring time
 */
function setDynamicBannerCookie() {
    var cookieHelper = require('../utilities/cookie');
    const expiringTime = BASE_CONFIG.dynamicBanner.dynamicBannerExpiringTime || 0;
    if (expiringTime > 0) {
        var date = new Date();
        date.setTime(date.getTime() + expiringTime);
        cookieHelper.setCookie('dynamicBannerClosed', 'true', date.toUTCString());
    } else {
        cookieHelper.setCookie('dynamicBannerClosed', 'true');
    }
}

/** Hide modal
 * @param  {HTMLElement} dynamicBannerElement - Dynamic Banner HTMLElement
 * @param {string} redirectURL - Redirect URL
 */
function hideBannerModal(dynamicBannerElement, redirectURL) {
    dynamicBannerElement.classList.remove(dynamicBannerModalShowClass);
    setDynamicBannerCookie();

    if (redirectURL) {
        window.location = redirectURL;
    }
}

/** Hide modal after data-hiding-time miliseconds if data-autohide is true
 * @param  {HTMLElement} dynamicBannerElement - Dynamic Banner HTMLElement
 */
function autohideBannerModal(dynamicBannerElement) {
    const isAutohideEnabled = BASE_CONFIG.dynamicBanner.dynamicBannerAutohide;
    const hidingTime = BASE_CONFIG.dynamicBanner.dynamicBannerHidingTime || 0;
    if (isAutohideEnabled && hidingTime > 0) {
        setTimeout(() => {
            hideBannerModal(dynamicBannerElement);
        }, hidingTime);
    }
}

/** Hide modal when clicking on close icon
 * @param  {HTMLElement} dynamicBannerElement - Dynamic Banner HTMLElement
 */
function attachCloseButtonClick(dynamicBannerElement) {
    const javascriptUtils = require('../utilities/javascriptUtils');
    javascriptUtils.delegateEvent(
        'click',
        `${dynamicBannerModalSelector} .close, ${dynamicBannerModalSelector} .dynamic-banner-modal-link-close`,
        (event) => {
            const element = event.target;
            let redirectURL = '';
            if (element instanceof HTMLAnchorElement) {
                event.preventDefault();
                redirectURL = element.getAttribute('href');
            }
            hideBannerModal(dynamicBannerElement, redirectURL);
        }
    );
}

/** Change block visibility on certain events
 * 2 events: click at bannerBlock1 and form submit at bannerBlock2
 * @param  {HTMLElement} dynamicBannerElement - Dynamic Banner HTMLElement
 */
function attachBlockVisibilityEvents(dynamicBannerElement) {
    const dynamicBannerModalContentElement = dynamicBannerElement.querySelector('.dynamic-banner-modal__content');

    if (dynamicBannerModalContentElement) {
        const javascriptUtils = require('../utilities/javascriptUtils');

        const showBlockOneClass = 'dynamic-banner-modal__content--show-one';
        const showBlockTwoClass = 'dynamic-banner-modal__content--show-two';
        const showBlockThreeClass = 'dynamic-banner-modal__content--show-three';

        // Show blockTwo on blockOneButton click
        const blockOneButtonSelector = `${dynamicBannerModalSelector} .dynamic-banner-modal__block--one .dynamic-banner-modal__button`;
        javascriptUtils.delegateEvent('click', blockOneButtonSelector, () => {
            dynamicBannerModalContentElement.classList.remove(showBlockThreeClass);
            dynamicBannerModalContentElement.classList.remove(showBlockOneClass);
            dynamicBannerModalContentElement.classList.add(showBlockTwoClass);
            // eslint-disable-next-line no-param-reassign
            dynamicBannerElement.dataset.stage = 2;
        });

        // Show blockThree on blockTwoForm success
        const blockTwoNewsletterFormSelector = `${dynamicBannerModalSelector} .dynamic-banner-modal__block--two .newsletter-form`;
        document.querySelector(blockTwoNewsletterFormSelector)?.addEventListener('newsletter-suscription-successful', function () {
            dynamicBannerModalContentElement.classList.remove(showBlockOneClass);
            dynamicBannerModalContentElement.classList.remove(showBlockTwoClass);
            dynamicBannerModalContentElement.classList.add(showBlockThreeClass);
            // eslint-disable-next-line no-param-reassign
            dynamicBannerElement.dataset.stage = 3;

            const hidingTime = BASE_CONFIG.dynamicBanner.dynamicBannerHidingAfterSuccessTime;
            if (hidingTime !== null && typeof hidingTime === 'number') {
                setTimeout(() => {
                    hideBannerModal(dynamicBannerElement);
                }, hidingTime);
            }
        });
    }
}

/** Change newsletter conditions check visibility
 * For the version 2, check will remain hidden until the newsletter input is focused
 * @param  {HTMLElement} dynamicBannerElement - Dynamic Banner HTMLElement
 */
function attachNewsletterConditionsVisibilityEvent(dynamicBannerElement) {
    if (dynamicBannerElement.classList.contains('dynamic-banner-modal--version2')) {
        const newsletterInput = dynamicBannerElement.querySelector('#newsletter-input-popup');
        const newsletterConditionsCheckBoxId = 'newsletter-checkbox-popup';
        const newsletterConditionsCheckBox = dynamicBannerElement.querySelector(`#${newsletterConditionsCheckBoxId}`);
        const newsletterConditionsCheckLabel = dynamicBannerElement.querySelector(`label[for="${newsletterConditionsCheckBoxId}"]`);
        if (newsletterInput && newsletterConditionsCheckBox && newsletterConditionsCheckLabel) {
            newsletterConditionsCheckBox.classList.add('d-none');
            newsletterConditionsCheckLabel.classList.add('d-none');

            newsletterInput.addEventListener('focus', function () {
                newsletterConditionsCheckBox.classList.remove('d-none');
                newsletterConditionsCheckLabel.classList.remove('d-none');
            });
        }
    }
}

/** Add dynamicBannerModal visibility events & timers
 * @param  {HTMLElement} dynamicBannerElement - Dynamic Banner HTMLElement
 */
function initVisibilityEvents(dynamicBannerElement) {
    showBannerModal(dynamicBannerElement);
    autohideBannerModal(dynamicBannerElement);
    attachCloseButtonClick(dynamicBannerElement);
    attachBlockVisibilityEvents(dynamicBannerElement);
    attachNewsletterConditionsVisibilityEvent(dynamicBannerElement);
}

module.exports = function () {
    const dynamicBannerElement = document.querySelector(dynamicBannerModalSelector);
    if (BASE_CONFIG.dynamicBanner.isDynamicBannerEnabled
        && dynamicBannerElement
    ) {
        initVisibilityEvents(dynamicBannerElement);
    }
};
