const cookieHelper = require('../../utilities/cookie');
const nativefy = require('../../utilities/dom/nativefy');
const { isEmpty, isObject, isString } = require('../../utilities/type');
const datalayerCheckout = require('../datalayerCheckout');
const { internalEventCenter } = require('./events');
const fetchCurrentCurrencyCode = require('./helpers/fetchCurrentCurrencyCode');
const fetchProductFieldObject = require('./helpers/fetchProductFieldObject');
const sendHelpers = require('./helpers/sendHelpers');
const { createProductFieldObject } = require('./models/productFieldObject');
const { getProductFieldObjectFromDOM } = require('./helpers/impressions');
const promisify = require('../../utilities/promisify');
const urlUtilities = require('../../utilities/urlUtilities');
const { getProductIdFromDOM } = require('../../utilities/pdpHelpers');

/**
 * Handle a product click event over a product impression or an element with a
 * product ID.
 * @param {Element} target The element clicked
 * @param {ProductFieldObject|string} product The productFieldObject or the
 * product ID related to the click
 */
function handleProductClick(target, product) {
    let productPromise;

    // Wrap every posible initial data in a promise
    if (isObject(product)) {
        productPromise = promisify(product);
    } else if (isString(product)) {
        productPromise = fetchProductFieldObject(product);
    } else if (target.hasAttribute('data-pid')) {
        productPromise = fetchProductFieldObject(
            target.getAttribute('data-pid')
        );
    } else {
        productPromise = Promise.resolve(null);
    }

    // Fetch the info from server as fallback
    productPromise = productPromise.then(
        productFieldObject => productFieldObject === null
            ? fetchProductFieldObject(target.getAttribute('data-pid'))
            : productFieldObject
    );

    const link = target.href;

    Promise.all([
        productPromise,
        fetchCurrentCurrencyCode()
    ])
        .then(([
            /** @type {ProductFieldObject} */
            productFieldObject,
            currencyCode
        ]) => {
            internalEventCenter.publish(
                internalEventCenter.EVENTS.productClicked,
                {
                    currencyCode,
                    productFieldObject,
                    redirectUrl: link,
                    target
                }
            );
        })
        .catch(() => {
            if (link) window.location = link;
        });
}

/**
 * Track the events related to ADN
 */
function trackAdnEvents() {
    $('.adn-eco-link').on('click', function () {
        sendHelpers.sendAdnEcoEvent();
    });

    $('.adn-club-link-pt').on('click', function () {
        sendHelpers.sendAdnClubEventMenu();
    });

    $('[data-category*="888"]').on('click', function () {
        const categoryString = $(this).data('category-list');
        const split = categoryString.split('/');
        let categoryList = split[1];
        sendHelpers.sendAdnClubEventDropdown(categoryList);
    });
}

/**
 * Track the events related to cart
 */
function trackCartEvents() {
    internalEventCenter.subscribe(
        internalEventCenter.EVENTS.productAddedToCart,
        ({ productFieldObject, currencyCode }) => {
            sendHelpers.sendCartAction(
                'add',
                createProductFieldObject(productFieldObject),
                currencyCode
            );
        }
    );

    internalEventCenter.subscribe(
        internalEventCenter.EVENTS.productRemovedFromCart,
        ({ productFieldObject, currencyCode }) => {
            sendHelpers.sendCartAction(
                'remove',
                createProductFieldObject(productFieldObject),
                currencyCode
            );
        }
    );

    internalEventCenter.subscribe(
        internalEventCenter.COMMANDS.sendEventFirstStepOfCheckout,
        ({ currencyCode, eventDataLabel, productFieldObjects }) => {
            datalayerCheckout.updateDatalayerCheckoutInfo({
                currencyCode,
                eventDataLabel,
                productFieldObjects
            });
            internalEventCenter.publish(
                internalEventCenter.EVENTS.checkoutStageChanged,
                {
                    stageName: 'cart'
                }
            );
        }
    );
}

/**
 * Track the events of checkout
 */
function trackCheckoutEvents() {
    internalEventCenter.subscribe(
        internalEventCenter.EVENTS.checkoutStageChanged,
        function ({ stageName, redirectUrl }) {
            sendHelpers.sendCheckoutStep(stageName, redirectUrl);
        }
    );

    internalEventCenter.subscribe(
        internalEventCenter.COMMANDS.sendEventPurchase,
        sendHelpers.sendPurchase
    );
}

/**
 * Track the clicks over product impression
 */
function trackClicksOnImpressedProduct() {
    const clickable = '.js-dl-product-click';
    const notProductCard = ':not(.line-item-name)';
    $('body').on('click', clickable + notProductCard, function (evt) {
        evt.preventDefault();
        const target = nativefy($(this));
        handleProductClick(target, getProductFieldObjectFromDOM(target));
    });

    internalEventCenter.subscribe(
        [
            internalEventCenter.EVENTS.productClicked
        ],
        function ({
            currencyCode, productFieldObject, redirectUrl, target
        }) {
            if (!isEmpty(productFieldObject) && window.google_tag_manager) {
                sendHelpers.sendProductClick(
                    productFieldObject,
                    currencyCode,
                    redirectUrl,
                    target
                );
            } else if (redirectUrl) {
                window.location = redirectUrl;
            }
        }
    );
}

/**
 * Track the clicks over elements related to a product by its ID
 */
function trackClicksOnProductIdRelatedElements() {
    const clickable = '.js-dl-product-click';
    const productCard = '.line-item-name';
    $('body').on('click', clickable + productCard, function (evt) {
        evt.preventDefault();
        const target = nativefy($(this));
        handleProductClick(target);
    });

    $('.wishlistTile').click(function (evt) {
        evt.preventDefault();
        const target = nativefy($(this));
        const parentProduct = target.closest('.product');
        if (parentProduct) {
            const pid = parentProduct.getAttribute('data-pid');
            handleProductClick(target, pid);
        }
    });

    $('.product-colors__selector').on('click', function () {
        // Selectores de color en PDP desktop
        // El click provoca un cambio asincrono de la PDP a los datos del variant, no una redireccion
        if (!$(this).children('.color-value').hasClass('selected')) {
            const currentPid = getProductIdFromDOM();
            const target = nativefy($(this));
            const queryString = urlUtilities.getQueryStringFromUrl(
                target.getAttribute('data-url')
            );
            const destinationPid = queryString
                ? urlUtilities.getUrlParameter('pid', queryString)
                : null;

            if (destinationPid) {
                Promise.all([
                    fetchProductFieldObject(destinationPid),
                    fetchCurrentCurrencyCode()
                ])
                    .then(([
                        /** @type {ProductFieldObject} */
                        productFieldObject,
                        currencyCode
                    ]) => {
                        internalEventCenter.publish(
                            internalEventCenter.EVENTS.productColorClicked,
                            {
                                currencyCode,
                                previousProductId: currentPid,
                                productFieldObject
                            }
                        );
                    });
            }
        }
    });
}

/**
 * Track the events related to content segmentation
 */
function trackContentSegment() {
    internalEventCenter.subscribe(
        internalEventCenter.EVENTS.segmentSet,
        sendHelpers.sendSegmentModal
    );
}

/**
 * Track Cookiebot related events
 */
function trackCookiebot() {
    const handleCookiebot = () => {
        if (!cookieHelper.cookieExists('analyticsCookieConsent')) {
            sendHelpers.sendCookieConsent();
        }
    };

    window.addEventListener('CookiebotOnAccept', handleCookiebot);
    window.addEventListener('CookiebotOnDecline', handleCookiebot);
}

module.exports = {
    init: function () {
        trackCookiebot();
        trackAdnEvents();
        trackCartEvents();
        trackCheckoutEvents();
        trackClicksOnImpressedProduct();
        trackClicksOnProductIdRelatedElements();
        trackContentSegment();
    }
};
