const impressionFieldObject = require('./impressionFieldObject');

/**
 * Extended data of the model
 * @typedef {Object} ProductFieldObjectExtension
 * @property {number} quantity
 */

/**
 * ProductFieldObject model
 * ------------------------
 * Info about a product that is needed for an event that involves products
 * (click, purchase...)
 *
 * Differences with ImpressionFieldObject
 * --------------------------------------
 * The differences are few. For instance, the impression does not need it the
 * quantity. So, nowadays the main difference is the concept and the purpose of
 * each model.
 *
 * @link https://developers.google.com/analytics/devguides/collection/ua/gtm/enhanced-ecommerce?hl=es#product-clicks
 *
 * @typedef {impressionFieldObject.ImpressionFieldObject & ProductFieldObjectExtension} ProductFieldObject
 */

const ProductFieldObjectProto = Object.create(impressionFieldObject.ImpressionFieldObjectProto);

ProductFieldObjectProto.toDatalayer = function () {
    const result = impressionFieldObject.ImpressionFieldObjectProto.toDatalayer.call(this);
    result.quantity = this.quantity;
    return result;
};

/**
 * Create a product field object
 *
 * @param {Object} dto - DTO of an ProductFieldObject
 * @param {string} dto.id - ID of the master (no color, no size)
 * @param {string} dto.name - The name of the product
 * @param {string} dto.brand - 'hombre' | 'mujer'
 * @param {string} dto.price - Price of the product
 * @param {string} dto.quantity - How many products
 * @param {string} dto.category - Category of the product
 * @param {string} dto.variant - Color of the product
 * @param {EcommerceListObject|string} dto.list - List of the product
 * @param {number} dto.position - The position of the product in the list
 * @param {string} dto.dimension1 - Size of the product
 * @param {string} dto.dimension7 - is the product in discount ('true' or 'false')
 * @param {string} dto.content_ids - Variation Group ID for Facebook's pixel or
 * variant ID
 * @property {Object} dto.addons - Addons required for workflow of the app
 * @property {string} dto.addons.adDatalayerProductId - The adDatalayerProductId
 * which is usually the Variation Group ID
 * @returns {ProductFieldObject} new instance
 */
function createProductFieldObject(dto) {
    const instance = impressionFieldObject.createImpressionFieldObject(dto);
    Object.setPrototypeOf(instance, ProductFieldObjectProto);

    instance.quantity = dto.quantity;

    return instance;
}

module.exports = {
    createProductFieldObject
};
