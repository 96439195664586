const uid = require('../../../../utilities/uid/non-secure');
const getAnalyticsConfig = require('../../../../common/getAnalyticsConfig');
const {
    createEcommerceListObject
} = require('../../models/ecommerceListObject');
const { getListContext } = require('../trackers/listContext/tracker');
const {
    getCurrentCategoryListIdFromDOM
} = require('./listChunkCategoryListId');
const { getListPrefixFromDOM } = require('./listChunkListPrefix');

const analyticsConfig = getAnalyticsConfig();

/**
 * Get the Ecommerce list object UID used to storage or generate a new one
 * @async
 * @param {Element} listRootElement The root element of a list of products
 * @returns {string} The list uid
 */
function getListUidFromDOM(listRootElement) {
    var listUid = listRootElement.getAttribute(analyticsConfig.dataAttributes.listUid);
    if (!listUid) {
        listUid = uid();
        listRootElement.setAttribute(analyticsConfig.dataAttributes.listUid, listUid);
    }
    return listUid;
}

/**
 * Returns true if the passed element is inside a minicart component or in the
 * modal which appears after adding a product to cart.
 * @param {Element} element - item
 * @returns {boolean} - The result
 */
function isInsideMinicart(element) {
    return (
        element?.closest('.minicart-recommendations')
        || element?.closest('.added-to-cart-modal')
    );
}

/**
 * Get the Ecommerce list object for a rendered product
 * @async
 * @param {Element} listRootElement The root element of a list of products
 * @returns {Promise<EcommerceListObject>} The list
 */
async function getListFromDOM(listRootElement) {
    let listUid = getListUidFromDOM(listRootElement);

    let listContext = await getListContext();
    // New chunks of the list model (EcommerceListObject)
    let listPrefix = await getListPrefixFromDOM(listRootElement);
    let referrerPage = listContext.referrerPage;
    let referrerComponent = listContext.referrerComponent;
    let categoryListId = listContext.categoryListId || getCurrentCategoryListIdFromDOM();
    let referrerProductId = listContext.referrerProductId;

    if (isInsideMinicart(listRootElement)) {
        /*
        Special case: Not a clicked referrer component but works similar.
        Page must be ommited
        @example /productos_similares => /minicesta/productos_similares
        */
        referrerPage = undefined;
        referrerComponent = analyticsConfig.list.referrerComponents.minicart;
    } else if (listPrefix === analyticsConfig.list.prefixes.search) {
        /*
        Search list will always be the prefix only, no context info
        @example /buscador
        */
        referrerComponent = undefined;
        referrerPage = undefined;
        referrerProductId = undefined;
        categoryListId = undefined;
    } else if (referrerProductId) {
        if (
            listPrefix === analyticsConfig.list.prefixes.similarProducts
            || listPrefix === analyticsConfig.list.prefixes.sliderCompleteTheLook
        ) {
            /*
            More products at the end of PDP
            @example /productos_similares/25220239042
            @example /productos_completa_look/25220239042
            */
            referrerComponent = undefined;
            referrerPage = undefined;
            categoryListId = undefined;
        }
    }

    return createEcommerceListObject({
        referrerPage,
        referrerComponent,
        listPrefix,
        categoryListId,
        referrerProductId,
        uid: listUid
    });
}

module.exports = {
    getListFromDOM,
    getListUidFromDOM
};
