const { internalEventCenter } = require('../events');

/**
 * Send an event to data layer
 * @param {DataLayerEvent} event - Event to send
 * @returns {DataLayerEvent} - success
 */
function sendEvent(event) {
    if (!event) return undefined;
    window.dataLayer = window.dataLayer || [];
    window.dataLayer.push(event);
    internalEventCenter.publish(
        internalEventCenter.EVENTS.datalayerEventSent,
        event
    );
    return event;
}

module.exports = sendEvent;
