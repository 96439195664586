var tileSlider = require('./productTileSlider');
const createSwiper = require('../components/swiperFactory');
const getAnalyticsEventCenter = require('../common/getAnalyticsEventCenter');

const analyticsEventCenter = getAnalyticsEventCenter();
const SPACE_BETWEEN_DEFAULT = 16;
const INITIALIZING_SWIPER_CLASS = 'swiper-container--initializing'; // Custom class
const INITIALIZED_SWIPER_CLASS = 'swiper-container-initialized'; // Class created by Swiper

/**
 * @typedef {Object} sliderConfig slider config
 * @property {number} spaceBetween space between slides
 * @property {boolean} scrollbar scrollbar
 */

/**
 * Initialize swiper slider.
 * @param {Element} target - swiper slider target element
 * @param {sliderConfig} [sliderConfig] slider config
 * @returns {Promise.<Object>} - new Swiper instance
 */
async function swiperInit(target, sliderConfig) {
    target.classList.add(INITIALIZING_SWIPER_CLASS);
    return createSwiper(target, {
        direction: 'horizontal',
        slidesPerView: 'auto',
        slidesPerColumn: 1,
        spaceBetween: sliderConfig && sliderConfig.spaceBetween ? sliderConfig.spaceBetween : SPACE_BETWEEN_DEFAULT,
        grabCursor: true,
        mousewheel: false,
        speed: 350,
        loop: false,
        updateOnWindowResize: true,
        watchOverflow: true,
        scrollbar: sliderConfig && sliderConfig.scrollbar ? {
            el: '.recommendations__swiper-scrollbar',
            hide: false
        } : false,
        on: {
            init: function () {
                target.classList.remove(INITIALIZING_SWIPER_CLASS);
            },
            slideChange: function () {
                analyticsEventCenter.publish(
                    analyticsEventCenter.EVENTS.sliderActiveSlideChanged,
                    this
                );
            }
        }
    });
}

/**
 * Check if sliderContainer is initialized
 * @param {Element} sliderContainer - Slider Container Element to check if initialized
 * @returns {boolean} True if initialized, false otherwise
 */
function isSwiperSliderInitialized(sliderContainer) {
    return sliderContainer
        && (sliderContainer.classList.contains(INITIALIZING_SWIPER_CLASS)
            || sliderContainer.classList.contains(INITIALIZED_SWIPER_CLASS));
}

/**
 * Creates a Mutation Observer to watch for recommendation swipers
 * so they can be initialized once they have slide-items.
 * It also initializes tile media swipers
 * @returns {MutationObserver} - observer
 */
function createMutationObserver() {
    const observer = new MutationObserver(async function (mutations) {
        await Promise.all(mutations.map(async (mutation) => {
            const mutationTarget = mutation.target;
            const loadedImage = mutationTarget.querySelector('.recommendations__slide-item img');
            const swiperContainer = mutationTarget.querySelector('.swiper-container');

            if (mutation.type === 'childList'
                && loadedImage
                && swiperContainer
                && !isSwiperSliderInitialized(swiperContainer)
            ) {
                const recommendationSwiperContainer = mutationTarget.querySelector(
                    '.recommendations__swiper-container'
                );
                await swiperInit(recommendationSwiperContainer);
                tileSlider.initNonGridTiles($(mutationTarget));
            }
        }));
    });

    return observer;
}

/**
 * Inits existing recommendation sliders
 * It also inits productTile media sliders
 * @param {sliderConfig} [sliderConfig] slider config
 */
async function initExistingSliders(sliderConfig) {
    const automaticInitSliders = document.querySelectorAll(
        '.recommendations__swiper-container[data-automatically-init="true"]'
    );
    if (automaticInitSliders.length === 0) {
        return;
    }

    await Promise.all(
        Array.from(automaticInitSliders).map(
            async (sliderContainer) => {
                if (!isSwiperSliderInitialized(sliderContainer)) {
                    await swiperInit(sliderContainer, sliderConfig);
                    tileSlider.initNonGridTiles($(sliderContainer));
                }
            }
        )
    );
}

/**
 * Inits existing recommendation sliders, and if sliderContainer given,
 * starts oberserving for mutations (recommendations given by Einstein)
 * @param {Element} sliderContainer - Recommendation slider to initialize.
 * @param {sliderConfig} [sliderConfig] slider config
 */
function initSliders(sliderContainer, sliderConfig) {
    initExistingSliders(sliderConfig);
    if (sliderContainer) {
        const observer = createMutationObserver();
        observer.observe(sliderContainer, { childList: true });
    }
}

/**
 * Inits existing recommendation sliders, and if sliderContainer given,
 * starts oberserving for mutations (recommendations given by Einstein)
 * @param {Element} sliderContainer - Recommendation slider to initialize.
 * @param {sliderConfig} [sliderConfig] slider config
 * @param {boolean} [forceInit] - True to force the slider initialization
 * @returns {Swiper|undefined} new swiper instance
 */
async function initSlider(sliderContainer, sliderConfig, forceInit) {
    let instance;
    if (sliderContainer
        && (forceInit || !isSwiperSliderInitialized(sliderContainer))
    ) {
        instance = await swiperInit(sliderContainer, sliderConfig);
        tileSlider.initNonGridTiles($(sliderContainer));
    }
    return instance;
}

module.exports = {
    initSliders: initSliders,
    initSlider: initSlider
};
