const getAnalyticsEventCenter = require('../../common/getAnalyticsEventCenter');

const analyticsEventCenter = getAnalyticsEventCenter();

module.exports = {
    init: function () {
        analyticsEventCenter.subscribe(
            analyticsEventCenter.EVENTS.productAddedToCart,
            function () {
                if (typeof _lt === 'function') {
                    _lt('send', 'cv', {
                        type: 'AddToCart'
                    }, ['21fccf32-f0f2-4dfc-80d1-02c8f4293135']);
                }
            }
        );
    }
};
