var cookieHelper = require('../utilities/cookie');
var debounce = require('lodash/debounce');
var login = require('../login/login');
const javascriptUtils = require('../utilities/javascriptUtils');

$('input[name=newsletter-checkbox]').on('click', function () {
    $(this).removeClass('custom-control-required-error');
    $(this).siblings('.required-checkbox-text').css('display', 'none');
});

/**
 * Create newsletter cookie
 */
function createNewsletterCookie() {
    var date = new Date();
    // Set seven days cookie
    date.setTime(date.getTime() + (7 * 24 * 60 * 60 * 1000));
    document.cookie = 'newsletterSubscription=1; expires=' + date.toUTCString() + ';path=/';
}

/**
 * Show newsletter modal if the cookie does not exist
 */
function showNewsletterModal() {
    if ($('#newsletterModal').length && !cookieHelper.cookieExists('newsletterSubscription')) {
        $('#newsletterModal').modal('show');
    }
}

/**
 * appends params to a url
 * @param {Object} data - data returned from the server's ajax call
 * @param {Object} button - button that was clicked for email sign-up
 * @param {Object} form - the current form
 */
function displayMessage(data, button, form) {
    $.spinner().stop();
    if (data.success) {
        form.find('.newsletter-radiobuttons .custom-control-input:checked').prop('checked', false);
        form.find('.newsletter-input').val('');
        form.find('.show-on-change').css('display', 'none');
        form.find('.required-checkbox-text').css('display', 'none');
        $('.newsletter-checkbox')[0].checked = false;

        $('#newsletterModal').modal('hide');

        if ($('.email-signup-message').length === 0) {
            $('body').append(
                '<div class="email-signup-message"></div>'
            );
        }

        $('.email-signup-message')
            .append('<div class="email-signup-alert text-center alert-success">' + data.msg + '</div>');

        setTimeout(function () {
            $('.email-signup-message').remove();
            button.removeAttr('disabled');
        }, 3000);
    } else {
        form.find('.newsletter-input').addClass('is-invalid');
        form.find('.invalid-feedback-input').text(data.msg);
    }
}

/**
 * @on4u same function as in helpers/emailHelpers.js
 *
 *  Checks if the newsletter email value entered is correct format
 * @param {string} email - user inserted data on email input
 * @return {boolean} - Whether email is valid
 */
function validateEmail(email) {
    var regex = /^[\w.%+-]+@[\w.-]+\.[\w]{2,6}$/;
    return regex.test(email);
}

/**
 * Validate newsletter form
 */
function newsletterValidations() {
    var newsletterForm = $('form.newsletter-form');
    if (newsletterForm.length > 0) {
        var submitBtn = newsletterForm.find('[type="submit"]');

        newsletterForm.find('.email-input').bind('blur change keyup', debounce(function () {
            submitBtn.prop('disabled', !validateEmail($(this).val()));
        }, 500));
    }
}

module.exports = function () {
    showNewsletterModal();
    newsletterValidations();

    $('.newsletter-input').on('keyup', debounce(function (event) {
        if (event.currentTarget.value !== '') {
            $(this).closest('.newsletter-form').find('.show-on-change').css('display', 'flex');
        } else {
            $('.show-on-change').css('display', 'none');
            $('.required-checkbox-text').css('display', 'none');
            $(this).removeClass('is-invalid');
        }
    }, 300));

    $('#newsletterModal').on('hidden.bs.modal', function () {
        createNewsletterCookie();
    });

    $('.newsletter-country-selector').ready(function () {
        $('.newsletter-country option').each(function () {
            if (this.hasAttribute('country') && this.getAttribute('country') !== 'eu') {
                this.style = 'display:none;';
            }
        });

        if (cookieHelper.getCookieValue('countryCode') === 'eu') {
            $('.newsletter-country').prop('selectedIndex', -1);
        }

        if (cookieHelper.cookieExists('adLocale')) {
            $('.newsletter-country-selector').css('display', 'none');
        }
    });

    $('.newsletter-form').on('submit', function (e) {
        e.preventDefault();
        var form = $(this);

        const isDynamicBannerForm = form.closest('.dynamic-banner-modal').length > 0;
        const showNewsletterFullPageMessages = !isDynamicBannerForm;

        var url = form.find('.newsletter-subscribe-button').data('href');
        var button = form.find('.newsletter-subscribe-button');
        var emailId = form.closest('.newsletter-form').find('input[name=hpEmailSignUp]').val();
        var countryCode = '';
        const isAdLocaleValueValid = cookieHelper.cookieExists('adLocale')
            && typeof cookieHelper.getCookieValue('adLocale').split(',')[1] !== 'undefined';
        if (isAdLocaleValueValid) {
            countryCode = cookieHelper.getCookieValue('adLocale')
                .split(',')[0]
                .replace('"', '');
        } else if ($('.newsletter-country').length) {
            countryCode = form.find('option:selected', '.newsletter-country').attr('value');
        }

        if (form.find('input[name=newsletter-checkbox]:checked').length === 0) {
            form.find('input[name=newsletter-checkbox]').addClass('custom-control-required-error');
            form.find('.required-checkbox-text').css('display', 'block');
            return;
        }

        if (form.find('.newsletter-country').prop('selectedIndex') === -1) {
            form.find('.required-country-text').css('display', 'block');
            return;
        }

        if (showNewsletterFullPageMessages) {
            $.spinner().start();
        }

        form.attr('disabled', true);
        $.ajax({
            url: url,
            type: 'post',
            dataType: 'json',
            data: {
                emailId: emailId,
                countryCode: countryCode
            },
            success: function (data) {
                window.dataLayer = window.dataLayer || [];

                window.dataLayer.push({
                    event: 'NoECC',
                    eventCategory: 'Suscripcion Newsletter Paso 2'
                });

                if (showNewsletterFullPageMessages) {
                    if (data.existingProfiles) {
                        login.methods.displayExistingProfilesButtons(data.existingProfiles, 'email');
                        $('#duplicatedUserModal').modal('show');
                    }
                    displayMessage(data, button, form);
                }

                if (data.success) {
                    createNewsletterCookie();
                }

                javascriptUtils.triggerCustomEvent(form[0], 'newsletter-suscription-successful');
            },
            error: function (err) {
                displayMessage(err, button, form);
            }
        });
    });
};
