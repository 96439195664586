const { pipeAsync } = require('./fp');

const queueInputTypes = {
    /**
     * The functions process the same input data
     */
    COMMON_INPUT: 'common_input',
    /**
     * Each function in queue takes the output of the previous function as
     * input data
     */
    SEQUENTIAL_INPUT: 'sequential_input'
};

/**
 * Create a queue of functions
 *
 * @param {Object} [options] Queue options
 * @param {string} [options.inputType] How queue process the data passed to
 * start()
 * @returns {Object} The queue
 */
function createPromisesQueue({
    inputType = queueInputTypes.SEQUENTIAL_INPUT
} = {}) {
    const functionsQueue = [];

    /**
     * Add a function to the end of the queue
     * @param {Function} fn The function
     */
    function add(fn) {
        functionsQueue.push(fn);
    }

    /**
     * Remove a function from the queue
     * @param {Function} fn The function to remove
     */
    function remove(fn) {
        functionsQueue = functionsQueue.filter(item => item !== fn);
    }

    /**
     * Run the piped execution of the queued functions
     * @param {*} inputData The starting data
     * @returns {Promise<*>} The result of the last function
     */
    function start(inputData) {
        if (functionsQueue.length === 0) {
            return Promise.resolve();
        }

        let q = functionsQueue;
        if (inputType === queueInputTypes.COMMON_INPUT) {
            // Pass the input data to each function of the queue
            q = q.map(fn => () => fn(inputData));
        }

        return pipeAsync(...q)();
    }

    return {
        add,
        remove,
        start
    };
}

module.exports = {
    createPromisesQueue,
    queueInputTypes
};
