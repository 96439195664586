const panzoom = require('panzoom');

const BASE_CONFIG = window.BASE_CONFIG;
const pageUtilities = require('../utilities/pageUtilities');
const javascriptUtils = require('../utilities/javascriptUtils');
const nativefy = require('./../utilities/dom/nativefy');

const mobileMediaQuery = window.matchMedia(
    '(max-width: ' + (BASE_CONFIG.responsive.screen__768 - 1) + 'px)'
);
let panzoomInstance;

/**
 *  Obtain the calculated zoom images wrapper height
 * @param  {jQuery} zoomContainer product zoom container
 * @returns {number} - The height in pixels
 */
function getWrapperHeight(zoomContainer) {
    const standardImage = $('.product-images-slider .swiper-slide:not(.video-slide):first img:first');
    const imagesProp = standardImage
        ? standardImage.width() / standardImage.height()
        : BASE_CONFIG.productImgProportion;
    const imagesContainerWidth = zoomContainer.width();

    const imagesInWrapper = zoomContainer.find('.zoom-images-wrapper .zoom-picture').length;
    return (imagesInWrapper * imagesContainerWidth) / imagesProp;
}

/**
 * Shows desktop zoom popup: all product pictures with scroll
 * @param {jQuery|HTMLElement} currentSlide current slide
 * @param {jQuery} zoomContainer selected product zoom container
 * @param {Object} pageY Y positions of click event
 */
function showDesktopZoomPopup(currentSlide, zoomContainer, pageY) {
    const productImages = $('.product-images-slider__wrapper .swiper-slide:not(.video-slide)');
    const clickedImageOffset = ((pageY - $(currentSlide).offset().top) / $(currentSlide).height())
        .toFixed(2);
    const clickedImagePosition = productImages.index($(currentSlide));
    const wrapperHeight = getWrapperHeight(zoomContainer);
    const imagesInWrapper = $('.zoom-images-wrapper .zoom-picture').length;
    const offsetFromTop = (wrapperHeight / imagesInWrapper)
        * clickedImagePosition + (wrapperHeight / imagesInWrapper) * clickedImageOffset;

    zoomContainer.removeClass('d-none');
    $('body').css('overflow-y', 'hidden');

    zoomContainer.find('.zoom-images-wrapper--desktop .custom-lazyload').each(function () {
        $(this).attr('src', $(this).data('src'));
        $(this).removeClass('custom-lazyload');
    });

    zoomContainer.find('.zoom-images-wrapper--desktop ').height(wrapperHeight);
    zoomContainer.scrollTop(offsetFromTop);
}

/**
 * Moves the zoomed image to the clicked point
 * @param {HTMLElement} mobileZoomWrapper - panzoom main element
 * @param {Event} event - Javascript event
 * @param {HTMLElement} imageElement - Original zoomed image element
 */
function moveMobileZoomImageToClickedPoint(mobileZoomWrapper, event, imageElement) {
    // The available scroll distance for X & Y axes
    const pageScrollDistanceX = mobileZoomWrapper.getBoundingClientRect().width !== window.innerWidth
        ? Math.abs(mobileZoomWrapper.getBoundingClientRect().width - window.innerWidth)
        : window.innerWidth;
    const pageScrollDistanceY = mobileZoomWrapper.getBoundingClientRect().height !== window.innerHeight
        ? Math.abs(mobileZoomWrapper.getBoundingClientRect().height - window.innerHeight)
        : window.innerHeight;
    const X = (event.pageX * pageScrollDistanceX) / imageElement.getBoundingClientRect().width;
    const Y = (event.pageY * pageScrollDistanceY) / imageElement.getBoundingClientRect().height;

    // moveTo needs to be done after the container is visible to work properly
    panzoomInstance.moveTo(
        -X,
        -Y
    );
}

/**
 * Shows mobile zoom popup: Zoom clicked image usingpanzoom
 * @param {jQuery|HTMLElement} currentSlide current slide
 * @param {jQuery} zoomContainer selected product zoom container
 * @param {Event} event event
 */
function showMobileZoomPopup(currentSlide, zoomContainer, event) {
    const currentSlideElement = nativefy(currentSlide);
    const zoomContainerElement = nativefy(zoomContainer);
    const imageElement = currentSlideElement.querySelector('img');

    const mobileZoomWrapper = zoomContainerElement.querySelector('.zoom-images-wrapper--mobile');
    const clonedCurrentSlide = currentSlideElement.cloneNode(true);
    const clonedImageElement = clonedCurrentSlide.querySelector('img');
    if (clonedImageElement) {
        clonedImageElement.classList.add('zoom-images-wrapper__image');
    }
    mobileZoomWrapper.appendChild(clonedCurrentSlide);

    panzoomInstance = panzoom(mobileZoomWrapper, {
        minZoom: 2, // Only allow the initial zoom
        maxZoom: 2, // Only allow the initial zoom
        initialZoom: 2,
        bounds: true,
        boundsPadding: 1.025
    });
    zoomContainer.removeClass('d-none');

    moveMobileZoomImageToClickedPoint(mobileZoomWrapper, event, imageElement);
}

/**
 * Closes desktop zoom popup
 * @param {jQuery} zoomContainer selected product zoom container
 */
function closeDesktopZoomPopup(zoomContainer) {
    zoomContainer.scrollTop(0);
    zoomContainer.addClass('d-none');
    $('body').css('overflow-y', 'unset');
}

/**
 * Closes mobile zoom popup
 * @param {jQuery} zoomContainer selected product zoom container
 */
function closeMobileZoomPopup(zoomContainer) {
    const zoomContainerElement = nativefy(zoomContainer);
    const mobileZoomWrapper = zoomContainerElement.querySelector('.zoom-images-wrapper--mobile');
    javascriptUtils.empty(mobileZoomWrapper);

    zoomContainer.addClass('d-none');
    $('body').css('overflow-y', 'unset');

    if (typeof panzoomInstance !== 'undefined') {
        panzoomInstance.dispose();
    }
}

/**
 * Disable PDP zoom
 */
function disableZoom() {
    const zoomContainer = document.querySelector('.product-images-container');
    zoomContainer.style.pointerEvents = 'none';
}

/**
 * Enable PDP zoom
 */
function enableZoom() {
    const zoomContainer = document.querySelector('.product-images-container');
    zoomContainer.style.pointerEvents = '';
}

module.exports = {
    methods: {
        disableZoom,
        enableZoom
    },
    init: function () {
        $('.product-images-zoom').on(
            'click touchend', '.product-images-zoom__btn-close',
            function () {
                const zoomContainer = $(this).closest('.product-images-zoom');
                const isBundleItem = zoomContainer.hasClass('bundle-item-zoom');

                if (mobileMediaQuery.matches && !isBundleItem) {
                    closeMobileZoomPopup(zoomContainer);
                } else {
                    closeDesktopZoomPopup(zoomContainer);
                }
            }
        );

        $('body').on('product:image-zoom', function (event, data) {
            const currentTarget = $(data.currentTarget);
            let pid;
            let isBundleItem = false;

            if (pageUtilities.isBundleDetailPage()) {
                if (currentTarget.hasClass('bundle-item__zoom')) {
                    pid = currentTarget.data('pid');
                    isBundleItem = true;
                } else {
                    pid = currentTarget.closest('.product-detail-page').data('pid');
                }
            } else {
                pid = currentTarget.closest('.product-detail-page').find('.product-info__section').data('pid');
            }

            const zoomContainer = $('.product-images-zoom[data-pid=' + pid + ']');

            if (zoomContainer.length) {
                const currentSlide = data.currentTarget;

                if (mobileMediaQuery.matches && !isBundleItem) {
                    const currentPicture = $(currentSlide).find('picture');

                    showMobileZoomPopup(currentPicture, zoomContainer, data);
                } else {
                    showDesktopZoomPopup(currentSlide, zoomContainer, data.pageY);
                }
            }
        });
    }
};
