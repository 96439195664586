// eslint-disable-next-line
///<reference path="../../../common/monitors/scroll.js" />

const debounce = require('lodash/debounce');
const { onScrollEnd, createScrollMonitor, isScrollAlreadyMonitored } = require('../../../common/monitors/scroll');
const { composeAsync } = require('../../../utilities/fp');
const sendEvent = require('../helpers/sendEvent');
const {
    monitorProductTilesInView,
    queryProductTilesInView,
    getStoredImpressionFieldObjectFromDOM
} = require('./dom');
const { filterAlreadySentImpressions, saveSentImpressions } = require('./history');
const {
    createEvent,
    createEcommerceObjectForImpressions,
    getListOfImpressionsFromEvent,
    parseToGoogleImpressionFieldObject
} = require('./data');
const { internalEventCenter } = require('../events');

/**
 * Minimum time viewing products without scrolling
 */
const MILLISECONDS_VIEWING_PRODUCTS = 1000; // milliseconds

/**
 * Get the array of the currently visible impressions that have not been sent
 * yet. The impression data will be formatted and ready for sending to Datalayer.
 * @async
 * @returns {Object[]} - Array of ImpressionFieldObjects as Google waits
 */
const getImpressions = composeAsync(
    filterAlreadySentImpressions,
    elements => Promise.all(elements.map(composeAsync(
        parseToGoogleImpressionFieldObject,
        getStoredImpressionFieldObjectFromDOM
    ))),
    queryProductTilesInView
);

/**
 * Get the Ecommerce Object for sending the currently visible impressions to
 * Datalayer
 * @async
 * @returns {Object} - Ecommerce object for impressions
 */
const getEcommerceObjectForImpressions = composeAsync(
    createEcommerceObjectForImpressions,
    getImpressions
);

/**
 * Emit the event of sent impressions
 * @param {DataLayerEvent} datalayerEvent - The just-sent datalayer event
 * @returns {void}
 */
function handleDatalayerEventSent(datalayerEvent) {
    var impressions = getListOfImpressionsFromEvent(datalayerEvent);
    if (impressions.length > 0) {
        internalEventCenter.publish(
            internalEventCenter.EVENTS.impressionsSent,
            impressions
        );
    }
}

/**
 * Send to data layer an action of impressions
 * @async
 * @returns {void}
 */
const sendImpressionsEvent = composeAsync(
    sendEvent,
    createEvent,
    getImpressions
);

/**
 * Handle scroll ending and send the datalayer event if the scroll is stop
 * for a certain time
 * @param {ScrollMonitorEvent} monitorEvent - event data
 * @returns {void}
 */
function handleScrollEnd(monitorEvent) {
    const { isScrolling } = monitorEvent;
    setTimeout(() => {
        if (!isScrolling()) {
            sendImpressionsEvent();
        }
    }, MILLISECONDS_VIEWING_PRODUCTS);
}

/**
 * Handle new impression appearing and the action is stop for a certain time
 * @returns {void}
 */
const handleDynamicImpressions = debounce(
    sendImpressionsEvent,
    MILLISECONDS_VIEWING_PRODUCTS
);

/**
 * Initialize the tracking of impressions
 */
function init() {
    monitorProductTilesInView();

    // Monitorize sent impressions in order to send them once
    internalEventCenter.subscribe(internalEventCenter.EVENTS.impressionsSent, saveSentImpressions);
    internalEventCenter.subscribe(internalEventCenter.EVENTS.datalayerEventSent, handleDatalayerEventSent);

    // Handle potential events that can show new impressions
    internalEventCenter.subscribe(
        [
            internalEventCenter.EVENTS.sliderActiveSlideChanged
        ],
        handleDynamicImpressions
    );

    // Main scroll
    onScrollEnd(handleScrollEnd);

    // Monitorize the scrollable modals or popup while they are shown
    let modalScrollMonitor;
    internalEventCenter.subscribe(
        internalEventCenter.EVENTS.modalShown,
        ({ scrollableContainer }) => {
            if (!scrollableContainer) return;
            /* Monitor the modal only once. Sometimes additional events can be
            received when a modal is updated without closing. */
            if (!isScrollAlreadyMonitored(scrollableContainer)) {
                modalScrollMonitor = createScrollMonitor(scrollableContainer);
                modalScrollMonitor.onScrollEnd(handleScrollEnd);
            }
            handleDynamicImpressions();
        }
    );
    internalEventCenter.subscribe(
        internalEventCenter.EVENTS.modalWillBeHidden,
        () => modalScrollMonitor?.destroy()
    );
}

module.exports = {
    init,
    getEcommerceObjectForImpressions
};
