/**
 * Get the ID of the product of PDP
 * @returns {string | null} - PID
 */
function getProductIdFromDOM() {
    var res = null;
    var elem = document.querySelector('.product-detail-page');
    if (elem && elem.dataset.pid) {
        res = elem.dataset.pid;
    }
    return res;
}

/**
 * Get the ID of the master product of PDP
 * @returns {string | null} - PID
 */
function getMasterIdFromDOM() {
    var res = null;
    var elem = document.querySelector('.product-detail-page');
    if (elem && elem.getAttribute('data-master-pid')) {
        res = elem.getAttribute('data-master-pid');
    }
    return res;
}

module.exports = {
    getMasterIdFromDOM: getMasterIdFromDOM,
    getProductIdFromDOM: getProductIdFromDOM
};
