const { isPromise } = require('./type');

/**
 * Get always a promise of the input
 *
 * @param {any} value The input value
 * @returns {Promise} The original promise or a new promise with the value
 */
function promisify(value) {
    return isPromise(value) ? value : Promise.resolve(value);
}

module.exports = promisify;
