/**
 * @typedef {object} ViewportIntersectionPayload
 * @property {number} intersectionRatio - intersection ratio [0..1]. Ex: 0.75
 * @property {boolean} isAboveView - true if the target is in upper side
 * @property {boolean} isInView - true if the target is inside the view
 * @property {boolean} isBelowView - true if the target is in lower side
 */

/**
 * @callback ViewportIntersectionCallback
 * @param {Element} target - target element
 * @param {ViewportIntersectionPayload} payload - additional info about the
 * intersection
 * @return {void}
 */

/**
 * Returns true if the bounding client rect is above the half view
 * @param {DOMRect} targetBoundingClientRect - Bounding client rect of an element or observer's entry
 * @param {number} targetBoundingClientRect.top - Top is required
 * @param {number} targetBoundingClientRect.bottom - Bottom is required
 * @param {DOMRect} viewportRect - Viewport or parent
 * @param {number} viewportRect.top - top is required
 * @param {number} viewportRect.bottom - bottom is required
 * @returns {boolean} - true if it is above view
 */
function isAboveHalfView(targetBoundingClientRect, viewportRect) {
    const halfView = (viewportRect.bottom - viewportRect.top) / 2;
    return (
        targetBoundingClientRect.bottom < halfView
        && targetBoundingClientRect.top < viewportRect.top
    );
}

/**
 * Returns true if the bounding client rect is above the half view
 * @param {DOMRect} targetBoundingClientRect - Bounding client rect of an element or observer's entry
 * @param {number} targetBoundingClientRect.top - Top is required
 * @param {number} targetBoundingClientRect.bottom - Bottom is required
 * @param {DOMRect} viewportRect - Viewport or parent
 * @param {number} viewportRect.top - top is required
 * @param {number} viewportRect.bottom - bottom is required
 * @returns {boolean} - true if it is above view
 */
function isBelowHalfView(targetBoundingClientRect, viewportRect) {
    const halfView = (viewportRect.bottom - viewportRect.top) / 2;
    return (
        targetBoundingClientRect.top > halfView
        && targetBoundingClientRect.bottom > viewportRect.bottom
    );
}

/**
 * Create an intersection observer
 * @param {ViewportIntersectionCallback} callbackIn - Callback function to call when the observer
 * detects that the target is entering the view
 * @param {ViewportIntersectionCallback} [callbackOut] - Callback function to call when the observer
 * detects that the target is leaving the view
 * @param {Object} [options] - Observer options
 * @param {boolean} [options.once=false] - Observe only once
 * @param {Element} [options.root=null] - The element that is used as the viewport for checking
 * visibility. By default it will use the browser viewport
 * @param {number} [options.threshold=0] - the percentage of the target's
 * visibility the observer's callback should be executed
 * @returns {IntersectionObserver} - intersection observer
 */
function createViewportIntersectionObserver(
    callbackIn,
    callbackOut = false,
    {
        once = false,
        threshold = 0,
        root = null
    } = {}
) {
    const observer = new IntersectionObserver(
        (entries) => {
            entries.forEach((entry) => {
                const target = entry.target;
                let callback = entry.isIntersecting ? callbackIn : callbackOut;
                if (callback && entry.rootBounds) {
                    callback(target, {
                        intersectionRatio: entry.intersectionRatio,
                        isAboveView: isAboveHalfView(entry.boundingClientRect, entry.rootBounds),
                        isInView: entry.isIntersecting,
                        isBelowView: isBelowHalfView(entry.boundingClientRect, entry.rootBounds)
                    });
                    if (once) {
                        observer.unobserve(target);
                    }
                }
            });
        },
        {
            root: root,
            threshold: threshold
        }
    );
    return observer;
}

module.exports = createViewportIntersectionObserver;
