const { createPersistentStore } = require('../../../utilities/createStore');
const getAnalyticsConfig = require('../../../common/getAnalyticsConfig');
const { createEcommerceListAndPosition } = require('../models/ecommerceListAndPosition');

const analyticsConfig = getAnalyticsConfig();

module.exports = createPersistentStore({
    createModelFromDto: createEcommerceListAndPosition,
    dbName: analyticsConfig.localforage.dbDatalayer,
    dbTable: analyticsConfig.localforage.stores.lastListAndPositionWhereProductWasClicked.name
});
