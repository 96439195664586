var throttle = require('lodash/throttle');
const createSwiper = require('../components/swiperFactory');
var deviceUtilities = require('../utilities/deviceUtilities');

/**
 * Init swiper slider.
 *
 * @param {Element|string} container - HTMLElement or string (with CSS
 * Selector) of swiper container HTML element. Required.
 * @returns {Promise.<Object>} - Swiper instance
 */
async function swiperInit(container) {
    return createSwiper(container, {
        direction: 'horizontal',
        slidesPerView: 'auto',
        slidesPerColumn: 1,
        spaceBetween: 8,
        grabCursor: true,
        mousewheel: false,
        speed: 350,
        loop: false,
        updateOnWindowResize: true,
        watchOverflow: true,
        navigation: {
            nextEl: '.bundle-items__swiper-button-next',
            prevEl: '.bundle-items__swiper-button-prev'
        }
    });
}

/**
 * Calculate slider arrow position.
 *
 * @param {Object} container Slider container
 */
function calculateArrowsPosition(container) {
    var imageHeight = container.find('.bundle-item__image').outerHeight();
    var radioButtonHeight = container.find('.form-check-label').css('padding-top').split('px')[0];
    if (imageHeight !== 0) {
        container.children('.swiper-button-next, .swiper-button-prev').css('top', imageHeight / 2 + parseInt(radioButtonHeight, 10) + 'px');
    }
}

/**
 * Calculates and set Bundle items Container height
 * only for tablet and desktop, so button is in screen
 */
function calculateBundledItemsHeight() {
    var bundledItemsSection = $('.bundle-items');
    var headerHeight = Math.round($('.page-header').outerHeight());
    var addToCartButton = Math.round($('.bundle-info__buttons--desktop').outerHeight(true));
    var productInfo = Math.round($('.product-info-pdp').innerHeight());
    var sliderHeight = window.innerHeight - headerHeight - productInfo - addToCartButton;

    if (deviceUtilities.isTablet() || deviceUtilities.isDesktop()) {
        bundledItemsSection.css('max-height', sliderHeight + 'px');
    } else {
        bundledItemsSection.css('max-height', 'unset');
    }
}

/**
* Shows bundle item sizes, only for desktop screen sizes.
* @param {Object} associatedSizeInfo size info block to show
*/
function showDesktopBundleSizes(associatedSizeInfo) {
    var productCardHeight = associatedSizeInfo.closest('.bundle-item').outerHeight(true);
    var primaryBlockHeight = associatedSizeInfo.closest('.bundled-product').find('.bundle-item__body').outerHeight(true);
    var top = productCardHeight - primaryBlockHeight - associatedSizeInfo.outerHeight(true);
    associatedSizeInfo.css('top', top + 'px');
}

/**
* Clones bundle item sizes to a common stikcy wrapper and
* shows it, only for mobile screen sizes.
* @param {Object} associatedSizeInfo size info block to show
* @param {string} bundeItemPid item PID
*/
function showMobileBundleSizes(associatedSizeInfo, bundeItemPid) {
    const clonedContainer = $('.cloned-sizes');
    var clonedSiezInfo = clonedContainer.find('.product-info__sizes-info[data-pid="' + bundeItemPid + '"]');
    if (clonedSiezInfo.length < 1) {
        associatedSizeInfo.clone().appendTo(clonedContainer).addClass('cloned');
        clonedSiezInfo = clonedContainer.find('.product-info__sizes-info[data-pid="' + bundeItemPid + '"]');
    }

    $('html, body').animate({
        scrollTop: associatedSizeInfo.closest('.bundled-product').offset().top - Math.round($('.page-header').outerHeight())
    }, 500, function () {
        clonedSiezInfo.css('bottom', '0');
    });
}

/**
 * Closes bundle size selector, in mobile and desktop
 * @param {Object} selector size info block(s) to close
 */
function closeBundleSizeSelector(selector) {
    if (deviceUtilities.isDesktop() || deviceUtilities.isTablet()) {
        selector.css('top', '100%');
    } else {
        selector.css('bottom', '-100%');
    }
}

/**
 * Fills and shows size and edit section in Bundled product
 * @param {*} $productContainer selected bundle item
 */
function updateSizeAndEditButton($productContainer) {
    var size = $productContainer.find('.product-sizes__option.selected').attr('data-attr-value');
    if (!size || size === '') { return; }

    // The option selected is the no-size one.
    var isEmptySizeOption = $productContainer.find('.form-control.select-size option:selected').data('hide');
    if (isEmptySizeOption) {
        $productContainer.find('.bundled-product__size-and-edit').removeClass('show');
        return;
    }

    // $productContainer.closest('.bundle-item-group').find('.bundled-product__size-and-edit').removeClass('show');
    $productContainer.find('.bundled-product__size').text(size);
    $productContainer.find('.bundled-product__size-and-edit').addClass('show');
}

/**
 * Checks if there is one products selected in each bundle item group
 * @returns {boolean} all products are checked
 */
function areAllProductsChecked() {
    var result = true;

    Array.prototype.forEach.call($('.bundle-item-group'), function (group) {
        var inputChecked = $(group).find('input[class*=bundled-product-input]:checked');
        if (inputChecked.length <= 0) {
            result = false;
        }
    });

    return result;
}

/**
 * Checks if all products and their sizes are selected
 * @returns {boolean} all groups have selected product and size
 */
function allProductsAndSizesSelected() {
    if (!areAllProductsChecked()) {
        return false;
    }

    var selectedItemsSizes = $('input[class*=bundled-product-input]:checked').closest('.bundled-product').find('.product-sizes__selector');
    var result = true;

    Array.prototype.forEach.call(selectedItemsSizes, function (sizeSelector) {
        if ($(sizeSelector).find('.selected').length <= 0) {
            result = false;
        }
    });

    return result;
}

module.exports = {
    methods: {
        closeBundleSizeSelector: closeBundleSizeSelector,
        showDesktopBundleSizes: showDesktopBundleSizes,
        showMobileBundleSizes: showMobileBundleSizes,
        updateSizeAndEditButton: updateSizeAndEditButton
    },
    init: function () {
        $(document).ready(function () {
            $('.bundle__swiper-container').each(function () {
                var container = $(this);
                // check if only one bundled item
                if ($(this).find('.bundle__slide-item').length > 0 && !$(this).hasClass('swiper-container-initialized')) {
                    swiperInit(container).then(function () {
                        calculateArrowsPosition(container);
                    });
                }

                window.addEventListener('resize', throttle(function () {
                    calculateArrowsPosition(container);
                }, 300));
            });

            calculateBundledItemsHeight();
        });

        window.addEventListener('resize', throttle(function () {
            calculateBundledItemsHeight();
            $('.bundle-detail-page .product-info__sizes-info:not(.product-tile__sizes-info)').css('top', 'unset').css('bottom', 'unset');
        }, 300));
    },

    showRealValue: function () {
        $('.bundled-product').on('click', function () {
            $(this).closest('.bundle-item-group').find('.bundle-item__unselected-error').hide(); // clean errors

            if (areAllProductsChecked()) {
                var currencySymbol = $('#product-info-primary-section').find('.price .value').attr('data-symbol');
                var totalPrice = 0;
                $('input[class*=bundled-product-input]:checked').each(function () {
                    var price = $(this).closest('.bundled-product').find('.price .sales .value').attr('content');
                    totalPrice += parseInt(price, 10);
                });
                $('.bundle-info__value-quantity').text(currencySymbol + totalPrice);
                $('.bundle-info__value').show();
            } else {
                $('.bundle-info__value').hide();
            }
        });
    },

    bundleItemZoom: function () {
        $('.bundle-item__zoom').on('click', function (event) {
            event.preventDefault();
            $('body').trigger('product:image-zoom', event);
        });
    },

    changeAddtoCartButtonText: function () {
        $('.bundled-product').on('click', function () {
            var button = $('.bundle-info__buttons .add-to-cart-global');
            if (allProductsAndSizesSelected()) {
                button.text(button.attr('data-addtocart'));
            } else {
                button.text(button.data('bundle'));
            }
        });
    },

    triggerClonedSizeSelection: function () {
        $('body').on('click', '.cloned-sizes .product-sizes__option', function () {
            const bundeItemPid = $(this).closest('.bundle-item__sizes-info').data('pid');
            const originalSizeInfo = $('.product-info__sizes-info[data-pid="' + bundeItemPid + '"]:not(.cloned)');
            const thisRel = $(this).attr('rel');

            $('.cloned-sizes .product-sizes__option').removeClass('selected');
            $(this).addClass('selected');

            // Click original size button
            originalSizeInfo.find('.product-sizes__option[rel="' + thisRel + '"]').trigger('click');

            // Close cloned size section
            closeBundleSizeSelector($(this).closest('.bundle-item__sizes-info'));
        });
    },
    blockMagicMouseHorizontalScroll: function () {
        if (document.querySelector('.bundle-items')) {
            document.querySelector('.bundle-items').addEventListener('wheel', throttle(function (e) {
                if (e.wheelDeltaX !== 0) {
                    e.stopPropagation();
                    e.preventDefault();
                    e.cancelBubble = false;
                }
                return false;
            }, 100), false);
        }
    }
};
