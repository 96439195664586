const pdpHelpers = require('../../../../utilities/pdpHelpers');
const checkIfCurrentPage = require('../../helpers/checkIfCurrentPage');

/**
 * Get the ID of the referrer product from DOM for datalayer list construction
 * @return {string|undefined} The ID of the referrer product
 */
function getReferrerProductIdFromDOM() {
    let pid;
    if (checkIfCurrentPage().isProductDetailPage) {
        pid = pdpHelpers.getMasterIdFromDOM();
        if (!pid) {
            pid = pdpHelpers.getProductIdFromDOM();
        }
    }
    return pid;
}

module.exports = {
    getReferrerProductIdFromDOM
};
