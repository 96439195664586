const checkIfCurrentPage = require('../../helpers/checkIfCurrentPage');
const getAnalyticsConfig = require('../../../../common/getAnalyticsConfig');

const analyticsConfig = getAnalyticsConfig();

/**
 * Get the page prefix for a datalayer list
 * @returns {string} - Page
 * @example /home
 */
const getCurrentPageFromDOM = () => {
    let pagePrefix;
    if (checkIfCurrentPage().isHomePage) {
        pagePrefix = analyticsConfig.list.referrerPages.home;
    } else if (checkIfCurrentPage().isCartPage) {
        pagePrefix = analyticsConfig.list.referrerPages.cart;
    } else if (checkIfCurrentPage().isStorePage.blackFriday) {
        pagePrefix = analyticsConfig.list.referrerPages.blackFriday;
    }
    return pagePrefix;
};

module.exports = {
    getCurrentPageFromDOM
};
