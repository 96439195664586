const GTM_CONFIG = require('../../../../config/gtmConfig');
const getAnalyticsEventCenter = require('../common/getAnalyticsEventCenter');
const getPipelinesUrls = require('../common/getPipelinesUrls');
const { isEmpty, isObject } = require('../utilities/type');

const PIPELINES_URLS = getPipelinesUrls();

/**
 * @typedef {object} DatalayerCheckoutInfo
 * @property {string} currencyCode Current currency code
 * @property {string} eventDataLabel Label of the event
 * @property {object[]} productFieldObjects Array of DTOs of the
 * productFieldObject of the cart items.
 */

/**
 * Get analytics checkout info object
 * @return {DatalayerCheckoutInfo} Stored checkout info
 */
function getDatalayerCheckoutInfo() {
    let datalayerCheckoutInfo = null;

    const stringifiedData = window.localStorage.getItem(GTM_CONFIG.localStorageItems.checkoutInfo);
    if (stringifiedData) {
        const parsed = JSON.parse(stringifiedData);
        if (isObject(parsed) && !isEmpty(parsed)) {
            datalayerCheckoutInfo = parsed;
        }
    }

    return datalayerCheckoutInfo;
}

/**
 * Remove analytics checkout info object
 */
function removeDatalayerCheckoutInfo() {
    window.localStorage.removeItem(
        GTM_CONFIG.localStorageItems.checkoutInfo
    );
}

/**
 * Sets analytics checkout info. If datalayerCheckoutInfo is not provided, then
 * the server will be queried for that cart info
 * @param {DatalayerCheckoutInfo} [datalayerCheckoutInfo] Cart data for datalayer
 * @return {Promise<void>} Action result
 */
function updateDatalayerCheckoutInfo(datalayerCheckoutInfo) {
    var promise;
    if (isEmpty(datalayerCheckoutInfo)) {
        promise = new Promise((resolve, reject) => {
            $.ajax({
                url: `${PIPELINES_URLS.analyticsGetCheckoutInfo}`,
                method: 'POST',
                success: function (data) {
                    if (isEmpty(data.datalayerCheckoutInfo)) {
                        window.localStorage.removeItem(
                            GTM_CONFIG.localStorageItems.checkoutInfo
                        );
                    } else {
                        window.localStorage.setItem(
                            GTM_CONFIG.localStorageItems.checkoutInfo,
                            JSON.stringify(data.datalayerCheckoutInfo)
                        );
                    }
                    resolve();
                },
                error: function () {
                    window.localStorage.removeItem(
                        GTM_CONFIG.localStorageItems.checkoutInfo
                    );
                    reject();
                }
            });
        });
    } else {
        window.localStorage.setItem(
            GTM_CONFIG.localStorageItems.checkoutInfo,
            JSON.stringify(datalayerCheckoutInfo)
        );
        promise = Promise.resolve();
    }
    return promise;
}

module.exports = {
    init: function () {
        $('.checkout-btn').click(function (e) {
            // Import here to assure that is ready
            const analyticsEventCenter = getAnalyticsEventCenter();
            if (window.google_tag_manager) {
                e.preventDefault();
                const redirectLink = $(this).attr('href');

                updateDatalayerCheckoutInfo()
                    .then(() => {
                        analyticsEventCenter.publish(
                            analyticsEventCenter.EVENTS.checkoutStageChanged,
                            {
                                stageName: 'initCheckout',
                                redirectUrl: redirectLink
                            }
                        );
                    })
                    .catch(() => {
                        window.location = redirectLink;
                    });
            }
        });
    },
    getDatalayerCheckoutInfo,
    removeDatalayerCheckoutInfo,
    updateDatalayerCheckoutInfo
};
