/**
 * Creates an overlay, appends it to body
 * @param {string} identifier - Class name to identify who creates the overlay
 * @param {string} element - Element or node on which to append overlay
 */
function createOverlay(identifier, element) {
    var htmlString = '<div class="overlay ';

    if (identifier) {
        htmlString += identifier + '-overlay';
    }

    htmlString += '">'
        + '<!-- Overlay -->'
        + '</div>';

    var appdenElement = element || 'body';
    $(appdenElement).append(htmlString);
}

/**
 * Deletes overlay
 */
function deleteOverlay() {
    $('.overlay').remove();
}

/**
 * Stops page scrolling
 * adding format to body:
 *  - overflow hidden
 *  - padding to replace scrollbar space
 */
function stopPageScroll() {
    $('body').addClass('modal-open');
}

/**
 * Resume page scrolling
 */
function resumePageScroll() {
    $('body').removeClass('modal-open');
}

module.exports = {
    createOverlay: createOverlay,
    deleteOverlay: deleteOverlay,
    stopPageScroll: stopPageScroll,
    resumePageScroll: resumePageScroll
};
