const { internalEventCenter } = require('../components/datalayer/events');

/* eslint-disable-next-line valid-jsdoc */
/**
 * Get the AnalyticsEventCenter outside the datalayer component. This is the
 * only way to access to it without duplicating requires.
 */
function getAnalyticsEventCenter() {
    const { adAnalytics = false } = window;
    if (!adAnalytics) {
        throw new Error('Public analytics method are not available yet');
    }
    // Constants and their JSDoc
    const {
        COMMANDS,
        EVENTS,
        PAYLOADS
    } = internalEventCenter;

    // Common methods
    const {
        publish,
        subscribe,
        unsubscribe
    } = adAnalytics.analyticsEventCenter;

    // Exported in this manner we can access to the original JSDoc of the
    // commands, events and payload
    return {
        COMMANDS,
        EVENTS,
        PAYLOADS,
        /**
         * Publish an analytic event
         * @param {ANALYTICS_EVENTS} eventName The event to publish to
         * @param {*} [data] The payload that is useful for event subscribers
         */
        publish,
        /**
         * Subscribe to an analytic event
         * @param {string|string[]} eventName The event/s to subscribe to
         * @param {function((Event|JQuery.event|any), any)} handler The handler of the
         * subscription, which receives an event
         */
        subscribe,
        /**
         * Unsubscribe from an analytic event
         * @param {string|string[]} eventName The event/s from ANALYTICS_EVENTS to
         * unsubscribe from
         * @param {function((Event|JQuery.event|any), any)} handler The handler of the
         * subscription that must be deleted
         */
        unsubscribe
    };
}

module.exports = getAnalyticsEventCenter;
