const { getBooleanAttribute, setBooleanAttribute } = require('../../../utilities/dom/booleanAttribute');
const whenNodeIsAdded = require('../../../utilities/dom/whenNodeIsAdded');
const { compose } = require('../../../utilities/fp');
const uid = require('../../../utilities/uid/non-secure');
const createViewportIntersectionObserver = require('../../../utilities/viewport/createViewportIntersectionObserver');
const { getListAndPositionFromDOM } = require('../ecommerceListTracking');
const { internalEventCenter } = require('../events');
const getAnalyticsConfig = require('../../../common/getAnalyticsConfig');
const { queryProductImpressionElementFromDescendant } = require('../helpers/queryElement');
const { createImpressionFieldObject } = require('../models/impressionFieldObject');
const impressionStore = require('../stores/impressionsStore');

const analyticsConfig = getAnalyticsConfig();

/**
 * Get the modal if the product tile is inside a fixed modal
 * @param {Element} targetProductTile - Node of the product tile
 * @returns {Element|null} - modal
 */
const getAncestorModal = (targetProductTile) => targetProductTile.closest('.modal');

/**
 * Get the raw impression data from the DOM. This will not include the list and
 * the position.
 * @param {Element} element - target element
 * @returns {ImpressionFieldObject} - JSON data for impression
 */
const getIncompleteImpressionFromElement = (element) => {
    const stringifiedImpression = element.getAttribute(
        analyticsConfig.dataAttributes.productImpression
    );
    try {
        const dto = JSON.parse(stringifiedImpression);
        return createImpressionFieldObject(dto);
    } catch {
        /* eslint-disable no-console */
        if (stringifiedImpression?.indexOf('<wainclude') === 0) {
            console.error('Dynamic impression detected. It has an unprocessed wainclude.');
        } else {
            console.error('Unknown error when reading the impression');
        }
        /* eslint-enable no-console */
        return null;
    }
};

/**
 * Get the impression id from a single tile to interact with impression store.
 * @param {Element} element - target element
 * @returns {string} - JSON data for impression
 */
const getImpressionIdFromElement = (element) => {
    const impressionId = element.getAttribute(
        analyticsConfig.dataAttributes.productImpressionId
    );
    if (!impressionId) {
        throw new RangeError('Impresion ID has no value for element');
    }

    return impressionId;
};

/**
 * Get the impression data from the impressions store given a product impression
 * element or a descendant
 * @param {Element} element Product impression element or descendant
 * @returns {Promise<ImpressionFieldObject|null>} Datalayer impression
 */
const getStoredImpressionFieldObjectFromDOM = compose(
    impressionStore.getItem,
    getImpressionIdFromElement,
    queryProductImpressionElementFromDescendant
);

/**
 * Run impression initialization code for a single product impression
 * @param {Element} element Target element
 * @returns {void}
 */
function initializeProductImpressionElement(element) {
    const isAlreadyInitialized = getBooleanAttribute(
        element,
        analyticsConfig.dataAttributes.productImpressionCached
    );
    const isInitializing = getBooleanAttribute(
        element,
        analyticsConfig.dataAttributes.productImpressionCaching
    );

    if (isAlreadyInitialized || isInitializing) return;

    setBooleanAttribute(
        element,
        analyticsConfig.dataAttributes.productImpressionCaching,
        true
    );

    const impressionId = getImpressionIdFromElement(element);
    const incompleteImpression = getIncompleteImpressionFromElement(element);

    if (incompleteImpression === null) return;

    const impressionPromise = incompleteImpression.updateListAndPosition(
        getListAndPositionFromDOM(element)
    );

    impressionStore
        /* We set an impression promise to accelerate the setting for early
         * readings such as the ones needed in pageview event */
        .setItem(impressionId, impressionPromise)
        .then((impression) => {
            internalEventCenter.publish(
                internalEventCenter.EVENTS.impressionShownForTheFirstTime,
                {
                    element,
                    impression
                }
            );
            setBooleanAttribute(
                element,
                analyticsConfig.dataAttributes.productImpressionCaching,
                false
            );
            setBooleanAttribute(
                element,
                analyticsConfig.dataAttributes.productImpressionCached,
                true
            );
            element.removeAttribute(
                analyticsConfig.dataAttributes.productImpression
            );
        });
}

/**
 * Observe document product tiles to watch whether they are in viewport or not
 * @returns {void}
 */
function monitorProductTilesInView() {
    const intersectionObservers = {};
    const defaultKey = 'document';

    /**
     * Create an observer for the given root element
     * @param {Element} root - viewport element
     * @returns {IntersectionObserver} - new observer
     */
    const createObserver = (root = null) => createViewportIntersectionObserver(
        (elem, { intersectionRatio }) => {
            // For threshold 0
            initializeProductImpressionElement(elem);
            if (intersectionRatio >= 0.5) {
                // For threshold 0.5
                setBooleanAttribute(elem, analyticsConfig.dataAttributes.isProductInView, true);
            }
        },
        (elem) => {
            setBooleanAttribute(elem, analyticsConfig.dataAttributes.isProductInView, false);
        },
        {
            threshold: [0, 0.5],
            root
        }
    );

    /**
     * Get the observer related to the root element
     * @param {Element} root - viewport element
     * @returns {IntersectionObserver} - related observer
     */
    const getIntersectionObserver = (root) => {
        const attrViewportUid = 'data-viewport-uid';
        let key = defaultKey;
        if (root) {
            key = root.getAttribute(attrViewportUid);
            if (!key) {
                key = uid();
                root.setAttribute(attrViewportUid, key);
            }
        }
        if (!(key in intersectionObservers)) {
            intersectionObservers[key] = createObserver(root);
        }
        return intersectionObservers[key];
    };

    whenNodeIsAdded(
        analyticsConfig.selectors.productImpressionElement,
        (node) => {
            const modal = getAncestorModal(node);
            const observer = getIntersectionObserver(modal);
            observer.observe(node);
        }
    );
}

/**
 * Get all the visible tiles in the present time
 * @returns {Element[]} - Visible tiles
 */
function queryProductTilesInView() {
    return Array.from(
        document.querySelectorAll(
            `${analyticsConfig.selectors.productImpressionElement}[${analyticsConfig.dataAttributes.isProductInView}]`
        )
    );
}

module.exports = {
    getStoredImpressionFieldObjectFromDOM,
    monitorProductTilesInView,
    queryProductTilesInView
};
