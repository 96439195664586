const createSwiper = require('../components/swiperFactory');
const deviceUtilities = require('../utilities/deviceUtilities');
const { handleLogoVisibility } = require('../product/productDetailStyles').methods;

/**
 * Init swiper slider.
 * If swiper slider already exists, it is not reinitialized and returns null
 *
 * @param {Element|string} productImageSwiper - HTMLElement or string (with CSS
 * Selector) of swiper container HTML element. Required.
 * @returns {Promise.<Object>} - Swiper instance
 */
async function swiperInit() {
    // Calculates available space to set fixed swiper images height
    var headerHeight = Math.round($('.page-header .header-banner').outerHeight());
    var infoSectionHeight = Math.round($('#product-info-primary-section').innerHeight());
    var availableHeight = window.innerHeight - headerHeight - infoSectionHeight;

    const moreThanOneSlide = $('.product-images-slider .swiper-container .swiper-slide').length > 1;
    if (moreThanOneSlide) {
        return createSwiper('.product-images-slider .swiper-container', {
            direction: 'vertical',
            slidesPerView: 1,
            slidesPerColumn: 1,
            spaceBetween: 0,
            grabCursor: true,
            mousewheel: false,
            updateOnWindowResize: true,
            watchOverflow: true,
            loop: false,
            height: availableHeight,
            speed: 350,
            pagination: {
                el: '.swiper-pagination',
                clickable: true
            },
            on: {
                slideChangeTransitionEnd: function () {
                    handleLogoVisibility();
                }
            }
        });
    }
    return Promise.resolve(null);
}

module.exports = async function () {
    /* type {object} Swiper instance */
    var productImageSwiper = null;

    if (deviceUtilities.isSmallerThan768()) {
        productImageSwiper = await swiperInit();
        handleLogoVisibility();
    }

    window.addEventListener('resize', async function () {
        if (deviceUtilities.isSmallerThan768()) {
            if (!productImageSwiper) {
                productImageSwiper = await swiperInit();
            }
        } else if (productImageSwiper) {
            productImageSwiper.destroy();
            $('.product-images-slider .swiper-pagination').empty();
            productImageSwiper = null;
        }
    });
};
