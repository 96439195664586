const getPipelinesUrls = require('../../../common/getPipelinesUrls');

const PIPELINES_URLS = getPipelinesUrls();

/**
 * Fetch the user info from the server session
 * @async
 * @returns {Promise.<Object>} - user required for datalayer
 */
const fetchUserInfo = () => fetch(PIPELINES_URLS.analyticsGetUser)
    .then((response) => response.json())
    .then((json) => json.user);

module.exports = fetchUserInfo;
