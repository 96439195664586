/**
 * Adds added class to wishlist button and displays success or erro message
 * @param {string} data - data returned from the server's ajax call
 */
function displayMessageAndChangeIcon(data) {
    $.spinner().stop();
    var status;
    if (data.success) {
        status = 'alert-success';
    } else {
        status = 'alert-danger';
    }

    if ($('.add-to-wishlist-messages').length === 0) {
        $('body').append(
            '<div class="add-to-wishlist-messages "></div>'
        );
    }
    $('.add-to-wishlist-messages')
        .append('<div class="add-to-wishlist-alert alert text-center ' + status + '">' + data.msg + '</div>');

    setTimeout(function () {
        $('.add-to-wishlist-messages').remove();
    }, 5000);
}

module.exports = {
    addToWishlist: function () {
        $('body').on('click', '.wishlistTile', function (e) {
            e.preventDefault();
            var wishlistTile = $(this);
            var url;
            var pid;

            if ($(this).closest('.product-detail').hasClass('bundle-item')) {
                pid = $(this).closest('.product-detail').data('pid');
            } else {
                pid = $(this).closest('.product').data('pid');
            }

            if (!pid) {
                return;
            }

            var optionId = $(this).closest('.product-detail').find('.product-option').attr('data-option-id');
            var optionVal = $(this).closest('.product-detail').find('.options-select option:selected').attr('data-value-id');
            optionId = optionId || null;
            optionVal = optionVal || null;

            $.spinner().start();
            $(this).attr('disabled', true);

            // Heart button always adds to wishlist (never removes from list).
            url = $(this).attr('href');
            if (!url) {
                $.spinner().stop();
                $(this).removeAttr('disabled');
                return;
            }

            $.ajax({
                url: url,
                type: 'post',
                dataType: 'json',
                data: {
                    pid: pid,
                    optionId: optionId,
                    optionVal: optionVal
                },
                success: function (data) {
                    displayMessageAndChangeIcon(data);
                    wishlistTile.removeAttr('disabled');
                },
                error: function (err) {
                    displayMessageAndChangeIcon({
                        success: false,
                        msg: err.responseJSON.message
                    });
                    $(this).removeAttr('disabled');
                }
            });
        });
    }
};
