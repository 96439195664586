const onDOMContentLoaded = require('../utilities/dom/onDOMContentLoaded');

const BASE_CONFIG = window.BASE_CONFIG;
const mql = window.matchMedia('(max-width: ' + (BASE_CONFIG.responsive.screen__1025 - 1) + 'px)');
const headerBanner = document.getElementsByClassName('header-banner')[0];
const header = document.getElementsByClassName('header')[0];
const floatingPlPNavigation = document.getElementsByClassName('floating-plp-navigation')[0];
const floatingNavigation = document.getElementsByClassName('floating-navigation');

/**
 * Calculate the height and position of the Floating Navigations (her/him) based on the screen width and header height
 */
function setFloatingNavigationPosition() {
    if (!floatingNavigation || floatingNavigation.length === 0) {
        return;
    }
    if (!mql.matches) {
        var headerHeight = (headerBanner.offsetHeight || 0) + header.offsetHeight;
        for (var i = 0; i < floatingNavigation.length; i++) {
            floatingNavigation[i].style.top = headerHeight + 'px';
        }
    } else {
        for (var j = 0; j < floatingNavigation.length; j++) {
            floatingNavigation[j].style.top = 'inherit';
        }
    }
}

/**
 * Calculate the height and position of the PLP Navigation based on the screen width and header height
 */
function setFloatingPlpNavigationPosition() {
    if (!floatingPlPNavigation) { return; }
    var headerHeight = (headerBanner.offsetHeight || 0)
        + header.offsetHeight;
    floatingPlPNavigation.style.top = headerHeight + 'px';
}

/**
 * Removes transparent background
 */
function goSolid() {
    floatingPlPNavigation.classList.remove('floating-plp-navigation--transparent');
}

/**
 * Adds transparent background
 */
function goTransparent() {
    floatingPlPNavigation.classList.add('floating-plp-navigation--transparent');
}

onDOMContentLoaded(() => {
    if (floatingPlPNavigation) {
        window.addEventListener('scroll', function () {
            if (this.oldScroll > this.scrollY) {
                goSolid();
            } else {
                goTransparent();
            }
            this.oldScroll = this.scrollY;
        });
    }
});

module.exports = {
    setFloatingNavigationPosition: setFloatingNavigationPosition,
    setFloatingPlpNavigationPosition: setFloatingPlpNavigationPosition
};
