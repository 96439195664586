const pageUtilities = require('../utilities/pageUtilities');

/**
 * Get original element data attributes and set in new element
 * @param {Object} originalElement Original
 * @param {Object} newElement New
 */
function cloneDataAttributes(originalElement, newElement) {
    const data = originalElement.data();
    Object.keys(data).forEach(function (key) {
        var attrName = 'data-' + key.replace(/[A-Z]/g, function ($0) {
            return '-' + $0.toLowerCase();
        });
        newElement.attr(attrName, data[key]);
    });
}

/**
 * Check if last units alert message must be shown
 * @param {Object} $option Current select option element
 * @param {Object} lastUnitsAlertMessage last Units Alert Message element
 * @return {boolean} True if message must be shown
 */
function showLastUnitsAlertMessage($option, lastUnitsAlertMessage) {
    return $option.hasClass('stock-last-units')
        && lastUnitsAlertMessage.length > 0
        && !pageUtilities.isQuickView($option)
        && (pageUtilities.isQuickShop($option) || pageUtilities.isProductDetailPage());
}

/**
 * Check if stock alert modal link must be shown
 * @param {Object} $option Current select option element
 * @param {Object} stockAlertModalLink stock Alert Modal Link element
 * @return {boolean} True if link must be shown
 */
function showStockAlertModalLink($option, stockAlertModalLink) {
    return $option.hasClass('disabled--unavailable')
        && stockAlertModalLink.length > 0
        && !pageUtilities.isQuickView($option)
        && (pageUtilities.isQuickShop($option) || pageUtilities.isProductDetailPage());
}

/**
 * Update customized select options when original select has changed
 * @param {Object} customizedSelect Customized select object
 * @param {Object} $select Original select object
 */
function updateOptions(customizedSelect, $select) {
    var numberOfOptions = $select.children('option').length;
    var customizedSelectOptionClasses = customizedSelect.children('li')
        .attr('class')
        .replace('stock-last-units', '')
        .replace('disabled--unavailable', '')
        .replace('disabled', '')
        .replace('selected', '');

    customizedSelect.empty();
    const stockAlertModalLink = $('.stock-alert-modal-link').first();
    const lastUnitsAlertMessage = $('.product-info__last-units').first();

    for (var i = 0; i < numberOfOptions; i++) {
        var classSelected = '';
        var classDisabled = '';
        var selectOptionClasses = '';
        var $option = $select.children('option').eq(i);

        if (!$option.data('hide') || $option.data('hide') === false || $option.data('hide') === 'false') {
            if ($option.val() === $select.val()) {
                classSelected = 'selected';
            }

            if ($option.attr('class')) {
                selectOptionClasses = $option.attr('class').split(/\s+/).toString().replace(',', ' ');
            }

            if ($option.attr('disabled')) {
                classDisabled = 'disabled';
            }

            var customizedListItem = $('<li />', {
                html: '<span class="product-sizes__value">' + $option.text() + '</span>',
                rel: $option.val(),
                role: 'option',
                'aria-selected': !!selectOptionClasses,
                class: classDisabled
                    + ' '
                    + classSelected
                    + ' '
                    + selectOptionClasses
            });

            cloneDataAttributes($option, customizedListItem);

            if (showStockAlertModalLink($option, stockAlertModalLink)) {
                stockAlertModalLink.clone().removeClass('d-none').appendTo(customizedListItem);
            } else if (showLastUnitsAlertMessage($option, lastUnitsAlertMessage)) {
                lastUnitsAlertMessage.clone().removeClass('d-none').appendTo(customizedListItem);
            }

            customizedListItem.appendTo(customizedSelect);
        }
    }

    var listItems = customizedSelect.children('li');
    listItems.addClass(customizedSelectOptionClasses);

    if (!$select.hasClass('disable-custom-select-listener')) {
        listItems.click(function (e) {
            if ($(this).hasClass('disabled--unavailable')) {
                return;
            }

            e.stopPropagation();

            if (!$(this).hasClass('disabled')) {
                listItems.removeClass('selected');
                listItems.attr('aria-selected', false);
                $(this).addClass('selected');
                $(this).attr('aria-selected', true);
                $select.val($(this).attr('rel'));
                $select.trigger('change');
            }
        });
    }
}

var onSelectOptionsChange = (function () {
    var config = {
        attributes: true, childList: true, subtree: true
    };

    /**
     * Callback function to execute when mutations are observed
     *
     * @param {Object} selectContainer Customized select parent
     * @param {event} onChange event to launch
     * @return {MutationObserver} MutationObserver
     */
    function revertStyleModification(selectContainer, onChange) {
        return function (mutationsList, observer) {
            var hasSelectChanged = mutationsList.some(function (mutationItem) {
                var tagName = mutationItem.target.tagName.toLowerCase();
                return $.inArray(tagName, ['select', 'option']) !== -1;
            });
            if (hasSelectChanged) {
                observer.disconnect();
                onChange();
                observer.observe(selectContainer, config);
            }
        };
    }

    return (selectContainer, onChange) => {
        // Create an observer instance linked to the callback function
        var observer = new MutationObserver(revertStyleModification(selectContainer, onChange));
        // Start observing the target node for configured mutations
        observer.observe(selectContainer, config);
    };
}());

/**
 * Update customized select options when original select has changed
 * @param {element} element - Optional, select element to customize.
 */
function customSelects(element) {
    var elements = element || $('.customized-select');

    elements.each(function () {
        var customizedSelect = $(this);
        var $select = $(this).siblings('select');

        if ($select.hasClass('force-initial-select')) {
            $select.removeClass('force-initial-select');
            $select.trigger('change');

            // Fix for Bundles. TODO refactor
            if (customizedSelect.hasClass('isBundle') && $select.find('option:selected').hasClass('disabled--unavailable')) {
                var productContainer = customizedSelect.closest('.bundled-product');
                productContainer.find('.bundled-product-input').attr('disabled', true);
                productContainer.find('.edit-icon').addClass('disabled');
                productContainer.addClass('disabled');
            }
        } else {
            updateOptions(customizedSelect, $select);
        }

        if (!$select.hasClass('disable-custom-select-listener')) {
            onSelectOptionsChange($select[0].parentNode, function () {
                updateOptions(customizedSelect, $select);
            });
        }
    });
}

module.exports = {
    customizeSelects: customSelects
};
