const getPipelinesUrls = require('../../../common/getPipelinesUrls');
const { urlWithSearchParams } = require('../../../utilities/urlUtilities');
const { createProductFieldObject } = require('../models/productFieldObject');

const PIPELINES_URLS = getPipelinesUrls();
/**
 * Fetch the datalayer product info from server
 * @param {string} productId - pid
 * @returns {Promise.<ProductFieldObject>} - ProductFieldObject
 */
function fetchProductFieldObject(productId) {
    if (!productId || productId === '') return Promise.resolve({});
    return fetch(
        urlWithSearchParams(PIPELINES_URLS.analyticsGetProductFieldObject, {
            pid: productId
        })
    )
        .then((response) => response.json())
        .then((json) => json.productFieldObject)
        .then(createProductFieldObject);
}

module.exports = fetchProductFieldObject;
