/**
 * Calculate the height of the minicart based on the window
 * Top position is always 0.
 */
function calculateMinicartPosition() {
    const topPosition = 0;
    var menuHeight = window.innerHeight;
    $('.popover-minicart').css('top', topPosition + 'px');
    $('.popover-minicart').css('height', menuHeight + 'px');
}

/**
 * Changes No Stock Messages visibility
 * @param {HTMLCollection} messages messages to show
 * @param {boolean} show show/hide messages
 */
function changeNoStockMessageVisibility(messages, show) {
    if (!messages) return;
    messages.forEach(function (message) {
        if (show) {
            message.classList.remove('d-none');
        } else {
            message.classList.add('d-none');
        }
    });
}

/**
 * Shows/Hides no stock message
 * checking if any item of miniCart is not available
 */
function manageNoStockMessage() {
    var cartMessages = document.querySelectorAll('.minicart-products-tabcontent .no-stock-alert, .cart-content .no-stock-alert');
    var cartNotAvaliableProducts = document.querySelectorAll('.minicart-products-tabcontent .not-available, .cart-content .not-available');

    var sflMessages = document.querySelectorAll('.sfl-content .no-stock-alert');
    var sflNotAvaliableProducts = document.querySelectorAll('.sfl-content .not-available');

    if (cartNotAvaliableProducts.length > 0) {
        changeNoStockMessageVisibility(cartMessages, true);
    } else {
        changeNoStockMessageVisibility(cartMessages, false);
    }

    if (sflNotAvaliableProducts.length > 0) {
        changeNoStockMessageVisibility(sflMessages, true);
    } else {
        changeNoStockMessageVisibility(sflMessages, false);
    }
}

module.exports = {
    calculateMinicartPosition: calculateMinicartPosition,
    manageNoStockMessage: manageNoStockMessage
};
