const throttle = require('lodash/throttle');
const nativefy = require('../../utilities/dom/nativefy');
const { getBooleanAttribute, setBooleanAttribute } = require('../../utilities/dom/booleanAttribute');
const { generateSingletonExports } = require('../../utilities/singleton');

const ATTRIBUTE_MONITORED = 'data-scroll-monitored';
const CLASSNAME = 'is-scrolling';
const STATE_ON = 'on';
const STATE_OFF = 'off';

let isDocumentMonitored = false;

/**
 * @typedef {object} ScrollMonitorEvent
 * @property {Element} target - Scroll parent (by default: document)
 * @property {function():boolean} isScrolling - Check if it is scrolling
 */

/**
 * Check if an element has its scroll already monitored
 * @param {Element|jQuery} scrollableElement Element to test the scroll
 * watching
 * @returns {boolean} true if it is monitored
 */
function isScrollAlreadyMonitored(scrollableElement) {
    if (scrollableElement === document) return isDocumentMonitored;
    const element = nativefy(scrollableElement);
    return getBooleanAttribute(element, ATTRIBUTE_MONITORED);
}

/**
 * Set value to the monitored attribute
 * @param {Element|jQuery} scrollableElement Element
 * @param {boolean} monitored true or false
 */
function setMonitored(scrollableElement, monitored) {
    const element = nativefy(scrollableElement);
    if (element !== document) {
        setBooleanAttribute(element, ATTRIBUTE_MONITORED, monitored);
    } else {
        isDocumentMonitored = monitored;
    }
}

/**
 * Creates a new scrollEndEventDispatcher. An object with this function:
 * - watch()
 *
 * Its goal is to wait a timeout and fire a scrollend pseudoevent
 * @param {Function} onEndFunction - scrollend handler
 * @returns {Object} scrollEndEventDispatcher
 */
function createScrollEndEventDispatcher(onEndFunction) {
    const SCROLLEND_EVENT_TIMEOUT = 500;
    const SCROLLEND_RESET_TIMEOUT = 200;

    let timeoutScrollStopId = null;

    /**
     * Cancels the execution of `onEndFunction` from a previous scheduling.
     * @returns {void}
     */
    function cancelEventDispatch() {
        clearTimeout(timeoutScrollStopId);
    }

    /**
     * Schedule the execution of `onEndFunction` after event timeout fires.
     * @returns {void}
     */
    function setupEventDispatch() {
        timeoutScrollStopId = setTimeout(() => {
            onEndFunction();
            timeoutScrollStopId = null;
        }, SCROLLEND_EVENT_TIMEOUT);
    }

    return {
        /**
         * If a new watcher is fired after SCROLLEND_RESET_TIMEOUT, then the event
         * dispatch will be reset.
         * Otherwise, the timeout will finish between 300 and 500 milliseconds after
         * the last watch command that setup the event dispatch.
         * In that manner it will avoid to flickers cause by an early scrollEnd
         * event.
         * @returns {void}
         */
        watch: throttle(() => {
            cancelEventDispatch();
            setupEventDispatch();
        }, SCROLLEND_RESET_TIMEOUT)
    };
}

/**
 * Factory function of an scroll monitor
 * @param {Element|jQuery} [scrollableElement=document] Element to watch the
 * scrolling
 * @returns {Object} Scroll monitor
 */
function createScrollMonitor(scrollableElement) {
    const listeners = {
        start: [],
        end: []
    };

    let element = nativefy(scrollableElement);
    if (!element) {
        // Default value
        // eslint-disable-next-line no-param-reassign
        element = document;
    }

    if (isScrollAlreadyMonitored(element)) {
        throw new Error('Element scroll is already being monitored');
    }

    let classlistTarget = element === document
        ? element.documentElement
        : element;
    let state = STATE_OFF;

    /**
     * Check if monitor is in scrolling state
     * @returns {boolean} - true if it is scrolling
     */
    function isScrolling() {
        return state === STATE_ON;
    }

    /**
     * Call to a handler
     * @param {function(ScrollMonitorEvent)} handler - handler
     */
    function triggerHandler(handler) {
        handler({
            target: element,
            isScrolling
        });
    }

    /**
     * Set the monitor in scrolling state
     */
    function on() {
        state = STATE_ON;
        classlistTarget.classList.add(CLASSNAME);
        listeners.start.forEach(triggerHandler);
    }

    /**
     * Set the monitor out of scrolling state
     */
    function off() {
        state = STATE_OFF;
        classlistTarget.classList.remove(CLASSNAME);
        listeners.end.forEach(triggerHandler);
    }

    /**
     * Scrollend pseudoevent handler function
     */
    function handleScrollEnd() {
        off();
    }

    const scrollEndEventDispatcher = createScrollEndEventDispatcher(handleScrollEnd);

    /**
     * Scroll event handler function
     * @return {void}
     */
    function handleScroll() {
        if (!isScrolling()) on();
        scrollEndEventDispatcher.watch();
    }

    /**
     * Destroy current monitor
     */
    function destroy() {
        element.removeEventListener('scroll', handleScroll);
        setMonitored(element, false);
        classlistTarget = null;
    }

    /**
     * Add a new handler for the scroll start
     * @param {function(ScrollMonitorEvent)} handler - handler to add
     * @return {void}
     */
    function onScrollEnd(handler) {
        if (!listeners.end.includes(handler)) {
            listeners.end.push(handler);
        }
    }

    /**
     * Add a new handler for the scroll ending
     * @param {function(ScrollMonitorEvent)} handler - handler to add
     * @return {void}
     */
    function onScrollStart(handler) {
        if (!listeners.start.includes(handler)) {
            listeners.start.push(handler);
        }
    }

    element.addEventListener('scroll', handleScroll, { capture: false, passive: true });
    setMonitored(element, true);

    return {
        destroy,
        onScrollStart,
        onScrollEnd,
        isScrolling
    };
}

let mainMonitor = null;

module.exports = generateSingletonExports('scroll',
    {
        createScrollMonitor,
        init: function init() {
            if (mainMonitor) return;
            mainMonitor = createScrollMonitor(document);
        },
        isScrollAlreadyMonitored,
        isScrolling: function isScrolling() {
            if (!mainMonitor) throw new Error('Not active monitoring');
            return mainMonitor.isScrolling();
        },
        /**
         * Add a new handler for the main monitor scroll ending
         * @param {function(ScrollMonitorEvent)} handler - handler to add
         * @return {void}
         */
        onScrollEnd: function onScrollEnd(handler) {
            if (!mainMonitor) throw new Error('Not active monitoring');
            return mainMonitor.onScrollEnd(handler);
        },
        /**
         * Add a new handler for the main monitor scroll starting
         * @param {function(ScrollMonitorEvent)} handler - handler to add
         * @return {void}
         */
        onScrollStart: function onScrollStart(handler) {
            if (!mainMonitor) throw new Error('Not active monitoring');
            return mainMonitor.onScrollStart(handler);
        }
    });
