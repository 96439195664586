/* eslint-disable valid-jsdoc */
/**
 * Returns PIPELINES_URLS
 * @returns {import('../../../../config/pipelinesUrls').PipelinesUrls} PIPELINES_URLS
 */
function getPipelinesUrls() {
    const { PIPELINES_URLS = false } = window;
    if (!PIPELINES_URLS) {
        throw new Error('PIPELINES_URLS unavailable');
    }
    return PIPELINES_URLS;
}

module.exports = getPipelinesUrls;
