/**
 * Snippet taken from
 * https://github.com/lukeed/uid/blob/master/src/index.js
 * @author lukeed
 * @license MIT
 */

var IDX = 256; var HEX = []; var SIZE = 256; var
    BUFFER;
// eslint-disable-next-line no-plusplus
while (IDX--) HEX[IDX] = (IDX + 256).toString(16).substring(1);

/**
 * Fast generation of an UID of desired length.
 * While faster than other methods, its result is not cryptographically secure.
 * Suitable for browser and server
 * @param {number} [len=11] - leght of the UID
 * @returns {string} - generated UID
 */
function uid(len) {
    var i = 0; var
        tmp = (len || 11);
    if (!BUFFER || ((IDX + tmp) > SIZE * 2)) {
        for (BUFFER = '', IDX = 0; i < SIZE; i++) {
            // eslint-disable-next-line no-bitwise
            BUFFER += HEX[Math.random() * 256 | 0];
        }
    }
    // eslint-disable-next-line no-plusplus
    return BUFFER.substring(IDX, IDX++ + tmp);
}

module.exports = uid;
