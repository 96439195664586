const debounce = require('lodash/debounce');
const getAnalyticsConfig = require('../../../../common/getAnalyticsConfig');
const generalStore = require('../../stores/generalStore');

const analyticsConfig = getAnalyticsConfig();

/**
 * Set time in milliseconds of the last update.
 * @async
 * @returns {Promise<number>} milliseconds of the last update
 */
function setLastUpdate() {
    return generalStore.setItem(
        analyticsConfig.localforage.stores.general.items.lastUpdate,
        Date.now()
    );
}

/**
 * Set last update saving writtings (blocking ops) when detecting a sequence of
 * various updates.
 */
const debouncedSetLastUpdate = debounce(setLastUpdate, 2000);

/**
 * Record the time of the item just stored
 * @param {*} justStoredItem Item resultant of a setItem function
 * @returns {*} The same justStoredItem
 */
function trackUpdate(justStoredItem) {
    debouncedSetLastUpdate();
    return justStoredItem;
}

module.exports = {
    trackUpdate
};
