const BASE_CONFIG = window.BASE_CONFIG;
const deviceUtilities = require('../utilities/deviceUtilities');
const pageUtilities = require('../utilities/pageUtilities');
const onDOMContentLoaded = require('../utilities/dom/onDOMContentLoaded');

/**
 * Calculate the heights of the header and the main section
 * to give styles to the Product Detail page in mobile
 *
 * @param {int} headerHeight Header outerheight
 * @param {int} primarySectionHeight Details first section outerheight
 */
function applyMobileStyles(headerHeight, primarySectionHeight) {
    var sliderHeight = window.innerHeight - headerHeight;
    var sliderImgHeight = sliderHeight - primarySectionHeight;

    if (deviceUtilities.isMobile() && (BASE_CONFIG.productImgProportion * sliderImgHeight < window.innerWidth)) {
        var productNameHeight = Math.round($('.product-info__name').outerHeight(true));
        var primarySectionPadding = parseInt($('#product-info-primary-section').css('padding-top'), 10);
        sliderImgHeight += productNameHeight + primarySectionPadding;
    }

    const backButton = document.querySelector('.product-detail__back-link');
    if (backButton && !pageUtilities.isBundleDetailPage()) {
        var pageHeaderHeight = document.querySelector('.page-header').offsetHeight;
        backButton.style.top = pageHeaderHeight + 'px';
    }

    if (pageUtilities.isBundleDetailPage()) {
        $('.product-images-container').css('height', sliderHeight - primarySectionHeight + 'px');
        $('.product-images-slider').css('height', sliderHeight - primarySectionHeight + 'px');
        $('.product-images-slider img').css('height', sliderHeight - primarySectionHeight + 'px');
        $('.product-images-slider .pdp-video').css('height', sliderHeight - primarySectionHeight + 'px');
    } else {
        $('.product-info-pdp').css('margin-top', '-' + primarySectionHeight + 'px');
        $('.product-detail__color-swatches').css('max-height', primarySectionHeight + 'px');
        $('.product-detail__color-swatches').css('margin-top', '-' + primarySectionHeight + 'px');
        $('.product-images-slider').css('height', sliderHeight + 'px');
        $('.product-images-slider img').css('height', sliderImgHeight + 'px');
        $('.product-images-slider .pdp-video').css('height', sliderImgHeight + 'px');
    }
}

/**
 * Calculate the heights of the header to give styles to the Product Detail page in desktop
 * @param {int} headerHeight Header outerheight
 */
function applyDesktopStyles(headerHeight) {
    $('.product-info__wrapper').css('top', headerHeight + 'px');
    $('.product-info__sizes-info').css('top', '100%');
}

/**
 * Clean styles generated in function "applyMobileStyles"
 */
function cleanMobileStyles() {
    $('.product-images-slider').removeAttr('style');
    $('.product-images-slider img').removeAttr('style');
    $('.product-images-slider .pdp-video').removeAttr('style');
    $('.product-info-pdp').removeAttr('style');
    $('.product-images-container').removeAttr('style');

    const backButton = document.querySelector('.product-detail__back-link');
    if (backButton && !pageUtilities.isBundleDetailPage()) {
        backButton.style.top = '';
    }
}

/**
 * Check matchMedia and stylesApplied to execute the necessary function
 *
 * @param {MediaQueryList} e Media Query List for match media
 */
function checkStyles(e) {
    if (e.matches) {
        applyMobileStyles(
            Math.round($('.page-header .header-banner').outerHeight()),
            Math.round($('#product-info-primary-section').innerHeight())
        );
    } else {
        cleanMobileStyles();
        applyDesktopStyles(Math.round($('.page-header').outerHeight()));
    }
}

const header = document.getElementsByClassName('page-header')[0];
const headerLogo = document.querySelector('.logo-home');
const pdpTransparentClass = 'page-header__pdp--transparent';
const pdpShowLogoClass = 'logo-home--show';

/**
 * Shows logo
 */
function showLogo() {
    headerLogo?.classList.add(pdpShowLogoClass);
}

/**
 * Hides logo
 */
function hideLogo() {
    headerLogo?.classList.remove(pdpShowLogoClass);
}

/**
 * Removes transparent background and shows logo
 */
function goSolid() {
    header.classList.remove(pdpTransparentClass);
    showLogo();
}

/**
 * Adds transparent background and hides logo
 */
function goTransparent() {
    header.classList.add(pdpTransparentClass);
    hideLogo();
}

/**
 * Handles logo visibility based on product image slider:
 *  - shows if there's only one slide (ex. bundles)
 *  - shows if active slide is the first one
 *  - hides otherwise
 */
function handleLogoVisibility() {
    var slides = document.querySelectorAll('.product-images-slider .swiper-slide');
    var activeSlide = document.querySelector('.product-images-slider .swiper-slide-active');
    var activeSlidePosition = activeSlide ? activeSlide.getAttribute('data-position') : null;

    if (slides?.length === 1
        || (activeSlidePosition && parseInt(activeSlidePosition, 10) === 0)) {
        showLogo();
    } else {
        hideLogo();
    }
}

module.exports = {
    methods: {
        checkStyles: checkStyles,
        handleLogoVisibility: handleLogoVisibility
    },
    function() {
        onDOMContentLoaded(() => {
            checkStyles(deviceUtilities.mqlMaxWidth768);
            deviceUtilities.mqlMaxWidth768.addListener(checkStyles);

            $('.header-banner .close').on('click', function () {
                const headerHeight = Math.round($('.header-navigation').outerHeight());
                if (deviceUtilities.isSmallerThan768()) {
                    applyMobileStyles(
                        Math.round($('.page-header .header-banner').outerHeight()),
                        Math.round($('#product-info-primary-section').innerHeight())
                    );
                } else {
                    $('.product-info__wrapper').css('top', headerHeight + 'px');
                }
            });

            if (header) {
                window.addEventListener('scroll', function () {
                    if (deviceUtilities.isDesktop()) {
                        return;
                    }

                    const isScrollingUp = this.oldScroll >= this.scrollY;
                    if (this.scrollY === 0) {
                        handleLogoVisibility();
                    } else if (isScrollingUp) {
                        goTransparent();
                    } else {
                        goSolid();
                    }
                    this.oldScroll = this.scrollY;
                });
            }

            header.addEventListener('click', ()=> {
                if (deviceUtilities.isSmallerThan768()) {
                    var menuIsOpen = document.querySelector('.navbar-header__menu').classList.contains('is-opened');
                    var searchIsOpen = document.querySelector('.site-search').classList.contains('show');
                    if (menuIsOpen || searchIsOpen) {
                        goSolid();
                    } else {
                        goTransparent();
                    }
                }
            });
        });
    }
};
