var history = {};

/**
 * Build an unique key for the impression of a product
 * @param {Object} impressionFieldObject an ImpressionFieldObject for Google
 * @returns {string} safe unique key
 * @throws {Error} impressionFieldObject must have list and content_ids
 */
function uniqueKey(impressionFieldObject) {
    let { list, content_ids: contentIds } = impressionFieldObject;
    var key = `${list}#${contentIds}`;
    if (!list || !contentIds) {
        throw new Error(`There is no enough data to generate a replicable unique key. (Key: ${key})`);
    }
    return key;
}

/**
 * Check if an impression has been already sent.
 * @param {ImpressionFieldObject} impressionFieldObject - target impression
 * @returns {boolean} true if it has been sent
 */
function isSent(impressionFieldObject) {
    return uniqueKey(impressionFieldObject) in history;
}

const isNewImpression = (impressionFieldObject) => !isSent(impressionFieldObject);

/**
 * Filter an array of impressions to get only the new ones (not sent yet)
 * @param {ImpressionFieldObject[]} impressionFieldObjects - list of impressions
 * @returns {ImpressionFieldObject[]} - filtered array
 */
function filterAlreadySentImpressions(impressionFieldObjects) {
    return impressionFieldObjects.filter(isNewImpression);
}

/**
 * Save the impressions to history log as sent
 * @param {Object[]} impressionFieldObjects - list of impressions in datalayer
 * format
 * @returns {void}
 */
function saveSentImpressions(impressionFieldObjects) {
    impressionFieldObjects.forEach((item) => {
        history[uniqueKey(item)] = true;
    });
}

module.exports = {
    filterAlreadySentImpressions,
    saveSentImpressions
};
