var base = require('base/login/login');
var formValidation = require('base/components/formValidation');
var pageUtilities = require('../utilities/pageUtilities');

/**
 * Shows DuplicatedUserModal buttons depending on which profiles exist.
 * @param {Array} existingProfiles - Existing profiles (SFCC, Google, Facebook)
 * @param {string} type - type of the account(s) found (phone/email)
 */
function displayExistingProfilesButtons(existingProfiles, type) {
    document.querySelectorAll('#duplicatedUserModal .modal-body').forEach(element => {
        element.classList.add('d-none');
        if (element.dataset.type === type) {
            element.classList.remove('d-none');
        }
    });

    document.querySelectorAll('#duplicatedUserModal .password-reset').forEach(element => {
        element.classList.add('d-none');
        if (element.dataset.type === type) {
            element.classList.remove('d-none');
        }
    });

    document.querySelectorAll('.duplicated-user-btn').forEach((element) => {
        element.classList.add('d-none');
    });

    existingProfiles.forEach(function (ID) {
        document.querySelector('#duplicatedUserModal .profile-' + ID.toLowerCase()).classList.remove('d-none');
    });
}

/**
 * Checks if any required field are empty and
 * Toggles submit button status: enabled/disabled
 * @param {Object} loginForm Login form
 */
function toogleSubmitButtonStatus(loginForm) {
    var disable = false;
    var submitBtn = loginForm.find('[type="submit"]');
    var requiredFields = loginForm.find('[required]');

    requiredFields.each(function () {
        if (!$(this).val()) {
            disable = true;
        }
    });

    submitBtn.prop('disabled', disable);
}

module.exports = {
    selectPrefixBySite: function () {
        const getBaseConfig = require('../common/getBaseConfig');
        var BASE_CONFIG = getBaseConfig();
        var country = BASE_CONFIG.currentSiteCountryId;
        var updateSelect = require('../components/fakeSelect').methods.updateSelect;

        if (country === BASE_CONFIG.siteId.EU) {
            country = BASE_CONFIG.euDefaultCountryCode;
        }
        var countryPrefixValue = document.querySelector(
            '.login-phone-group__prefix option[id="' + country + '"]'
        )?.value;
        updateSelect(document.querySelector('.login-phone-group__prefix'), countryPrefixValue);
        updateSelect(document.querySelector('.register-phone-group__prefix'), countryPrefixValue);
        updateSelect(document.querySelector('.reset-password-phone-group__prefix'), countryPrefixValue);
    },
    login: function () {
        var loginForms = $('form.login');

        if (loginForms.length > 0) {
            loginForms.each((index, loginForm) => {
                toogleSubmitButtonStatus($(loginForm));
            });
        }

        $('form.login .form-control').bind('blur change keyup input', function () {
            var form = $(this).closest('form.login');
            if (form.length > 0) {
                toogleSubmitButtonStatus(form);
            }
        });

        loginForms.submit(function (e) {
            var form = $(this);
            e.preventDefault();
            var url = form.attr('action');
            form.spinner().start();
            $('form.login').trigger('login:submit', e);
            $.ajax({
                url: url,
                type: 'post',
                dataType: 'json',
                data: form.serialize(),
                success: function (data) {
                    form.spinner().stop();
                    if (!data.success) {
                        formValidation(form, data);
                        $('form.login').trigger('login:error', data);
                    } else {
                        $('form.login').trigger('login:success', data);

                        window.dataLayer = window.dataLayer || [];

                        window.dataLayer.push({
                            event: 'NoECC',
                            eventCategory: 'Inicio Sesion',
                            user_id: data.customerId
                        });

                        if (data.mustRedirectToPreviousUrl && !pageUtilities.isExternalReferrer()) {
                            window.history.back();
                        } else {
                            window.location.href = data.redirectUrl;
                        }
                    }
                },
                error: function (data) {
                    if (data.responseJSON.redirectUrl) {
                        window.location.href = data.responseJSON.redirectUrl;
                    } else {
                        $('form.login').trigger('login:error', data);
                        form.spinner().stop();
                    }
                }
            });
            return false;
        });
    },

    register: function () {
        $('form.registration').submit(function (e) {
            var form = $(this);
            e.preventDefault();
            var url = form.attr('action');
            form.spinner().start();
            $('.alert').remove();
            $('form.registration').trigger('login:register', e);
            $.ajax({
                url: url,
                type: 'post',
                dataType: 'json',
                data: form.serialize(),
                success: function (data) {
                    if (!data.success) {
                        formValidation(form, data);
                        if (data.existingProfiles) {
                            displayExistingProfilesButtons(data.existingProfiles, data.type);
                            $('#duplicatedUserModal').modal('show');
                        }
                    } else {
                        window.dataLayer = window.dataLayer || [];

                        window.dataLayer.push({
                            event: 'NoECC',
                            eventCategory: 'Creacion Cuenta',
                            user_id: data.customerId || ''
                        });

                        const formControl = require('../components/formControl');
                        formControl.resetForm(form);
                        window.scrollTo({ top: 0 });

                        var modalTemplate = $('#accountActivationLinkSentModal');
                        if (data.ad_createdWithFakeEmail === true) {
                            modalTemplate.addClass('sms-sent');
                        } else {
                            modalTemplate.removeClass('sms-sent');
                        }
                        modalTemplate.modal('show');
                    }
                },
                error: function (err) {
                    if (err.responseJSON.redirectUrl) {
                        window.location.href = err.responseJSON.redirectUrl;
                    } else {
                        formValidation(form, err.responseJSON);
                    }
                },
                complete: function () {
                    form.spinner().stop();
                }
            });
            return false;
        });
    },

    resetPassword: function () {
        $('.reset-password-form').submit(function (e) {
            e.preventDefault();
            var form = $(this);
            var resetModal = form.closest('.reset-password-modal');
            var url = form.attr('action');
            form.spinner().start();
            $('.reset-password-form').trigger('login:register', e);
            $.ajax({
                url: url,
                type: 'post',
                dataType: 'json',
                data: form.serialize(),
                success: function (data) {
                    form.spinner().stop();
                    if (!data.success) {
                        formValidation(form, data);
                    } else {
                        $('.alert.alert-danger').remove();
                        resetModal.find('.request-password-title').text(data.receivedMsgHeading);
                        resetModal.find('.reset-password-request').hide();
                        resetModal.find('.reset-password-success').show();
                        resetModal.find('.send-email-btn').hide();
                        resetModal.find('.create-account-btn').show();
                    }
                },
                error: function () {
                    form.spinner().stop();
                }
            });
            return false;
        });
    },

    oauthLogin: function () {
        $('.js-oauth-button').click(function (e) {
            e.preventDefault();
            let url = $(this).attr('href');
            if (!pageUtilities.isExternalReferrer()) {
                const urlUtilities = require('../utilities/urlUtilities');
                url = urlUtilities.updateParamInQueryString(url, 'previousUrl', document.referrer);
            }
            window.location = url;
        });
    },

    clearResetForm: base.clearResetForm,
    initUserExistingError: function () {
        var urlUtils = require('../utilities/urlUtilities');
        var existingProfiles = urlUtils.getUrlParameter('existingProfiles');
        if (existingProfiles) {
            displayExistingProfilesButtons(existingProfiles.split(','));
            $('#duplicatedUserModal').modal('show');

            var url = window.location.origin + window.location.pathname;
            window.history.replaceState(null, null, url);
        }
    },
    duplicatedModalResetPassword: function () {
        $('#duplicatedUserModal .password-reset').on('click', function () {
            $('#duplicatedUserModal').modal('hide');
        });
    },
    accountActivationModalLogin: function () {
        $('#accountActivationLinkSentModal .go-to-login-btn').on('click', function () {
            $('#login-tab').trigger('click');
            $('#accountActivationLinkSentModal').modal('hide');
        });
    },
    methods: {
        displayExistingProfilesButtons: displayExistingProfilesButtons
    }
};
