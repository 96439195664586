/* eslint-disable valid-jsdoc */
/**
 * Returns BASE_CONFIG
 * @returns {import('../../../../config/baseConfig').BaseConfig} - base config
 */
function getBaseConfig() {
    const { BASE_CONFIG = false } = window;
    if (!BASE_CONFIG) {
        throw new Error('BASE_CONFIG unavailable');
    }
    return BASE_CONFIG;
}

module.exports = getBaseConfig;
