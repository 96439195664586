module.exports = {
    cookieExists: function (name) {
        return document.cookie.indexOf(name) >= 0;
    },

    deleteCookie: function (name) {
        document.cookie = name + '=; Path=/; Expires=Thu, 01 Jan 1970 00:00:01 GMT;';
    },

    getCookieValue: function (name) {
        const value = document.cookie.match('(^|;)\\s*' + name + '\\s*=\\s*([^;]+)');
        return value ? value.pop() : '';
    },

    /** Set a cookie with the name, value & expiring time parameters
     * @param  {string} name - Cookie name
     * @param  {string} value - Cookie value
     * @param  {string} [expires] - Cookie date in GMT string format
     */
    setCookie: function (name, value, expires) {
        document.cookie = expires
            ? `${name}=${value}; expires=${expires};path=/`
            : `${name}=${value}; path=/`;
    }
};
