const whenNodeIsAdded = require('../utilities/dom/whenNodeIsAdded');

const videoObserver = new IntersectionObserver((entries) => {
    entries.forEach(entry => {
        if (entry.intersectionRatio > 0) {
            entry.target.play();
            videoObserver.unobserve(entry.target);
        }
    });
}, { threshold: 0 });

/**
 * Play product tile videos when they appear on viewport
 * @param {HTMLElement} productTile - Product tile HTMLElement
 */
function observeAndPlayProductTileVideo(productTile) {
    const tileVideos = productTile.getElementsByClassName('tile-image__video');
    for (let i = 0; i < tileVideos.length; i++) {
        const video = tileVideos[i];
        if (video.getAttribute('data-autoplay')) {
            videoObserver.observe(video);
        }
    }
}

module.exports = {
    init: function () {
        whenNodeIsAdded('.product-tile', observeAndPlayProductTileVideo);
    }
};
