'use strict';

/** @type {SizeChartConfig} */
module.exports = {
    genderByPID: {
        1: 'hombre',
        2: 'mujer'
    },
    contentAssets: {
        1: 'size-chart--man',
        2: 'size-chart--woman'
    }
};
