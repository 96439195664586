const { isEmpty } = require('../../../utilities/type');
const { getEcommerceObjectForImpressions } = require('../impressions');
const getAnalyticsConfig = require('../../../common/getAnalyticsConfig');
const { createProductFieldObject } = require('../models/productFieldObject');
const { getListAndPositionForProductDetailPageview } = require('../ecommerceListTracking');

const analyticsConfig = getAnalyticsConfig();

/**
 * Generate the ecommerce object that suits to detail action
 * @async
 * @param {Object} productFieldObjectDto - DTO of ProductFieldObject
 * @param {string} currencyCode - currency code
 * @returns {Object} - Ecommerce object for detail
 */
async function getEcommerceObjectForDetail(productFieldObjectDto, currencyCode) {
    let ecommerce;

    if (isEmpty(productFieldObjectDto)) return ecommerce;

    const productFieldObject = await createProductFieldObject(productFieldObjectDto)
        .updateListAndPosition(
            getListAndPositionForProductDetailPageview()
        ).then(p => p.toDatalayer());

    ecommerce = {
        currencyCode: currencyCode,
        detail: {
            actionField: {
                list: productFieldObject.list
            },
            products: [
                productFieldObject
            ]
        }
    };

    return ecommerce;
}

/**
 * Create the pageview event for datalayer.
 * Prepared for detail and impressions.
 * @async
 * @param {Object} user - user for datalayer
 * @param {Object} pdpProductFieldObject - DTO of ProductFieldObject of the main
 * product of PDP
 * @param {string} currencyCode - Current currency code
 * @returns {Promise.<DataLayerEvent>} - pageview event
 */
async function createEvent(user, pdpProductFieldObject, currencyCode) {
    const [
        ecommerceDetail,
        ecommerceImpressions
    ] = await Promise.all([
        getEcommerceObjectForDetail(
            pdpProductFieldObject,
            currencyCode
        ),
        getEcommerceObjectForImpressions()
    ]);

    let ecommerce = Object.assign({}, ecommerceDetail, ecommerceImpressions);
    if (isEmpty(ecommerce)) {
        ecommerce = undefined;
    }

    return {
        event: analyticsConfig.gtm.events.pageView,
        user: user,
        ecommerce: ecommerce
    };
}

module.exports = createEvent;
