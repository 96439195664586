const onDOMContentLoaded = require('../utilities/dom/onDOMContentLoaded');
const pageUtilities = require('../utilities/pageUtilities');

const FOOTER_MESSAGE_SELECTOR = '.footer__discount-message';
const SEARCH_DISCOUNTS_SELECTOR = '.search-results .strike-through.old';
const PDP_DISCOUNTS_SELECTOR = '.product-detail__complete-the-look .strike-through.old, .product-detail__recommendations .strike-through.old';
const PD_DISCOUNTS_SELECTOR = '#maincontent .strike-through.old';

/**
 * Hanldes Search page discount message visibility
 *  Created as a specific function as it's needed outside
 */
function handleSearchDiscountMessages() {
    var footerMessage = document.querySelector(FOOTER_MESSAGE_SELECTOR);
    var searchThirdPrices = document.querySelectorAll(SEARCH_DISCOUNTS_SELECTOR);
    if (searchThirdPrices && searchThirdPrices.length > 0 && footerMessage) {
        footerMessage.classList.remove('d-none');
    }
}

/**
 * Hanldes discount message visibility
 * if there are discounted products
 * @param {Element[]} discountedPrices - searched prices with third price discount
 */
function handleDiscountMessage(discountedPrices) {
    var footerMessage = document.querySelector(FOOTER_MESSAGE_SELECTOR);
    if (discountedPrices && discountedPrices.length > 0 && footerMessage) {
        footerMessage.classList.remove('d-none');
    }
}

/**
 * Set event when dom content is loadesd
 */
function initDomLoadedEvents() {
    onDOMContentLoaded(() => {
        var discountedPrices;

        if (pageUtilities.isProductDetailPage()) {
            // PDP, Bundle Details Page
            discountedPrices = document.querySelectorAll(PDP_DISCOUNTS_SELECTOR);
        } else if (pageUtilities.isSearchPage()) {
            // Category, search resutls...
            handleSearchDiscountMessages();
            return;
        } else {
            // Home, PDPage
            discountedPrices = document.querySelectorAll(PD_DISCOUNTS_SELECTOR);
        }
        handleDiscountMessage(discountedPrices);
    });
}

module.exports = {
    init: function () {
        initDomLoadedEvents();
    },
    methods: {
        handleSearchDiscountMessages: handleSearchDiscountMessages

    }
};
