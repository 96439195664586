/* Functional programming utilities */

/**
 * Creates a function which is a composition of the passed functions. Each
 * function consumes the return value of the function that follows.
 * Composing functions `f()`, `g()`, and `h()` would produce the result
 * of `f(g(h()))`.
 *
 * @name compose
 * @param  {...Function} fns Functions to compose
 * @returns {Function} Function that is the composed `functions`
 * @example
 * const add1 = (n) => n + 1;
 * const mul2 = (n) => n * 2;
 *
 * const add1AndMul2 = compose(mul2, add1);
 * add1AndMul2(4) // = 10
 *
 * const mul2AndAdd1 = compose(add1, mul2);
 * mul2AndAdd1(4) // = 9
 */
const compose = (...fns) => x => fns.reduceRight((v, f) => f(v), x);

/**
 * Creates a function which is a composition of the passed asynchronous
 * functions. Each function consumes the value of the promise that returns the
 * function that follows. Composing functions `f()`, `g()`, and `h()` would
 * produce the result of `h.then(g).then(f)`.
 *
 * @name composeAsync
 * @param  {...Function} fns Functions to compose
 * @returns {Function} Function that is the composed `functions`
 * @example
 * const add1 = (n) => n + 1;
 * const mul2 = (n) => n * 2;
 *
 * const add1AndMul2 = compose(mul2, add1);
 * add1AndMul2(4).then(c => c) // c = 10
 */
const composeAsync = (...fns) => x => fns.reduceRight((v, f) => v.then(f), Promise.resolve(x));

/**
 * Creates a function which a composition of the passed functions, but in a
 * reverse order than `compose()`. The params can be read as a sequence of
 * events.
 *
 * @name pipe
 * @param  {...Function} fns Functions as a sequence
 * @returns {Function} Function that is the composed `functions`
 */
const pipe = (...fns) => (x) => fns.reduce((v, f) => f(v), x);

/**
 * Creates a function which a composition of the passed asynchronous functions,
 * but in a reverse order than `composeAsync()`.
 *
 * @name pipeAsync
 * @param  {...Function} fns Functions as a sequence
 * @returns {Function} Function that is the composed `functions`
 */
const pipeAsync = (...fns) => (x) => fns.reduce((v, f) => v.then(f), Promise.resolve(x));

module.exports = {
    compose,
    composeAsync,
    pipe,
    pipeAsync
};
