var cartHelpers = require('./cartHelpers');

const pageUtilities = require('../utilities/pageUtilities');
const minicartUtils = require('../components/minicartUtils');
const { processDataLayerCartActionOnProduct } = require('../utilities/datalayerHelpers');
const redemptionsAndCoupons = require('../components/redemptionsAndCoupons').methods;

/**
 * Update the status of the button
 * @param {string} newStatus - The new status of the button
 */
function saveForLaterBtnUpdate(newStatus) {
    var button = $('.saveForLaterShow-btn');

    if (button.length && newStatus === 'enable') {
        button.removeClass('disabled');

        if (button.data('url')) {
            button.attr('href', button.data('url'));
        }
    } else if (button.length && newStatus === 'disable') {
        if (!button.hasClass('disabled')) {
            button.addClass('disabled');
        }
        if (button.data('url')) {
            button.attr('href', '#');
        }
    }

    var minicartButton = $('.view-reserved-btn-minicart');
    if (minicartButton.length) {
        if (newStatus === 'enable') {
            minicartButton.removeClass('disabled');
        } else if (newStatus === 'disable' && !minicartButton.hasClass('disabled')) {
            minicartButton.addClass('disabled');
        }
    }
}

/**
 * Update the Save for later count on minicart input
 * @param {int|null} count - The number of items in save for later
 */
function minicartSflCountUpdate(count) {
    var countElem = $('.saveForLater-quantity');
    var minicartTab = $('.save-for-later-tab');
    if (count !== null && countElem) {
        countElem.val(count);

        if (minicartTab.length && count === 0) {
            minicartTab.addClass('disabled');
        } else if (minicartTab && count > 0) {
            minicartTab.removeClass('disabled');
        }
    }
}

/**
 * Updates the minicart tab
 */
function updateMinicartSflTab() {
    var url = $('.sfl-products-url').val();

    if (!$('.saveforlater-tabcontent')) {
        return;
    }

    $.ajax({
        url: url,
        type: 'get',
        success: function (data) {
            if (data) {
                $('.saveforlater-tabcontent .product-summary').html(data);
                minicartUtils.manageNoStockMessage();
            }
            saveForLaterBtnUpdate('enable');
        }
    });
}

module.exports = function () {
    if (pageUtilities.isSaveForLaterPage()) {
        minicartUtils.manageNoStockMessage();
    }

    $('body').on('click', '.saveforlater-btn', function (event) {
        event.preventDefault();

        var UUID = $(this).data('uuid');
        var pid = $(this).data('pid');
        var url = $(this).data('action');
        var qty = $(this).data('quantity');

        if ($('.quantity.custom-select').length) {
            var selector = $(this).closest('.product-card__content').find('.quantity.custom-select');
            if (selector.length) {
                qty = selector.val();
            }
        }

        if (!pid || !qty || !UUID || !url) {
            return;
        }

        var formData = {
            UUID: UUID,
            pid: pid,
            qty: qty
        };

        $(this).closest('.product-card')?.spinner().start();

        $.ajax({
            url,
            type: 'post',
            dataType: 'json',
            data: formData,
            success(data) {
                if (data.success) {
                    // Remove the line item with UUID
                    $('.uuid-' + data.addedUUID).remove();

                    // Cart update
                    cartHelpers.methods.generalCartUpdate(data.basket);

                    if (data.saveForLater && data.saveForLater.products) {
                        // Change the count of the reserve button
                        var count = data.saveForLater.products.length;
                        $('.saveForLaterShow-title__count').text(' (' + count + ')');

                        // Update the hidden minicart count
                        minicartSflCountUpdate(count);
                    }

                    // Enable the save for later button
                    saveForLaterBtnUpdate('enable');

                    // Update the content of Save for Later Tab
                    updateMinicartSflTab();

                    redemptionsAndCoupons.hideRedemptionAppliedMessage();

                    cartHelpers.methods.createSuccessNotification(data.message);

                    processDataLayerCartActionOnProduct(data.dataLayerCartActionOnProduct);
                }
            },
            error(err) {
                cartHelpers.methods.createErrorNotification(err.responseJSON.errorMessage);
            }
        });
    });

    $('body').on('click', '.saveForLater-remove-product', function (event) {
        event.preventDefault();

        var pid = $(this).data('pid');
        var url = $(this).data('action');
        var uuid = $(this).data('uuid');

        if (!url || !pid || !uuid) {
            return;
        }

        var formData = {
            pid: pid
        };

        $(this).closest('.product-card')?.spinner().start();

        $.ajax({
            url: url,
            type: 'post',
            dataType: 'json',
            data: formData,
            success(data) {
                // Remove the lineitem with UUID
                $('.uuid-' + uuid).remove();

                // Change the count of the reserve button
                if (data.titleCount !== null) {
                    $('.saveForLaterShow-title__count').text(' (' + data.titleCount + ')');
                }

                // If there is not any product on save for later cart
                if ($('.cart-content__product-container').length
                    && $('.cart-content__product-container').find('.card').length === 0
                    && $('.save-for-later-empty-msg').length) {
                    $('.cart-content__product-container').html($('.save-for-later-empty-msg').val());
                    $('body').trigger('cart:emptySfl');
                    saveForLaterBtnUpdate('disable');
                }

                // If there is not any product on save for later minicart
                if ($('.saveforlater-tabcontent').length && $('.save-for-later-tabContent').find('.card').length === 0) {
                    $('body').trigger('cart:emptySfl');
                    saveForLaterBtnUpdate('disable');
                }

                // Update the count on SFL button
                minicartSflCountUpdate(data.saveForLater.products.length);
                minicartUtils.manageNoStockMessage();
            },
            error(err) {
                cartHelpers.methods.createErrorNotification(err.responseJSON.errorMessage);
            }
        });
    });

    $('body').on('click', '.saveForLater-addToCart-btn', function (event) {
        event.preventDefault();

        if ($(this).hasClass('disabled')) {
            return;
        }

        var uuid = $(this).data('uuid');
        var pid = $(this).data('pid');
        var qty = $(this).data('qty');
        var addToCartUrl = $(this).data('action');
        var errorMsg = $('.add-to-cart-error-msg').val();
        var isCartPage = pageUtilities.isCartPage();
        var isEmptyCart = $('.full-cart').length <= 0;

        if (!pid || !addToCartUrl) {
            cartHelpers.methods.createErrorNotification(errorMsg);
            return;
        }

        var form = {
            pid: pid,
            quantity: qty || 1,
            UUID: uuid,
            isCartPage: isCartPage,
            isEmptyCart: isEmptyCart
        };

        // Add product to cart
        $.ajax({
            url: addToCartUrl,
            type: 'post',
            dataType: 'json',
            data: form,
            success(data) {
                // eslint-disable-next-line no-param-reassign
                data.addedProductID = form.pid;

                // Remove the line item with UUID
                if (data.addedUUID) {
                    $('.uuid-' + data.addedUUID).remove();
                }

                // Update the count in save for later button
                if (data.titleCount !== null) {
                    $('.saveForLaterShow-title__count').text(' (' + data.titleCount + ')');
                    $('.minicart').trigger('count:update', data.cartItems);
                }

                // Actions if SFL is empty
                if ($('.sflProductContainer').find('.card').length === 0
                    && $('.save-for-later-empty-msg').length) {
                    $('.sflProductContainer').append($('.save-for-later-empty-msg').val());
                    $('body').trigger('cart:emptySfl');
                    $('.container.cart').removeClass('minicart--no-footer');
                    $('.checkout-btn').removeClass('disabled');
                }

                cartHelpers.methods.updateMinicartProductsTab();
                cartHelpers.methods.generalCartUpdate(data.basket);

                if (isCartPage) {
                    $('body').trigger('cart:UpdateCartPage', data);
                }

                $('body').trigger('product:afterAddToCart', data);
                processDataLayerCartActionOnProduct(data.dataLayerCartActionOnProduct, $(this));
                minicartSflCountUpdate(data.saveForLater.products.length);

                if (data.message) {
                    cartHelpers.methods.createSuccessNotification(data.message);
                }

                redemptionsAndCoupons.hideRedemptionAppliedMessage();
            },
            error() {
                cartHelpers.methods.createErrorNotification(errorMsg);
            }
        });
    });
};
