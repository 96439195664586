const base = require('base/product/base');
const detailImageSlider = require('./detailImageSlider');
// var wishlistHeart = require('./wishlistHeart');
const productDetailMobile = require('./productDetailStyles');
const quickViewImageSlider = require('./quickViewImageSlider');
const scrollAnimate = require('../components/scrollAnimate');
const BASE_CONFIG = window.BASE_CONFIG;
const SIZE_CHART_CONFIG = require('../../../../config/sizeChartConfig');
const PIPELINES_URLS = window.PIPELINES_URLS;
const deviceUtilities = require('../utilities/deviceUtilities');
const pageUtilities = require('../utilities/pageUtilities');
const bundleDetails = require('./bundleDetails');
const customSelects = require('../components/customSelects');
const { processDataLayerCartActionOnProduct } = require('../utilities/datalayerHelpers');
const detailHelper = require('../product/detail');

/**
 * Retrieves the relevant pid value
 * @param {jquery} $el - DOM container for a given add to cart button
 * @return {string} - value to be used when adding product to cart
 */
function getPidValue($el) {
    var pid;

    if ($('#quickViewModal').hasClass('show') && !$('.product-set').length) {
        pid = $($el).closest('.modal-content').find('.product-quickview').data('pid');
    } else if ($('.product-set-detail').length || $('.product-set').length) {
        pid = $($el).closest('.product-detail').find('.product-id').text();
    } else {
        pid = $('.product-detail:not(".bundle-item")').data('pid');
    }

    return pid;
}

/**
 * Retrieve contextual quantity selector
 * @param {jquery} $el - DOM container for the relevant quantity
 * @return {jquery} - quantity selector DOM container
 */
function getQuantitySelector($el) {
    return $el && $('.set-items').length
        ? $($el).closest('.product-detail').find('.quantity-select')
        : $('.quantity-select');
}

/**
 * Retrieves the value associated with the Quantity pull-down menu
 * @param {jquery} $el - DOM container for the relevant quantity
 * @return {string} - value found in the quantity input
 */
function getQuantitySelected($el) {
    return getQuantitySelector($el).val();
}

/**
 * Process the attribute values for an attribute that has image swatches
 *
 * @param {Object} attr - Attribute
 * @param {string} attr.id - Attribute ID
 * @param {Object[]} attr.values - Array of attribute value objects
 * @param {string} attr.values.value - Attribute coded value
 * @param {string} attr.values.url - URL to de/select an attribute value of the product
 * @param {boolean} attr.values.isSelectable - Flag as to whether an attribute value can be
 *     selected.  If there is no variant that corresponds to a specific combination of attribute
 *     values, an attribute may be disabled in the Product Detail Page
 * @param {jQuery} $productContainer - DOM container for a given product
 * @param {Object} msgs - object containing resource messages
 */
function processSwatchValues(attr, $productContainer, msgs) {
    attr.values.forEach(function (attrValue) {
        var $attrValue = $productContainer.find('[data-attr="' + attr.id + '"] [data-attr-value="'
            + attrValue.value + '"]');
        var $swatchButton = $attrValue.parent();

        if (attrValue.selected) {
            $attrValue.addClass('selected');
            $attrValue.siblings('.selected-assistive-text').text(msgs.assistiveSelectedText);
        } else {
            $attrValue.removeClass('selected');
            $attrValue.siblings('.selected-assistive-text').empty();
        }

        if (attrValue.url) {
            $swatchButton.attr('data-url', attrValue.url.replace(/_DL\d/g, '').replace(/DL\d/g, 'Original'));
        } else {
            $swatchButton.removeAttr('data-url');
        }
    });
}

/**
 * Process attribute values associated with an attribute that does not have image swatches
 *
 * @param {Object} attr - Attribute
 * @param {string} attr.id - Attribute ID
 * @param {Object[]} attr.values - Array of attribute value objects
 * @param {string} attr.values.value - Attribute coded value
 * @param {string} attr.values.url - URL to de/select an attribute value of the product
 * @param {boolean} attr.values.isSelectable - Flag as to whether an attribute value can be
 *     selected.  If there is no variant that corresponds to a specific combination of attribute
 *     values, an attribute may be disabled in the Product Detail Page
 * @param {jQuery} $productContainer - DOM container for a given product
 * @param {boolean} updateEquivalentSizes - Flag to update equivalent sizes or not
 */
function processNonSwatchValues(attr, $productContainer, updateEquivalentSizes) {
    var $attr = '[data-attr="' + attr.id + '"]';

    // TODO refactor. This avoids specifically product Tiles, make it more versatile?
    var $defaultOption = $productContainer.find('.product-info__sizes-info:not(.product-tile__sizes-info) ' + $attr + ' .select-' + attr.id + ' option:first');
    $defaultOption.attr('value', attr.resetUrl);

    attr.values.forEach(function (attrValue) {
        var $attrValue = $productContainer.find('.product-info__sizes-info:not(.product-tile__sizes-info) ' + $attr + ' [data-attr-value="' + attrValue.value + '"]');

        $attrValue.attr('value', attrValue.url)
            .removeAttr('disabled')
            .removeClass('disabled--unavailable')
            .removeClass('stock-last-units');

        if (attr.id === 'size') {
            if (updateEquivalentSizes === true) {
                $attrValue.empty();
                if (attrValue.equivalentSize) {
                    $attrValue.text(attrValue.displayValue + ' (' + attrValue.equivalentSize + ')');
                } else {
                    $attrValue.text(attrValue.displayValue);
                }
            }

            if (!attrValue.selectable) {
                $attrValue.addClass('disabled--unavailable');
            }

            if (attrValue.lastUnits) {
                $attrValue.addClass('stock-last-units');
            }
        } else if (!attrValue.selectable) {
            $attrValue.attr('disabled', true);
        }
    });
}

/**
 * Routes the handling of attribute processing depending on whether the attribute has image
 *     swatches or not
 *
 * @param {Object} attrs - Attribute
 * @param {jQuery} $productContainer - DOM element for a given product
 * @param {Object} msgs - object containing resource messages
 * @param {boolean} updateEquivalentSizes - Flag to update equivalent sizes or not

 */
function updateAttrs(attrs, $productContainer, msgs, updateEquivalentSizes) {
    // Currently, the only attribute type that has image swatches is Color.
    var attrsWithSwatches = ['color'];

    attrs.forEach(function (attr) {
        if (attrsWithSwatches.indexOf(attr.id) > -1) {
            processSwatchValues(attr, $productContainer, msgs);
        } else {
            processNonSwatchValues(attr, $productContainer, updateEquivalentSizes);
        }
    });
}

/**
 * Updates the availability status in the Product Detail Page
 *
 * @param {Object} response - Ajax response object after an
 *                            attribute value has been [de]selected
 * @param {jQuery} $productContainer - DOM element for a given product
 */
function updateAvailability(response, $productContainer) {
    var availabilityValue = '';

    var availabilityMessages = response.product.availability.messages;
    if (!response.product.readyToOrder) {
        availabilityValue = '<li><div>' + response.resources.info_selectforstock + '</div></li>';
    } else {
        availabilityMessages.forEach(function (message) {
            availabilityValue += '<li><div>' + message + '</div></li>';
        });
    }

    $($productContainer).trigger('product:updateAvailability', {
        product: response.product,
        $productContainer: $productContainer,
        message: availabilityValue,
        resources: response.resources
    });
}

/**
 * Generates html for product attributes section
 *
 * @param {array} attributes - list of attributes
 * @return {string} - Compiled HTML
 */
function getAttributesHtml(attributes) {
    if (!attributes) {
        return '';
    }

    var html = '';

    attributes.forEach(function (attributeGroup) {
        if (attributeGroup.ID === 'mainAttributes') {
            attributeGroup.attributes.forEach(function (attribute) {
                html += '<div class="attribute-values">' + attribute.label + ': '
                    + attribute.value + '</div>';
            });
        }
    });

    return html;
}

/**
 * Dynamically creates Bootstrap carousel from response containing images
 * @param {Array} media - Product video and images
 * @param {jQuery} $productContainer - DOM element for a given product
 */
function createProductImageSlider(media, $productContainer) {
    var carousel = $productContainer.find('.product-images-slider');
    var carouselContent = '<div class="swiper-container"><div class="swiper-wrapper product-images-slider__wrapper">';

    if (media.length === 0) {
        media.push({
            url: BASE_CONFIG.placeholders.pdp,
            alt: '',
            title: '',
            class: 'placeholder'
        });
    } else {
        for (var i = 0; i < media.length; i++) {
            if (media[i].url && media[i].url.length > 0) {
                // Custom AB Test. ProductPage_Video: TestSegmentA doesn't show video
                if (!window.testAB_ProductPage_Video
                    || (window.testAB_ProductPage_Video && window.testAB_ProductPage_Video !== 'TestSegmentA')) {
                    carouselContent += '<div class="swiper-slide product-images-slider__slide video-slide"'
                    + 'data-position="' + i + '"'
                    + '>'
                    + '<video class="pdp-video" preload="none" data-autoplay="true" loop muted playsinline>'
                    + '<source src="' + media[i].url + '" type="video/mp4" /></video></div>';
                }
            } else {
                var image = media[i]['pdp-original'];
                var imgClass = (image.class && image.class !== 'undefined')
                    ? image.class : 'product-images-slider__image ';
                carouselContent
                    += '<div class="swiper-slide product-images-slider__slide"'
                    + 'data-position="' + i + '"'
                    + '>'
                    + '<div class="product-images-slider__img-container">'
                    + '<picture>'
                    + '<img class="' + imgClass + '"'
                    + ' src="' + image.url + '" data-orig-url="' + image.url + '"'
                    + ' loading="lazy" fetchpriority="high" itemprop="media"'
                    + ' alt="' + image.alt + ' image number ' + i + '" title="' + image.title + '" itemprop="image" />'
                    + '</picture>'
                    + '</div></div>';
            }
        }
    }

    carouselContent += '</div><div class="swiper-pagination swiper-pagination-over-image"></div></div>';

    carousel.empty();
    carousel.append(carouselContent);
    carousel.removeAttr('style');

    if ($productContainer.hasClass('product-quickview')) {
        quickViewImageSlider();
    } else {
        detailImageSlider();
        productDetailMobile.methods.checkStyles(deviceUtilities.mqlMaxWidth768);
    }

    // Add the event listener again to the slider
    $('.product-images-slider .swiper-slide').on('click', function (event) {
        if (!$(this).hasClass('video-slide')) {
            $('body').trigger('product:image-zoom', event);
        } else {
            var video = $(this).find('.pdp-video').get(0);
            // Linter is disabled because although any value is set to video, it is being used to play or pause itself
            // eslint-disable-next-line no-unused-expressions
            video && video.paused ? video.play() : video.pause();
        }
    });

    $('body').trigger('product:image-slider-created');
}

/**
 * Dynamically changes product Zoom Images
 * @param {Array} imgs - Array of large product images
 * @param {jQuery} $productContainer - DOM element for a given product
 */
function changeProductZoomImages(imgs, $productContainer) {
    var zoomImagesWrapper = $productContainer.find('.zoom-images-wrapper--desktop');
    var zoomWrapperContent = '';
    zoomImagesWrapper.empty();

    if (imgs.length === 0) {
        imgs.push({
            url: BASE_CONFIG.placeholders.pdp,
            alt: '',
            title: '',
            class: 'placeholder'
        });
    }

    for (var i = 0; i < imgs.length; i++) {
        zoomWrapperContent += '<div class="zoom-picture"><img class="zoom-image custom-lazyload" src="' + imgs[i].url + '" alt="' + imgs[i].alt + ' image number ' + i + '" title="' + imgs[i].title + '" itemprop="image"/></div>';
    }

    zoomImagesWrapper.append(zoomWrapperContent);
}

/**
 * Parses JSON from Ajax call made whenever an attribute value is [de]selected
 * @param {Object} response - response from Ajax call
 * @param {Object} response.product - Product object
 * @param {string} response.product.id - Product ID
 * @param {Object[]} response.product.variationAttributes - Product attributes
 * @param {Object[]} response.product.images - Product images
 * @param {boolean} response.product.hasRequiredAttrsSelected - Flag as to whether all required
 *     attributes have been selected.  Used partially to
 *     determine whether the Add to Cart button can be enabled
 * @param {jQuery} $productContainer - DOM element for a given product.
 * @param {boolean} updateEquivalentSizes - Flag to update equivalent sizes or not

 */
function handleVariantResponse(response, $productContainer, updateEquivalentSizes) {
    var isChoiceOfBonusProducts = $productContainer.parents('.choose-bonus-product-dialog').length > 0;
    var isVaraint;
    if (response.product.variationAttributes) {
        updateAttrs(response.product.variationAttributes, $productContainer, response.resources, updateEquivalentSizes);
        isVaraint = response.product.productType === 'variant';
        if (isChoiceOfBonusProducts && isVaraint) {
            $productContainer.parent('.bonus-product-item')
                .data('pid', response.product.id);

            $productContainer.parent('.bonus-product-item')
                .data('ready-to-order', response.product.readyToOrder);
        }
    }

    // Update Name
    $productContainer.find('.product-info__name').empty().html(response.product.productName);

    // Update Description
    if (response.product.shortDescription) {
        $productContainer.find('.product-description').empty().html(response.product.shortDescription);
    }

    // Update Characteristics
    if (response.product.adCharacteristic && response.product.adCharacteristic.length > 0) {
        var characteristicList = '';
        response.product.adCharacteristic.forEach(function (characteristic) {
            characteristicList += '<li>' + characteristic + '</li>';
        });
        $productContainer.find('.product-characteristics').empty().html(characteristicList);
    }

    if (!pageUtilities.isBundleDetailPage()) {
        // Update primary images
        var primaryImageUrls = response.product.adImages['pdp-original'];
        var productMedia = response.product.productMedia;
        createProductImageSlider(productMedia, $productContainer);
        changeProductZoomImages(primaryImageUrls, $productContainer);
    }

    // Update pricing
    var priceHtml = response.product.price.html;
    if (!isChoiceOfBonusProducts) {
        var $priceSelector = $('.product-info__price .price', $productContainer).length
            ? $('.product-info__price .price', $productContainer)
            : $('.product-info__price .price');
        $priceSelector.replaceWith(priceHtml);
    }

    // Update discount message
    var hasDiscount = priceHtml.indexOf('strike-through old') !== -1;

    var discountMessage = document.querySelector('.product-info__price .discount-message');
    if (hasDiscount) {
        discountMessage?.classList.remove('d-none');
    } else {
        discountMessage?.classList.add('d-none');
    }

    // Update promotions
    $productContainer.find('.promotions').empty().html(response.product.promotionsHtml);

    // Update selected color name
    var updatedColorName = $productContainer.find('.color-attribute .selected').parent().attr('title');
    $('.product-colors__selected-name').text(updatedColorName);
    $('.product-info__selected-name').text(updatedColorName);

    var { updateColorNamePosition } = require('./detail').methods;
    updateColorNamePosition($productContainer);

    updateAvailability(response, $productContainer);

    if (isChoiceOfBonusProducts) {
        var $selectButton = $productContainer.find('.select-bonus-product');
        $selectButton.trigger('bonusproduct:updateSelectButton', {
            product: response.product, $productContainer: $productContainer
        });
    } else {
        // Enable "Add to Cart" button if all required attributes have been selected
        $('button.add-to-cart, button.add-to-cart-global, button.update-cart-product-global').trigger('product:updateAddToCart', {
            product: response.product, $productContainer: $productContainer
        }).trigger('product:statusUpdate', response.product);

        $('body').trigger('product:loadStockConfig', {
            product: response.product
        });

        // Enable "Save Adn Gift" button if all required attributes have been selected
        $('.btn.save-adn-gift').trigger('detail:updateSaveAdnGift', {
            product: response.product, $productContainer: $productContainer
        }).trigger('product:statusUpdate', response.product);
    }

    // Update attributes
    $productContainer.find('.main-attributes').empty()
        .html(getAttributesHtml(response.product.attributes));
}

/**
 * @typespec UpdatedQuantity
 * @type Object
 * @property {boolean} selected - Whether the quantity has been selected
 * @property {string} value - The number of products to purchase
 * @property {string} url - Compiled URL that specifies variation attributes, product ID, options,
 *     etc.
 */

/**
 * Updates the quantity DOM elements post Ajax call
 * @param {UpdatedQuantity[]} quantities -
 * @param {jQuery} $productContainer - DOM container for a given product
 */
function updateQuantities(quantities, $productContainer) {
    if (!($productContainer.parent('.bonus-product-item').length > 0)) {
        var optionsHtml = quantities.map(function (quantity) {
            var selected = quantity.selected ? ' selected ' : '';
            return '<option value="' + quantity.value + '"  data-url="' + quantity.url + '"'
                + selected + '>' + quantity.value + '</option>';
        }).join('');
        getQuantitySelector($productContainer).empty().html(optionsHtml);
    }
}

/**
 * updates the product view when a product attribute is selected or deselected or when
 *         changing quantity
 * @param {string} selectedValueUrl - the Url for the selected variation value
 * @param {jQuery} $productContainer - DOM element for current product
 * @param {string} updateEquivalentSizes - Flag to update equivalent sizes or not
 * @param {string} triggerAddToCart - Flag to trigger Add To Cart after attribute select

 */
function attributeSelect(selectedValueUrl, $productContainer, updateEquivalentSizes, triggerAddToCart) {
    if (selectedValueUrl) {
        $('body').trigger('product:beforeAttributeSelect',
            { url: selectedValueUrl, container: $productContainer });

        $.ajax({
            url: selectedValueUrl,
            method: 'GET',
            success: function (data) {
                handleVariantResponse(data, $productContainer, updateEquivalentSizes);
                updateQuantities(data.product.quantities, $productContainer);
                var updatedData = data;
                updatedData.product.id = updatedData.product.id.replace(/_DL\d/, '');
                $('body').trigger('product:afterAttributeSelect',
                    { data: data, container: $productContainer });

                if ($('.product-sizes__error').length > 0) {
                    $('.product-sizes__error').hide();
                }

                detailHelper.methods.closeSizeSelector();

                if (triggerAddToCart) {
                    $('.add-to-cart').trigger('click');
                }

                bundleDetails.methods.updateSizeAndEditButton($productContainer.closest('.bundled-product'));

                $.spinner().stop();
            },
            error: function () {
                $.spinner().stop();
            }
        });
    }
}

/**
 * @on4u Function copied and unchanged.
 * If you modify this, remember to check the functions in the base file and update this message.
 *
 * Parses the html for a modal window
 * @param {string} html - representing the body and footer of the modal window
 *
 * @return {Object} - Object with properties body and footer.
 */
function parseHtml(html) {
    var $html = $('<div>').append($.parseHTML(html));

    var body = $html.find('.choice-of-bonus-product');
    var footer = $html.find('.modal-footer').children();

    return { body: body, footer: footer };
}

/**
 * @on4u Function copied and unchanged.
 * If you modify this, remember to check the functions in the base file and update this message.
 *
 * Retrieves url to use when adding a product to the cart
 *
 * @param {Object} data - data object used to fill in dynamic portions of the html
 */
function chooseBonusProducts(data) {
    $('.modal-body').spinner().start();

    if ($('#chooseBonusProductModal').length !== 0) {
        $('#chooseBonusProductModal').remove();
    }
    var bonusUrl;
    if (data.bonusChoiceRuleBased) {
        bonusUrl = data.showProductsUrlRuleBased;
    } else {
        bonusUrl = data.showProductsUrlListBased;
    }

    var htmlString = '<!-- Modal -->'
        + '<div class="modal fade" id="chooseBonusProductModal" tabindex="-1" role="dialog">'
        + '<span class="enter-message sr-only" ></span>'
        + '<div class="modal-dialog choose-bonus-product-dialog" '
        + 'data-total-qty="' + data.maxBonusItems + '"'
        + 'data-UUID="' + data.uuid + '"'
        + 'data-pliUUID="' + data.pliUUID + '"'
        + 'data-addToCartUrl="' + data.addToCartUrl + '"'
        + 'data-pageStart="0"'
        + 'data-pageSize="' + data.pageSize + '"'
        + 'data-moreURL="' + data.showProductsUrlRuleBased + '"'
        + 'data-bonusChoiceRuleBased="' + data.bonusChoiceRuleBased + '">'
        + '<!-- Modal content-->'
        + '<div class="modal-content">'
        + '<div class="modal-header">'
        + '    <span class="">' + data.labels.selectprods + '</span>'
        + '    <button type="button" class="close pull-right" data-dismiss="modal">'
        + '        <span aria-hidden="true">&times;</span>'
        + '        <span class="sr-only"> </span>'
        + '    </button>'
        + '</div>'
        + '<div class="modal-body"></div>'
        + '<div class="modal-footer"></div>'
        + '</div>'
        + '</div>'
        + '</div>';
    $('body').append(htmlString);
    $('.modal-body').spinner().start();

    $.ajax({
        url: bonusUrl,
        method: 'GET',
        dataType: 'json',
        success: function (response) {
            var parsedHtml = parseHtml(response.renderedTemplate);
            $('#chooseBonusProductModal .modal-body').empty();
            $('#chooseBonusProductModal .enter-message').text(response.enterDialogMessage);
            $('#chooseBonusProductModal .modal-header .close .sr-only').text(response.closeButtonText);
            $('#chooseBonusProductModal .modal-body').html(parsedHtml.body);
            $('#chooseBonusProductModal .modal-footer').html(parsedHtml.footer);
            $('#chooseBonusProductModal').modal('show');
            $.spinner().stop();
        },
        error: function () {
            $.spinner().stop();
        }
    });
}

/**
 * Show a generic toast message with added to cart info
 * @param {string} messageType - message type (alert-danger, alert-success)
 * @param {string} messageText - message text to show inside the toast
 */
function showAddedToCartToast(messageType, messageText) {
    $('.add-to-cart-messages').remove();

    const addToCartMsgWrapper = `<div class="add-to-cart-messages add-to-cart-messages--${messageType}"></div>`;
    $('body').append(addToCartMsgWrapper);

    $('.add-to-cart-messages').append(
        `<div class="alert ${messageType} add-to-basket-alert" role="alert"><span>${messageText}</span></div>`
    );

    setTimeout(function () {
        $('.add-to-basket-alert').remove();
    }, 5000);
}

/**
 * Updates the Mini-Cart quantity value after the customer has pressed the "Add to Cart" button
 * @param {Object} response - ajax response from clicking the add to cart button
 */
function handlePostCartAdd(response) {
    const mustUpdateMinicartCount = !response.error && response.status !== 500;
    if (mustUpdateMinicartCount) {
        $('.minicart').trigger('count:update', response);
    }

    const messageType = response.error || response.status === 500 ? 'alert-danger' : 'alert-success';
    let messageText = response.message;
    const mustAddGenericErrorMessage = !messageText && messageType === 'alert-danger';
    if (mustAddGenericErrorMessage) {
        messageText = $('.add-to-cart-error-msg').val() || '';
    }

    const mustShowToast = response.error
        || (response.isQuickShop && deviceUtilities.isMobile())
        || response.isShopTheLook;
    // show add to cart toast
    if (response.newBonusDiscountLineItem
        && Object.keys(response.newBonusDiscountLineItem).length !== 0
    ) {
        chooseBonusProducts(response.newBonusDiscountLineItem);
    } else if (mustShowToast) {
        showAddedToCartToast(messageType, messageText);
    } else {
        const addedToCartModal = require('./addedToCartModal');
        addedToCartModal.openModal(response.newProductId, messageText, response.newLineItemHtml);
    }
}

/**
 * Retrieves the bundle product item ID's for the Controller to replace bundle master product
 * items with their selected variants
 *
 * @return {string[]} - List of selected bundle product item ID's
 */
function getChildProducts() {
    var selectedBundleItems = $('.bundled-product input:checked');
    var childProducts = [];
    selectedBundleItems.each(function () {
        childProducts.push({
            pid: $(this).closest('.bundled-product').find('.product-id').text(),
            quantity: parseInt($(this).closest('.bundled-product').find('label.quantity').data('quantity'), 10)
        });
    });

    return childProducts.length ? JSON.stringify(childProducts) : [];
}

/**
 * Retrieve product options
 *
 * @param {jQuery} $productContainer - DOM element for current product
 * @return {string} - Product options and their selected values
 */
function getOptions($productContainer) {
    var options = $productContainer
        .find('.product-option')
        .map(function () {
            var $elOption = $(this).find('.options-select');
            var urlValue = $elOption.val();
            var selectedValueId = $elOption.find('option[value="' + urlValue + '"]')
                .data('value-id');
            return {
                optionId: $(this).data('option-id'),
                selectedValueId: selectedValueId
            };
        }).toArray();

    return JSON.stringify(options);
}

/**
 * Makes a call to the server to report the event of adding an item to the cart
 *
 * @param {string | boolean} url - a string representing the end point to hit so that the event can be recorded, or false
 */
function miniCartReportingUrl(url) {
    if (url) {
        $.ajax({
            url: url,
            method: 'GET',
            success: function () {
                // reporting urls hit on the server
            },
            error: function () {
                // no reporting urls hit on the server
            }
        });
    }
}

/**
 * Checks unselected products in Bundles.
 * One product from each group must be selected
 * @returns {Object} result
 */
function checkUnselectedBundledProducts() {
    var unselectedGroupError = [];
    var bundleGroups = $('.bundle-item-group');

    Array.prototype.forEach.call(bundleGroups, function (selector) {
        var errorContainer = $(selector).find('.bundle-item__unselected-error');
        errorContainer.hide();

        var selected = $(selector).find('.bundled-product-input:checked');
        if (selected.length !== 1) {
            errorContainer.show();
            unselectedGroupError.push(errorContainer);
        }
    });

    return unselectedGroupError;
}

/**
 * Checks if there are unselected sizes.
 * In bundles, selected products must have a selected size.
 * @returns {Object} result
 */
function checkUnselectedSizes() {
    var sizeSelectors;
    var unselectedSelector = [];

    if (pageUtilities.isBundleDetailPage()) {
        sizeSelectors = $('.bundled-product input:checked').closest('.bundled-product').find('.product-sizes__selector');
    } else {
        sizeSelectors = $('.product-info-pdp .product-sizes__selector');
    }

    Array.prototype.forEach.call(sizeSelectors, function (selector) {
        if ($(selector).find('.selected').length < 1) {
            if ($(selector).closest('.bundled-product .form-check-label').length) {
                $(selector).closest('.bundled-product .form-check-label').addClass('unselected-size');
                $(selector).closest('.bundled-product').find('.bundled-product__error').show();
                unselectedSelector.push($(selector).closest('.bundled-product'));
            } else {
                $(selector).closest('.product-sizes').find('.product-sizes__error').show();
                unselectedSelector.push($(selector).closest('.product-sizes'));
            }
        } else {
            $(selector).closest('.bundled-product .form-check-label')?.removeClass('unselected-size');
            $(selector).closest('.bundled-product')?.find('.bundled-product__error').hide();
        }
    });

    return unselectedSelector;
}

/**
 * Add to cart
 * @on4u overrided Add To Cart function
 * @param {Object} element Add to cart button
 */
function addToCartHandler(element) {
    var pid;
    var pidsObj;
    var setPids;

    var unselectedSizes = checkUnselectedSizes();
    if (unselectedSizes.length > 0) {
        scrollAnimate(unselectedSizes[0]);
        return;
    }

    if (pageUtilities.isBundleDetailPage()) {
        var unselectedProducts = checkUnselectedBundledProducts();
        if (unselectedProducts.length > 0) {
            scrollAnimate(unselectedProducts[0]);
            return;
        }
    }

    $('body').trigger('product:beforeAddToCart', element);

    if ($('.set-items').length && $(element).hasClass('add-to-cart-global')) {
        setPids = [];

        $('.product-detail').each(function () {
            if (!$(element).hasClass('product-set-detail')) {
                setPids.push({
                    pid: $(element).find('.product-id').text(),
                    qty: $(element).find('.quantity-select').val(),
                    options: getOptions($(element))
                });
            }
        });
        pidsObj = JSON.stringify(setPids);
    }

    pid = getPidValue($(element));

    var $productContainer = $(element).closest('.product-detail');
    if (!$productContainer.length) {
        $productContainer = $(element).closest('.quick-view-dialog').find('.product-detail');
    }

    var form = {
        pid: pid,
        pidsObj: pidsObj,
        childProducts: getChildProducts(),
        quantity: getQuantitySelected($(element))
    };

    if (!$('.bundle-item').length) {
        form.options = getOptions($productContainer);
    }

    $(this).trigger('updateAddToCartFormData', form);

    $.ajax({
        url: PIPELINES_URLS.cartAddProduct,
        method: 'POST',
        data: form,
        success: function (data) {
            handlePostCartAdd(data);
            $('body').trigger('product:afterAddToCart', data);
            processDataLayerCartActionOnProduct(data.dataLayerCartActionOnProduct, element);
            $.spinner().stop();
            miniCartReportingUrl(data.reportingURL);
        },
        error: function (data) {
            handlePostCartAdd(data);
            $.spinner().stop();
        }
    });
}

module.exports = {
    attributeSelect: attributeSelect,

    methods: base.methods,
    focusChooseBonusProductModal: base.focusChooseBonusProductModal,
    onClosingChooseBonusProductModal: base.onClosingChooseBonusProductModal,
    trapChooseBonusProductModalFocus: base.trapChooseBonusProductModalFocus,

    productTileColor: function () {
        $(document).on('click', '.tile-color-swatch', function (e) {
            e.preventDefault();
            const swatchPlpLink = $(this);
            const swatchPlpWrapper = swatchPlpLink.children('.swatch-wrapper');

            if (swatchPlpWrapper.hasClass('selected')) {
                return;
            }

            const swatchPid = swatchPlpLink.data('pid');
            const productWrapper = swatchPlpLink.closest('.product');
            const originProductTile = swatchPlpLink.closest('.product-tile');
            const newProductTile = productWrapper.find('.product-tile[data-pid="' + swatchPid + '"]');

            if (newProductTile.length > 0) {
                originProductTile.hide();
                newProductTile.show();
                productWrapper.find('.swatch-wrapper').removeClass('selected');
                productWrapper.find('.tile-color-swatch[data-pid="' + swatchPid + '"] .swatch-wrapper').addClass('selected');
            } else {
                const form = {
                    pid: swatchPid,
                    swatchWrapperHtml: swatchPlpLink.closest('.color-swatches')[0].outerHTML,
                    isSearchPage: pageUtilities.isSearchPage()
                };

                $.ajax({
                    url: PIPELINES_URLS.tileGetVariation,
                    method: 'POST',
                    data: form,
                    /**
                     * Success callback
                     * @param {Object} data Response of Tile-GetVariation
                     * @param {string} data.productTileHtml Html of rendered productTile.isml
                     * @param {string} data.datalayerImpression Stringified data of the impression (`Analytics-GetImpression`)
                    */
                    success: function (data) {
                        originProductTile.hide();
                        const createdProductTile = $(data.productTileHtml);
                        createdProductTile.attr('data-datalayer-impression', JSON.stringify(data.datalayerImpression));
                        createdProductTile.appendTo(productWrapper);
                        customSelects.customizeSelects(createdProductTile.find('.customized-select'));
                        require('../product/productTileSlider').init();
                        productWrapper.find('.swatch-wrapper').removeClass('selected');
                        productWrapper.find('.tile-color-swatch[data-pid="' + swatchPid + '"] .swatch-wrapper').addClass('selected');
                    }
                });
            }
        });
    },

    colorAttribute: function () {
        $(document).on('click', '[data-attr="color"] button', function (e) {
            e.preventDefault();

            if ($(this).attr('disabled')) {
                return;
            }
            var $productContainer = $(this).closest('.set-item');
            if (!$productContainer.length) {
                $productContainer = $(this).closest('.product-detail');
            }

            var updateEquivalentSizes = true;
            attributeSelect($(this).attr('data-url'), $productContainer, updateEquivalentSizes);
        });
    },

    selectAttribute: function () {
        $(document).on('change', 'select[class*="select-"]:not(.disable-custom-select-listener), .options-select', function (e) {
            e.preventDefault();
            let triggerAddToCart = false;

            var $productContainer = $(this).closest('.set-item');
            if (!$productContainer.length) {
                $productContainer = $(this).closest('.product-detail');
            }

            var isSizeSelectorOpened = $(this).closest('.product-info__section').hasClass('opened');
            if ($(this).hasClass('select-size') && !deviceUtilities.isDesktop() && $(this).closest('.product-quickview').length === 0 && isSizeSelectorOpened) {
                triggerAddToCart = true;
            }
            attributeSelect(e.currentTarget.value, $productContainer, false, triggerAddToCart);
        });
    },

    availability: function () {
        $(document).on('change', '.quantity-select', function (e) {
            e.preventDefault();

            var $productContainer = $(this).closest('.product-detail');
            if (!$productContainer.length) {
                $productContainer = $(this).closest('.modal-content').find('.product-quickview');
            }

            if ($('.bundle-items', $productContainer).length === 0) {
                attributeSelect($(e.currentTarget).find('option:selected').data('url'),
                    $productContainer);
            }
        });
    },

    addToCart: function () {
        $(document).on('click', 'button.add-to-cart, button.add-to-cart-global', function (event) {
            addToCartHandler(event.currentTarget);
        });
    },
    selectBonusProduct: base.selectBonusProduct,
    removeBonusProduct: base.removeBonusProduct,
    enableBonusProductSelection: base.enableBonusProductSelection,
    showMoreBonusProducts: base.showMoreBonusProducts,
    addBonusProductsToCart: base.addBonusProductsToCart,
    getPidValue: getPidValue,
    getQuantitySelector: getQuantitySelector,
    getQuantitySelected: getQuantitySelected,
    updateQuantities: updateQuantities,
    miniCartReportingUrl: miniCartReportingUrl,
    handlePostCartAdd: handlePostCartAdd,
    sizeChart: function () {
        $('body').on('click', '.size-chart-link', function (e) {
            e.preventDefault();
            var url = $(this).data('url');
            var pid = $(this).data('pid');
            var pidGender = pid.toString().substring(0, 1);
            var currentSizeChartGender = $('.contentTallas').attr('id');
            var $prodSizeChart = $('.size-chart-modal__content');

            if (!$prodSizeChart.is(':empty') && SIZE_CHART_CONFIG.genderByPID[pidGender] !== currentSizeChartGender) {
                $prodSizeChart.empty();
            }

            $('body').trigger('closeTileSizeSelector');

            if ($prodSizeChart.is(':empty')) {
                $.ajax({
                    url: url,
                    type: 'get',
                    dataType: 'json',
                    success: function (data) {
                        $prodSizeChart.append(data.content);
                    }
                });
            }
        });
    }
};
