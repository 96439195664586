const GTM_CONFIG = require('../../../../../../config/gtmConfig');
const { createProductFieldObject } = require('../models/productFieldObject');
const {
    getLastListAndPositionWhereProductWasAddedToCart,
    getLastListAndPositionWhereProductWasClicked
} = require('../ecommerceListTracking');
const datalayerCheckout = require('../../datalayerCheckout');
const { createDataLayerEvent } = require('../models/dataLayerEvent');
const sendEvent = require('./sendEvent');

/**
 * Sends click event on adn club on dropdown menu
  * @param {string} category data-category-list
 */
function sendAdnClubEventDropdown(category) {
    createDataLayerEvent({
        eventData: {
            action: GTM_CONFIG.eventActions.adn,
            category: GTM_CONFIG.eventCategories.adnClub2,
            label: category
        }
    }).then(sendEvent);
}

/**
 * Sends click event on adn club on principal menu
 */
function sendAdnClubEventMenu() {
    createDataLayerEvent({
        eventData: {
            action: GTM_CONFIG.eventActions.adn,
            category: GTM_CONFIG.eventCategories.adnClub1,
            label: 'adn_club'
        }
    }).then(sendEvent);
}

/**
 * Sends click event on adn ecosistema
 */
function sendAdnEcoEvent() {
    createDataLayerEvent({
        eventData: {
            action: GTM_CONFIG.eventActions.adn,
            category: GTM_CONFIG.eventCategories.adnEcosistema,
            label: 'descubre_adn'
        }
    }).then(sendEvent);
}

/**
 * Sends a cart action to datalayer
 * @param {string} action The action just performed ('add', 'remove')
 * @param {ProductFieldObject} productFieldObject The product info
 * @param {string} currencyCode The current currency code
 */
async function sendCartAction(action, productFieldObject, currencyCode) {
    const validActions = ['add', 'remove'];
    if (validActions.indexOf(action) < 0) {
        throw new RangeError(`Invalid action ("${action}"). Accepted: ${validActions.join(', ')}`);
    }
    const category = (action === 'remove')
        ? GTM_CONFIG.eventCategories.removeFromCart
        : GTM_CONFIG.eventCategories.addToCart;

    let finalProductFieldObject = await productFieldObject.updateListAndPosition(
        getLastListAndPositionWhereProductWasAddedToCart(
            productFieldObject.addons.adDatalayerProductId
        )
    );
    finalProductFieldObject = finalProductFieldObject.toDatalayer();

    createDataLayerEvent({
        eventData: {
            category,
            label: finalProductFieldObject.name
        },
        ecommerce: {
            currencyCode,
            [action]: {
                actionField: {
                    list: finalProductFieldObject.list
                },
                products: [
                    finalProductFieldObject
                ]
            }
        }
    }).then(sendEvent);
}

/**
 * Send checkout stage datalayer info
 * @async
 * @param {string} currentStep - Current Stage Category name
 * @param {string} [targetUrl] - To redirect after push
 */
async function sendCheckoutStep(currentStep, targetUrl) {
    var redirected = false;
    const currentStepInfo = GTM_CONFIG.eventCategories.checkout[currentStep];
    const datalayerCheckoutInfo = datalayerCheckout.getDatalayerCheckoutInfo();
    // Complete the data of lists and positions
    datalayerCheckoutInfo.productFieldObjects = await Promise.all(
        datalayerCheckoutInfo.productFieldObjects.map(
            p => createProductFieldObject(p).updateListAndPosition(
                getLastListAndPositionWhereProductWasAddedToCart(
                    p.addons.adDatalayerProductId
                )
            )
        )
    );

    createDataLayerEvent({
        eventData: {
            category: currentStepInfo?.length > 0 ? currentStepInfo[1] : undefined,
            label: datalayerCheckoutInfo.eventDataLabel
        },
        ecommerce: {
            currencyCode: datalayerCheckoutInfo.currencyCode,
            checkout: {
                actionField: {
                    step: currentStepInfo ? currentStepInfo[0] : undefined
                },
                products: datalayerCheckoutInfo.productFieldObjects.map(
                    p => p.toDatalayer()
                )
            }
        }
    }, {
        callback() {
            if (targetUrl && !redirected) {
                redirected = true;
                window.location = targetUrl;
            }
        }
    }).then(sendEvent);
}

/**
 * Sets a cookie to know if the consent cookie datalayer has already been pushed
 * Set the same expire date as Cookiebot
 */
function setCookieConsentDatalayerCookie() {
    var date = new Date();
    date.setTime(date.getTime() + (365 * 24 * 60 * 60 * 1000));
    document.cookie = 'analyticsCookieConsent=true; expires=' + date.toUTCString() + ';path=/';
}

/**
 * Sends Coockie consent datalayer info
 */
function sendCookieConsent() {
    if (Cookiebot) {
        var cookieEvent = {
            event: GTM_CONFIG.events.cookie,
            event_name: 'page_view',
            cookies_legal_cm: [{
                ad_storage: Cookiebot.hasResponse && Cookiebot.consent.marketing ? 'granted' : 'denied',
                analytics_storage: Cookiebot.hasResponse && Cookiebot.consent.statistics ? 'granted' : 'denied'
            }],
            eventCallback: function () {
                setCookieConsentDatalayerCookie();
            }
        };

        sendEvent(cookieEvent);
    }
}

/**
 * Sends product clicked datalayer info
 * @async
 * @param {ProductFieldObject} productFieldObject - the product info
 * @param {string} currencyCode - the current currency code
 * @param {string} targetUrl - To redirect after push
 * @param {Element} target - Clicked element
 */
async function sendProductClick(productFieldObject, currencyCode, targetUrl, target) {
    var redirected = false;

    let finalProductFieldObject = await productFieldObject.updateListAndPosition(
        getLastListAndPositionWhereProductWasClicked(
            productFieldObject.addons.adDatalayerProductId,
            target
        )
    );
    finalProductFieldObject = finalProductFieldObject.toDatalayer();

    createDataLayerEvent({
        eventData: {
            category: GTM_CONFIG.eventCategories.click,
            label: finalProductFieldObject.name
        },
        ecommerce: {
            currencyCode: currencyCode,
            click: {
                actionField: {
                    list: finalProductFieldObject.list
                },
                products: [
                    finalProductFieldObject
                ]
            }
        }
    }, {
        callback() {
            if (targetUrl && !redirected) {
                redirected = true;
                window.location = targetUrl;
            }
        }
    }).then(sendEvent);
}

/**
 * Send the purchase event to datalayer
 * @async
 * @param {Object} datalayerPurchaseInfo The data of the purchase
 */
async function sendPurchase(datalayerPurchaseInfo) {
    var datalayerCheckoutInfo = datalayerCheckout.getDatalayerCheckoutInfo();
    // Complete the data of lists and positions
    datalayerCheckoutInfo.productFieldObjects = await Promise.all(
        datalayerCheckoutInfo.productFieldObjects.map(
            p => createProductFieldObject(p).updateListAndPosition(
                getLastListAndPositionWhereProductWasAddedToCart(
                    p.addons.adDatalayerProductId
                )
            )
        )
    );

    createDataLayerEvent({
        eventData: {
            category: GTM_CONFIG.eventCategories.purchase,
            label: datalayerCheckoutInfo.eventDataLabel || undefined
        },
        ecommerce: {
            currencyCode: datalayerCheckoutInfo.currencyCode,
            purchase: {
                actionField: datalayerPurchaseInfo?.actionField ?? undefined,
                products: datalayerCheckoutInfo.productFieldObjects.map(
                    p => p.toDatalayer()
                )
            }
        }
    }, {
        callback: datalayerCheckout.removeDatalayerCheckoutInfo
    }).then(sendEvent);
}

/**
 * Sends Segment Modal option datalayer info
 * @param {string} segment Selected Content segment value
 */
function sendSegmentModal(segment) {
    createDataLayerEvent({
        eventData: {
            action: GTM_CONFIG.eventActions.segmentModal,
            category: GTM_CONFIG.eventCategories.home,
            label: segment
        }
    }).then(sendEvent);
}

module.exports = {
    sendAdnClubEventDropdown: sendAdnClubEventDropdown,
    sendAdnClubEventMenu: sendAdnClubEventMenu,
    sendAdnEcoEvent: sendAdnEcoEvent,
    sendCartAction: sendCartAction,
    sendCheckoutStep: sendCheckoutStep,
    sendCookieConsent: sendCookieConsent,
    sendProductClick: sendProductClick,
    sendPurchase: sendPurchase,
    sendSegmentModal: sendSegmentModal
};
