module.exports = function () {
    if ('loading' in HTMLImageElement.prototype) {
        const images = document.querySelectorAll('img[loading="lazy"]');
        images.forEach(img => {
            var lazyImg = img;
            lazyImg.src = img.dataset.src;
        });
    }
};
$(document).ready(function () {
    $('img').each(function () {
        if ($(this).hasClass('zoom-image') === false) {
            $(this).attr('loading', 'lazy');
        }
    });
});
