const contentSegmentCookie = require('./contentSegmentCookie');
const floatingNavigation = require('./floatingNavigation');
const keyboardAccessibility = require('base/components/keyboardAccessibility');
const overlay = require('./overlay');
const BASE_CONFIG = window.BASE_CONFIG;
const mql = window.matchMedia('(max-width: ' + (BASE_CONFIG.responsive.screen__1025 - 1) + 'px)');

var isDesktop = function () {
    return !mql.matches;
};

/**
 * Close menu and clear current selection
 */
function closeMenu() {
    if ($('.main-menu--home').length !== 0 && !mql.matches) {
        // If i'm in home and desktop, do not close.
        return;
    }
    $('.navbar-toggler').removeClass('is-opened');
    $('.main-menu').removeClass('in').hide().attr('aria-hidden', 'true');
    $('.modal-background').hide();
    overlay.resumePageScroll();
    $('header').siblings().attr('aria-hidden', 'false');
}

/**
 * Remove desktop generated styles
 */
function clearDesktopStyles() {
    $('.dropdown-menu').css('top', 'inherit')
        .css('padding-top', '0px');
    $('.dropdown-menu__container').css('height', 'inherit');
}

/**
 * Remove mobile generated styles
 */
function clearMobileStyles() {
    $('.main-menu').removeAttr('style');
    $('.dropdown-menu').removeAttr('style');
}

/**
 * Hide Sublevels
 */
function hideSublevels() {
    $('.dropdown--first-level.show .dropdown--second-level').removeClass('show is-selected').attr('aria-expanded', 'false');
    $('.dropdown--first-level.show .dropdown--sublevel').removeClass('show is-selected').attr('aria-expanded', 'false');
    $('.dropdown--first-level.show .dropdown-third-level--desktop').hide();
    $('.dropdown--first-level.show .dropdown-menu__container--third-level').hide();
}

/**
 * Check if menu first level is selected or not
 * @return {boolean} Is Selected
 */
function isFirstLevelSelected() {
    return $('.dropdown--first-level.is-selected').length > 0;
}

/**
 * Check if there is any first level dropdown associated to the value of the segment
 * @return {boolean} True if nav item exists
 */
function isContentSegmentAssociatedCategory() {
    const contentSegmentValue = contentSegmentCookie.getContentSegmentValue();
    return contentSegmentValue
        && $('.dropdown--first-level > [data-category-content-segment="' + contentSegmentValue + '"]').length > 0;
}

/**
 * Get menu category associated with the current segment
 * @param {int} contentSegmentValue Segment type value
 * @return {Object} jQuery element - Nav item associated to the current segment
 */
function getContentSegmentCategory(contentSegmentValue) {
    return $('.dropdown--first-level > [data-category-content-segment="' + contentSegmentValue + '"]').parent();
}

/**
 * Set Dropdown Menu lateral position to make lateral scrolling
 * @param {Object} selectedDropdownMenu jQuery element
 */
function setDropdownMenuLateralTranslate(selectedDropdownMenu) {
    var selectedDropdownParent;

    if (selectedDropdownMenu) {
        selectedDropdownParent = selectedDropdownMenu.closest('.dropdown--first-level');
    } else {
        if (isFirstLevelSelected()) {
            selectedDropdownParent = $('.dropdown--first-level.is-selected').first();
        } else {
            selectedDropdownParent = $('.dropdown--first-level').first();
        }
        // eslint-disable-next-line no-param-reassign
        selectedDropdownMenu = selectedDropdownParent.find('.dropdown-menu');
    }

    var selectedIndex = selectedDropdownParent.index() + 1;

    $('.dropdown--first-level').each(function (index) {
        var loopIndex = index + 1;

        if (loopIndex === selectedIndex) {
            selectedDropdownMenu.css('transform', 'translateX(0)');
        } else {
            var translateValue = window.innerWidth * (loopIndex - selectedIndex);
            $(this).find('.dropdown-menu').css('transform', 'translateX(' + translateValue + 'px)');
        }
    });
}

/**
 * Set Dropdown Menu position and height
 * @param {Object} selectedDropdownMenu jQuery element
 */
function setDropdownMenuPosition(selectedDropdownMenu = null) {
    var allDropdownMenus = $('.main-menu .dropdown-menu');

    if (isDesktop()) {
        var headerHeight = ($('.header-banner').outerHeight() || 0) + $('.header').outerHeight();
        var firstLevelHeight = $('.dropdown--first-level').outerHeight();
        var menuPosition = window.innerHeight - headerHeight;

        allDropdownMenus.css('top', '-' + headerHeight + 'px')
            .css('padding-top', headerHeight + firstLevelHeight + 'px');

        allDropdownMenus.find('.dropdown-menu__container').css('height', menuPosition);
    } else {
        var menuGroupHeight = $('.menu-group').outerHeight();
        var navItemHeight = $('.navbar-nav .nav-item').first().outerHeight();
        allDropdownMenus.height(menuGroupHeight - navItemHeight + 'px');
        setDropdownMenuLateralTranslate(selectedDropdownMenu);
    }
}

/**
 * Calculate the height and position of the menu based on the screen width and header height
 */
function calculateMenuPosition() {
    var headerHeight = ($('.header-banner').outerHeight() || 0) + $('.header').outerHeight();

    if (isDesktop()) {
        $('.main-menu').css('top', headerHeight + 'px');
    } else {
        var menuHeight = window.innerHeight - headerHeight;
        $('.main-menu')
            .css('top', headerHeight + 'px')
            .css('height', menuHeight + 'px');
    }
}

/**
 * Hide selected dropdown and remove selection
 * @param {boolean} removeSelection or not
 */
function hideSelectedFirstLevelDropdown(removeSelection = false) {
    var selectedDropdown = $('.dropdown--first-level.show');
    selectedDropdown.removeClass('show');
    selectedDropdown.children('.dropdown-menu').removeClass('show');
    selectedDropdown.children('.nav-link').attr('aria-expanded', 'false');

    if (removeSelection) {
        selectedDropdown.removeClass('is-selected');
    }
}

/**
 * Change Selected Sublevel
 * @param {Object} selectedItem Dropdown toggle
 * @param {Object} selectedItemParent Parent dropdown
 * @param {int} level Submenu level
 */
function changeSelectedSublevel(selectedItem = null, selectedItemParent = null, level = null) {
    var siblingDropdowns;

    if (!selectedItem || selectedItem.length < 1 || !selectedItemParent || selectedItemParent.length < 1 || !level) {
        return;
    }

    if (level === 2) {
        siblingDropdowns = $('.dropdown--first-level.show .dropdown--second-level');
    } else if (level === 3) {
        siblingDropdowns = selectedItemParent.siblings('.dropdown-item');
    }

    if (siblingDropdowns.length > 0) {
        siblingDropdowns.removeClass('show is-selected');
        siblingDropdowns.each(function () {
            $(this).find('.dropdown-toggle').attr('aria-expanded', 'false');
        });
    }

    if (!selectedItemParent.hasClass('show')) {
        selectedItemParent.addClass('show is-selected');
        selectedItem.attr('aria-expanded', 'true');
    } else if (level === 3) {
        selectedItemParent.removeClass('show is-selected');
        selectedItem.attr('aria-expanded', 'false');
    }
}

/**
 * Change selected second level dropdown
 * @param {Object} selectedItem Dropdown toggle
 * @param {Object} selectedItemParent Parent dropdown
 */
function changeSelectedSecondLevel(selectedItem, selectedItemParent) {
    if (!selectedItem || selectedItem.length < 1 || !selectedItemParent || selectedItemParent.length < 1) {
        return;
    }

    if (isDesktop()) {
        hideSublevels();
        var categoryID = selectedItem.data('category');
        var firstLevelParent = selectedItemParent.closest('.dropdown--first-level');
        var selectedThirdLevel = firstLevelParent.find('.dropdown-menu__container--third-level [data-category="' + categoryID + '"] .dropdown-third-level--desktop');
        selectedItemParent.addClass('show is-selected');
        selectedThirdLevel.closest('.dropdown-menu__container--third-level').show();
        selectedThirdLevel.show();
    } else if (selectedItemParent.hasClass('show')) {
        hideSublevels();
    } else {
        changeSelectedSublevel(selectedItem, selectedItemParent, 2);
    }
}

/**
 * Show selected first level dropdown and default child category
 * @param {Object} selectedItem Dropdown toggle
 * @param {Object} selectedItemParent Parent dropdown
 */
function showSelectedFirstLevelDropdown(selectedItem, selectedItemParent) {
    selectedItemParent.addClass('show is-selected');
    selectedItem.siblings('.dropdown-menu').addClass('show is-selected');
    $('.main-menu__navbar').addClass('child-is-opened');
    selectedItem.attr('aria-expanded', 'true').focus();

    if (selectedItemParent.find('.dropdown--second-level.is-selected').length < 1) {
        var defaultCategoryItem;
        var defaultCategoryLevel;
        var defaultCategoryID = selectedItemParent.data('default-category');

        if (defaultCategoryID && defaultCategoryID !== '') {
            defaultCategoryItem = $('.dropdown-menu__container:not(.dropdown-menu__container--third-level) [data-category="' + defaultCategoryID + '"]');
            defaultCategoryLevel = defaultCategoryItem.data('menu-level');
        }

        if (defaultCategoryItem && defaultCategoryItem.length > 0) {
            if (defaultCategoryLevel === 3) {
                changeSelectedSecondLevel(selectedItem, selectedItemParent);
            } else if (defaultCategoryLevel === 2) {
                changeSelectedSecondLevel(defaultCategoryItem, defaultCategoryItem.parent());
            }
        }
    }

    $('.dropdown-menu').scrollTop(0);
}

/**
 * Open selected dropdown or the first dropdown if none is selected
 */
function openDefaultFirstLevelDropdown() {
    if ($('.main-menu__navbar').hasClass('child-is-opened')) {
        return;
    }

    let selectedDropdown = $('.dropdown--first-level').first();
    let nonDefaultDropdown = false;
    let selectedItemParent;
    let selectedItem;
    let defaultCategoryID;
    let defaultCategoryItem;
    let defaultCategoryLevel;

    if (isFirstLevelSelected()) {
        nonDefaultDropdown = true;
        selectedDropdown = $('.dropdown--first-level.is-selected');
    }

    if (!nonDefaultDropdown
        && $('.js-current-category').length > 0
        && $('.js-current-category').data('category') !== 'non-category'
    ) {
        defaultCategoryID = $('.js-current-category').data('category');
        defaultCategoryItem = $('.main-menu [data-category="' + defaultCategoryID + '"]');

        if (defaultCategoryItem && defaultCategoryItem.length > 0) {
            nonDefaultDropdown = true;
            defaultCategoryLevel = defaultCategoryItem.data('menu-level');
            selectedDropdown = defaultCategoryItem.closest('.dropdown--first-level');

            if (defaultCategoryLevel > 1) {
                defaultCategoryItem = $('.dropdown-menu__container:not(.dropdown-menu__container--third-level) [data-category="' + defaultCategoryID + '"]');

                if (defaultCategoryLevel === 3) {
                    defaultCategoryItem.addClass('is-active');
                    $('.dropdown-menu__container--third-level [data-category="' + defaultCategoryID + '"]').addClass('is-active');
                    selectedItemParent = defaultCategoryItem.closest('.dropdown--second-level');
                    selectedItem = selectedItemParent.find('.dropdown-toggle').first();
                    changeSelectedSecondLevel(selectedItem, selectedItemParent);
                } else if (defaultCategoryLevel === 2) {
                    changeSelectedSecondLevel(defaultCategoryItem, defaultCategoryItem.parent());
                }
            }
        }
    }

    if (!nonDefaultDropdown && isContentSegmentAssociatedCategory()) {
        selectedDropdown = getContentSegmentCategory(contentSegmentCookie.getContentSegmentValue());
    }

    setDropdownMenuPosition(selectedDropdown.find('.dropdown-menu').first());
    showSelectedFirstLevelDropdown(selectedDropdown.find('.dropdown-toggle').first(), selectedDropdown);
}

/**
 * Clear styles and set new styles for current device
 * @param {MediaQueryList} e Media Query List for match media
 */
function setDeviceStyles(e) {
    if (e.matches) {
        clearDesktopStyles();
        $('.dropdown--first-level .dropdown-third-level--desktop').hide();
        $('.dropdown--first-level .dropdown-menu__container--third-level').hide();
        $('.homepage').css('padding-top', '0px');
    } else if (!e.matches) {
        clearMobileStyles();

        var mainMenuHeight = ($('.main-menu--home').length > 0) ? $('.main-menu--home').outerHeight() : null;

        if (mainMenuHeight) {
            $('.homepage').css('padding-top', mainMenuHeight + 'px');
        }

        if ($('.dropdown--second-level.is-selected').length > 0) {
            var selectedItemParent = $('.dropdown--second-level.is-selected');
            changeSelectedSecondLevel(selectedItemParent.find('.dropdown-toggle--second-level'), selectedItemParent);
        }
    }

    floatingNavigation.setFloatingNavigationPosition();
    floatingNavigation.setFloatingPlpNavigationPosition();
    calculateMenuPosition();
    setDropdownMenuPosition();
}

module.exports = function () {
    if (!window.sessionStorage.getItem('hidden_header_banner') || window.sessionStorage.getItem('hidden_header_banner') < 0) {
        // Show ticker
        $('.header-banner').removeClass('d-none');

        // Lower logo in home page
        if ($('.pd-intro-image').length > 0) {
            var tickerHeight = $('.header-banner').innerHeight();
            $('.pd-intro-image').css('top', tickerHeight);
        }

        floatingNavigation.setFloatingNavigationPosition();
        floatingNavigation.setFloatingPlpNavigationPosition();
        calculateMenuPosition();
        setDropdownMenuPosition();
    }

    $('.header-banner .close').on('click', function () {
        // Hide ticker and set session item.
        $('.header-banner').addClass('d-none');
        window.sessionStorage.setItem('hidden_header_banner', '1');
        floatingNavigation.setFloatingNavigationPosition();
        floatingNavigation.setFloatingPlpNavigationPosition();
        calculateMenuPosition();
        setDropdownMenuPosition();
    });

    setDeviceStyles(mql);
    mql.addListener(setDeviceStyles);

    keyboardAccessibility('.main-menu .nav-link, .main-menu .dropdown-link',
        {
            40: function (menuItem) { // down
                if (menuItem.hasClass('nav-item')) { // top level
                    $('.navbar-nav .show').removeClass('show')
                        .children('.dropdown-menu')
                        .removeClass('show');
                    menuItem.addClass('show').children('.dropdown-menu').addClass('show');
                    menuItem.find('ul > li > a')
                        .first()
                        .focus();
                } else {
                    menuItem.removeClass('show').children('.dropdown-menu').removeClass('show');
                    if (!(menuItem.next().length > 0)) { // if this is the last menuItem
                        menuItem.parent().parent().find('li > a') // set focus to the first menuitem
                            .first()
                            .focus();
                    } else {
                        menuItem.next().children().first().focus();
                    }
                }
            },
            39: function (menuItem) { // right
                if (menuItem.hasClass('nav-item')) { // top level
                    menuItem.removeClass('show').children('.dropdown-menu').removeClass('show');
                    $(this).attr('aria-expanded', 'false');
                    menuItem.next().children().first().focus();
                } else if (menuItem.hasClass('dropdown')) {
                    menuItem.addClass('show').children('.dropdown-menu').addClass('show');
                    $(this).attr('aria-expanded', 'true');
                    menuItem.find('ul > li > a')
                        .first()
                        .focus();
                }
            },
            38: function (menuItem) { // up
                if (menuItem.hasClass('nav-item')) { // top level
                    menuItem.removeClass('show').children('.dropdown-menu').removeClass('show');
                } else if (menuItem.prev().length === 0) { // first menuItem
                    menuItem.parent().parent().removeClass('show')
                        .children('.nav-link')
                        .attr('aria-expanded', 'false');
                    menuItem.parent().children().last().children() // set the focus to the last menuItem
                        .first()
                        .focus();
                } else {
                    menuItem.prev().children().first().focus();
                }
            },
            37: function (menuItem) { // left
                if (menuItem.hasClass('nav-item')) { // top level
                    menuItem.removeClass('show').children('.dropdown-menu').removeClass('show');
                    $(this).attr('aria-expanded', 'false');
                    menuItem.prev().children().first().focus();
                } else {
                    menuItem.closest('.show').removeClass('show')
                        .closest('li.show').removeClass('show')
                        .children()
                        .first()
                        .focus()
                        .attr('aria-expanded', 'false');
                }
            },
            27: function (menuItem) { // escape
                var parentMenu = menuItem.hasClass('show')
                    ? menuItem
                    : menuItem.closest('li.show');
                parentMenu.children('.show').removeClass('show');
                parentMenu.removeClass('show').children('.nav-link')
                    .attr('aria-expanded', 'false');
                parentMenu.children().first().focus();
            }
        },
        function () {
            return $(this).parent();
        });

    $('.dropdown:not(.disabled) [data-toggle="dropdown"]')
        .on('click', function (e) {
            e.preventDefault();
            calculateMenuPosition();

            if ($('.main-menu--home').length < 1) {
                $('.modal-background').show();
            }

            var selectedItem = $(this);
            var selectedItemParent = selectedItem.parent();

            if (selectedItemParent.hasClass('dropdown--first-level')) {
                var contentSegment = selectedItem.data('category-content-segment');
                contentSegmentCookie.setContentSegmentValue(contentSegment);

                if (!selectedItemParent.hasClass('show')) {
                    if (!isDesktop()) {
                        setDropdownMenuLateralTranslate(selectedItem.siblings('.dropdown-menu'));
                    }

                    hideSelectedFirstLevelDropdown(true);
                    showSelectedFirstLevelDropdown(selectedItem, selectedItemParent);
                }
            } else if (selectedItem.hasClass('dropdown-toggle--second-level')) {
                changeSelectedSecondLevel(selectedItem, selectedItemParent);
            } else if (selectedItem.hasClass('dropdown-toggle--sublevel')) {
                changeSelectedSublevel(selectedItem, selectedItemParent, 3);
            }
        })
        .on('mouseenter', function () {
            if (isDesktop() && !$('.main-menu__navbar').hasClass('child-is-opened')) {
                overlay.stopPageScroll();

                if ($('.main-menu--home').length < 1) {
                    $('.modal-background').show();
                }

                openDefaultFirstLevelDropdown();
            }
        });

    $('.main-menu__navbar').on('mouseleave', function () {
        if (isDesktop()) {
            $(this).removeClass('child-is-opened');
            overlay.resumePageScroll();
            hideSelectedFirstLevelDropdown(false);
            closeMenu();
        }
    });

    $('.modal-background').click(function (e) {
        if ($('.main-menu').hasClass('in')) {
            e.preventDefault();
            closeMenu();
            $('.modal-background').hide();
            overlay.resumePageScroll();
        } else if ($('.site-search').hasClass('show')) {
            overlay.resumePageScroll();
            $('.modal-background').hide();
            $('.site-search').removeClass('show').slideUp(350);
        }
    });

    $('.navbar-toggler')
        .on('click', function (e) {
            e.preventDefault();
            calculateMenuPosition();

            if (!isDesktop()) {
                if ($('.main-menu').hasClass('in')) {
                    closeMenu();
                    $('.modal-background').hide();
                    overlay.resumePageScroll();
                    $('body').trigger('menu:hide');
                    $('.ad').removeClass('inverted');
                    $('.adn-club').removeClass('inverted');
                    $('.adn-inverted').removeClass('menu-opened');
                    $('.adn-minicart-inverted').removeClass('menu-opened');
                } else {
                    overlay.stopPageScroll();
                    $('.site-search').hide();
                    $('.navbar-toggler').addClass('is-opened');
                    $('.main-menu').addClass('in').show();
                    $('.ad').addClass('inverted');
                    $('.adn-club').addClass('inverted');
                    $('.adn-inverted').addClass('menu-opened');
                    $('.adn-minicart-inverted').addClass('menu-opened');

                    openDefaultFirstLevelDropdown();

                    $('.main-menu').attr('aria-hidden', function (index, attr) {
                        return attr === false;
                    });
                    $('.main-menu').siblings().attr('aria-hidden', function (index, attr) {
                        return attr === false;
                    });
                    $('header').siblings().attr('aria-hidden', function (index, attr) {
                        return attr === false;
                    });
                    $('body').trigger('menu:show');
                }
            }
        }).on('mouseenter', function () {
            if (isDesktop()) {
                overlay.stopPageScroll();
                $('.site-search').hide();
                $('.navbar-toggler').addClass('is-opened');
                $('.main-menu').addClass('in').show();

                if ($('.main-menu--home').length < 1) {
                    $('.modal-background').show();
                }

                calculateMenuPosition();
                openDefaultFirstLevelDropdown();

                $('.main-menu').attr('aria-hidden', function (index, attr) {
                    return attr === false;
                });
                $('.main-menu').siblings().attr('aria-hidden', function (index, attr) {
                    return attr === false;
                });
                $('header').siblings().attr('aria-hidden', function (index, attr) {
                    return attr === false;
                });
                $('body').trigger('menu:show');
            }
        });

    $('.navbar-header .search__toggler').on('click', function (e) {
        e.preventDefault();
        closeMenu();

        if ($('.main-menu--home').length < 1) {
            $('.modal-background').show();
        }

        $(this).siblings('.site-search').addClass('show').slideDown(350);
        $('.site-search .search-field').focus();
    });

    $('.navbar-header .search .reset-button').on('click', function () {
        overlay.resumePageScroll();
        $('.modal-background').hide();
        $('.site-search').removeClass('show').slideUp(350);
    });

    // Close menu and searcher when the minicart opens
    $('.minicart .hide-no-link').on('click', function () {
        if (!$('.minicart .popover').hasClass('show')) {
            closeMenu();
            $('.site-search').hide();
        }
    });
};
