const copy = require('copy-to-clipboard');
const onDOMContentLoaded = require('../utilities/dom/onDOMContentLoaded');
const { delegateEvent } = require('../utilities/javascriptUtils');

/**
 * Handle the click of a `button.btn-copy-to-clipboard`, copying to the
 * clipboard the text specified in the attribute `data-copy-text`.
 * @param {Event} event Click event
 * @param {Element} target The element that matched the selector
 * @throws {TypeError}
 */
function handleButtonClick(event, target) {
    var textToCopy = target.getAttribute('data-copy-text');
    if (textToCopy) {
        copy(textToCopy);
    } else {
        throw new TypeError('target has no `data-copy-text` attribute');
    }
}

/**
 * Initialize the component functionality
 */
function init() {
    onDOMContentLoaded(function () {
        delegateEvent('click', '.btn-copy-to-clipboard', handleButtonClick);
    });
}

module.exports = {
    init: init
};
