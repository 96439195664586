const emojiRegex = require('emoji-regex');

/**
 * Remove all emoji
 * @param {string} str - The string to clean
 * @returns {string} - New string without emojis
 */
function filterEmoji(str) {
    return str.replace(emojiRegex(), '');
}

/**
 * Removes a custom character from both ends of target string and returns a new
 * string, without modifying the original string.
 * If no char is passed, it will run an standard String.prototype.trim().
 *
 * @param {string} target string to process
 * @param {string} [char] character to remove
 * @returns {string} trimmed string
 * @throws RangeError
 *
 * @example trim('/path/to/any/dir/', '/') => 'path/to/any/dir'
 * @example trim('  this is an usual trim  ') => 'this is an usual trim'
 */
function trim(target, char) {
    if (arguments.length < 2) {
        return target.trim();
    }

    if (char.length !== 1) {
        throw new RangeError(
            'The second param must be one char only. Other string lengths are not supported'
        );
    }

    let start = 0;
    while (target[start] === char && start < target.length) {
        start += 1;
    }

    let end = target.length - 1;
    while (target[end] === char && end > start) {
        end -= 1;
    }

    return target.substring(start, end + 1);
}

module.exports = {
    filterEmoji: filterEmoji,
    trim: trim
};
