const { compose } = require('../../../../../utilities/fp');
const getAnalyticsConfig = require('../../../../../common/getAnalyticsConfig');
const { createEcommerceListObject } = require('../../../models/ecommerceListObject');
const generalStore = require('../../../stores/generalStore');
const { trackUpdate } = require('../lastUpdate');
const { isEmpty } = require('../../../../../utilities/type');

const analyticsConfig = getAnalyticsConfig();

const store = generalStore;
const itemName = analyticsConfig.localforage.stores.general.items.listContext;

/**
 * @typedef {import('../../../models/ecommerceListObject').EcommerceListObject} EcommerceListObject
 */

/**
 * Get the datalayer list context info (page, component, category)
 * @async
 * @returns {Promise<EcommerceListObject>} - List object with info of the
 * context
 */
async function getDatalayerListContext() {
    // Get stored DTO and build the model
    return store.getItem(itemName).then(
        // Prevent first empty context
        listContext => listContext ?? createEcommerceListObject({})
    );
}

/**
 * Save in local storage the info of a EcommerceListObject
 * @param {EcommerceListObject} newDatalayerListContext - List
 * object with info of the context (page, component, category)
 * @returns {Promise} Writing result
 */
function setDatalayerListContext(newDatalayerListContext) {
    return store.setItem(itemName, newDatalayerListContext).then(trackUpdate);
}

/**
 * Delete the value of all properties of the context
 * @returns {Promise} Operation result
 */
const resetDatalayerListContext = compose(
    setDatalayerListContext,
    createEcommerceListObject,
    () => ({})
);

/**
 * Merge in local storage the info of a EcommerceListObject
 * @param {Object} data Data to update
 * @param {EcommerceListObject} [data.toSet] List object with new info of the
 * context (page, component, category, prefix...)
 * @param {string[]} [data.toReset] Array of context keys to reset
 * @returns {Promise} Operation result
 */
function updateDatalayerListContext({
    toSet,
    toReset
}) {
    return getDatalayerListContext().then(context => {
        if (!isEmpty(toSet)) {
            Object.keys(toSet).forEach(key => {
                if (toSet[key]) {
                    context[key] = toSet[key];
                }
            });
        }
        if (!isEmpty(toReset)) {
            toReset.forEach(key => {
                context[key] = undefined;
            });
        }
        return setDatalayerListContext(context);
    });
}

const listContextStore = {
    get: getDatalayerListContext,
    reset: resetDatalayerListContext,
    set: setDatalayerListContext,
    update: updateDatalayerListContext
};

module.exports = listContextStore;
