const onDOMContentLoaded = require('./onDOMContentLoaded');
const uid = require('../uid/non-secure');

/**
 * Create a MutationObserver to execute a callback when a node that suits the
 * selector is added.
 * @param {string} nodeSelector - selector to query the node to DOM
 * @param {function(Element):void} callback - Callback for every added node
 * @returns {MutationObserver} - new MutationObserver
 */
function createAddedNodeObserver(nodeSelector, callback) {
    const callbackOnAddedNode = (addedNode) => {
        if (addedNode.matches?.(nodeSelector)) {
            callback(addedNode);
        } else if (addedNode.querySelectorAll) {
            [...addedNode.querySelectorAll(nodeSelector)].forEach(callback);
        }
    };

    const processEachMutation = (mutation) => {
        Array.prototype.forEach.call(
            mutation.addedNodes || [],
            callbackOnAddedNode
        );
    };

    return new MutationObserver(function (mutationRecords) {
        mutationRecords.forEach(processEachMutation);
    });
}

/**
 * Execute a callback when a node that suits the selector is added. The callback
 * is executed over the already rendered nodes too.
 * @param {string} nodeSelector - selector to query the node to DOM
 * @param {function(Element):void} callback - Callback for every added node
 */
function whenNodeIsAdded(nodeSelector, callback) {
    const alreadyHandledFlag = `data-addition-handled-${uid()}`;

    /**
     * Run the callback only once
     * @param {Element} node - Node to pass to callback
     * @returns {void}
     */
    function runOnceForNode(node) {
        if (node.getAttribute(alreadyHandledFlag) === 'true') return;
        callback(node);
        node.setAttribute(alreadyHandledFlag, 'true');
    }

    onDOMContentLoaded(() => {
        // Initially rendered nodes
        [...document.querySelectorAll(nodeSelector)].forEach(runOnceForNode);
        // Dinamically added ones
        const nodeObserver = createAddedNodeObserver(
            nodeSelector,
            runOnceForNode
        );
        nodeObserver.observe(document, {
            childList: true,
            subtree: true
        });
    });
}

module.exports = whenNodeIsAdded;
