'use strict';

module.exports = {
    events: {
        action: 'w_analytics_event',
        cookie: 'cookielegalupdate',
        pageView: 'w_pageview'
    },
    eventCategories: {
        addToCart: 'ECC - Add To Cart',
        adnEcosistema: 'menu_principal',
        adnClub1: 'menu_principal',
        adnClub2: 'catalogo',
        checkoutStep1: 'ECC - Checkout Step1',
        checkoutStep2: 'ECC - Checkout Step2',
        checkoutStep3: 'ECC - Checkout Step3',
        checkoutStep4: 'ECC - Checkout Step4',
        checkoutStep5: 'ECC - Checkout Step5',
        checkoutStep6: 'ECC - Checkout Step6',
        click: 'ECC - Click Producto',
        home: 'home',
        impression: 'ECC - Impressions',
        purchase: 'ECC - Purchase',
        removeFromCart: 'ECC - Remove From Cart',
        checkout: {
            cart: [1, 'ECC - Checkout Step1'],
            initCheckout: [2, 'ECC - Checkout Step2'],
            shipping: [3, 'ECC - Checkout Step3'],
            payment: [4, 'ECC - Checkout Step4'],
            placeOrder: [5, 'ECC - Checkout Step5'],
            submitted: [6, 'ECC - Checkout Step6']
        }
    },
    eventActions: {
        adn: 'click_adn',
        category: 'categoria',
        segmentModal: 'click_genero_home'
    },
    listPrefixes: {
        cart: '/cesta',
        minicart: '/minicesta',
        catalog: '/catalogo/',
        completeTheLook: '/productos_completa_look/',
        direct: '/directo/',
        floatingNavigation: '/flotantes/',
        home: '/home',
        saveForLater: '/guardar_para_mas_tarde/',
        search: '/buscador/',
        shopTheLook: '/productos_shop_the_look/',
        similarProducts: '/productos_similares/',
        wishlist: '/wishlist/'
    },
    localStorageItems: {
        productInfo: 'analytics_product_info', // Ultima lista en la que ha aparecido
        checkoutInfo: 'analytics_checkout_info',
        clickedCategoryList: 'analytics_clicked_category_list',
        pageDesignerListPrefix: 'analytics_page_designer_list_prefix',
        isPageDesignerList: 'is_page_designer_list'
    }
};
