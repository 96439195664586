const checkIfCurrentPage = require('./checkIfCurrentPage');
const getAnalyticsConfig = require('../../../common/getAnalyticsConfig');

const analyticsConfig = getAnalyticsConfig();

/**
 * Get the root element of the storepage (Page Designer) which contain a tile
 * if it is passed as param. Otherwise will search in document.
 *
 * @param {Element} [productImpressionElement] - target productImpressionElement
 * @returns {Element|null} - Parent storepage
 */
const queryStorepageRootElement = (productImpressionElement) => {
    var storepageElement = null;
    if (checkIfCurrentPage().isLandingPageOfCategory) {
        storepageElement = productImpressionElement
            ? productImpressionElement.closest('.storepage[data-category]')
            : document.querySelector('.storepage[data-category]');
    }
    return storepageElement;
};

/**
 * Get the parent list of a tile, which contains the name of the list to send to
 * datalayer. If there is no one, search for the root element of a storepage
 * (Page Designer), which contains all the impressions in the main content, as
 * a PLP with an unique list.
 *
 * @param {Element} productImpressionElement - target productImpressionElement
 * @returns {Element} - Parent datalayer list
 */
const queryListRootElement = (productImpressionElement) => {
    let root = productImpressionElement.closest(`[${analyticsConfig.dataAttributes.list}]`) ?? false;
    if (!root) {
        root = queryStorepageRootElement(productImpressionElement);
    }
    return root;
};

/**
 * Get the node list of every descendant element which produces a product
 * impression. The result will usually be the root element of a product tile,
 * but not always.
 *
 * @param {Element} [queryParent] - The origin element of the query
 * @returns {NodeList} - List of elements
 */
const queryProductImpressionElements = (queryParent) => {
    let origin = queryParent;
    if (!origin) {
        origin = document;
    }
    return origin.querySelectorAll(
        analyticsConfig.selectors.productImpressionElement
    );
};

/**
 * Get the parent product element from a descendant element (ex: clicked link).
 * The result will usually be the root element of a product tile, but every
 * element which produces a product impression can be returned.
 *
 * @param {Element} element - descendant element
 * @returns {Element} - Ancestor product element
 */
const queryProductImpressionElementFromDescendant = (element) => {
    return element.closest(analyticsConfig.selectors.productImpressionElement);
};

module.exports = {
    queryListRootElement,
    queryProductImpressionElements,
    queryProductImpressionElementFromDescendant,
    queryStorepageRootElement
};
