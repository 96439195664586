const PIPELINES_URLS = window.PIPELINES_URLS;
const base = require('./base');
const deviceUtilities = require('../utilities/deviceUtilities');
const pageUtilities = require('../utilities/pageUtilities');
const urlUtils = require('../utilities/urlUtilities');
var throttle = require('lodash/throttle');
const { processDataLayerCartActionOnProduct } = require('../utilities/datalayerHelpers');

const productTileSizeInfoVisibleClass = 'product-info__sizes-info--visible';

/**
 * Shows tile item sizes, only for desktop screen sizes.
 * @param {Object} productTileWrapper Product Tile wrapper
 * @param {Object} associatedSizeInfo size info block to show
 */
function showDesktopTileSizes(productTileWrapper, associatedSizeInfo) {
    if (!productTileWrapper || !associatedSizeInfo) {
        return;
    }

    var productTileWrapperHeight = productTileWrapper.outerHeight(false);
    var productTileBodyHeight = productTileWrapper.find('.tile-body').outerHeight(false);
    var productImageHeight = productTileWrapper.find('.image-container').outerHeight(false);
    var associatedSizeInfoHeight = associatedSizeInfo.outerHeight(true);
    var sizeInfoMaxHeight = productImageHeight * 0.8; // The percentage of maximum height that the size selector can fill

    if (associatedSizeInfoHeight > sizeInfoMaxHeight) {
        associatedSizeInfoHeight = sizeInfoMaxHeight;
    }

    if (!productTileWrapperHeight || !productTileBodyHeight || !productImageHeight || !associatedSizeInfoHeight) {
        return;
    }

    associatedSizeInfo.addClass(productTileSizeInfoVisibleClass);
    associatedSizeInfo
        .css('bottom', 'inherit')
        .css('max-height', sizeInfoMaxHeight + 'px')
        .css('top', productTileWrapperHeight - productTileBodyHeight - associatedSizeInfoHeight + 'px');
}

/**
 * Closes Tile size selector, in mobile and desktop
 * @param {Object} selector size info block(s) to close
 * @param {string} device Device size selector to close
 * @param {boolean} avoidTransition Remove size selector closing transition
 */
function closeTileSizeSelector(selector, device, avoidTransition) {
    if (device === 'desktop' || (!device && !deviceUtilities.isSmallerThan768())) {
        selector.css('top', '100%');
        selector.css('bottom', 'inherit');
    } else if (device === 'mobile' || deviceUtilities.isSmallerThan768()) {
        if (avoidTransition) {
            selector.addClass('no-transition');
            selector.css('bottom', '-100%');
            setTimeout(function () {
                selector.removeClass('no-transition');
            }, 400);
        } else {
            selector.css('bottom', '-100%');
        }
    }
    selector.removeClass(productTileSizeInfoVisibleClass);
}

/**
 * Clones tile item sizes to a common stiky wrapper and
 * shows it, only for mobile screen sizes.
 * @param {Object} associatedSizeInfo size info block to show
 * @param {string} productTilePid item PID
 */
function showMobileTileSizes(associatedSizeInfo, productTilePid) {
    if ($('.product-tile-cloned-sizes').length < 1) {
        $('body').append('<div class="product-tile-cloned-sizes"></div>');
    }

    let clonedContainer = $('.product-tile-cloned-sizes');

    let clonedSizesInfo = clonedContainer.find('.product-info__sizes-info[data-pid="' + productTilePid + '"]');

    if (clonedSizesInfo.length < 1) {
        associatedSizeInfo.clone().appendTo(clonedContainer).addClass('cloned');
        clonedSizesInfo = clonedContainer.find('.product-info__sizes-info[data-pid="' + productTilePid + '"]');
    }

    clonedSizesInfo.addClass(productTileSizeInfoVisibleClass);
    clonedSizesInfo
        .css('top', 'inherit')
        .css('bottom', '0');

    const scroll = require('../utilities/scroll');
    scroll.stopPageScroll();
}

/**
 * Add product tile to cart
 * @param {Object} element Selected size item
 */
function addTileToCartHandler(element) {
    const queryString = $(element).attr('rel');
    const variationGroupPid = $(element).closest('.product-tile__sizes-info').data('pid');

    if (!queryString) {
        return;
    }
    var $shopTheLookProductContainer = $(element).closest('.shop-the-look-card__product');
    var isShopTheLook = $shopTheLookProductContainer.length > 0;

    var $productContainer = $(element).closest('.product-tile-quick-shop');
    if ($productContainer.length < 1) {
        if (isShopTheLook) {
            $productContainer = $shopTheLookProductContainer;
        } else if (deviceUtilities.isSmallerThan768()) {
            $productContainer = $('.product-tile-quick-shop[data-pid="' + variationGroupPid + '"]');
        } else {
            $productContainer = $(element).closest('.product-tile');
        }
    }

    if ($productContainer) {
        $productContainer.spinner().start();
    }

    var pid = urlUtils.getUrlParameter('pid', queryString);
    var qty = urlUtils.getUrlParameter('quantity', queryString);
    var isCartPage = pageUtilities.isCartPage();
    var isEmptyCart = $('.full-cart').length <= 0;

    $.ajax({
        url: PIPELINES_URLS.cartAddProduct,
        method: 'POST',
        data: {
            pid: pid,
            quantity: qty,
            isCartPage: isCartPage,
            isEmptyCart: isEmptyCart
        },
        success: function (data) {
            var successData = data;
            successData.isShopTheLook = isShopTheLook;
            successData.isQuickShop = true;
            base.handlePostCartAdd(successData);
            $('body').trigger('product:afterAddToCart', successData);
            processDataLayerCartActionOnProduct(data.dataLayerCartActionOnProduct, element);
            $('body').trigger('closeTileSizeSelector', element);
            base.miniCartReportingUrl(successData.reportingURL);

            if (isCartPage) {
                var redemptionsAndCoupons = require('../components/redemptionsAndCoupons').methods;
                redemptionsAndCoupons.hideRedemptionAppliedMessage();
                $('body').trigger('cart:UpdateCartPage', data);
            }

            if (element.closest('.minicart-recommendations')) {
                var cartHelpers = require('../cart/cartHelpers');
                cartHelpers.methods.updateMinicartProductsTab();
            }
        },
        error: function (data) {
            base.handlePostCartAdd(data);
        },
        complete: function () {
            if ($productContainer) {
                $productContainer.spinner().stop();
            }
        }
    });
}

/**
 * Closes every product tile size info block
 */
function closeEveryTileSizeInfo() {
    const allVisibleTileSizeInfo = $(`.${productTileSizeInfoVisibleClass}`);
    closeTileSizeSelector(allVisibleTileSizeInfo, 'mobile');
    closeTileSizeSelector(allVisibleTileSizeInfo, 'desktop');
}

/**
 * Adds the resize event,
 * and then closes all product tiles sizes blocks.
 */
function closeSizesOnResize() {
    window.addEventListener('resize', throttle(function () {
        closeEveryTileSizeInfo();
    }, 300));
}

/**
 * Listen to sizes info external clicks
 */
function listenSizesInfoExternalClick() {
    $('body').on('click', (event) => {
        const isAnySizeInfoVisible = $(`.${productTileSizeInfoVisibleClass}`).length > 0;
        const clickedElementIsOutsideSizeInfo = event.target.closest(`.${productTileSizeInfoVisibleClass}`) === null;
        const clickedElementIsOutsideSizeInfoTrigger = event.target.closest('.js-product-tile-add-to-cart') === null;
        if (isAnySizeInfoVisible
            && clickedElementIsOutsideSizeInfo
            && clickedElementIsOutsideSizeInfoTrigger
        ) {
            closeEveryTileSizeInfo();
        }
    });
}

/**
 * Listen & handle click on add to cart element on product tiles
 */
function tileAddToCartButton() {
    $('body').on('click', '.js-product-tile-add-to-cart', function () {
        const quickShopButton = $(this);
        const pid = quickShopButton.data('pid');
        const isUniqueSize = quickShopButton.data('unique-size');
        const productTileWrapper = quickShopButton.closest('.product-tile');
        const associatedSizeInfo = productTileWrapper.find('.product-info__sizes-info');

        closeEveryTileSizeInfo();
        if (isUniqueSize || isUniqueSize === 'true') {
            associatedSizeInfo.find('.js-quickshop-option').trigger('click');
        } else if (deviceUtilities.isSmallerThan768()) {
            showMobileTileSizes(associatedSizeInfo, pid);
        } else {
            showDesktopTileSizes(productTileWrapper, associatedSizeInfo);
        }
    });
}

/**
 * Listen & handle click on quickshop enabled option elements
 */
function tileAddToCart() {
    $('body').on('click', '.js-quickshop-option:not(.disabled--unavailable)', function (event) {
        addTileToCartHandler(event.currentTarget);
    });
}

/**
 * Listen & handle click on the tile sizes close element
 */
function closeTileSizeSelectorButton() {
    $('body').on('closeTileSizeSelector', function (event, element) {
        if (deviceUtilities.isSmallerThan768()) {
            closeTileSizeSelector($('.product-tile-cloned-sizes .product-info__sizes-info'), 'mobile');
        } else if ($(element).length > 0) {
            closeTileSizeSelector($(element).closest('.product-info__sizes-info'), 'desktop');
        }
    });

    $('body').on('click', '.js-tile-sizes-close', function () {
        $('body').trigger('closeTileSizeSelector', $(this));
    });
}

module.exports = {
    init: function () {
        closeSizesOnResize();
        tileAddToCartButton();
        tileAddToCart();
        closeTileSizeSelectorButton();
        listenSizesInfoExternalClick();
    }
};
