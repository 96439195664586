const localforage = require('localforage');
const promisify = require('./promisify');
const { isFunction } = require('./type');

const dbNaming = (function () {
    const usedDbNamesTables = [];
    const areNamesReserved = (dbName, dbTable) => usedDbNamesTables.some(
        (pair) => pair[0] === dbName && pair[1] === dbTable
    );
    const reserveNames = (dbName, dbTable) => usedDbNamesTables.push(
        [dbName, dbTable]
    );

    return {
        areNamesReserved,
        reserveNames
    };
}());

/**
 * @typedef {object} LocalStore
 * @property {function(string):Promise<any|null>} getItem
 * @property {function(string, any):Promise<any>} setItem
 */

/**
 * Create new memory store
 * @async
 * @param {string} name The name of the instance
 * @returns {LocalStore} localforage-alike memory cache
 */
function createMemoryStore() {
    const items = {};
    const store = {
        getItem(key) {
            return items[key] ?? Promise.resolve(null);
        },
        has(key) {
            return key in items;
        },
        setItem(key, item) {
            const itemToStore = promisify(item);
            items[key] = itemToStore;
            return Promise.resolve(itemToStore);
        }
    };
    return store;
}

/**
 * Create a client persistent store
 * @param {Object} options Options
 * @param {string} options.dbName The name of the database
 * @param {string} options.dbTable The name of the table
 * @param {string} [options.createModelFromDto] The creator function to parse a
 * DTO into a model. If it is set, the item should be tried to store after a
 * `toDTO()` conversion.
 * @returns {LocalStore} The new store
 */
function createPersistentStore({
    dbName,
    dbTable,
    createModelFromDto
} = {}) {
    if (dbNaming.areNamesReserved(dbName, dbTable)) {
        throw new Error('You must choose another names. Useful info can be overwritten');
    }

    /**
     * @type {localforage} The storage facade for read/write into IndexedDB,
     * LocalStorage or WebSQL.
     */
    const store = localforage.createInstance({
        name: dbName,
        storeName: dbTable
    });

    /** @type {LocalStore} Used for delaying readings launched before */
    const runningPromises = createMemoryStore();
    dbNaming.reserveNames(dbName, dbTable);

    const mustStoreDtos = isFunction(createModelFromDto);

    const publicMethods = {
        getItem(key) {
            let item = promisify(null);
            if (runningPromises.has(key)) {
                item = runningPromises.getItem(key).then(() => store.getItem(key));
            } else {
                item = store.getItem(key);
            }
            if (mustStoreDtos) {
                item = item.then(
                    (dto) => dto !== null ? createModelFromDto(dto) : dto
                );
            }
            return item;
        },
        setItem(key, item) {
            let itemToStore = item;
            if (mustStoreDtos) {
                itemToStore = promisify(item).then(
                    model => isFunction(model.toDTO) ? model.toDTO() : model
                );
            }
            runningPromises.setItem(key, itemToStore);
            return store.setItem(key, itemToStore).then(() => item);
        }
    };

    return publicMethods;
}

module.exports = {
    createMemoryStore,
    createPersistentStore
};
