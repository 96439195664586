const getAnalyticsConfig = require('../../../common/getAnalyticsConfig');
const { createEcommerceListAndPosition } = require('../models/ecommerceListAndPosition');
const { createEcommerceListObject } = require('../models/ecommerceListObject');

const analyticsConfig = getAnalyticsConfig();

const unknownAddedToCartListAndPosition = createEcommerceListAndPosition({
    list: createEcommerceListObject({
        listPrefix: analyticsConfig.list.prefixes.unknownAddedToCartList
    })
});

const unknownClickedListAndPosition = createEcommerceListAndPosition({
    list: createEcommerceListObject({
        listPrefix: analyticsConfig.list.prefixes.unknownClickedList
    })
});

module.exports = {
    unknownAddedToCartListAndPosition,
    unknownClickedListAndPosition
};
