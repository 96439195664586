const getPipelinesUrls = require('../common/getPipelinesUrls');
const onDOMContentLoaded = require('../utilities/dom/onDOMContentLoaded');
const { isProductDetailPage } = require('../utilities/pageUtilities');

// This is a special var name for webpack partials processing
// eslint-disable-next-line camelcase, no-undef
__webpack_public_path__ = getPipelinesUrls().staticJs;

let importedSwiper;

/**
 * Import Swiper dynamic partial
 * @returns {Swiper} Swiper constructor
 */
function importSwiper() {
    const firstImportNeeded = !importedSwiper;
    if (firstImportNeeded) {
        importedSwiper = import(
            /* webpackChunkName: "swiper.js" */ 'swiper'
        ).then((module) => module.default);
    }

    return importedSwiper;
}

/**
 * Create a Swiper instance from the dynamic partial
 * @param {Element|string} swiperContainer - HTMLElement or string (with CSS
 * Selector) of swiper container HTML element. Required.
 * @param {Object} [swiperOptions] - object with Swiper parameters. Optional.
 * @returns {Promise.<Object>} - Swiper instance
 */
function createSwiper(swiperContainer, swiperOptions) {
    return importSwiper().then((Swiper) => {
        return new Swiper(swiperContainer, swiperOptions);
    });
}

/* -------------------------------------------------------------------------- *\
 * PRELOAD for PERFORMANCE                                                    *
 * -----------------------                                                    *
 * In order to improve performance (ex: pdp initial slider), do not wait to   *
 * creation calling to import the partial                                     *
\* -------------------------------------------------------------------------- */

/**
 * Trigger the library import based on some conditions
 * @returns {void}
 */
const preloadSwiperIfNeeded = () => {
    if (isProductDetailPage()) {
        importSwiper();
    }
};

/*
First attemp (the DOM can not be completed)
-------------------------------------------
This is posible because the prolead conditions consists in finding elements.
IMPORTANT: If any new condition is based on not finding an element, this attemp
must be omited and we must wait to DOMContentComplete
*/
preloadSwiperIfNeeded();

/* Second attemp (the DOM is completed) */
onDOMContentLoaded(preloadSwiperIfNeeded);

module.exports = createSwiper;
