const { queryListRootElement } = require('../../helpers/queryElement');
const {
    createEcommerceListAndPosition
} = require('../../models/ecommerceListAndPosition');
const { getStoredImpressionFieldObjectFromDOM } = require('../../helpers/impressions');
const { getProductPositionFromDOM } = require('./position');
const { getList } = require('../trackers/listTracker');
const getAnalyticsConfig = require('../../../../common/getAnalyticsConfig');
const ecommerceListObject = require('../../models/ecommerceListObject');

const analyticsConfig = getAnalyticsConfig();

/**
 * Get the list that will be used for the pageview event for PDP or PLP
 * @param {ecommerceListObject.EcommerceListObject} [context] Extra context data
 * @param {string} [context.categoryListId] Target category for direct accessing to PLP
 * @returns {EcommerceListAndPosition} The list for the direct access
 */
function getEcommerceListAndPositionForDirectAccess({ categoryListId } = {}) {
    const listDto = {
        listPrefix: analyticsConfig.list.prefixes.direct
    };
    if (categoryListId) {
        listDto.categoryListId = categoryListId;
    }
    const listDirect = createEcommerceListAndPosition({
        list: ecommerceListObject.createEcommerceListObject(listDto)
    });
    return listDirect;
}

/**
 * Get the list and position of a product extracting the data from the DOM
 * @async
 * @param {Element} productElement The root element of the product
 * @returns {Promise<EcommerceListAndPosition>} List and position
 */
function getEcommerceListAndPositionFromDOM(productElement) {
    const listRootElement = queryListRootElement(productElement);
    const listPromise = getList(listRootElement);
    const position = getProductPositionFromDOM(productElement, listRootElement);

    return listPromise.then(
        (list) => createEcommerceListAndPosition({
            list,
            position
        })
    );
}

/**
 * Get the list and position of a product element by means of looking into
 * impressions store before slower ways.
 * Faster than getEcommerceListAndPositionFromDOM()
 * @async
 * @param {Element} productElement The root element of the product
 * @returns {Promise<EcommerceListAndPosition>} List and position
 */
function getEcommerceListAndPositionFromImpressionOfElement(productElement) {
    return getStoredImpressionFieldObjectFromDOM(productElement).then(
        (impressionFieldObject) => impressionFieldObject !== null
            ? createEcommerceListAndPosition({
                list: impressionFieldObject.list,
                position: impressionFieldObject.position
            })
            : getEcommerceListAndPositionFromDOM(productElement) // fallback to slowest
    );
}

module.exports = {
    getEcommerceListAndPositionForDirectAccess,
    getEcommerceListAndPositionFromDOM,
    getEcommerceListAndPositionFromImpressionOfElement
};
