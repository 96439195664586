var throttle = require('lodash/throttle');
const { postalCodes } = window.BASE_CONFIG;

/**
 * Adds or remove a given class.
 * @param {Element} node Element to change classlist
 * @param {boolean} shouldAdd true if class has to be added
 * @param {string} classname css class to add or remove
 * @returns {undefined}
 */
function addOrRemoveClass(node, shouldAdd, classname) {
    return node.classList[shouldAdd ? 'add' : 'remove'](classname);
}

/**
 * Check if default option is empty
 * @param {Element} node Element to check options
 * @returns {undefined}
 */
function checkValueIsEmpty(node) {
    var isEmpty = false;
    if (node.tagName === 'SELECT' && (node.options.length > 0) && node.options[node.selectedIndex]) {
        var optionText = node.options[node.selectedIndex].text;
        isEmpty = optionText === '';
    } else {
        isEmpty = node.value === '';
    }
    return isEmpty;
}

/**
 * Sets the correct regex to postal code field
 * @param {string} selectedCountry - The selected country id
 * @param {string} selectedState - The selected state id
 * @param {boolean} isBillingStep - True if the current step is billing
 */
function resetCpRegex(selectedCountry, selectedState, isBillingStep) {
    if (!selectedCountry && !selectedState) {
        return;
    }

    const cpElem = isBillingStep
        ? $('#billingZipCode')
        : $('#zipCode.form-control, #shippingZipCodedefault, #billingZipCode');

    let regex;
    const countryRegex = postalCodes[selectedCountry];
    if (countryRegex && typeof countryRegex === 'object') {
        regex = selectedState ? countryRegex[selectedState] : countryRegex.default;
    } else if (countryRegex && typeof countryRegex !== 'object') {
        regex = countryRegex;
    } else {
        regex = postalCodes.default;
    }

    cpElem.attr('pattern', regex);
}

/**
 * Listen to the modified selects and modify the value of the original select
 * @param {Element} originalSelect Element to change value
 * @returns {undefined}
 */
function listenCustomizedSelects(originalSelect) {
    var customizedSelect = originalSelect.siblings('.customized-select');

    var listItems = customizedSelect.find('.customized-select__option');
    listItems.each(function () {
        $(this).click(function (e) {
            e.stopPropagation();
            if ($(this).data('attr-value')) {
                listItems.removeClass('selected');
                $(this).addClass('selected');
                originalSelect.val($(this).data('attr-value'));
                originalSelect.trigger('change');
            }
        });
    });
}

/**
 * Removes all fields values and
 *  removes validation classes (is-valid, is-invalid)
 *  removes class for label posicion (is-filled)
 * @param {JQuery} form - form to be reset
 */
function resetForm(form) {
    $('.form-control', form).val('');
    $('.custom-control-input, .form-check-input', form).prop('checked', false);
    form.find('.form-group').removeClass('is-filled is-valid is-invalid');
}

/**
 * Check if any required field is empty
 * Required radio buttons and checkboxes are not managed this way,
 * as they have to show an error instead of blocking submit state
 * @param {HTMLElement} form full form from DOM
 * @returns {boolean} isEmpty true if any required field is empty/not checked, false otherwise
 */
function isRequiredEmpty(form) {
    const requiredElements = $('input[required]:not([type=radio],[type=checkbox]),textarea[required],select[required]', form);
    let isEmpty = false;
    let i = 0;
    while (!isEmpty && i < requiredElements.length) {
        const element = requiredElements[i];
        if (element.value.length === 0) {
            isEmpty = true;
        }

        i += 1;
    }
    return isEmpty;
}

/**
 * Toggle disabled prop from submitButton if any required field is empty/not checked
 * @param {HTMLElement} form full form from DOM
 */
function handleSubmitState(form) {
    const isEmpty = isRequiredEmpty(form);
    const submitButton = $('[type=submit]:not([data-always-enabled-submit="true"])', form);
    if (submitButton.length > 0) {
        submitButton.prop('disabled', isEmpty);
        if (submitButton.hasClass('interaction--click-button-desktop')) {
            submitButton.addClass('interaction--enable-disable-desktop-form-v3');
        }
    }
}

module.exports = {
    resetForm: resetForm,
    checkValueIsEmpty: checkValueIsEmpty,
    addDelegatedListeners: function () {
        var self = this;
        $(document).on('change input', '.form-control', throttle(function (event) {
            self.modifyFormGroupState(event.currentTarget);
        }, 200));

        $(document).on('focusin', '.form-control', function () {
            var $formGroup = $(this).closest('.form-group');
            if ($formGroup.length > 0) {
                $formGroup.addClass('is-focused');
                $formGroup.removeClass('is-invalid');
                $formGroup.removeClass('is-valid');
                $(this).siblings('.invalid-feedback').empty();
            }
        });

        $(document).on('focusout', '.form-control', function () {
            var $formGroup = $(this).closest('.form-group');
            if ($formGroup.length > 0) {
                $formGroup.removeClass('is-focused');
            }
        });

        $('form.handled-submit-status').bind('blur change keyup input', function (elem) {
            var form = elem.target.closest('form.handled-submit-status');
            handleSubmitState(form);
        });
    },

    addInvalidInputClassToParent: function (node) {
        // The purpose of this function is to add is-invalid class to form-group parent
        // so the form control label can be modified when the parent has the class.
        // There was another way to do this, but we had to extend so many files just to do this
        // functionality, so we chose doing this way
        var $target = node;
        // Create an observer instance
        var observer = new MutationObserver(function (mutations) {
            mutations.forEach(function (mutation) {
                var $node = mutation.target;
                if ($($node).hasClass('is-invalid')) {
                    $($node).parent('.form-group').addClass('is-invalid');
                } else {
                    $($node).parent('.form-group').removeClass('is-invalid');
                    $($node).siblings('.invalid-feedback').empty();
                }
            });
        });

        observer.observe($target, { attributes: true });
    },

    init: function () {
        var self = this;
        this.addDelegatedListeners();

        $('.form-control').each(function () {
            self.modifyFormGroupState($(this).get(0));
            self.addInvalidInputClassToParent($(this).get(0));
        });

        $('.customized-original-select').each(function () {
            listenCustomizedSelects($(this));
        });

        $('form').each(function () {
            $(this).attr('novalidate', '');
        });

        const shippingCountryId = $('#country, #shippingCountrydefault').val();
        const shippingStateId = $('#state, #shippingStatedefault').val();
        if (shippingCountryId && shippingCountryId !== '') {
            resetCpRegex(shippingCountryId, shippingStateId, false);
        }

        const billingCountryId = $('#billingCountry').val();
        const billingStateId = $('#billingState').val();
        if (billingCountryId && billingCountryId !== '') {
            resetCpRegex(billingCountryId, billingStateId, true);
        }

        var handledSubmitStatusForms = $('form.handled-submit-status');
        if (handledSubmitStatusForms.length > 0) {
            handledSubmitStatusForms.each(function (e, form) {
                handleSubmitState(form);
            });
        }
    },

    modifyFormGroupState: function (formControl) {
        var formGroup = formControl.closest('.form-group');
        if (formGroup === null) return;
        if (!formGroup.classList.contains('force-filled')) {
            var isEmpty = checkValueIsEmpty(formControl);
            if (formGroup.closest('.filled-fields')) {
                isEmpty = false;
            }
            var isFilled = !isEmpty;
            addOrRemoveClass(formGroup, isFilled, 'is-filled');
        }
        formGroup.classList.remove('is-invalid');
        formControl.classList.remove('is-invalid');
        // Prevent custom selects, Stripe form-groups
        // from having the same styles as the basic ones
        if (formGroup.querySelector('select') || formGroup.querySelector('textarea') || formGroup.classList.contains('custom-select')
            || formGroup.querySelector('.payment-elements-input')
            || formGroup.querySelector('input:disabled')) {
            formGroup.classList.add('no-edit-icon');
        }
    }
};

$('.required-checkbox').on('click', function () {
    $(this).removeClass('is-invalid');
    $(this).siblings('.required-checkbox-text').empty();
});

/**
 * Show states related to selected country on state select
 * @param {jQueryObject} stateSelect state select element
 * @param {string} selectedCountry value of country select
 */
function resetStateSelect(stateSelect, selectedCountry) {
    var allOptions = $('#all-states-options').children();
    var regEx = '^' + selectedCountry + '-.*';

    var countryStates = allOptions.filter(function () {
        return this.id.match(regEx);
    });

    stateSelect.html('');
    stateSelect.val([]).refreshCssBlankPseudo();

    stateSelect.append('<option id="" value=""> </option>');
    countryStates.clone().appendTo(stateSelect);
}

/**
 * Show comunas related to selected state on comuna select
 * @param {jQueryObject} comunaSelect comuna select element
 * @param {string} selectedState value of state select
 */
function resetComunaSelect(comunaSelect, selectedState) {
    var allOptions = $('#all-comunas-options').children();
    var regEx = `^${selectedState}.*`;

    comunaSelect.html('');
    comunaSelect.val([]).refreshCssBlankPseudo();

    comunaSelect.append('<option id="" value=""> </option>');

    if (selectedState !== '') {
        var stateComunas = allOptions.filter(function () {
            return this.id.match(regEx);
        });

        stateComunas.clone().appendTo(comunaSelect);
    }
}

$('#country, #countryCode, #shippingCountrydefault').on('change', function () {
    var selectedCountry = $(this).val();
    var stateSelect = $('#state, #stateCode, #shippingStatedefault');
    resetStateSelect(stateSelect, selectedCountry);
    resetCpRegex(selectedCountry, null, false);
});

$('#billingCountry').on('change', function () {
    var billingSelectedCountry = $(this).val();
    var stateSelect = $('#billingState');
    resetStateSelect(stateSelect, billingSelectedCountry);
    resetCpRegex(billingSelectedCountry, null, true);
});

$('#shippingStatedefault, #billingState, #state').on('change', function () {
    const selectedState = $(this).val();
    const selectInputID = $(this).attr('id');
    let selectedCountry;
    let comunaSelect;
    let isBillingStep = false;

    switch (selectInputID) {
        case 'state':
            selectedCountry = $('#country').val();
            comunaSelect = $('#comuna');
            break;
        case 'shippingStatedefault':
            selectedCountry = $('#shippingCountrydefault').val();
            comunaSelect = $('#shippingComunadefault');
            break;
        case 'billingState':
            selectedCountry = $('#billingCountry').val();
            comunaSelect = $('#billingComuna');
            isBillingStep = true;
            break;
        default:
            break;
    }

    resetComunaSelect(comunaSelect, selectedState);
    if (selectedCountry) {
        resetCpRegex(selectedCountry, selectedState, isBillingStep);
    }
});
