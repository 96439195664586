const onDOMContentLoaded = require('../utilities/dom/onDOMContentLoaded');
const deviceUtilities = require('../utilities/deviceUtilities');

const TRANSPARENT_CLASS = 'page-header__transparent';
var HEADER;
var TRANSPARENT_HEADER_ENABLED;
var IS_TRANSPARENT_ONLY_MOBILE;

/**
 * Initialize Dom Constants
 */
function initConstants() {
    HEADER = document.getElementsByClassName('page-header')[0];
    TRANSPARENT_HEADER_ENABLED = HEADER?.dataset.transparent === 'true';
    IS_TRANSPARENT_ONLY_MOBILE = HEADER?.classList.contains('page-header__transparent--mobile');
}

/**
 * Removes transparent background and shows logo
 */
function goSolid() {
    HEADER.classList.remove(TRANSPARENT_CLASS);
}

/**
 * Adds transparent background and hides logo
 */
function goTransparent() {
    HEADER.classList.add(TRANSPARENT_CLASS);
}

/**
 * Inits Sctoll listener
 */
function initScrollListener() {
    if (!HEADER || !TRANSPARENT_HEADER_ENABLED) {
        return;
    }

    window.addEventListener('scroll', function () {
        if (IS_TRANSPARENT_ONLY_MOBILE && !deviceUtilities.isMobile()) {
            return;
        }

        const isScrollingUp = this.oldScroll >= this.scrollY;
        if (isScrollingUp) {
            goTransparent();
        } else {
            goSolid();
        }
        this.oldScroll = this.scrollY;
    });
}

/**
 * Handles Header transparency
 *  checking if menu or search are open
 */
function handleHeaderTransparency() {
    if (IS_TRANSPARENT_ONLY_MOBILE && !deviceUtilities.isMobile()) {
        return;
    }
    var menuIsOpen = document.querySelector('.navbar-header__menu')?.classList.contains('is-opened');
    var searchIsOpen = document.querySelector('.site-search')?.classList.contains('show');
    if (menuIsOpen || searchIsOpen) {
        goSolid();
    } else {
        goTransparent();
    }
}

/**
 * Init header event listeners
 *  for click and menu hover
 */
function initHeaderEventListeners() {
    if (!HEADER || !TRANSPARENT_HEADER_ENABLED) {
        return;
    }

    HEADER.addEventListener('click', ()=> {
        handleHeaderTransparency();
    });
    var menuTogglerButton = document.querySelector('.navbar-toggler.navbar-header__menu');
    menuTogglerButton?.addEventListener('mouseover', ()=> {
        handleHeaderTransparency();
    });
}

module.exports = {
    init: function () {
        onDOMContentLoaded(function () {
            initConstants();
            initScrollListener();
            initHeaderEventListeners();
        });
    }
};
