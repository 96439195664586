const createSwiper = require('../components/swiperFactory');

/**
 * Init swiper slider.
 */
function quickViewImageSlider() {
    // eslint-disable-next-line no-new
    createSwiper('.product-images-slider .swiper-container', {
        direction: 'horizontal',
        slidesPerView: 1,
        slidesPerColumn: 1,
        spaceBetween: 5,
        grabCursor: true,
        mousewheel: false,
        updateOnWindowResize: true,
        watchOverflow: true,
        loop: false,
        speed: 350,
        pagination: {
            el: '.swiper-pagination',
            clickable: true
        }
    });
}

module.exports = function () {
    quickViewImageSlider();
};
