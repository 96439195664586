/**
 * NOTE: Unused component, to activate it include "@import 'components/ellipsedText'" in /scss/ad/_main.scss
 *  and "processInclude(require('./components/ellipsedText'));" in /js/main.js
 */

/**
 * Manages ellipsed text 'show more'/'show less' buttons
 *  @param {HTMLElement} element clicked link
 */
function toogleEllipsedText(element) {
    element.closest('.ellipsed-text').toggleClass('ellipsed-text__show-all');
}

module.exports = function () {
    $('#maincontent').on(
        'click', '.ellipsed-text__link',
        function () {
            toogleEllipsedText($(this));
        }
    );
};
