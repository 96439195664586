const BASE_CONFIG = window.BASE_CONFIG;
const mqlDesktop = window.matchMedia('(max-width: ' + (BASE_CONFIG.responsive.screen__1025 - 1) + 'px)');
const mqlMaxWidth768 = window.matchMedia('(max-width: ' + (BASE_CONFIG.responsive.screen__768 - 1) + 'px)');
const mqlMobile = window.matchMedia('(max-width: ' + (BASE_CONFIG.responsive.screen__480 - 1) + 'px)');

/**
 * @returns {boolean} Returns true if current device screen is touchable
 */
function isPureTouchScreen() {
    return window.matchMedia('(hover: none)').matches;
}

/** isDesktop
 * @returns {boolean} is destkop
 */
var isDesktop = function () {
    return !mqlDesktop.matches;
};

/** isTablet
 * @returns {boolean} is tablet
 */
var isTablet = function () {
    return !mqlMaxWidth768.matches && !isDesktop();
};

/** isMobile
 * @returns {boolean} is mobile
 */
var isMobile = function () {
    return mqlMobile.matches;
};

/** isSmallerThan768
 * @returns {boolean} is smaller than 768px
 */
var isSmallerThan768 = function () {
    return mqlMaxWidth768.matches;
};

module.exports = {
    isPureTouchScreen: isPureTouchScreen,
    isDesktop: isDesktop,
    isTablet: isTablet,
    isMobile: isMobile,
    isSmallerThan768: isSmallerThan768,
    mqlDesktop: mqlDesktop,
    mqlMaxWidth768: mqlMaxWidth768,
    mqlMobile: mqlMobile
};
