/**
 * Redemptions and coupons
 * Includes Loyalty Redemptions and Coupon codes:
 *  - Cashback/Saldo puntos
 *  - Premium balance
 *  - Coupon codes
*/

const { delegateEvent } = require('../utilities/javascriptUtils');
const { checkValueIsEmpty } = require('../components/formControl');
const getBaseConfig = require('../common/getBaseConfig');
const javascriptUtils = require('../utilities/javascriptUtils');

const BASE_CONFIG = getBaseConfig();
const LOYALTY_MESSAGE_SELECTOR = '.promotion-information--loyalty';

/**
 * Opens Redemptions And Coupons block collapsible
 *  for desktop only
 *
 *  Note: replaced by direct activation in the template components/redemptionsAndCoupons.isml
 *  Probably future use for a ABTest
 */
/**
function openPromoCodeBlock() {
    const promoCodeTitle = document.querySelector('.redemptions-coupons .title');
    var deviceUtilities = require('../utilities/deviceUtilities');
    if (deviceUtilities.isDesktop() && promoCodeTitle) {
        $(promoCodeTitle).trigger('click');
    }
}
*/

/**
 * Updates form errors with given message or
 *  clears it if no message is given
 * @param {(string | null)} message message optional
 */
function updateFormError(message) {
    var couponErrors = document.querySelector('.redemptions-coupons-form .invalid-feedback');
    couponErrors.innerHTML = message || '';
}

/**
 * Hides the Loyalty Redemption Applied Message
 */
function hideRedemptionAppliedMessage() {
    var appliedMessageElement = document.querySelector(LOYALTY_MESSAGE_SELECTOR);
    if (appliedMessageElement) {
        appliedMessageElement.classList.add('d-none');
    }
}

/**
 * Shows the Loyalty Redemption Applied Message
 */
function showRedemptionAppliedMessage() {
    var appliedMessageElement = document.querySelector(LOYALTY_MESSAGE_SELECTOR);
    if (appliedMessageElement) {
        appliedMessageElement.classList.remove('d-none');
    }
}

/**
 * Handles form submit event listener
 * callback decides what custom event to throw
 *  - loyalty redemptions
 *  - coupon code
 */
function setSubmitEventListener() {
    delegateEvent('click', '.redemptions-coupons__submit-btn', function (event) {
        event.preventDefault();
        var selectedOption = document.querySelector('input[name=redemptionsAndCouponsRadioBtn]:checked')?.value;

        updateFormError();

        var form = document.querySelector('.redemptions-coupons-form');
        var isAdnOrder = form.dataset.isAdnOrder === 'true' || false;
        var customEventName;

        if (selectedOption === BASE_CONFIG.promotionType.COUPON) {
            customEventName = 'redemptionsAndCoupons:couponCode';
        } else if (selectedOption === BASE_CONFIG.loyalty.promotionType.cashback
            || selectedOption === BASE_CONFIG.loyalty.promotionType.premiumBalance
        ) {
            customEventName = 'redemptionsAndCoupons:loyaltyRedemptions';
        }

        if (isAdnOrder) {
            customEventName += '--AdnOrder';
        }

        javascriptUtils.triggerCustomEvent(document, customEventName);
    });
}

/**
 * Hanldes form Status based on the coupon code input status
 * @param {Element} [submitButton] submit button
 */
function handleButtonStatusForCoupons(submitButton) {
    var button = submitButton || document.querySelector('.redemptions-coupons__submit-btn');
    var codeInput = document.querySelector('.coupon-code-field');
    button.disabled = checkValueIsEmpty(codeInput);
}

/**
 * Hanldes form Status
 */
function handleFormStatus() {
    var submitButton = document.querySelector('.redemptions-coupons__submit-btn');
    var selectedOption = document.querySelector('input[name=redemptionsAndCouponsRadioBtn]:checked');

    if (submitButton.length <= 0) {
        return;
    }

    if (!selectedOption || selectedOption.length <= 0) {
        submitButton.disabled = true;
        return;
    }

    updateFormError();

    if (selectedOption.value === BASE_CONFIG.promotionType.COUPON) {
        handleButtonStatusForCoupons(submitButton);
    } else {
        submitButton.disabled = false;
    }
}

/**
 * Sets an event for when coupon code input y clicked,
 *  corresponding radio button is checked
 */
function setCouponCodeInputEvent() {
    delegateEvent('click', '.coupon-code-field', function () {
        // Select Coupon radio button
        var couponRadioButton = document.querySelector('.redemptions-coupons__input--coupon');
        if (couponRadioButton.length !== -1) {
            couponRadioButton.checked = true;
            handleButtonStatusForCoupons();
        }
    });
}

/**
 * Set event for radio buttons change, and handle form status
 *  if the selected option is coupon codes, checks the code input
 */
function setFormStatusEvents() {
    delegateEvent('change', '.redemptions-coupons__input', function () {
        handleFormStatus();
    });

    delegateEvent('keyup', '.coupon-code-field', function () {
        handleButtonStatusForCoupons();
    });
}

module.exports = {
    methods: {
        hideRedemptionAppliedMessage: hideRedemptionAppliedMessage,
        showRedemptionAppliedMessage: showRedemptionAppliedMessage,
        updateFormError: updateFormError
    },
    init: function () {
        setSubmitEventListener();
        setCouponCodeInputEvent();
        setFormStatusEvents();
    }
};
