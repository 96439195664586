const checkIfCurrentPage = require('../../helpers/checkIfCurrentPage');

/**
 * Gets the H1.page-title text formatted as a category list id
 * @example " Back to work " -> back_to_work
 * @example "Adolfo Domínguez | Web Oficial" -> adolfo_domínguez_|_web_oficial
 * @return {string} category list id based on main title
 */
function getCurrentCategoryListIdFromPageTitle() {
    let categoryListId;
    let $pageTitle = $('.page-title');

    if ($pageTitle.length === 0) return categoryListId;

    categoryListId = $pageTitle
        .text()
        .toLowerCase()
        .trim()
        .replace(/[ -]+/g, '_');

    return categoryListId;
}

/**
 * Get the current category list identifier from DOM
 * @returns {string} category identifier for datalayer list
 */
function getCurrentCategoryListIdFromDOM() {
    let categoryListId;
    // Use current category
    if (
        checkIfCurrentPage().isSearchPage
    ) {
        // Get the ID of the current category
        const currentCategoryId = document.querySelector('.js-current-category')
            ?.dataset.category;

        // Get the menu item for the current category
        const menuItemWithDatalayerInfo = document.querySelector(
            `[data-category="${currentCategoryId}"][data-category-list]`
        );
        if (menuItemWithDatalayerInfo) {
            categoryListId = menuItemWithDatalayerInfo.getAttribute('data-category-list');
        }

        if (!categoryListId) {
            /* When the category is not visible in the main menu...
             * Use the page title to build a list ID as fallback
             */
            categoryListId = getCurrentCategoryListIdFromPageTitle();
        }
    }
    return categoryListId;
}

module.exports = {
    getCurrentCategoryListIdFromDOM
};
