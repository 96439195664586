$('#country-selector-submit').on('click', function (e) {
    e.preventDefault();
    var href = $('#country-selector-submit').data('href');
    var country = $('option:selected', '#selector-country');
    var lang = $('option:selected', '#language').val();
    var url = '';
    var code = country.attr('code');

    if (country.attr('url')) {
        url = window.location.protocol + '//' + window.location.host + country.attr('url');

        if (country.attr('site') && country.attr('site') !== '') {
            url += lang + '_' + country.attr('site');
        }
    } else {
        url = country.attr('url-' + lang);
    }

    $.ajax({
        url: href,
        type: 'post',
        dataType: 'json',
        data: {
            countryCode: code
        },
        success: function () {
            window.location.href = url;
        }
    });
});
