const { isFunction } = require('../type');

const mixin = {
    /**
     * Get the raw data (POJO) of a rich object to store or transmit it
     * @returns {Object} Data Transfer Object
     */
    toDTO() {
        const model = this;
        const dto = Object.getOwnPropertyNames(model).reduce(
            (result, key) => {
                let value = model[key];
                const includeIntoDTO = !isFunction(value);
                if (includeIntoDTO) {
                    if (isFunction(value?.toDTO)) {
                        value = value.toDTO();
                    }
                    // eslint-disable-next-line no-param-reassign
                    result[key] = value;
                }
                return result;
            },
            {}
        );
        return dto;
    }
};

/**
 * Apply the mixin to be able to transform an object into a DTO (Data Transfer
 * Object), which in this case will be a POJO (Plain Old Javascript Object).
 * @param {Object} base Object to extend
 * @returns {Object} New object with new DTO-related methods
 */
function mixinDTO(base) {
    return Object.assign({}, base, mixin);
}

module.exports = mixinDTO;
