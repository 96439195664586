const getAnalyticsConfig = require('../../../common/getAnalyticsConfig');
const onDOMContentLoaded = require('../../../utilities/dom/onDOMContentLoaded');
const { existsElement } = require('../../../utilities/dom/query');
const {
    isHomePage, isCartPage, isProductDetailPage, isSearchPage, isExternalReferrer,
    isLandingPageOfCategory
} = require('../../../utilities/pageUtilities');

const analyticsConfig = getAnalyticsConfig();

const exists = (selector) => document.querySelector(selector) !== null;

const checkIfCurrentPage = (() => {
    let hasExternalReferrer = isExternalReferrer();
    const checkedCache = {
        isCartPage: undefined,
        isHomePage: undefined,
        isStorePage: {
            blackFriday: undefined
        },
        isLandingPageOfCategory: undefined,
        isProductDetailPage: undefined,
        isSearchPage: undefined,
        isSearchPageByCategory: false,
        isSearchPageByKeyword: false
    };
    let isReady = false;

    onDOMContentLoaded(() => {
        checkedCache.isCartPage = isCartPage();
        checkedCache.isHomePage = isHomePage();
        checkedCache.isStorePage.blackFriday = existsElement('.storepage#blackfriday');
        checkedCache.isLandingPageOfCategory = isLandingPageOfCategory();
        checkedCache.isProductDetailPage = isProductDetailPage();

        if (isSearchPage()) {
            checkedCache.isSearchPage = true;
            checkedCache.isSearchPageByCategory = exists(analyticsConfig.list.selectorsByType.searchByCategory);
            checkedCache.isSearchPageByKeyword = exists(analyticsConfig.list.selectorsByType.searchByKeyword);
        }
        isReady = true;
    });

    return () => {
        if (!isReady) {
            throw new Error(
                'Called too soon. Should wait to DOMContentLoaded.'
            );
        }

        return {
            hasExternalReferrer: hasExternalReferrer,
            isCartPage: checkedCache.isCartPage,
            isHomePage: checkedCache.isHomePage,
            isStorePage: checkedCache.isStorePage,
            isLandingPageOfCategory: checkedCache.isLandingPageOfCategory,
            isProductDetailPage: checkedCache.isProductDetailPage,
            isSearchPage: checkedCache.isSearchPage,
            isSearchPageByCategory: checkedCache.isSearchPageByCategory,
            isSearchPageByKeyword: checkedCache.isSearchPageByKeyword
        };
    };
})();

module.exports = checkIfCurrentPage;
